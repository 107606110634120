import { Box, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IInformationArticle } from '@rs/providers/interfaces/entities/IInformationArticle';
import ImagesView from '@rs/components/ImagesView';
import InformationArticleService from '@rs/services/InformationArticleService';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import { Sections } from '@rs/constants/Sections';
import parse from 'html-react-parser';
import { useApi } from '@rs/helpers/hooks';

export default function SingleArticle() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<IInformationArticle | undefined>(undefined);

  const getArticleRequest = useApi(InformationArticleService.getArticle);

  const loadData = useCallback(async () => {
    const item = await getArticleRequest(parseInt(id || ''));
    setData(item);
  }, [id]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <LoadPermissionsContainer section={Sections.informationArticles}>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 1,
              color: (t) => t.palette.primary.main,
              cursor: 'pointer',
              fontSize: (t) => t.typography.subtitle2.fontSize,
              fontWeight: 600,
            }}
          >
            <Link
              to='#'
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: (t) => t.typography.h5.fontSize, mr: 0.5 }} />
              Back
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              {data?.fld_title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ minWidth: '342px', display: 'flex', flexDirection: 'column' }}>
            <ImagesView files={data?.files} />
          </Box>
          <Box sx={{ pl: 3 }}>
            <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
              About
            </Typography>
            {parse(data?.fld_description || '')}
          </Box>
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
