import moment from 'moment';

export function dateToFromNowDaily(myDate: string | Date, todayOnly?: boolean) {
  const date =
    typeof myDate === 'string'
      ? /[0-9]+\/[0-9]+\/[0-9]+\s[0-9]+:[0-9]+/i.test(myDate)
        ? moment(myDate, 'DD/MM/YYYY HH:mm')
        : moment(myDate, 'YYYY-MM-DD HH:mm')
      : moment(myDate);

  const fromNow = date.fromNow();

  const config = todayOnly
    ? {
        // when the date is closer, specify custom values
        lastWeek: '[' + fromNow + ']',
        lastDay: '[' + fromNow + ']',
        sameDay: '[Today], h:mma',
        nextDay: '[' + fromNow + ']',
        nextWeek: '[' + fromNow + ']',
        // when the date is further away, use from-now functionality
        sameElse: function () {
          return '[' + fromNow + ']';
        },
      }
    : {
        // when the date is closer, specify custom values
        lastWeek: '[Last] dddd, h:mma',
        lastDay: '[Yesterday], h:mma',
        sameDay: '[Today], h:mma',
        nextDay: '[Tomorrow], h:mma',
        nextWeek: '[Next] dddd, h:mma',
        // when the date is further away, use from-now functionality
        sameElse: function () {
          return '[' + fromNow + ']';
        },
      };

  return date.calendar(null, config);
}

export function get12hTime(hour: number, minute: number) {
  return moment(new Date(2022, 1, 1, hour, minute)).format('hh:mm a');
}
