import { AuthState } from '@rs/reducers/authSlice';
import User from '@rs/providers/models/User';
import { createTransform } from 'reduxjs-toolkit-persist';

const AuthReducerTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: AuthState) => {
    return {
      ...inboundState,
      user: { ...inboundState.user },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sections: inboundState.sections.map(({ permissions, ...other }) => other),
    };
  },
  // transform state being rehydrated
  (outboundState: AuthState) => {
    return { ...outboundState, user: new User(outboundState.user) };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['auth'] },
);

export default AuthReducerTransform;
