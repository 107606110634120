/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import { IConversation } from './interfaces/entities/IConversation';
import { IConversationFilter } from '@rs/providers/interfaces/filters/IConversationFilter';
import { ICreateConversationRequest } from './interfaces/requests/ICreateConversationRequest';
import { IGetConversationResponse } from './interfaces/responses/IGetConversationResponse';
import { IUpdateConversationStatusRequest } from './interfaces/requests/IUpdateConversationStatusRequest';

export default class ConversationProvider extends BaseProvider {
  getConversations = async (limit?: number, offset?: number, filters?: IConversationFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_uid !== undefined) {
      filtersArray.push(`conversation.fld_uid = ${filters?.fld_uid}`);
    }
    if (filters?.fld_resident_id !== undefined) {
      filtersArray.push(`conversation.fld_resident_id = ${filters?.fld_resident_id}`);
    }
    if (filters?.fld_conversation_type_id !== undefined) {
      filtersArray.push(
        `conversation.fld_conversation_type_id = ${filters?.fld_conversation_type_id}`,
      );
    }

    return this.get<IGetConversationResponse>(`${this.getBaseApiUrl()}/rs/conversations/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
  createConversation = async (value: Partial<IConversation>) => {
    const { fld_resident_id, fld_conversation_type_id, fld_title, fld_status } = value;
    return this.post<
      ICreateConversationRequest,
      {
        conversation_id: number;
      }
    >(
      `${this.getBaseApiUrl()}/rs/conversations/`,
      {
        resident_id: fld_resident_id,
        conversation_type_id: fld_conversation_type_id,
        title: fld_title,
        status: fld_status,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
  updateConversationStatus = async (id: number, status: ConversationStatuses) => {
    return this.post<IUpdateConversationStatusRequest, void>(
      `${this.getBaseApiUrl()}/rs/conversations/${id}/`,
      {
        status,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
}
