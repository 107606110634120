/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState } from 'react';

import { Alert, Box, Dialog, DialogContent, IconButton, Theme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from '@rs/helpers/hooks';
import ParcelService from '@rs/services/ParcelService';
import { IParcel } from '@rs/providers/interfaces/entities/IParcel';
import { IParcelHistory } from '@rs/providers/interfaces/entities/IParcelHistory';
import { ParcelStatuses } from '@rs/constants/ParcelStatuses';
import moment from 'moment';

interface IProps {
  item?: IParcel;
  open: boolean;
  onClose: () => void;
}

export default function HistoryDialog(props: IProps) {
  const { onClose, open, item } = props;

  const [history, setHistory] = useState<IParcelHistory[]>([]);
  const getParcelHistoryRequest = useApi(ParcelService.getParcelHistory);

  const loadData = useCallback((parcelId: number) => {
    (async () => {
      const items =
        (await getParcelHistoryRequest(undefined, undefined, { fld_parcel_id: [parcelId] })) || [];
      setHistory(items);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadData(item?.fld_uid || 1);
    })();
  }, [item]);

  const getStatusAlertType = (status: ParcelStatuses) => {
    switch (status) {
      case ParcelStatuses.received: {
        return 'warning';
      }
      case ParcelStatuses.collected: {
        return 'success';
      }
      case ParcelStatuses.returned: {
        return 'error';
      }
    }
  };

  console.log(history);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='sm'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            History
          </Typography>

          <IconButton
            onClick={() => onClose()}
            sx={{
              color: (t: Theme) => t.palette.grey[700],
            }}
            aria-label='Return'
          >
            <CloseIcon sx={{ fontSize: '2.5rem' }} />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 5 }}>
          {history.map((item) => (
            <Box
              key={item.fld_uid}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 2,
                mb: 2,
                borderBottom: 1,
                borderColor: (t: Theme) => t.palette.grey[100],
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Alert
                  sx={{
                    mr: 2,
                    py: 0,
                    '& .MuiAlert-message': {
                      py: 0,
                    },
                    '& .MuiTypography-root': {
                      lineHeight: '2rem',
                    },
                  }}
                  icon={false}
                  severity={getStatusAlertType(item.fld_status)}
                >
                  <Typography variant='overline'>{item.fld_status}</Typography>
                </Alert>
                <Box>
                  <Typography variant='body2'>
                    {moment(item.fld_action_date, 'DD/MM/YYYY HH:mm').format(
                      'HH:mm a, DD MMMM YYYY',
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant='subtitle1'>{item.fld_person_name}</Typography>
                <Typography variant='caption'>{item.fld_person_role || item.fld_phone}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
