/* eslint-disable camelcase */

import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  Fab,
  FormControlLabel,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { ConversationOptionFieldTypes } from '@rs/constants/ConversationOptionFieldTypes';
import ConversationService from '@rs/services/ConversationService';
import { ConversationTypes } from '@rs/constants/ConversationTypes';
import { IConversationOption } from '@rs/providers/interfaces/entities/IConversationOption';
import { IConversationOptionField } from '@rs/providers/interfaces/entities/IConversationOptionField';
import { IConversationOptionFieldType } from '@rs/providers/interfaces/entities/IConversationOptionFieldType';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import errorHandler from '@rs/helpers/errorHandler';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  item?: IConversationOption;
  open: boolean;
  onClose: (data?: Partial<IResident>) => void;
  getFieldTypeLabel: (type: string) => string;
  conversationOptionFieldTypes: IConversationOptionFieldType[];
}

interface FormData {
  name: string;
  type: ConversationTypes;
  fields: {
    id?: number;
    label: string;
    isRequired: boolean;
    type: ConversationOptionFieldTypes;
  }[];
}

export default function ManageRequestTypeDialog(props: IProps) {
  const { onClose, open, item, getFieldTypeLabel } = props;
  const alert = useAlert();
  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [conversationTypes, setConversationTypes] = useState<IConversationType[]>([]);

  const formDefaultData = useMemo<FormData>(
    () => ({
      name: item?.fld_name || '',
      type: item?.conversation_type?.fld_name || ConversationTypes.request,
      fields:
        item?.conversation_option_fields?.map((x) => ({
          id: x.fld_uid,
          label: x.fld_label,
          isRequired: x.fld_required,
          type:
            props.conversationOptionFieldTypes.find(
              (ft) => ft.fld_uid === x.fld_conversation_option_field_type_id,
            )?.fld_name || ConversationOptionFieldTypes.string,
        })) || [],
    }),
    [item],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const { fields, append, remove } = useFieldArray({ name: 'fields', control: form.control });

  useEffect(() => {
    errorHandler(
      async () => {
        const conversationTypes = await ConversationService.getConversationTypes();
        setConversationTypes(conversationTypes);
      },
      alert,
      navigate,
    );
  }, []);

  useEffect(() => {
    form.reset(formDefaultData);
  }, [item]);

  const onCancel = () => {
    onClose();
    form.reset(formDefaultData);
  };

  const onTogglePopover = () => {
    setOpenPopover((prevOpen) => !prevOpen);
  };

  const onClosePopover = (type?: IConversationOptionFieldType) => {
    if (type) {
      append({
        type: type.fld_name,
        label: '',
        isRequired: false,
      });
    }
    setOpenPopover(false);
  };

  const onSave = () => {
    const formData = form.getValues();

    const conversationType = conversationTypes.find((x) => x.fld_name === formData.type);

    const updatedItem: Partial<IConversationOption> = {
      ...(item || {}),
      ...{
        // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fld_conversation_type_id: conversationType!.fld_uid,
        fld_name: formData.name,
        conversation_option_fields: formData.fields.map((x, i) => ({
          fld_label: x.label,
          fld_required: x.isRequired,
          // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fld_conversation_option_field_type_id: props.conversationOptionFieldTypes.find(
            (c) => c.fld_name === x.type,
          )!.fld_uid,
          fld_uid: x.id,
          fld_conversation_option_id: item?.fld_uid,
          fld_order: i,
        })) as IConversationOptionField[],
      },
    };

    onClose(updatedItem);
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      fullWidth
      maxWidth='sm'
      sx={{
        borderRadius: 0,
        '& .MuiDialog-paper': {
          maxWidth: 680,
        },
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {item ? 'Edit' : 'New'}
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              type='submit'
              form='form'
              color='primary'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              {item ? 'Save & Close' : 'Create'}
            </Button>
          </Box>
        </Box>
        <Box>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '2rem',
                pb: 5,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: (t: Theme) => t.palette.grey[100],
              }}
            >
              <Box sx={{ mt: 5 }}>
                <Typography variant='h6'>Name:</Typography>
                <Controller
                  name={'name'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the name',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ mt: 2 }}
                      variant='outlined'
                      error={!!formState.errors.name}
                      helperText={formState.errors.name?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  mt: 5,
                }}
              >
                <Typography variant='h6'>Type:</Typography>
                <Box sx={{ mt: 3 }}>
                  <Controller
                    name={'type'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup row name='type' value={value.toString()} onChange={onChange}>
                        <FormControlLabel
                          value={ConversationTypes.request}
                          control={<Radio />}
                          label={<Typography variant='body2'>Request</Typography>}
                        />
                        <FormControlLabel
                          value={ConversationTypes.issue}
                          control={<Radio />}
                          label={<Typography variant='body2'>Issue</Typography>}
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Form fields</Typography>
              {fields.map(
                (x, i) =>
                  x.type && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '1rem',
                        py: 2,
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: (t: Theme) => t.palette.grey[100],
                      }}
                      key={x.id || uuidv4()}
                    >
                      <Fab sx={{ boxShadow: 'none' }} color='primary' size='small'>
                        {i + 1}
                      </Fab>
                      <Controller
                        name={`fields.${i}.label`}
                        control={form.control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Please enter the label name',
                          },
                        }}
                        render={({ field: { onChange, value }, formState }) => (
                          <TextField
                            variant='outlined'
                            error={
                              formState.errors.fields ? !!formState.errors.fields[i]?.label : false
                            }
                            helperText={
                              formState.errors.fields
                                ? formState.errors.fields[i]?.label?.message
                                : undefined
                            }
                            onChange={onChange}
                            value={value}
                            label={getFieldTypeLabel(fields[i].type)}
                          />
                        )}
                      />
                      <Controller
                        name={`fields.${i}.isRequired`}
                        control={form.control}
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            row
                            name='type'
                            value={value.toString()}
                            onChange={(_, value) => onChange(value === 'true')}
                          >
                            <FormControlLabel
                              value='false'
                              control={<Radio />}
                              label={<Typography variant='body2'>optional</Typography>}
                            />
                            <FormControlLabel
                              value='true'
                              control={<Radio />}
                              label={<Typography variant='body2'>required</Typography>}
                            />
                          </RadioGroup>
                        )}
                      />
                      <IconButton
                        size='large'
                        onClick={() => remove(i)}
                        sx={{ ml: 2, p: 0, color: (t) => t.palette.grey[600] }}
                        aria-label='Remove'
                      >
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  ),
              )}
              <Box
                sx={{
                  mt: 5,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Fab
                  ref={anchorRef}
                  size='small'
                  color='primary'
                  id='composition-button'
                  aria-controls={openPopover ? 'composition-menu' : undefined}
                  aria-expanded={openPopover ? 'true' : undefined}
                  aria-haspopup='true'
                  onClick={onTogglePopover}
                >
                  <AddIcon />
                </Fab>
                <Popper
                  sx={{
                    pb: 1,
                    zIndex: 1,
                    '& .MuiPaper-root': {
                      boxShadow:
                        '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
                    },
                  }}
                  open={openPopover}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement='top'
                  transition
                  disablePortal
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={() => onClosePopover()}>
                          <MenuList
                            autoFocusItem={openPopover}
                            id='composition-menu'
                            aria-labelledby='composition-button'
                          >
                            {props.conversationOptionFieldTypes.map((x) => (
                              <MenuItem key={x.fld_uid} onClick={() => onClosePopover(x)}>
                                {getFieldTypeLabel(x.fld_name)}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
