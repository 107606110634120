import { Box, TextField, Typography } from '@mui/material';
import { selectGeneralLoader, setLoader } from '@rs/reducers/generalSlice';
import {
  useApi,
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useInput,
  useUpdateEffect,
} from '@rs/helpers/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import BookingDialog from './components/BookingDialog';
import BookingService from '@rs/services/BookingService';
import { BookingStatuses } from '@rs/constants/BookingStatuses';
import { IBooking } from '@rs/providers/interfaces/entities/IBooking';
import { Link } from 'react-router-dom';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import Lottie from 'lottie-react';
import MyBookingsListItem from '@rs/pages/resident/bookings/components/MyBookingsListItem';
import { Sections } from '@rs/constants/Sections';
import emptyBookingsAnimation from './animations/empty-bookings.json';
import searchInputStyles from '@rs/styles/searchInput';
import { selectAuthUser } from '@rs/reducers/authSlice';

export default function MyBookings() {
  const loading = useAppSelector(selectGeneralLoader);
  const user = useAppSelector(selectAuthUser);
  const dispatch = useAppDispatch();
  const [page] = useState(0);
  const pageSize = useMemo(() => 100, []);
  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, 1000);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState<IBooking>();

  const [data, setData] = useState<{ count: number; items: IBooking[] }>({
    count: 0,
    items: [],
  });
  const getBookingsRequest = useApi(BookingService.getBookings);
  const updateBookingRequest = useApi(BookingService.updateBooking);

  const loadData = useCallback(
    async (reset: boolean) => {
      if (!user?.residentId) return;
      dispatch(setLoader(true));
      const filters = {
        // eslint-disable-next-line camelcase
        fld_resident_id: user.residentId,
        // eslint-disable-next-line camelcase
        search_term: search,
      };
      const bookingResponse = await getBookingsRequest(pageSize, page * pageSize, filters);

      if (reset) {
        setData({ count: bookingResponse?.count || 0, items: bookingResponse?.items || [] });
      } else {
        setData((prevData) => ({
          count: bookingResponse?.count || 0,
          items: [...prevData.items, ...(bookingResponse?.items || [])],
        }));
      }
    },
    [page, search, user],
  );

  const onOpenDialog = (booking: IBooking) => {
    setActiveItem(booking);
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<IBooking>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      if (!activeItem?.fld_uid) return;
      await updateBookingRequest(activeItem.fld_uid, item);
      await loadData(true);
      setOpenDialog(false);
    }
    setActiveItem(undefined);
  };

  const onBookingCancel = async (booking: IBooking) => {
    if (!booking?.fld_uid) return;
    const updateBooking = {
      ...booking,
      // eslint-disable-next-line camelcase
      fld_booking_status: BookingStatuses.cancelled,
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await updateBookingRequest(booking.fld_uid, updateBooking);
    await loadData(true);
  };

  useEffect(() => {
    loadData(true);
  }, []);

  useUpdateEffect(() => {
    loadData(true);
  }, [search]);

  return (
    <LoadPermissionsContainer section={Sections.bookings}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' sx={{ mr: 5, minWidth: 'fit-content' }}>
              My Bookings
            </Typography>
            <TextField
              sx={{ ...searchInputStyles, ...{ width: '100%', m: 0 } }}
              onChange={searchInput.onChange}
              value={searchInput.value}
              placeholder='Search'
              variant='outlined'
              margin='normal'
            />
          </Box>
        </Box>
        <Box
          height={'100%'}
          sx={
            data.items.length === 0
              ? { display: 'flex', alignItems: 'center', justifyContent: 'center' }
              : {}
          }
        >
          {!loading && (
            <>
              {data.items && data.items.length > 0 ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
                    Ongoing
                  </Typography> */}
                  {data.items &&
                    data.items.length > 0 &&
                    data.items.map((booking, index) => (
                      <MyBookingsListItem
                        key={index}
                        data={booking}
                        openDialog={() => onOpenDialog(booking)}
                        cancelBooking={() => onBookingCancel(booking)}
                      />
                    ))}
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Lottie
                    animationData={emptyBookingsAnimation}
                    loop={true}
                    style={{ maxWidth: '240px' }}
                  />
                  <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
                    No bookings
                  </Typography>
                  <Link to='/resident-bookings'>book now</Link>
                </Box>
              )}
            </>
          )}
          <BookingDialog
            isEdit={true}
            booking={activeItem}
            item={activeItem?.booking_type}
            open={openDialog}
            onClose={onCloseDialog}
          />
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
