import { Box, CssBaseline } from '@mui/material';
import { selectAuthSections, selectAuthUser } from '@rs/reducers/authSlice';

import Copyright from '@rs/components/Copyright';
import { Outlet } from 'react-router-dom';
import SideBar from '@rs/components/SideBar';
import { useAppSelector } from '@rs/helpers/hooks';

const sideBarWidth = 310;

export default function AuthenticatedContainer() {
  const user = useAppSelector(selectAuthUser);
  const sections = useAppSelector(selectAuthSections);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {user && sections.length ? <SideBar width={310} /> : null}
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
          marginLeft: `${sideBarWidth}px`,
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Outlet />
        <Copyright sx={{ mt: 3, textAlign: 'center' }} />
      </Box>
    </Box>
  );
}
