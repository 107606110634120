import { ThemeOptions } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import defaultLogo from "../assets/logo.png";

export interface GeneralState {
  loader: boolean;
  theme: ThemeOptions;
  logo: string;
  activeUser?: {
    email: string;
    password: string;
  };
}

const defaultTheme = {
  palette: {
    primary: {
      main: "#007dff",
      dark: "#0063ca",
      contrastText: "#fff",
    },
    secondary: {
      main: "#03dac5",
      dark: "#018786",
      contrastText: "#000",
    },
    error: {
      main: "#FF505F",
      contrastText: "#fff",
    },
    success: {
      main: "#4CAF50",
      contrastText: "#fff",
    },
    tonalOffset: 0.2,
    text: {
      primary: "#25303C",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h1: {
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: "7rem",
      letterSpacing: "-0.09375rem",
    },
    h2: {
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: "4.5rem",
      letterSpacing: "-0.03125rem",
    },
    h3: {
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: "3.5rem",
    },
    h4: {
      fontWeight: 700,
      fontSize: "2.125rem",
      lineHeight: "2.25rem",
    },
    h5: {
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.01125rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.009375rem",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.009375rem",
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.00625rem",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.03125rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.015625rem",
    },
    button: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1rem",
      letterSpacing: "0.078125rem",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "1rem",
      letterSpacing: "0.025rem",
    },
    overline: {
      fontWeight: 600,
      fontSize: "0.625rem",
      lineHeight: "1rem",
      letterSpacing: "0.09375rem",
    },
  },
};

const initialState: GeneralState = {
  loader: false,
  theme: defaultTheme,
  logo: defaultLogo,
  activeUser: undefined,
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setActiveUser: (
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) => {
      state.activeUser = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setStyle: (
      state,
      action: PayloadAction<{
        logo: string;
        primaryColor: string;
        onPrimaryColor: string;
        secondaryColor: string;
        onSecondaryColor: string;
      }>
    ) => {
      state.logo = action.payload.logo;
      const newPalette = {
        ...state.theme.palette,
        primary: {
          main: action.payload.primaryColor,
          contrastText: action.payload.onPrimaryColor,
        },
        secondary: {
          main: action.payload.secondaryColor,
          contrastText: action.payload.onSecondaryColor,
        },
      };
      state.theme = { ...state.theme, palette: newPalette };
    },
    setLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    resetStyle: (state) => {
      state.theme = defaultTheme;
      state.logo = defaultLogo;
    },
  },
});

export const { setLoader, setStyle, resetStyle, setLogo, setActiveUser } =
  generalSlice.actions;

export const selectGeneralLoader = (state: RootState) => state.general.loader;
export const selectGeneralTheme = (state: RootState) => state.general.theme;
export const selectGeneralLogo = (state: RootState) => state.general.logo;
export const selectActiveUser = (state: RootState) => state.general.activeUser;

export default generalSlice.reducer;
