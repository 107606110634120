/* eslint-disable camelcase */

import 'react-quill/dist/quill.snow.css';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileUploader from '@rs/components/FileUploader';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import ImageUploader from '@rs/components/ImageUploader';
import ReactQuill from 'react-quill';
import SaveIcon from '@mui/icons-material/Save';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import quillEditorStyles from '@rs/styles/quillEditor';
import { useUpdateEffect } from '@rs/helpers/hooks';

interface IProps {
  item?: IApartment;
  type?: string;
  open: boolean;
  buildingOptions: ISelectOption[];
  apartmentTypeOptions: ISelectOption[];
  apartmentGroupOptions: ISelectOption[];
  onDelete: () => void;
  onClose: (data?: Partial<IApartment>) => void;
}

interface FormData {
  apartmentTypeId: string;
  apartmentGroupId: string;
  name: string;
  order: string;
  buildingId: string;
  floor: string;
  description: string;
}

export default function ManageApartmentDialog(props: IProps) {
  const {
    onClose,
    open,
    item,
    type,
    onDelete,
    buildingOptions,
    apartmentTypeOptions,
    apartmentGroupOptions,
  } = props;

  const [floorOptions, setFloorOptions] = useState<ISelectOption[]>([]);
  const [files, setFiles] = useState<UploaderFile[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<UploaderFile[]>([]);

  const formDefaultData = useMemo(
    () => ({
      buildingId: item?.fld_building_id.toString() || '',
      apartmentTypeId: item?.fld_apartment_type_id.toString() || '',
      apartmentGroupId: item?.fld_apartment_group_id.toString() || '',
      name: item?.fld_apartment_name || '',
      order: item?.fld_apartment_order.toString() || '',
      floor: item?.fld_floor.toString() || '',
      description: item?.fld_description || '',
    }),
    [item],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const watchBuildingId = form.watch('buildingId');
  const watchFloor = form.watch('floor');

  useEffect(() => {
    form.reset(formDefaultData);
  }, [item]);

  useEffect(() => {
    const selectedBuilding = buildingOptions.find((x) => x.value === watchBuildingId);
    const floors = Number(selectedBuilding?.additional);
    if (floors > 0) {
      setFloorOptions([
        ...Array(floors)
          .fill(0)
          .map((x, index) => ({ value: (index + 1).toString(), label: (index + 1).toString() })),
      ]);
    }
  }, [buildingOptions, watchBuildingId]);

  useUpdateEffect(() => {
    if (floorOptions.length) {
      const selectedFloor = floorOptions.find((x) => x.value === watchFloor);
      if (!selectedFloor) {
        form.setValue('floor', '');
      }
    }
  }, [floorOptions, watchFloor]);

  const onCancel = () => {
    onClose();
  };

  const onRemove = () => {
    onDelete();
  };

  const onSave = () => {
    const formData = form.getValues();

    const updatedItem: Partial<IApartment> = {
      fld_building_id: parseInt(formData.buildingId),
      fld_apartment_type_id: parseInt(formData.apartmentTypeId),
      fld_apartment_group_id: parseInt(formData.apartmentGroupId),
      fld_apartment_name: formData.name,
      fld_apartment_order: parseInt(formData.order),
      fld_floor: parseInt(formData.floor),
      fld_description: formData.description,
    };

    updatedItem.files = item?.files || [];
    updatedItem.updatedFiles = files;
    updatedItem.attachedFiles = item?.attachedFiles || [];
    updatedItem.updatedAttachedFiles = attachedFiles;

    onClose(updatedItem);
  };

  const images = useMemo(() => item?.files || [], [item]);
  const attached = useMemo(() => item?.attachedFiles || [], [item]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {item ? 'Edit' : 'New'}
          </Typography>
          <Box>
            {type === 'edit' && (
              <Button
                variant='text'
                onClick={() => onRemove()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                  ml: 2,
                  color: (t: Theme) => t.palette.error.main,
                }}
              >
                <DeleteForeverIcon sx={{ mr: 0.5 }} />
                Remove
              </Button>
            )}
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              type='submit'
              form='form'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              {type === 'edit' && <SaveIcon sx={{ fontSize: '1rem', mr: 0.5 }} />}
              {item ? 'Save changes' : 'Create'}
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              if (!files.length) {
                console.error('Please upload at least 1 image');
                return;
              }
              onSave();
            })}
            noValidate
          >
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Audience:</Typography>
              <Box sx={{ display: 'flex' }}>
                <Controller
                  name={'apartmentTypeId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the apartment type',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <FormControl sx={{ width: '50%', mr: 5, mt: 2 }} variant='standard'>
                      <InputLabel id='type-checkbox-label'>Apartment Type</InputLabel>
                      <Select
                        labelId='type-checkbox-label'
                        id='type-checkbox'
                        value={value}
                        onChange={onChange}
                        renderValue={(selected) =>
                          apartmentTypeOptions.find((x) => x.value === selected)?.label
                        }
                        error={!!formState.errors.apartmentTypeId}
                      >
                        {apartmentTypeOptions.map((item) => (
                          <MenuItem
                            sx={{
                              px: 1,
                              py: 0,
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Radio size='small' checked={value === item.value} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name={'apartmentGroupId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the apartment group',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <FormControl sx={{ width: '50%', mt: 2 }} variant='standard'>
                      <InputLabel id='group-checkbox-label'>Apartment Group</InputLabel>
                      <Select
                        labelId='group-checkbox-label'
                        id='group-checkbox'
                        value={value}
                        onChange={onChange}
                        renderValue={(selected) =>
                          apartmentGroupOptions.find((x) => x.value === selected)?.label
                        }
                        error={!!formState.errors.apartmentGroupId}
                      >
                        {apartmentGroupOptions.map((item) => (
                          <MenuItem
                            sx={{
                              px: 1,
                              py: 0,
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Radio size='small' checked={value === item.value} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Picture:</Typography>
              <Box sx={{ mt: 2 }}>
                <ImageUploader
                  maxAmount={4}
                  images={images}
                  onImagesUpdated={(items) => {
                    setFiles(items);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridGap: '2.5rem',
                mt: 5,
              }}
            >
              <Box>
                <Typography variant='h6'>Apartment name</Typography>
                <Controller
                  name={'name'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the apartment name',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Name'
                      variant='outlined'
                      error={!!formState.errors.name}
                      helperText={formState.errors.name?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography variant='h6'>Apartment №</Typography>
                <Controller
                  name={'order'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the order',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Number'
                      variant='outlined'
                      error={!!formState.errors.order}
                      helperText={formState.errors.order?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Attachments:
                </Typography>
                <FileUploader
                  maxAmount={4}
                  attachedFiles={attached}
                  onFilesUpdated={(items) => {
                    setAttachedFiles(items);
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Location</Typography>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '33%', mr: 5 }}>
                  <Controller
                    name={'buildingId'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the building',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <FormControl sx={{ width: '100%', mt: 3, mr: 5 }} variant='standard'>
                        <InputLabel id='building-checkbox-label'>Building</InputLabel>
                        <Select
                          labelId='building-checkbox-label'
                          id='building-checkbox'
                          value={value}
                          onChange={onChange}
                          renderValue={(selected) =>
                            buildingOptions.find((x) => x.value === selected)?.label
                          }
                          error={!!formState.errors.buildingId}
                        >
                          {buildingOptions.map((item) => (
                            <MenuItem
                              sx={{
                                px: 1,
                                py: 0,
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Radio size='small' checked={value === item.value} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <Controller
                    name={'floor'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the floor',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <FormControl sx={{ width: '100%', mt: 3 }} variant='standard'>
                        <InputLabel id='building-checkbox-label'>Floor</InputLabel>
                        <Select
                          labelId='building-checkbox-label'
                          id='building-checkbox'
                          value={value}
                          onChange={onChange}
                          renderValue={(selected) =>
                            floorOptions.find((x) => x.value === selected)?.label
                          }
                          error={!!formState.errors.floor}
                        >
                          {floorOptions.map((item) => (
                            <MenuItem
                              sx={{
                                px: 1,
                                py: 0,
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Radio size='small' checked={value === item.value} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Info</Typography>
              <Box>
                <Controller
                  name={'description'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the description',
                    },
                    maxLength: {
                      value: 1024,
                      message: 'Max length should be less or equal 1024 characters',
                    },
                    validate: {
                      required: (value) => {
                        const textValue = value.replace(/<[^>]+>/g, '');
                        if (!textValue) return 'Please enter the description';
                      },
                      maxLength: (value) => {
                        const textValue = value.replace(/<[^>]+>/g, '');
                        if (textValue.length > 1024)
                          return 'Max length should be less or equal 1024 characters';
                      },
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <Box
                      sx={{
                        mt: 2,
                        ...quillEditorStyles,
                      }}
                    >
                      <ReactQuill
                        theme='snow'
                        onChange={onChange}
                        value={value}
                        modules={{
                          toolbar: [
                            [
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'link',
                              { list: 'ordered' },
                              { list: 'bullet' },
                              'blockquote',
                              'clean',
                            ],
                          ],
                          clipboard: {
                            matchVisual: false,
                          },
                        }}
                        placeholder='Enter apartment description here. Max length 1024 characters.'
                      />
                      <TextField
                        error={!!formState.errors.description}
                        helperText={formState.errors.description?.message}
                        value={value}
                        type='hidden'
                        sx={{
                          '& fieldset': {
                            display: 'none',
                          },
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
