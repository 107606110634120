/* eslint-disable camelcase */

import axios, { AxiosRequestConfig } from 'axios';

import { store } from '../store';

export default class BaseProvider {
  protected getBaseFilesUrl = () => {
    const state = store.getState();
    const { apiUrl, dataBaseInstance } = state.auth;
    return `${apiUrl?.replace('/api/v1', '')}/${dataBaseInstance}/files`;
  };

  protected getBaseApiUrl = () => {
    const state = store.getState();
    const { apiUrl, deploymentId, activeZone } = state.auth;

    return `${apiUrl}/deployment/${deploymentId}/zone/${activeZone?.id || ''}`;
  };

  protected getZoneId = () => {
    const state = store.getState();
    const { activeZone } = state.auth;

    return activeZone;
  };

  protected getApiDomain = () => {
    const state = store.getState();
    const { apiUrl } = state.auth;

    return apiUrl?.replace('/api/v1', '');
  };

  protected getAuthToken = () => {
    const state = store.getState();
    const { token } = state.auth;

    return token || '';
  };

  protected getSections = () => {
    const state = store.getState();
    const { sections } = state.auth;

    return sections;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected get = async <T>(url: string, config?: AxiosRequestConfig<any> | undefined) => {
    const result = await axios.get<T | { error: string } | { message: string }>(url, config);
    if (result.status === 200 && !!(result.data as { error: string }).error) {
      throw new Error((result.data as { error: string }).error);
    } else if (result.status === 200 && !!(result.data as { message: string }).message) {
      throw new Error((result.data as { message: string }).message);
    }
    return result.data as T;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected delete = async (url: string, config?: AxiosRequestConfig<any> | undefined) => {
    const result = await axios.delete<void | { error: string } | { message: string }>(url, config);
    if (result.status === 200 && !!(result.data as { error: string }).error) {
      throw new Error((result.data as { error: string }).error);
    } else if (result.status === 200 && !!(result.data as { message: string }).message) {
      throw new Error((result.data as { message: string }).message);
    }
  };

  protected post = async <T, T2>(
    url: string,
    data: Partial<T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: AxiosRequestConfig<any> | undefined,
  ) => {
    const result = await axios.post<T2 | { error: string } | { message: string }>(
      url,
      data,
      config,
    );
    if (result.status === 200 && !!(result.data as { error: string }).error) {
      throw new Error((result.data as { error: string }).error);
    } else if (result.status === 200 && !!(result.data as { message: string }).message) {
      throw new Error((result.data as { message: string }).message);
    }
    return result.data as T2;
  };
}
