/* eslint-disable camelcase */

import 'react-quill/dist/quill.snow.css';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileUploader from '@rs/components/FileUploader';
import { IEvent } from '@rs/providers/interfaces/entities/IEvent';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import ImageUploader from '@rs/components/ImageUploader';
import ReactQuill from 'react-quill';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import moment from 'moment';
import quillEditorStyles from '@rs/styles/quillEditor';
import { useAlert } from 'react-alert';

interface IProps {
  item?: IEvent;
  buildingOptions: ISelectOption[];
  apartmentTypeOptions: ISelectOption[];
  apartmentGroupOptions: ISelectOption[];
  open: boolean;
  onClose: (data?: Partial<IEvent>) => void;
}

interface FormData {
  title: string;
  description: string;
  buildingId: string;
  apartmentTypeId: string;
  apartmentGroupId: string;
  publishDate: string;
  publishTime: string;
  eventDate: string;
  eventStartTime: string;
  eventEndTime: string;
  location: string;
}

export default function ManageEventsDialog(props: IProps) {
  const { onClose, open, item, buildingOptions, apartmentTypeOptions, apartmentGroupOptions } =
    props;
  const alert = useAlert();

  const [files, setFiles] = useState<UploaderFile[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<UploaderFile[]>([]);

  const formDefaultData = useMemo(
    () => ({
      title: item?.fld_heading || '',
      description: item?.fld_description || '',
      buildingId:
        String(item?.events_relations ? item?.events_relations[0].fld_building_id : '') || '',
      apartmentTypeId:
        String(item?.events_relations ? item?.events_relations[0].fld_apartment_type_id : '') || '',
      apartmentGroupId:
        String(item?.events_relations ? item?.events_relations[0].fld_apartment_group_id : '') ||
        '',
      publishDate: moment(item?.fld_publish_date, 'YYYY-MM-DD HH:mm').format() || '',
      publishTime: moment(item?.fld_publish_date, 'YYYY-MM-DD HH:mm').format() || '',
      eventDate: moment(item?.fld_date_start, 'YYYY-MM-DD HH:mm').format() || '',
      eventStartTime: moment(item?.fld_date_start, 'YYYY-MM-DD HH:mm').format() || '',
      eventEndTime: moment(item?.fld_date_end, 'YYYY-MM-DD HH:mm').format() || '',
      location: item?.fld_location,
    }),
    [item],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  useEffect(() => {
    form.reset(formDefaultData);
  }, [item]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    let updatedItem: Partial<IEvent>;
    const formData = form.getValues();
    const publishDate = moment(
      `${moment.utc(formData.publishDate).format('YYYY-MM-DD')} ${moment
        .utc(formData.publishTime)
        .format('HH:mm:ss z')}`,
    ).format('DD/MM/YYYY HH:mm');

    const eventStartDate = moment(
      `${moment.utc(formData.eventDate).format('YYYY-MM-DD')} ${moment
        .utc(formData.eventStartTime)
        .format('HH:mm:ss z')}`,
    ).format('DD/MM/YYYY HH:mm');
    const eventEndDate = moment(
      `${moment.utc(formData.eventDate).format('YYYY-MM-DD')} ${moment
        .utc(formData.eventEndTime)
        .format('HH:mm:ss z')}`,
    ).format('DD/MM/YYYY HH:mm');

    if (item) {
      updatedItem = { ...item };
      updatedItem.fld_all_day = false;
      updatedItem.fld_heading = formData.title;
      updatedItem.fld_description = formData.description;
      updatedItem.fld_publish_date = publishDate;
      updatedItem.fld_date_start = eventStartDate;
      updatedItem.fld_date_end = eventEndDate;
      updatedItem.fld_location = formData.location;
      updatedItem.events_relations = [
        {
          fld_building_id: parseInt(formData.buildingId),
          fld_apartment_type_id: parseInt(formData.apartmentTypeId),
          fld_apartment_group_id: parseInt(formData.apartmentGroupId),
        },
      ];
    } else {
      updatedItem = {
        fld_all_day: false,
        fld_heading: formData.title,
        fld_description: formData.description,
        fld_publish_date: publishDate,
        fld_date_start: eventStartDate,
        fld_date_end: eventEndDate,
        fld_location: formData.location,
      };
      updatedItem.events_relations = [
        {
          fld_building_id: parseInt(formData.buildingId),
          fld_apartment_type_id: parseInt(formData.apartmentTypeId),
          fld_apartment_group_id: parseInt(formData.apartmentGroupId),
        },
      ];
    }

    updatedItem.files = item?.files || [];
    updatedItem.updatedFiles = files;
    updatedItem.attachedFiles = item?.attachedFiles || [];
    updatedItem.updatedAttachedFiles = attachedFiles;

    onClose(updatedItem);
  };

  const images = useMemo(() => item?.files || [], [item]);
  const attached = useMemo(() => item?.attachedFiles || [], [item]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {item ? 'Edit' : 'New'}
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              type='submit'
              form='form'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              {item ? 'Save' : 'Publish'}
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              if (!files.length) {
                alert.error('Please upload at least 1 image');
                return;
              }
              onSave();
            })}
            noValidate
          >
            <Box>
              <Box>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Audience:
                </Typography>
                <Controller
                  name={'buildingId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the building',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 'calc(33% - 24px)', mr: 5 }} variant='standard'>
                      <InputLabel id='building-checkbox-label'>Building</InputLabel>
                      <Select
                        labelId='building-checkbox-label'
                        id='building-checkbox'
                        value={value}
                        onChange={onChange}
                        renderValue={(selected) =>
                          buildingOptions.find((x) => x.value === selected)?.label
                        }
                      >
                        {buildingOptions.map((item) => (
                          <MenuItem
                            sx={{
                              px: 1,
                              py: 0,
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Radio size='small' checked={value === item.value} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name={'apartmentTypeId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the apartment type',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 'calc(33% - 24px)', mr: 5 }} variant='standard'>
                      <InputLabel id='type-checkbox-label'>Apartment Type</InputLabel>
                      <Select
                        labelId='type-checkbox-label'
                        id='type-checkbox'
                        value={value}
                        onChange={onChange}
                        renderValue={(selected) =>
                          apartmentTypeOptions.find((x) => x.value === selected)?.label
                        }
                      >
                        {apartmentTypeOptions.map((item) => (
                          <MenuItem
                            sx={{
                              px: 1,
                              py: 0,
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Radio size='small' checked={value === item.value} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name={'apartmentGroupId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the apartment group',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 'calc(33% - 22px)' }} variant='standard'>
                      <InputLabel id='group-checkbox-label'>Apartment Group</InputLabel>
                      <Select
                        labelId='group-checkbox-label'
                        id='group-checkbox'
                        value={value}
                        onChange={onChange}
                        renderValue={(selected) =>
                          apartmentGroupOptions.find((x) => x.value === selected)?.label
                        }
                      >
                        {apartmentGroupOptions.map((item) => (
                          <MenuItem
                            sx={{
                              px: 1,
                              py: 0,
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Radio size='small' checked={value === item.value} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 5 }}>
              <Box sx={{ mr: 5 }}>
                <Typography variant='h6'>Publish Date & Time:</Typography>
                <Controller
                  name={'publishDate'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the publish date',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <DatePicker
                      label='Date'
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      inputFormat='DD/MM/YYYY'
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ mt: 1, mr: 4 }}
                          error={!!formState.errors.publishDate}
                          helperText={formState.errors.publishDate?.message}
                          variant='standard'
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name={'publishTime'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the publish time',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TimePicker
                      label='Start time'
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      ampm={true}
                      minutesStep={5}
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ mt: 1 }}
                          error={!!formState.errors.publishTime}
                          helperText={formState.errors.publishTime?.message}
                          variant='standard'
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Picture:</Typography>
              <Box sx={{ mt: 2 }}>
                <ImageUploader
                  maxAmount={4}
                  images={images}
                  onImagesUpdated={(items) => {
                    setFiles(items);
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 5 }}>
              <Box sx={{ mr: 5 }}>
                <Typography variant='h6'>Event Date & Time:</Typography>
                <Controller
                  name={'eventDate'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the date',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <DatePicker
                      label='Date'
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      inputFormat='DD/MM/YYYY'
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ mt: 1, mr: 4 }}
                          error={!!formState.errors.eventDate}
                          helperText={formState.errors.eventDate?.message}
                          variant='standard'
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name={'eventStartTime'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the start time',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TimePicker
                      label='Start time'
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      ampm={true}
                      minutesStep={5}
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ mt: 1, mr: 4 }}
                          error={!!formState.errors.eventStartTime}
                          helperText={formState.errors.eventStartTime?.message}
                          variant='standard'
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name={'eventEndTime'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the end time',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TimePicker
                      label='End time'
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      ampm={true}
                      minutesStep={5}
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ mt: 1 }}
                          error={!!formState.errors.eventEndTime}
                          helperText={formState.errors.eventEndTime?.message}
                          variant='standard'
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
              }}
            >
              <Box sx={{ width: '60%', mt: 5, mr: 6 }}>
                <Typography variant='h6'>Title:</Typography>
                <Controller
                  name={'title'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the event title',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Event title'
                      variant='outlined'
                      error={!!formState.errors.title}
                      helperText={formState.errors.title?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '40%', mt: 5 }}>
                <Typography variant='h6'>Location:</Typography>
                <Controller
                  name={'location'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the event location',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Event location'
                      variant='outlined'
                      error={!!formState.errors.location}
                      helperText={formState.errors.location?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Description:</Typography>
              <Box>
                <Controller
                  name={'description'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the description',
                    },
                    maxLength: {
                      value: 1024,
                      message: 'Max length should be less or equal 1024 characters',
                    },
                    validate: {
                      required: (value) => {
                        const textValue = value.replace(/<[^>]+>/g, '');
                        if (!textValue) return 'Please enter the description';
                      },
                      maxLength: (value) => {
                        const textValue = value.replace(/<[^>]+>/g, '');
                        if (textValue.length > 1024)
                          return 'Max length should be less or equal 1024 characters';
                      },
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <Box
                      sx={{
                        mt: 2,
                        ...quillEditorStyles,
                      }}
                    >
                      <ReactQuill
                        theme='snow'
                        onChange={onChange}
                        value={value}
                        modules={{
                          toolbar: [
                            [
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'link',
                              { list: 'ordered' },
                              { list: 'bullet' },
                              'blockquote',
                              'clean',
                            ],
                          ],
                          clipboard: {
                            matchVisual: false,
                          },
                        }}
                        placeholder='Describe the event in few sentences. Max length 1024 characters.'
                      />
                      <TextField
                        error={!!formState.errors.description}
                        helperText={formState.errors.description?.message}
                        value={value}
                        type='hidden'
                        sx={{
                          '& fieldset': {
                            display: 'none',
                          },
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
              <Box sx={{ width: '40%' }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Attachments:
                </Typography>
                <FileUploader
                  maxAmount={4}
                  attachedFiles={attached}
                  onFilesUpdated={(items) => {
                    setAttachedFiles(items);
                  }}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
