/* eslint-disable camelcase */

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useApi, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import ApartmentService from '@rs/services/ApartmentService';
import BuildingService from '@rs/services/BuildingService';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageResidentDialog from './components/ManageResidentDialog';
import ResidentService from '@rs/services/ResidentService';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import { checkSectionPermissions } from '@rs/helpers/checkers';
import dataGridStyles from '@rs/styles/dataGrid';
import moment from 'moment';
import searchInputStyles from '@rs/styles/searchInput';
import { useConfirm } from 'material-ui-confirm';

const defaultSelectOption: ISelectOption = {
  label: 'All',
  value: 'all',
};

export default function Residents() {
  const confirm = useConfirm();

  const modifyAllowed = checkSectionPermissions(Sections.residents, 'modify');

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortOptions, setSortOptions] = useState<GridSortItem>({
    field: 'fld_timestamp',
    sort: 'desc',
  });
  const searchInput = useInput<string>('');
  const buildingInput = useInput<string>('all');
  const [buildingOptions, setBuildingOptions] = useState<ISelectOption[]>([defaultSelectOption]);
  const apartmentTypeInput = useInput<string>('all');
  const [apartmentTypeOptions, setApartmentTypeOptions] = useState<ISelectOption[]>([
    defaultSelectOption,
  ]);
  const apartmentGroupInput = useInput<string>('all');
  const [apartmentGroupOptions, setApartmentGroupOptions] = useState<ISelectOption[]>([
    defaultSelectOption,
  ]);
  const search = useDebounce<string>(searchInput.value, 1000);
  const [data, setData] = useState<{ count: number; items: IResident[] }>({ count: 0, items: [] });
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState<IResident | undefined>(undefined);

  const getResidentsRequest = useApi(ResidentService.getResidents);
  const createResidentRequest = useApi(ResidentService.createResident);
  const updateResidentRequest = useApi(ResidentService.updateResident);
  const deleteResidentRequest = useApi(ResidentService.deleteResident);
  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const getApartmentTypesRequest = useApi(ApartmentService.getApartmentTypes);
  const getApartmentGroupsRequest = useApi(ApartmentService.getApartmentGroups);

  const loadData = useCallback(async () => {
    const filters = {
      ...(search && { search_term: search }),
      ...(buildingInput.value !== 'all' && {
        fld_building_id: +buildingInput.value,
      }),
      ...(apartmentTypeInput.value !== 'all' && {
        fld_apartment_type_id: +apartmentTypeInput.value,
      }),
      ...(apartmentGroupInput.value !== 'all' && {
        fld_apartment_group_id: +apartmentGroupInput.value,
      }),
      sort_column: sortOptions?.field || '',
      sort_direction: sortOptions?.sort || '',
    };
    console.log(filters);
    const response = await getResidentsRequest(pageSize, page * pageSize, filters);
    setData({ count: response?.count || 0, items: response?.items || [] });
  }, [
    page,
    pageSize,
    search,
    sortOptions,
    buildingInput.value,
    apartmentTypeInput.value,
    apartmentGroupInput.value,
  ]);

  const onPageChanged = (_page: number) => {
    setPage(_page);
  };

  const onPerPageChanged = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onSortModelChanged = async (_model: GridSortModel) => {
    setSortOptions(_model[0]);
  };

  const onAdd = () => {
    setActiveItem(undefined);
    setOpenDialog(true);
  };

  const onEdit = (item: IResident) => {
    setActiveItem(item);
    setOpenDialog(true);
  };

  const onDelete = async (item: IResident) => {
    try {
      await confirm({ description: 'This will permanently delete the resident.' });
    } catch {
      return;
    }
    await deleteResidentRequest(item.fld_uid, item.fld_zu_user_id);
    await loadData();
  };

  const onCloseDialog = async (item?: Partial<IResident>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      if (activeItem) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await updateResidentRequest(item.fld_uid!, item);
      } else {
        await createResidentRequest(item);
      }
      setOpenDialog(false);
      await loadData();
    }
  };

  useEffect(() => {
    (async () => {
      const buildings = (await getBuildingsRequest())?.items || [];
      setBuildingOptions([
        defaultSelectOption,
        ...buildings.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_building_name,
        })),
      ]);
      const apartmentTypes = (await getApartmentTypesRequest()) || [];
      setApartmentTypeOptions([
        defaultSelectOption,
        ...apartmentTypes.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_apartment_type_name,
        })),
      ]);
      const apartmentGroups = (await getApartmentGroupsRequest()) || [];
      setApartmentGroupOptions([
        defaultSelectOption,
        ...apartmentGroups.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_apartment_group_name,
        })),
      ]);
      await loadData();
    })();
  }, []);

  const buildingOptionsWithoutDefault = useMemo(() => {
    return buildingOptions.filter((x) => x.value !== defaultSelectOption.value);
  }, [buildingOptions]);

  useUpdateEffect(() => {
    loadData();
  }, [sortOptions, buildingInput.value, apartmentTypeInput.value, apartmentGroupInput.value]);

  useUpdateEffect(() => {
    loadData();
  }, [search, page, pageSize]);

  const columns: GridColDef[] = [
    {
      field: 'resident.fld_firstname',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 20,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.fld_firstname} ${params.row.fld_surname}`.trim(),
    },
    {
      field: 'fld_email',
      headerName: 'Email',
      disableColumnMenu: true,
      sortable: false,
      flex: 25,
    },
    {
      field: 'fld_mobile',
      headerName: 'Phone',
      disableColumnMenu: true,
      sortable: false,
      flex: 15,
    },
    {
      field: 'apartment.fld_apartment_order',
      headerName: 'Apt.',
      disableColumnMenu: true,
      flex: 10,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.apartment?.fld_apartment_order || '',
    },
    {
      field: 'resident.fld_timestamp',
      headerName: 'Registration date',
      disableColumnMenu: true,
      flex: 17,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.fld_timestamp, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY'),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      flex: 10,
      align: 'right',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <SecureContainer sections={[Sections.residents]} permission='modify'>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => onEdit(params.row)}
                sx={{ p: 0, color: (t) => t.palette.primary.main }}
                aria-label='Edit'
              >
                <AccountCircleIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
          <SecureContainer sections={[Sections.residents]} permission='delete'>
            <Tooltip title='Delete'>
              <IconButton
                onClick={() => onDelete(params.row)}
                sx={{ ml: 5, p: 0, color: (t) => t.palette.error.main }}
                aria-label='Delete'
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
        </>
      ),
    },
  ];

  return (
    <LoadPermissionsContainer section={Sections.residents}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Residents ({data.count})
            </Typography>
            <TextField
              sx={searchInputStyles}
              onChange={searchInput.onChange}
              value={searchInput.value}
              placeholder='Search a resident'
              variant='outlined'
              margin='normal'
            />
          </Box>
          <Box>
            <SecureContainer sections={[Sections.residents]} permission='modify'>
              <Box>
                <Button
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => onAdd()}
                  sx={{
                    px: 2.5,
                    py: 1.5,
                  }}
                >
                  Add Resident
                </Button>
              </Box>
            </SecureContainer>
          </Box>
        </Box>
        <Box
          sx={{
            mb: 5,
            display: 'flex',
            alignItems: 'center',
            columnGap: '2rem',
          }}
        >
          <Typography variant='h6' fontWeight={600}>
            Filters:
          </Typography>
          <FormControl sx={{ width: 220 }} variant='standard'>
            <InputLabel id='building-checkbox-label'>Building</InputLabel>
            <Select
              labelId='building-checkbox-label'
              id='building-checkbox'
              value={buildingInput.value}
              onChange={buildingInput.onChange}
              renderValue={(selected) => buildingOptions.find((x) => x.value === selected)?.label}
            >
              {buildingOptions.map((item) => (
                <MenuItem
                  sx={{
                    px: 1,
                    py: 0,
                  }}
                  key={item.value}
                  value={item.value}
                >
                  <Radio size='small' checked={buildingInput.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 220 }} variant='standard'>
            <InputLabel id='type-checkbox-label'>Type</InputLabel>
            <Select
              labelId='type-checkbox-label'
              id='type-checkbox'
              value={apartmentTypeInput.value}
              onChange={apartmentTypeInput.onChange}
              renderValue={(selected) =>
                apartmentTypeOptions.find((x) => x.value === selected)?.label
              }
            >
              {apartmentTypeOptions.map((item) => (
                <MenuItem
                  sx={{
                    px: 1,
                    py: 0,
                  }}
                  key={item.value}
                  value={item.value}
                >
                  <Radio size='small' checked={apartmentTypeInput.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 220 }} variant='standard'>
            <InputLabel id='group-checkbox-label'>Group</InputLabel>
            <Select
              labelId='group-checkbox-label'
              id='group-checkbox'
              value={apartmentGroupInput.value}
              onChange={apartmentGroupInput.onChange}
              renderValue={(selected) =>
                apartmentGroupOptions.find((x) => x.value === selected)?.label
              }
            >
              {apartmentGroupOptions.map((item) => (
                <MenuItem
                  sx={{
                    px: 1,
                    py: 0,
                  }}
                  key={item.value}
                  value={item.value}
                >
                  <Radio size='small' checked={apartmentGroupInput.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box height={'100%'}>
          <DataGrid
            sx={dataGridStyles}
            rows={data.items}
            rowCount={data.count}
            columns={columns}
            page={page}
            pageSize={pageSize}
            sortModel={sortOptions ? [sortOptions] : []}
            sortingMode='server'
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={onPageChanged}
            onPageSizeChange={onPerPageChanged}
            onSortModelChange={onSortModelChanged}
            getRowId={(row: IResident) => row.fld_uid}
          />
        </Box>
        <ManageResidentDialog
          readonly={!modifyAllowed}
          item={activeItem}
          open={openDialog}
          onClose={onCloseDialog}
          buildingOptions={buildingOptionsWithoutDefault}
        />
      </>
    </LoadPermissionsContainer>
  );
}
