import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  persistCombineReducers,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import authReducer, { AuthState } from '@rs/reducers/authSlice';
import generalReducer, { GeneralState } from '@rs/reducers/generalSlice';
import AuthReducerTransform from './transforms/AuthReducerTransform';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel1,
  transforms: [AuthReducerTransform],
};

const _persistedReducer = persistCombineReducers(persistConfig, {
  auth: authReducer,
  general: generalReducer,
});

export const store = configureStore<{ auth: AuthState; general: GeneralState }>({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) => [
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })[0]!,
  ],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
