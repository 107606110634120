import BaseProvider from './BaseProvider';
import { IConversationOptionFilter } from './interfaces/filters/IConversationOptionFilter';
import { IGetConversationOptionResponse } from './interfaces/responses/IGetConversationOptionResponse';

export default class ConversationOptionProvider extends BaseProvider {
  getConversationOptions = async (
    limit?: number,
    offset?: number,
    filters?: IConversationOptionFilter,
  ) => {
    return this.get<IGetConversationOptionResponse>(
      `${this.getBaseApiUrl()}/rs/conversation-options/`,
      {
        params: {
          limit: limit || 100,
          offset: offset || 0,
          ...(filters?.search_term && {
            'search-term': filters?.search_term,
          }),
          ...(filters?.fld_uid && {
            filter: `fld_uid = ${filters?.fld_uid}`,
          }),
          ...(filters?.sort_column &&
            filters?.sort_direction && {
              sort: `${filters?.sort_column},${filters?.sort_direction}`,
            }),
        },
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };

  deleteConversationOption = async (id: number) => {
    return this.delete(`${this.getBaseApiUrl()}/rs/conversation-options/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
