import { Box, Drawer, IconButton, List, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { logout, selectAuthMainUserGroup, selectAuthUser } from '@rs/reducers/authSlice';
import { resetStyle, selectGeneralLogo } from '@rs/reducers/generalSlice';
import { useAppDispatch, useAppSelector } from '@rs/helpers/hooks';

import ApartmentIcon from '@mui/icons-material/Apartment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DrawerMenuItem from '@rs/components/DrawerMenuItem';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EventIcon from '@mui/icons-material/Event';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { IProps } from './interfaces';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import React from 'react';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import { UserGroups } from '@rs/constants/UserGroups';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

export default function SideBar(props: IProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logo = useAppSelector(selectGeneralLogo);
  const user = useAppSelector(selectAuthUser);
  const role = useAppSelector(selectAuthMainUserGroup);

  const onSignOut = () => {
    dispatch(logout());
    dispatch(resetStyle());
    navigate('/sign-in');
  };

  return (
    <Drawer variant='permanent' PaperProps={{ sx: { border: 'none' } }}>
      <Box
        sx={{
          width: props.width,
          px: 2,
          py: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <img src={logo} alt='logo' width={200} />
        </Box>
        <List component='nav' sx={{ border: 'none' }}>
          <SecureContainer
            sections={[Sections.residents]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/residents' label='Residents' icon={PeopleAltIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.bookingTypes]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/booking-types' label='Booking Types' icon={ListAltIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.bookings]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/bookings' label='Booking Requests' icon={CalendarTodayIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.conversations]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/service-desk' label='Service Desk' icon={DynamicFeedIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.conversationOptions]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/request-types' label='Requests & Issues' icon={FeedbackIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.parcels]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/parcels' label='Parcels' icon={MarkunreadMailboxIcon} />
          </SecureContainer>
          {/* <SecureContainer
            sections={[Sections.keyWaiver]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/key-waivers' label='Key Waivers' icon={VpnKeyIcon} />
          </SecureContainer> */}
          <SecureContainer
            sections={[Sections.buildings]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/buildings' label='Buildings' icon={ApartmentIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.apartments]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/apartments' label='Apartments' icon={HomeWorkIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.news]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/news' label='News' icon={ListAltIcon} />
          </SecureContainer>
          <SecureContainer
            sections={[Sections.events]}
            userGroups={[UserGroups.admin, UserGroups.concierge]}
          >
            <DrawerMenuItem href='/events' label='Events' icon={EventIcon} />
          </SecureContainer>
          {/* <SecureContainer sections={[]}>
            <DrawerMenuItem href='/visitors' label='Visitors' icon={PeopleOutlineIcon} />
          </SecureContainer> */}
          <SecureContainer sections={[Sections.news]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/news' label='News' icon={ListAltIcon} />
          </SecureContainer>
          <SecureContainer sections={[Sections.events]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/events' label='Events' icon={EventIcon} />
          </SecureContainer>
          <SecureContainer sections={[Sections.amenities]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/amenities' label='Amenities' icon={LocationOnIcon} />
          </SecureContainer>
          <SecureContainer sections={[Sections.buildings]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/my/building' label='My building' icon={ApartmentIcon} />
          </SecureContainer>
          <SecureContainer sections={[Sections.buildings]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/my/apartment' label='My apartment' icon={HomeWorkIcon} />
          </SecureContainer>
          <SecureContainer sections={[Sections.bookingTypes]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/resident-bookings' label='Bookings' icon={CalendarTodayIcon} />
          </SecureContainer>
          <SecureContainer sections={[Sections.bookings]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/my-bookings' label='My Bookings' icon={EventIcon} />
          </SecureContainer>
          <SecureContainer sections={[Sections.conversations]} userGroups={[UserGroups.resident]}>
            <DrawerMenuItem href='/help-desk' label='Help Desk' icon={HeadsetMicIcon} />
          </SecureContainer>
        </List>
        <Box>
          {/* <List component='nav' sx={{ border: 'none' }}>
            <DrawerMenuItem href='/visitors' label='Notification' badge='12' />
            <DrawerMenuItem href='/settings' label='Settings' />
          </List> */}
          {user ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 2,
                p: 2,
                mt: 2,
                backgroundColor: (t) => t.palette.grey[100],
              }}
            >
              <Link to='/profile' style={{ textDecoration: 'none' }}>
                <Box>
                  <Typography color='#000' fontWeight={500}>
                    {user.name}
                  </Typography>
                  <Typography variant='caption' sx={{ color: (t) => t.palette.grey[600] }}>
                    {role}
                  </Typography>
                </Box>
              </Link>
              <IconButton
                onClick={onSignOut}
                sx={{ py: 0, px: 1.5, color: (t) => t.palette.grey[600] }}
                aria-label='Logout'
              >
                <LogoutIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Drawer>
  );
}
