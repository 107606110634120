import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { IProps } from './interfaces';
import useStyles from './styles';

const Loader = ({ show }: IProps) => {
  const classes = useStyles();

  return show ? (
    <div className={classes.overlay}>
      <CircularProgress color='secondary' size={100} />
    </div>
  ) : null;
};

Loader.defaultProps = { show: false };

export default Loader;
