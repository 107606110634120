import { Box, Typography } from '@mui/material';

import ApartmentIcon from '@mui/icons-material/Apartment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { useState } from 'react';

interface IProps {
  data: IBuilding;
  baseUrl?: string;
}

export default function ListItem({ data, baseUrl }: IProps) {
  const description = data.fld_description.replace(/<[^>]+>/g, '');
  const [displayShowMore, setDisplayShowMore] = useState(data.fld_description.length > 200);
  const image = data.files.length > 0 ? data.files[0].preview : null;

  return (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#F7F9FA',
          borderRadius: 2.5,
          py: 2,
          pl: 2,
          pr: 1.25,
        }}
      >
        <Box
          sx={{
            width: '170px',
            height: '140px',
            borderRadius: 2.5,
            backgroundColor: '#ebeeef',
            backgroundImage: 'url("' + image + '")',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
          }}
        />
        <Box sx={{ width: '100%', pl: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Typography component='h2' variant='h6'>
              {data.fld_building_name}
            </Typography>
            <Link to={`/${baseUrl}/${data.fld_uid}`}>
              <Typography
                component='span'
                variant='subtitle2'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                View Details
                <ChevronRightIcon sx={{ fontSize: (t) => t.typography.body1.fontSize, ml: 0.5 }} />
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: '#0000008A' }}>
            <Typography
              component='span'
              variant='subtitle2'
              sx={{ display: 'flex', alignItems: 'center', pr: 2 }}
            >
              <LocationOnIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
              {data?.fld_address}
            </Typography>
            <Typography
              component='span'
              variant='subtitle2'
              sx={{
                position: 'relative',
                px: 2,
                display: 'flex',
                alignItems: 'center',
                '&:before, &:after': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: '10px',
                  left: 0,
                  width: '3px',
                  height: '3px',
                  borderRadius: '50%',
                  bgcolor: '#2121218A',
                },
                '&:after': { left: 'unset', right: 0 },
              }}
            >
              <ApartmentIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
              {data?.fld_floors} Floor{data?.fld_floors !== 1 && 's'}
            </Typography>
            <Typography
              component='span'
              variant='subtitle2'
              sx={{ display: 'flex', alignItems: 'center', pl: 2 }}
            >
              <MapsHomeWorkIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
              {data?.apartment_count} Apartment{data?.apartment_count !== 1 && 's'}
            </Typography>
          </Box>
          <Box sx={{ backgroundColor: '#fff', p: 1, borderRadius: 2.5 }}>
            <Typography component='span' variant='subtitle2' fontWeight={400}>
              {displayShowMore ? description.slice(0, 200) + '...' : description}
              {displayShowMore && (
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ color: (t) => t.palette.primary.main, ml: 0.5, cursor: 'pointer' }}
                  onClick={() => setDisplayShowMore(false)}
                >
                  show more
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
