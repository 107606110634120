import { Box } from '@mui/material';
import { IProps } from './interfaces';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IBookingTypeOpen } from '@rs/providers/interfaces/entities/IBookingTypeOpen';
import { get12hTime } from '@rs/helpers/date';
import moment from 'moment';

export default function OpenedHours({ open }: IProps) {
  const getOpenHours = (item: IBookingTypeOpen) => {
    if (!item.fld_open_hour) {
      return 'All day';
    }
    const openTime = `${get12hTime(Number(item.fld_open_hour), Number(item.fld_open_min))}`;
    const closeTime = `${get12hTime(Number(item.fld_close_hour), Number(item.fld_close_min))}`;

    return `${openTime} - ${closeTime}`;
  };

  return (
    <Box sx={{ px: 7, py: 5, bgcolor: '#f7f9fa', borderRadius: 2 }}>
      {open &&
        open.map((item) => (
          <Box
            key={item.fld_uid}
            sx={{
              borderBottom: '1px solid #fff',
              py: 1.5,
              display: 'flex',
              justifyContent: 'space-between',
              '&:first-of-type': {
                pt: 0,
              },
              '&:last-child': {
                borderBottom: 'none',
                pb: 0,
              },
            }}
          >
            <Box
              sx={{
                textTransform: 'uppercase',
                fontSize: (t) => t.typography.overline.fontSize,
                color: (t) => t.palette.secondary.contrastText,
                fontWeight: 600,
              }}
            >
              {moment()
                .day((item.fld_open_day + 1) % 7)
                .format('dddd')}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: (t) => t.palette.primary.main,
                fontSize: (t) => t.typography.overline.fontSize,
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
            >
              <AccessTimeIcon sx={{ fontSize: (t) => t.typography.overline.fontSize, mr: 1 }} />
              {getOpenHours(item)}
            </Box>
          </Box>
        ))}
    </Box>
  );
}
