/* eslint-disable camelcase */

import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useApi, useAppSelector } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ConversationService from '@rs/services/ConversationService';
import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import { ConversationTypes } from '@rs/constants/ConversationTypes';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import { IMessage } from '@rs/providers/interfaces/entities/IMessage';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import MessageService from '@rs/services/MessagesService';
import TextWithNewLines from '@rs/components/TextWithNewLines';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import { UserGroups } from '@rs/constants/UserGroups';
import { dateToFromNowDaily } from '@rs/helpers/date';
import dialogInputStyles from '@rs/styles/dialogInput';
import moment from 'moment';
import { selectAuthMainUserGroup } from '@rs/reducers/authSlice';

interface IProps {
  item?: IConversation;
  open: boolean;
  conversationTypes: IConversationType[] | undefined;
  onClose: () => void;
}

interface FormData {
  text: string;
}

export default function ConversationDialog(props: IProps) {
  const { onClose, open, item, conversationTypes } = props;
  const role = useAppSelector(selectAuthMainUserGroup);

  const [files, setFiles] = useState<UploaderFile[]>([]);

  const formDefaultData = {
    text: '',
  };

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const updateMessageRequest = useApi(MessageService.updateMessage);
  const getMessagesRequest = useApi(MessageService.getMessages);
  const createMessageRequest = useApi(MessageService.createMessage);
  const updateConversationStatusRequest = useApi(ConversationService.updateConversationStatus);

  const [conversationStatus, setConversationStatus] = useState<string | undefined>();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(true);

  const updateMessageReadAt = useCallback(
    async (message: IMessage) => {
      if (
        (!item &&
          message.fld_author_role !== UserGroups.resident &&
          // TODO: remove it after the fix on API
          message.fld_author_role.toString() !== 'Resident') ||
        !!message.fld_read_at
      ) {
        return;
      }
      updateMessageRequest(message.fld_uid, {
        ...message,
        fld_read_at: moment().format('DD/MM/YYYY HH:mm'),
      });
    },
    [item],
  );

  const loadLatestData = useCallback(async () => {
    const filters = item?.fld_uid
      ? {
          // eslint-disable-next-line camelcase
          fld_conversation_id: [item?.fld_uid],
        }
      : undefined;
    const latestMessages = (await getMessagesRequest(5, 0, filters)) || [];
    const lastMessage = messages[0];
    const newMessages: IMessage[] = [];
    for (
      let i = 0;
      i < latestMessages.length &&
      (lastMessage ? latestMessages[i].fld_uid !== lastMessage.fld_uid : true);
      i++
    ) {
      newMessages.push(latestMessages[i]);
    }
    setMessages([...newMessages, ...messages]);
    if (newMessages.length) {
      updateMessageReadAt(newMessages[0]);
    }
  }, [item, updateMessageReadAt]);

  const loadData = useCallback(async () => {
    setLoading(true);
    const filters = item?.fld_uid
      ? {
          // eslint-disable-next-line camelcase
          fld_conversation_id: [item?.fld_uid],
        }
      : undefined;
    const messages = (await getMessagesRequest(undefined, undefined, filters)) || [];
    setMessages(messages);
    if (messages.length) {
      updateMessageReadAt(messages[0]);
    }
    setLoading(false);
  }, [item, updateMessageReadAt]);

  useEffect(() => {
    form.reset(formDefaultData);
    if (item) {
      setConversationStatus(item.fld_status);
      loadData();
    } else {
      setMessages([]);
      setConversationStatus(undefined);
    }
  }, [item]);

  const onCancel = () => {
    onClose();
  };

  const onSendMessage = async () => {
    if (item) {
      const { text } = form.getValues();
      if (!text && !files.length) return;

      const message: Partial<IMessage> = {
        fld_text: text,
        fld_conversation_id: item.fld_uid,
        fld_author_role: role as UserGroups,
      };

      message.updatedFiles = [...files];

      await createMessageRequest(message);
      form.reset(formDefaultData);
      setFiles([]);
      await loadLatestData();
    }
  };

  const onChangeStatus = async (status: ConversationStatuses) => {
    if (!item) {
      return;
    }
    await updateConversationStatusRequest(item.fld_uid, status);
    setConversationStatus(status);
  };

  const onAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const acceptedFiles = Array.from(e.target.files);

    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      })),
    ]);
  };

  const onFileDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        loadLatestData();
      }, 30000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [open, loadLatestData]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='md'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {item?.fld_title || 'Dialog'}
          </Typography>
          <Box>
            {item &&
              conversationTypes?.find((x) => x.fld_uid === item.fld_conversation_type_id)
                ?.fld_name === ConversationTypes.issue &&
              conversationStatus === ConversationStatuses.new && (
                <Button
                  variant='outlined'
                  startIcon={<CheckIcon />}
                  color='warning'
                  onClick={() => onChangeStatus(ConversationStatuses.inProgress)}
                  sx={{
                    px: 1.5,
                    py: 1,
                    ml: 2,
                  }}
                >
                  Start
                </Button>
              )}
            {item &&
              conversationTypes?.find((x) => x.fld_uid === item.fld_conversation_type_id)
                ?.fld_name === ConversationTypes.issue &&
              conversationStatus === ConversationStatuses.inProgress && (
                <Button
                  variant='outlined'
                  startIcon={<CheckIcon />}
                  color='success'
                  onClick={() => onChangeStatus(ConversationStatuses.resolved)}
                  sx={{
                    px: 1.5,
                    py: 1,
                    ml: 2,
                  }}
                >
                  Resolve
                </Button>
              )}
            {item &&
              conversationTypes?.find((x) => x.fld_uid === item.fld_conversation_type_id)
                ?.fld_name === ConversationTypes.request &&
              conversationStatus === ConversationStatuses.new && (
                <Button
                  variant='outlined'
                  startIcon={<CheckIcon />}
                  color='success'
                  onClick={() => onChangeStatus(ConversationStatuses.approved)}
                  sx={{
                    px: 1.5,
                    py: 1,
                    ml: 2,
                  }}
                >
                  Approve
                </Button>
              )}
            {item &&
              conversationTypes?.find((x) => x.fld_uid === item.fld_conversation_type_id)
                ?.fld_name === ConversationTypes.request &&
              conversationStatus === ConversationStatuses.new && (
                <Button
                  variant='outlined'
                  startIcon={<CloseIcon />}
                  color='error'
                  onClick={() => onChangeStatus(ConversationStatuses.rejected)}
                  sx={{
                    px: 1.5,
                    py: 1,
                    ml: 2,
                  }}
                >
                  Reject
                </Button>
              )}
            <IconButton
              size='large'
              onClick={() => onCancel()}
              sx={{ ml: 2, p: 0, color: (t) => t.palette.grey[600] }}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Box
            sx={{
              py: 1,
              px: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {item?.resident?.files && (
              <Avatar src={item?.resident?.files[0]?.preview}>
                {(!item?.resident?.files[0]?.preview &&
                  `${item?.resident?.fld_firstname} ${item?.resident?.fld_surname}`.trim()) ||
                  'User'.at(0)}
              </Avatar>
            )}
            <Typography variant='subtitle2' sx={{ ml: 2 }}>
              {`${item?.resident?.fld_firstname} ${item?.resident?.fld_surname}`.trim() || 'User'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              rowGap: 2,
              flexDirection: 'column-reverse',
              p: 3,
              minHeight: 300,
              maxHeight: 500,
              overflowY: 'auto',
              height: '100%',
              backgroundColor: (t) => t.palette.grey[100],
            }}
          >
            {messages.map((x) => (
              <Box
                key={x.fld_uid}
                sx={{
                  display: 'flex',
                  justifyContent: x.fld_author_role === role ? 'flex-end' : 'flex-start',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      px: 1.5,
                      py: 1,
                      borderRadius: 5,
                      borderBottomRightRadius: x.fld_author_role === role ? 0 : undefined,
                      borderBottomLeftRadius: x.fld_author_role !== role ? 0 : undefined,
                      backgroundColor: (t) =>
                        x.fld_author_role === role ? t.palette.primary.main : '#fff',
                      color: (t) =>
                        x.fld_author_role === role
                          ? t.palette.primary.contrastText
                          : t.palette.grey[600],
                    }}
                  >
                    {!!x.fld_text && (
                      <Typography variant='subtitle2'>
                        <TextWithNewLines text={x.fld_text} />
                      </Typography>
                    )}
                    {!!x.files.length &&
                      x.files.map((file) => (
                        <Box key={file.file_id}>
                          <img
                            src={file.preview}
                            style={{ overflow: 'hidden', maxWidth: 200, borderRadius: 4 }}
                            alt={file.file_name}
                          />
                        </Box>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: x.fld_author_role === role ? 'flex-end' : 'flex-start',
                    }}
                  >
                    <Typography
                      variant='caption'
                      sx={{
                        color: (t) => t.palette.grey[600],
                      }}
                    >
                      {dateToFromNowDaily(moment(x.fld_timestamp, 'DD/MM/YYYY HH:mm:ss').format())}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
            {loading && (
              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Loading...
              </Box>
            )}
          </Box>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSendMessage();
            })}
            noValidate
          >
            {!!files.length && (
              <Box sx={{ px: 2, my: 1 }}>
                {files.map((x, i) => (
                  <Chip
                    sx={{
                      mr: 1,
                      mt: 1,
                    }}
                    key={i}
                    color='primary'
                    variant='outlined'
                    icon={<AttachFileIcon />}
                    label={(x as INewAttachedFile).file.name}
                    onDelete={() => onFileDelete(i)}
                  />
                ))}
              </Box>
            )}
            <Box
              sx={{
                py: 1,
                px: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                sx={{
                  color: (t: Theme) => t.palette.primary.main,
                }}
                aria-label='upload file'
                component='label'
              >
                <input hidden accept='image/*' type='file' onChange={onAttachment} />
                <AttachFileIcon />
              </IconButton>
              <Controller
                name={'text'}
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={dialogInputStyles}
                    placeholder='Type here'
                    variant='outlined'
                    margin='normal'
                    multiline
                    onChange={onChange}
                    value={value}
                    minRows={1}
                    maxRows={4}
                  />
                )}
              />
              <IconButton
                form='form'
                type='submit'
                sx={{
                  backgroundColor: (t) => t.palette.primary.main,
                  color: (t) => t.palette.primary.contrastText,
                  '&:hover': {
                    backgroundColor: (t) => t.palette.primary.dark,
                  },
                }}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
