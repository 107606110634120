import { Controller, useForm } from "react-hook-form";
import {
  selectAuthMainUserGroup,
} from "@rs/reducers/authSlice";
import {
  selectActiveUser,
  selectGeneralLogo,
  setActiveUser,
  setLoader,
} from "@rs/reducers/generalSlice";
import {
  useAppDispatch,
  useAppSelector,
  useUpdateEffect,
} from "@rs/helpers/hooks";

import AuthService from "@rs/services/AuthService";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Copyright from "@rs/components/Copyright";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { ISignInFormData } from "./interfaces";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import background from "../../assets/sign-in-background.jpg";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const logo = useAppSelector(selectGeneralLogo);
  const role = useAppSelector(selectAuthMainUserGroup);
  const activeUser = useAppSelector(selectActiveUser);

  const { handleSubmit, control } = useForm<ISignInFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: ISignInFormData) => {
    try {
      dispatch(setLoader(true));
      await AuthService.authenticate(data.email, data.password);
      dispatch(setActiveUser(data));
    } catch (error: any) {
      alert.error(error.message ? error.message : error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  useUpdateEffect(() => {
    if (activeUser) {
      navigate("/projects");
    }
  }, [activeUser]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={6}
        md={6}
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        component={Paper}
        boxShadow="none"
        elevation={6}
        square
      >
        <Box
          sx={{
            pt: 8,
            pb: 3,
            px: {
              md: 8,
              xs: 3,
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ ml: 3, mt: 8 }}>
            <img src={logo} alt="logo" style={{
              maxWidth: 400
            }} />
          </Box>

          <Grid container>
            <Grid item xs={12} md={9} lg={7}>
              <Typography
                component="h1"
                variant="h5"
                fontWeight={600}
                sx={{ mt: 8 }}
              >
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 3 }}
              >
                <Controller
                  name={"email"}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter the email",
                    },
                    pattern: {
                      value:
                        // eslint-disable-next-line no-control-regex
                        /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                      message: "Please enter valid email",
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      error={!!formState.errors.email}
                      helperText={formState.errors.email?.message}
                      onChange={onChange}
                      value={value}
                      label="Email"
                      placeholder="Enter email"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      autoComplete="email"
                      autoFocus
                    />
                  )}
                />
                <Controller
                  name={"password"}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter the password",
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      error={!!formState.errors.password}
                      helperText={formState.errors.password?.message}
                      onChange={onChange}
                      value={value}
                      label="Password"
                      placeholder="Enter password"
                      variant="standard"
                      margin="normal"
                      type="password"
                      fullWidth
                      autoComplete="current-password"
                    />
                  )}
                />
                <Box textAlign="center" marginTop={3}>
                  <Link
                    href="https://global.zutec.com/login/forgot_your_password.php"
                    variant="body2"
                    underline="hover"
                    margin="auto"
                  >
                    Forgot password?
                  </Link>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 6 }}
                >
                  Sign In
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Grid>
    </Grid>
  );
}
