import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useApi, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import BookingTypeService from '@rs/services/BookingTypeService';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IBookingType } from '@rs/providers/interfaces/entities/IBookingType';
import { IBookingTypeOpen } from '@rs/providers/interfaces/entities/IBookingTypeOpen';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageBookingTypeDialog from './components/ManageBookingTypeDialog';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import dataGridStyles from '@rs/styles/dataGrid';
import moment from 'moment';
import searchInputStyles from '@rs/styles/searchInput';
import { useConfirm } from 'material-ui-confirm';

import {createIntl, createIntlCache} from '@formatjs/intl';
import englishTranslation from '../../../translations/en-GB.json';

export default function BookingTypes() {
  const confirm = useConfirm();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortOptions, setSortOptions] = useState<GridSortItem>({
    field: 'fld_booking_type_heading',
    sort: 'asc',
  });
  const searchInput = useInput<string>('');

  const search = useDebounce<string>(searchInput.value, 1000);

  const [data, setData] = useState<{ count: number; items: IBookingType[] }>({
    count: 0,
    items: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState<IBookingType | undefined>(undefined);

  const getBookingTypesRequest = useApi(BookingTypeService.getBookingTypes);
  const createBookingTypeRequest = useApi(BookingTypeService.createBookingType);
  const updateBookingTypeRequest = useApi(BookingTypeService.updateBookingType);
  const deleteBookingTypeRequest = useApi(BookingTypeService.deleteBookingType);

  const loadData = useCallback(() => {
    (async () => {
      const filters = {
        // eslint-disable-next-line camelcase
        ...(search && { search_term: search }),
        // eslint-disable-next-line camelcase
        sort_column: sortOptions?.field || '',
        // eslint-disable-next-line camelcase
        sort_direction: sortOptions?.sort || '',
      };
      const response = await getBookingTypesRequest(pageSize, page * pageSize, filters);
      setData({ count: response?.count || 0, items: response?.items || [] });
    })();
  }, [page, pageSize, search, sortOptions]);

  const onPageChanged = (_page: number) => {
    setPage(_page);
  };

  const onPerPageChanged = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onSortModelChanged = async (_model: GridSortModel) => {
    setSortOptions(_model[0]);
  };

  const onEdit = (item: IBookingType) => {
    setActiveItem(item);
    setOpenDialog(true);
  };

  const onDelete = async (item: IBookingType) => {
    try {
      await confirm({ description: 'This will permanently delete the booking type.' });
    } catch {
      return;
    }
    await deleteBookingTypeRequest(item.fld_uid);
    await loadData();
  };

  const onAdd = () => {
    setActiveItem(undefined);
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<IBookingType>) => {
    (async () => {
      if (!item) {
        setOpenDialog(false);
      } else {
        if (activeItem) {
          // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          await updateBookingTypeRequest(item.fld_uid!, item, activeItem);
        } else {
          await createBookingTypeRequest(item);
        }
        setOpenDialog(false);
        await loadData();
      }
    })();
  };

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [sortOptions]);

  useUpdateEffect(() => {
    loadData();
  }, [search, page, pageSize]);

  // This is optional but highly recommended since it prevents memory leak
  const cache = createIntlCache();
  
  // Setting which translation file will be used
  const intl = createIntl({
      locale: 'en-GB',
      messages: englishTranslation,
    },
    cache
  );

  const columns: GridColDef[] = [
    {
      field: 'bookingType.fld_booking_type_heading',
      headerName: intl.formatMessage({
        id: "Pages.Manager.BookingTypes.simultaneous",
        defaultMessage: 'Booking Name'
      }),
      disableColumnMenu: true,
      flex: 20,
      valueGetter: (params: GridValueGetterParams) => params.row.fld_booking_type_heading,
    },
    {
      field: 'bookingType.fld_is_bookable',
      headerName: 'Type',
      disableColumnMenu: true,
      flex: 15,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.fld_is_bookable ? 'Bookable' : 'Unbookable',
    },
    {
      field: 'open',
      headerName: 'Available Days',
      disableColumnMenu: true,
      sortable: false,
      flex: 30,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.booking_type_open
          .map((x: IBookingTypeOpen) =>
            moment()
              .day(x.fld_open_day % 7)
              .format('ddd'),
          )
          .join(', '),
    },
    {
      field: 'fld_max_residents',
      headerName: intl.formatMessage({
          id: "Pages.Manager.BookingTypes.simultaneous",
          defaultMessage: 'Simultaneous Booking'
        }),
      disableColumnMenu: true,
      sortable: false,
      flex: 20,
      valueGetter: (params: GridValueGetterParams) => params.row.fld_max_residents || '-',
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      flex: 10,
      align: 'right',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <SecureContainer sections={[Sections.bookingTypes]} permission='modify'>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => onEdit(params.row)}
                sx={{ p: 0, color: (t) => t.palette.grey[600] }}
                aria-label='Edit'
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
          <SecureContainer sections={[Sections.bookingTypes]} permission='delete'>
            <Tooltip title='Delete'>
              <IconButton
                onClick={() => onDelete(params.row)}
                sx={{ ml: 5, p: 0, color: (t) => t.palette.error.main }}
                aria-label='Delete'
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
        </>
      ),
    },
  ];

  return (
    <LoadPermissionsContainer section={Sections.bookingTypes}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Booking Types
            </Typography>
            <TextField
              sx={searchInputStyles}
              onChange={searchInput.onChange}
              value={searchInput.value}
              placeholder='Search a booking item'
              variant='outlined'
              margin='normal'
            />
          </Box>
          <SecureContainer sections={[Sections.bookingTypes]} permission='modify'>
            <Box>
              <Button
                variant='contained'
                startIcon={<AddIcon />}
                onClick={() => onAdd()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                }}
              >
                Add
              </Button>
            </Box>
          </SecureContainer>
        </Box>
        <Box height={'100%'}>
          <DataGrid
            isRowSelectable={() => false}
            sx={dataGridStyles}
            rows={data.items}
            rowCount={data.count}
            columns={columns}
            page={page}
            pageSize={pageSize}
            sortModel={sortOptions ? [sortOptions] : []}
            sortingMode='server'
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={onPageChanged}
            onPageSizeChange={onPerPageChanged}
            onSortModelChange={onSortModelChanged}
            // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getRowId={(row: IBookingType) => row.fld_uid!}
          />
        </Box>
        <ManageBookingTypeDialog item={activeItem} open={openDialog} onClose={onCloseDialog} />
      </>
    </LoadPermissionsContainer>
  );
}
