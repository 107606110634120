export default {
  width: '100%',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0,
  },
  '& .MuiButtonBase-root': {
    paddingX: 3,

    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '48px',
      marginBottom: '8px',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: '0 8px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    backgroundColor: '#007DFF1A',
  },
};
