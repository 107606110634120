import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { IProps } from './interfaces';

export default function ImagesView({ files }: IProps) {
  const [active, setActive] = useState<IAttachedFile>();

  useEffect(() => {
    if (files) {
      setActive(files[0]);
    }
  }, [files]);

  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{
          width: '342px',
          height: '204px',
          mb: 2,
          backgroundColor: '#f7f9fa',
          borderRadius: 2.5,
        }}
      >
        {active && (
          <img
            src={active?.preview}
            alt={active?.file_name}
            style={{ width: '342px', height: '204px', objectFit: 'cover', borderRadius: 10 }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridColumnGap: 24,
          gridRowGap: 12,
        }}
      >
        {files &&
          files.map((file) => (
            <Box
              key={file.file_id}
              sx={{
                display: 'flex',
                border: '1px solid',
                borderColor:
                  file.file_id === active?.file_id ? (t) => t.palette.primary.main : 'transparent',
                borderRadius: 2,
              }}
            >
              <img
                src={file.preview}
                alt={file.file_name}
                onClick={() => setActive(file)}
                style={{
                  width: '66px',
                  height: '58px',
                  objectFit: 'cover',
                  borderRadius: 8,
                  cursor: 'pointer',
                }}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
}
