/* eslint-disable camelcase */

import { Box, Chip, IconButton, TextField, Theme, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useApi, useAppSelector, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IMessage } from '@rs/providers/interfaces/entities/IMessage';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import MessageService from '@rs/services/MessagesService';
import TextWithNewLines from '@rs/components/TextWithNewLines';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import { UserGroups } from '@rs/constants/UserGroups';
import { dateToFromNowDaily } from '@rs/helpers/date';
import dialogInputStyles from '@rs/styles/dialogInput';
import moment from 'moment';
import { selectAuthMainUserGroup } from '@rs/reducers/authSlice';

interface IProps {
  conversation: IConversation;
  onLastMessageUpdated: (message: IMessage) => void;
}

interface FormData {
  text: string;
}

export default function ConversationView(props: IProps) {
  const { conversation } = props;
  const role = useAppSelector(selectAuthMainUserGroup);

  const [files, setFiles] = useState<UploaderFile[]>([]);

  const formDefaultData = {
    text: '',
  };

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(true);

  const createMessageRequest = useApi(MessageService.createMessage);
  const getMessagesRequest = useApi(MessageService.getMessages, undefined, (state) => {
    setLoading(state);
  });
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const getMessagesQuietRequest = useApi(MessageService.getMessages, undefined, () => {});

  const loadLatestData = useCallback(async () => {
    const filters = conversation.fld_uid
      ? {
          // eslint-disable-next-line camelcase
          fld_conversation_id: [conversation.fld_uid],
        }
      : undefined;
    const latestMessages = (await getMessagesQuietRequest(5, 0, filters)) || [];
    const lastMessage = messages[0];
    const newMessages: IMessage[] = [];
    for (
      let i = 0;
      i < latestMessages.length &&
      (lastMessage ? latestMessages[i].fld_uid !== lastMessage.fld_uid : true);
      i++
    ) {
      newMessages.push(latestMessages[i]);
    }
    setMessages([...newMessages, ...messages]);
  }, [conversation, messages]);

  const loadData = useCallback(async () => {
    const filters = conversation.fld_uid
      ? {
          // eslint-disable-next-line camelcase
          fld_conversation_id: [conversation?.fld_uid],
        }
      : undefined;
    const messages = (await getMessagesRequest(undefined, undefined, filters)) || [];
    setMessages(messages);
  }, [conversation]);

  useEffect(() => {
    form.reset(formDefaultData);
    if (conversation) {
      loadData();
    } else {
      setMessages([]);
    }
  }, [conversation]);

  const onSendMessage = async () => {
    const { text } = form.getValues();
    if (!text && !files.length) return;

    const message: Partial<IMessage> = {
      fld_text: text,
      fld_conversation_id: conversation.fld_uid,
      fld_author_role: UserGroups.resident,
    };

    message.updatedFiles = [...files];

    await createMessageRequest(message);
    form.reset(formDefaultData);
    setFiles([]);
    await loadLatestData();
  };

  const onAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const acceptedFiles = Array.from(e.target.files);

    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      })),
    ]);
  };

  const onFileDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      loadLatestData();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [conversation, loadLatestData]);

  useUpdateEffect(() => {
    const lastMessage = messages[0];
    if (conversation.last_message?.fld_uid !== lastMessage.fld_uid) {
      props.onLastMessageUpdated(lastMessage);
    }
  }, [conversation.last_message, messages]);

  const isMyMessage = (message: IMessage) => {
    return (
      message.fld_author_role === role ||
      (role === UserGroups.resident && message.fld_author_role.toString() === 'Resident')
    );
  };

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: (t) => t.palette.grey[100],
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          rowGap: 2,
          flexDirection: 'column-reverse',
          p: 3,
          overflowY: 'auto',
        }}
      >
        {messages.map((x) => (
          <Box
            key={x.fld_uid}
            sx={{
              display: 'flex',
              justifyContent: isMyMessage(x) ? 'flex-end' : 'flex-start',
            }}
          >
            <Box>
              <Box
                sx={{
                  px: 1.5,
                  py: 1,
                  borderRadius: 5,
                  borderBottomRightRadius: isMyMessage(x) ? 0 : undefined,
                  borderBottomLeftRadius: !isMyMessage(x) ? 0 : undefined,
                  backgroundColor: (t) => (isMyMessage(x) ? t.palette.primary.main : '#fff'),
                  color: (t) =>
                    isMyMessage(x) ? t.palette.primary.contrastText : t.palette.grey[600],
                }}
              >
                {!!x.fld_text && (
                  <Typography variant='subtitle2'>
                    <TextWithNewLines text={x.fld_text} />
                  </Typography>
                )}
                {!!x.files.length &&
                  x.files.map((file) => (
                    <Box key={file.file_id}>
                      <img
                        src={file.preview}
                        style={{ overflow: 'hidden', maxWidth: 200, borderRadius: 4 }}
                        alt={file.file_name}
                      />
                    </Box>
                  ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: x.fld_author_role === role ? 'flex-end' : 'flex-start',
                }}
              >
                <Typography
                  variant='caption'
                  sx={{
                    color: (t) => t.palette.grey[600],
                  }}
                >
                  {dateToFromNowDaily(moment(x.fld_timestamp, 'DD/MM/YYYY HH:mm:ss').format())}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
        {loading && (
          <Box
            sx={{
              p: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Loading...
          </Box>
        )}
      </Box>
      <Box sx={{ backgroundColor: '#fff', borderBottomRightRadius: 8 }}>
        <form
          id='form'
          onSubmit={form.handleSubmit(() => {
            onSendMessage();
          })}
          noValidate
        >
          {!!files.length && (
            <Box sx={{ px: 2, my: 1 }}>
              {files.map((x, i) => (
                <Chip
                  sx={{
                    mr: 1,
                    mt: 1,
                  }}
                  key={i}
                  color='primary'
                  variant='outlined'
                  icon={<AttachFileIcon />}
                  label={(x as INewAttachedFile).file.name}
                  onDelete={() => onFileDelete(i)}
                />
              ))}
            </Box>
          )}
          <Box
            sx={{
              py: 1,
              px: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              sx={{
                color: (t: Theme) => t.palette.primary.main,
              }}
              aria-label='upload file'
              component='label'
            >
              <input hidden accept='image/*' type='file' onChange={onAttachment} />
              <AttachFileIcon />
            </IconButton>
            <Controller
              name={'text'}
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={dialogInputStyles}
                  placeholder='Type here'
                  variant='outlined'
                  margin='normal'
                  multiline
                  onChange={onChange}
                  value={value}
                  minRows={1}
                  maxRows={4}
                />
              )}
            />
            <IconButton
              form='form'
              type='submit'
              sx={{
                backgroundColor: (t) => t.palette.primary.main,
                color: (t) => t.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: (t) => t.palette.primary.dark,
                },
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
