import axios from "axios";
import { IAuthenticateResponse } from "./interfaces/responses/IAuthenticateResponse";
import { IGlobalLoginResponse } from "./interfaces/responses/IGlobalLoginResponse";

export default class AuthProvider {
  globalAuthenticate = async (email: string, password: string) => {
    const params = new URLSearchParams();
    params.append("user", email);
    params.append("pwd", password);
    params.append("client", "resi");
    params.append("action", "authenticateuser");
    return axios.post<{ userId: number }>(
      "https://global.zutec.com/global_webservices/login.php",
      params
    );
  };

  globalGetZones = async (email: string, password: string) => {
    const params = new URLSearchParams();
    params.append("user", email);
    params.append("pwd", password);
    params.append("client", "resi");
    params.append("action", "getzones");
    return axios.post<IGlobalLoginResponse>(
      "https://global.zutec.com/global_webservices/login.php",
      params
    );
  };

  authenticate = (
    email: string,
    password: string,
    apiUrl: string,
    deploymentId: string
  ) => {
    return axios.post<IAuthenticateResponse>(
      `${apiUrl}/deployment/${deploymentId}/authenticate/`,
      {
        username: email,
        password,
      }
    );
  };
}
