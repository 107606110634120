import { Theme } from '@mui/material';

export default {
  marginY: 0,
  borderRadius: 1,
  '& .MuiInputBase-input': {
    backgroundColor: (t: Theme) => t.palette.grey[100],
    color: (t: Theme) => t.palette.grey[600],
    paddingX: 2,
    paddingY: 0.75,
    fontSize: (t: Theme) => t.typography.subtitle2.fontSize,
    lineHeight: (t: Theme) => t.typography.subtitle2.lineHeight,
    letterSpacing: (t: Theme) => t.typography.subtitle2.letterSpacing,
    fontWeight: (t: Theme) => t.typography.subtitle2.fontWeight,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: 0,
  },
};
