/* eslint-disable camelcase */
import BaseProvider from './BaseProvider';
import { IGetZoneStyleResponse } from './interfaces/responses/IGetZoneStyleResponse';

export default class ZoneProvider extends BaseProvider {
  getZoneStyle = async () => {
    return this.get<IGetZoneStyleResponse>(`${this.getBaseApiUrl()}/style/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  formatLogoUrl = (logoUrl: string) => {
    return `${this.getApiDomain()}/${logoUrl.replaceAll('\\', '')}`;
  };
}
