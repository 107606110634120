import { Grid } from "@mui/material";
import ProjectListItem from "./item";
import { IZone } from "@rs/providers/interfaces/IZone";

interface Props {
    projects: IZone[]
    onProjectClick: (project: IZone) => {}
}

export default function ProjectList({ projects, onProjectClick }: Props) {
  const projectItems = projects?.map((project) => (
    <ProjectListItem key={project.id} project={project} onProjectClick={onProjectClick} />
  ));

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingBottom: "16px",
      }}
    >
      {projectItems}
    </Grid>
  );
}
