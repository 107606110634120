import { Theme } from '@mui/material';

export default {
  border: 0,
  boxShadow: '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: (t: Theme) => t.palette.grey[100],
    borderBottomColor: (t: Theme) => t.palette.primary.main,
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: (t: Theme) => t.typography.subtitle2.fontSize,
      lineHeight: (t: Theme) => t.typography.subtitle2.lineHeight,
      letterSpacing: (t: Theme) => t.typography.subtitle2.letterSpacing,
      fontWeight: (t: Theme) => t.typography.subtitle2.fontWeight,
    },
  },
};
