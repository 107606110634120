import { AlertContainer } from 'react-alert';
import { NavigateFunction } from 'react-router-dom';

export default async (
  func: () => Promise<void>,
  alert: AlertContainer,
  navigate?: NavigateFunction,
) => {
  try {
    return await func();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log(error);
    const message = (error.message ? error.message : error) as string;
    if (message.includes('Authentication failed.') && navigate) {
      navigate('/sign-in');
    } else {
      alert.error(message);
    }
  }
};
