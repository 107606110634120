import { Typography, Link, Box } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Copyright(props: any) {
  return (
    <Box {...props}>
      <Typography variant='body2'>
        {'For more features and components feel free to '}
        <Link href='#'>contact us</Link>.
      </Typography>
      <Typography marginTop={1} variant='body2' sx={{ color: (t) => t.palette.grey[600] }}>
        {'© Zutec. '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}
