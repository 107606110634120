/* eslint-disable camelcase */

import 'react-quill/dist/quill.snow.css';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import ApartmentService from '@rs/services/ApartmentService';
import BuildingService from '@rs/services/BuildingService';
import { IParcel } from '@rs/providers/interfaces/entities/IParcel';
import { IResidentSelectOption } from '@rs/providers/interfaces/IResidentSelectOption';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import { ParcelStatuses } from '@rs/constants/ParcelStatuses';
import ReactQuill from 'react-quill';
import ResidentService from '@rs/services/ResidentService';
import moment from 'moment';
import quillEditorStyles from '@rs/styles/quillEditor';
import { useApi } from '@rs/helpers/hooks';

interface IProps {
  open: boolean;
  onClose: (data?: Partial<IParcel>) => void;
}

interface FormData {
  amount: string;
  message: string;
  buildingId: string;
  apartmentId: string;
  residentId: string;
}

export default function ManageNewsDialog(props: IProps) {
  const { onClose, open } = props;

  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const getApartmentsRequest = useApi(ApartmentService.getApartments);
  const getResidentsRequest = useApi(ResidentService.getResidents);

  const [buildingOptions, setBuildingOptions] = useState<ISelectOption[]>([]);
  const [residentOptions, setResidentOptions] = useState<IResidentSelectOption[]>([]);
  const [apartmentOptions, setApartmentOptions] = useState<ISelectOption[]>([]);

  const formDefaultData = useMemo(
    () => ({
      amount: '',
      message: '',
      buildingId: '',
      apartmentId: '',
      residentId: '',
    }),
    [],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const watchBuildingId = form.watch('buildingId');
  const watchApartmentId = form.watch('apartmentId');

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const formData = form.getValues();
    const updatedItem: Partial<IParcel> = {
      fld_building_id: parseInt(formData.buildingId),
      fld_apartment_id: parseInt(formData.apartmentId),
      fld_resident_id: parseInt(formData.residentId),
      fld_amount: parseInt(formData.amount) || 0,
      fld_message: formData.message,
      fld_status: ParcelStatuses.received,
      fld_last_action_date: moment().format(),
    };

    onClose(updatedItem);
    form.reset(formDefaultData);
  };

  useEffect(() => {
    (async () => {
      const buildings = (await getBuildingsRequest())?.items || [];
      setBuildingOptions([
        ...buildings.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_building_name,
        })),
      ]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const apartments = watchBuildingId
        ? (
            await getApartmentsRequest(undefined, 0, {
              fld_building_id: +watchBuildingId,
            })
          )?.items || []
        : [];
      setApartmentOptions([
        ...apartments.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_apartment_name,
          additional: x.fld_building_id.toString(),
        })),
      ]);
      form.setValue('apartmentId', '');
      form.setValue('residentId', '');
    })();
  }, [watchBuildingId]);

  useEffect(() => {
    (async () => {
      const residents = watchApartmentId
        ? (
            await getResidentsRequest(undefined, 0, {
              fld_apartment_id: +watchApartmentId,
            })
          )?.items || []
        : [];
      setResidentOptions([
        ...residents.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_firstname,
          lastName: x.fld_surname,
          description: x.fld_apartment_id?.toString(),
          primary: x.fld_primary_resident,
          image: x.files && x.files[0],
        })),
      ]);
    })();
  }, [watchApartmentId]);

  useEffect(() => {
    const primaryResident = residentOptions.find((x) => x.primary);
    form.setValue('residentId', primaryResident ? primaryResident.value : '');
  }, [residentOptions]);

  const getResidentName = (resident: IResidentSelectOption) => {
    return `${resident?.label} ${resident?.lastName}`.trim();
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            New
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              type='submit'
              form='form'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Create
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>Notify:</Typography>
                <Alert severity='info' sx={{ px: 2, py: 0, ml: 2 }}>
                  <Typography variant='subtitle2'>
                    Pick one or more resident you want to notify about the parcel.
                  </Typography>
                </Alert>
              </Box>
              <Box sx={{ display: 'flex', mt: 5 }}>
                <Controller
                  name={'buildingId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the building',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: '33%', mr: 5 }} variant='standard'>
                      <InputLabel id='building-checkbox-label'>Building</InputLabel>
                      <Select
                        labelId='building-checkbox-label'
                        id='building-checkbox'
                        value={value}
                        onChange={onChange}
                        renderValue={(selected) =>
                          buildingOptions.find((x) => x.value === selected)?.label
                        }
                      >
                        {buildingOptions.map((item) => (
                          <MenuItem
                            sx={{
                              px: 1,
                              py: 0,
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Radio size='small' checked={value === item.value} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name={'apartmentId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the apartment',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: '33%', mr: 5 }} variant='standard'>
                      <InputLabel id='apartment-checkbox-label'>Apartment</InputLabel>
                      <Select
                        labelId='apartment-checkbox-label'
                        id='apartment-checkbox'
                        value={value}
                        disabled={!form.getValues('buildingId')}
                        onChange={onChange}
                        renderValue={(selected) =>
                          apartmentOptions.find((x) => x.value === selected)?.label || ''
                        }
                      >
                        {apartmentOptions.map((item) => (
                          <MenuItem
                            sx={{
                              px: 1,
                              py: 0,
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Radio size='small' checked={value === item.value} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name={'residentId'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the resident',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: '33%' }} variant='standard'>
                      <InputLabel id='resident-checkbox-label'>Resident</InputLabel>
                      <Select
                        labelId='resident-checkbox-label'
                        id='resident-checkbox'
                        value={value}
                        disabled={!(form.getValues('buildingId') && form.getValues('apartmentId'))}
                        onChange={onChange}
                        renderValue={(selected) => {
                          const resident = residentOptions.find((x) => x.value === selected);
                          return resident ? getResidentName(resident) : '';
                        }}
                      >
                        {residentOptions.map((item) => (
                          <MenuItem
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              px: 1,
                              py: 0,
                              borderBottom: 1,
                              borderColor: (t: Theme) => t.palette.grey[100],
                            }}
                            key={item.value}
                            value={item.value}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box
                                sx={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: '50%',
                                  backgroundColor: '#f3f3f3',
                                  backgroundImage: `url(${item?.image?.preview})`,
                                  backgroundSize: 'cover',
                                  mr: 1,
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  py: 1,
                                }}
                              >
                                <Typography variant='subtitle2'>{getResidentName(item)}</Typography>
                                <Typography variant='caption'>
                                  {item?.primary ? 'Primary Resident' : 'Resident'}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Radio size='small' checked={value === item.value} />
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
              <Box sx={{ width: '35%', mt: 5 }}>
                <Controller
                  name={'amount'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the amount',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Amount of packages'
                      variant='outlined'
                      type='number'
                      error={!!formState.errors.amount}
                      helperText={formState.errors.amount?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: 5 }}>
                <Box>
                  <Controller
                    name={'message'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the message',
                      },
                      maxLength: {
                        value: 1024,
                        message: 'Max length should be less or equal 1024 characters',
                      },
                      validate: {
                        required: (value) => {
                          const textValue = value.replace(/<[^>]+>/g, '');
                          if (!textValue) return 'Please enter the message';
                        },
                        maxLength: (value) => {
                          const textValue = value.replace(/<[^>]+>/g, '');
                          if (textValue.length > 1024)
                            return 'Max length should be less or equal 1024 characters';
                        },
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <Box
                        sx={{
                          mt: 2,
                          ...quillEditorStyles,
                        }}
                      >
                        <ReactQuill
                          theme='snow'
                          onChange={onChange}
                          value={value}
                          modules={{
                            toolbar: [
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'link',
                                { list: 'ordered' },
                                { list: 'bullet' },
                                'blockquote',
                                'clean',
                              ],
                            ],
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          placeholder='Type message here. Max length 1024 characters.'
                        />
                        <TextField
                          error={!!formState.errors.message}
                          helperText={formState.errors.message?.message}
                          value={value}
                          type='hidden'
                          sx={{
                            '& fieldset': {
                              display: 'none',
                            },
                          }}
                        />
                      </Box>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
