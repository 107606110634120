/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { IAttachedFile } from './interfaces/entities/IAttachedFile';
import { Sections } from '@rs/constants/Sections';

export interface OrderingOptions {
  order_direction: 'asc' | 'desc';
  order_column: string;
}

export default class FilesProvider extends BaseProvider {
  generatePreviewLink = (file: IAttachedFile) => {
    const link = encodeURI(
      `${this.getBaseFilesUrl()}/${file.repository}/${file.file_name}?token=${this.getAuthToken()}`,
    );
    return link;
  };

  getFile = async (section: Sections, entityId: number, fileId: number) => {
    const item = this.getSections().find((x) => x.name === section);
    if (!item) throw new Error('Failed to detect the section');
    const nodeId = item.id;
    return this.get<ArrayBuffer>(
      `${this.getBaseApiUrl()}/node/${nodeId}/${entityId}/file/${fileId}/`,
      {
        // return this.get<any>(
        // 'https://images.pling.com/img/00/00/48/70/84/1220648/e4fff450a6306e045f5c26801ce31c3efaeb.jpg', {
        headers: {
          auth: this.getAuthToken(),
        },
        responseType: 'arraybuffer',
      },
    );
  };

  createFile = async (section: Sections, entityId: number, file: File) => {
    const item = this.getSections().find((x) => x.name === section);
    if (!item) throw new Error('Failed to detect the section');
    const nodeId = item.id;

    const formData = new FormData();
    formData.set('file', file);
    return this.post<FormData, void>(
      `${this.getBaseApiUrl()}/node/${nodeId}/record/${entityId}/rs-file/`,
      formData,
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };

  deleteFile = async (section: Sections, entityId: number, fileId: number) => {
    const item = this.getSections().find((x) => x.name === section);
    if (!item) throw new Error('Failed to detect the section');
    const nodeId = item.id;
    return this.delete(
      `${this.getBaseApiUrl()}/node/${nodeId}/record/${entityId}/file/${fileId}/`,
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
}
