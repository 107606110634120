/* eslint-disable camelcase */

import 'react-quill/dist/quill.snow.css';

import { Box, Button, Dialog, DialogContent, TextField, Theme, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileUploader from '@rs/components/FileUploader';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import ImageUploader from '@rs/components/ImageUploader';
import ReactQuill from 'react-quill';
import SaveIcon from '@mui/icons-material/Save';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import quillEditorStyles from '@rs/styles/quillEditor';

interface IProps {
  item?: IBuilding;
  type?: string;
  open: boolean;
  onDelete: () => void;
  onClose: (data?: Partial<IBuilding>) => void;
}

interface FormData {
  name: string;
  floors: number;
  address: string;
  description: string;
}

export default function ManageBuildingDialog(props: IProps) {
  const { onClose, open, item, type, onDelete } = props;

  const [files, setFiles] = useState<UploaderFile[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<UploaderFile[]>([]);

  const formDefaultData = useMemo(
    () => ({
      name: item?.fld_building_name || '',
      description: item?.fld_description || '',
      floors: item?.fld_floors || undefined,
      address: item?.fld_address || '',
    }),
    [item],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  useEffect(() => {
    form.reset(formDefaultData);
  }, [item]);

  const onCancel = () => {
    onClose();
  };

  const onRemove = () => {
    onDelete();
  };

  const onSave = () => {
    const formData = form.getValues();

    const updatedItem: Partial<IBuilding> = {
      fld_building_name: formData.name,
      fld_floors: formData.floors,
      fld_address: formData.address,
      fld_description: formData.description,
    };

    updatedItem.files = item?.files;
    updatedItem.updatedFiles = files;
    updatedItem.attachedFiles = item?.attachedFiles || [];
    updatedItem.updatedAttachedFiles = attachedFiles;

    onClose(updatedItem);
  };

  const images = useMemo(() => item?.files || [], [item]);
  const attached = useMemo(() => item?.attachedFiles || [], [item]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {item ? 'Edit' : 'New'}
          </Typography>
          <Box>
            {type === 'edit' && (
              <Button
                variant='text'
                onClick={() => onRemove()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                  ml: 2,
                  color: (t: Theme) => t.palette.error.main,
                }}
              >
                <DeleteForeverIcon sx={{ mr: 0.5 }} />
                Remove
              </Button>
            )}
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              type='submit'
              form='form'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              {type === 'edit' && <SaveIcon sx={{ fontSize: '1rem', mr: 0.5 }} />}
              {item ? 'Save changes' : 'Create'}
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              if (!files.length) {
                console.error('Please upload at least 1 image');
                return;
              }
              onSave();
            })}
            noValidate
          >
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Picture:</Typography>
              <Box sx={{ mt: 2 }}>
                <ImageUploader
                  maxAmount={4}
                  images={images}
                  onImagesUpdated={(items) => {
                    setFiles(items);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridGap: '2.5rem',
                mt: 5,
              }}
            >
              <Box>
                <Typography variant='h6'>Building name</Typography>
                <Controller
                  name={'name'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the building name',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Name'
                      variant='outlined'
                      error={!!formState.errors.name}
                      helperText={formState.errors.name?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography variant='h6'>Floor Count</Typography>
                <Controller
                  name={'floors'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the floor count',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Amount'
                      variant='outlined'
                      error={!!formState.errors.floors}
                      helperText={formState.errors.floors?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography variant='h6'>Location</Typography>
                <Controller
                  name={'address'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the location address',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ width: '100%', mt: 2 }}
                      label='Address'
                      variant='outlined'
                      error={!!formState.errors.address}
                      helperText={formState.errors.address?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Attachments:
                </Typography>
                <FileUploader
                  maxAmount={4}
                  attachedFiles={attached}
                  onFilesUpdated={(items) => {
                    setAttachedFiles(items);
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>Info</Typography>
              <Box>
                <Controller
                  name={'description'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the description',
                    },
                    maxLength: {
                      value: 1024,
                      message: 'Max length should be less or equal 1024 characters',
                    },
                    validate: {
                      required: (value) => {
                        const textValue = value.replace(/<[^>]+>/g, '');
                        if (!textValue) return 'Please enter the description';
                      },
                      maxLength: (value) => {
                        const textValue = value.replace(/<[^>]+>/g, '');
                        if (textValue.length > 1024)
                          return 'Max length should be less or equal 1024 characters';
                      },
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <Box
                      sx={{
                        mt: 2,
                        ...quillEditorStyles,
                      }}
                    >
                      <ReactQuill
                        theme='snow'
                        onChange={onChange}
                        value={value}
                        modules={{
                          toolbar: [
                            [
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'link',
                              { list: 'ordered' },
                              { list: 'bullet' },
                              'blockquote',
                              'clean',
                            ],
                          ],
                          clipboard: {
                            matchVisual: false,
                          },
                        }}
                        placeholder='Enter building description here. Max length 1024 characters.'
                      />
                      <TextField
                        error={!!formState.errors.description}
                        helperText={formState.errors.description?.message}
                        value={value}
                        type='hidden'
                        sx={{
                          '& fieldset': {
                            display: 'none',
                          },
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
