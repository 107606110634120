import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sections } from '@rs/constants/Sections';
import { UserGroups } from '@rs/constants/UserGroups';
import { useAppSelector } from '@rs/helpers/hooks';
import { selectAuthSections, selectAuthUserGroups } from '@rs/reducers/authSlice';

interface IProps {
  children: React.ReactElement;
  sections: Sections[];
  userGroups?: UserGroups[];
  redirect?: boolean;
  permission?: 'read' | 'modify' | 'delete';
}

export default function SecureContainer(props: IProps) {
  const navigate = useNavigate();
  const authSections = useAppSelector(selectAuthSections);
  const userGroups = useAppSelector(selectAuthUserGroups);

    const getAllowed = useCallback(
        (sections: string[]) => {
            let filteredSections = authSections.filter((x) => sections.includes(x.name));

            if (props.userGroups) {
                const matchedUserGroups = userGroups.filter((x) =>
                    props.userGroups?.find((c) => x.name.includes(c)),
                );
                if (!matchedUserGroups.length) {
                    return false;
                }
            }

            if (props.permission) {
                filteredSections = filteredSections.filter(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    (x) => x.permissions && x.permissions[props.permission!],
                );
            }

            return !!filteredSections.length;
        },
        [authSections, props.sections],
    );

  const allowed = getAllowed(props.sections);

  useEffect(() => {
    if (!allowed && props.redirect) {
      navigate('/sign-in');
    }
  }, [allowed]);

  return allowed ? props.children : null;
}
