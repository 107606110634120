import { store } from '../store';

export const checkSectionPermissions = (
  section: string,
  permission: 'read' | 'modify' | 'delete',
) => {
  const state = store.getState();
  const { sections } = state.auth;
  const existingSection = sections.find((x) => section === x.name);
  if (!existingSection || !existingSection.permissions) return false;
  return !!existingSection.permissions[permission];
};
