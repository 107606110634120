import { Box } from '@mui/material';
import { IProps } from './interfaces';
import TextWithNewLines from '../TextWithNewLines';

export default function TableCellWithMultilineText({ text }: IProps) {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        display: text.includes('\n') ? '-webkit-box' : undefined,
      }}
    >
      <TextWithNewLines text={text} />
    </Box>
  );
}
