import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Typography,
} from '@mui/material';
import { useApi, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ApartmentService from '@rs/services/ApartmentService';
import BuildingService from '@rs/services/BuildingService';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import ListItem from './components/ListItem';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageApartmentDialog from './components/ManageApartmentDialog';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';

interface IFilters {
  building: string;
  floor: string;
  apartmentGroup: string;
}

export default function Apartments() {
  const [pageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [data, setData] = useState<{ count: number; items: IApartment[] }>({
    count: 0,
    items: [],
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem] = useState<IApartment | undefined>(undefined);

  const buildingInput = useInput<string>('all');
  const [buildingOptions, setBuildingOptions] = useState<ISelectOption[]>([]);
  const floorInput = useInput<string>('all');
  const [floorOptions, setFloorOptions] = useState<ISelectOption[]>([]);
  const [apartmentTypeOptions, setApartmentTypeOptions] = useState<ISelectOption[]>([]);
  const apartmentGroupInput = useInput<string>('all');
  const [apartmentGroupOptions, setApartmentGroupOptions] = useState<ISelectOption[]>([]);

  const filters = useMemo<IFilters>(
    () => ({
      building: buildingInput.value,
      floor: floorInput.value,
      apartmentGroup: apartmentGroupInput.value,
    }),
    [buildingInput.value, apartmentGroupInput.value, floorInput.value],
  );

  const debouncedFilters = useDebounce<IFilters>(filters, 500);

  const getApartmentsRequest = useApi(ApartmentService.getApartments);
  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const getApartmentTypesRequest = useApi(ApartmentService.getApartmentTypes);
  const getApartmentGroupsRequest = useApi(ApartmentService.getApartmentGroups);
  const createApartmentRequest = useApi(ApartmentService.createApartment);

  const loadData = useCallback(async () => {
    const result = await getApartmentsRequest(pageSize, page * pageSize, {
      // eslint-disable-next-line camelcase
      fld_building_id: debouncedFilters.building === 'all' ? undefined : +debouncedFilters.building,
      // eslint-disable-next-line camelcase
      fld_apartment_group_id:
        debouncedFilters.apartmentGroup === 'all' ? undefined : +debouncedFilters.apartmentGroup,
      // eslint-disable-next-line camelcase
      fld_floor: debouncedFilters.floor === 'all' ? undefined : +debouncedFilters.floor,
    });
    setData({ count: result?.total_count || 0, items: result?.items || [] });
  }, [page, pageSize, debouncedFilters]);

  const loadAudience = useCallback(async () => {
    const buildings = (await getBuildingsRequest())?.items || [];
    setBuildingOptions([
      { value: 'all', label: 'All' },
      ...buildings.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_building_name,
        additional: x.fld_floors.toString(),
      })),
    ]);

    const apartmentTypes = (await getApartmentTypesRequest()) || [];
    setApartmentTypeOptions([
      { value: 'all', label: 'All' },
      ...apartmentTypes.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_apartment_type_name,
      })),
    ]);
    const apartmentGroups = (await getApartmentGroupsRequest()) || [];
    setApartmentGroupOptions([
      { value: 'all', label: 'All' },
      ...apartmentGroups.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_apartment_group_name,
      })),
    ]);
  }, []);

  const onShowMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const onAdd = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<IApartment>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      await createApartmentRequest(item);
      setOpenDialog(false);
      await loadData();
    }
  };

  const getBuildingName = (apartment: IApartment) => {
    const buildingName = buildingOptions.find(
      (x) => x.value === String(apartment?.fld_building_id),
    );
    return buildingName?.label;
  };

  useEffect(() => {
    const floors = +(buildingOptions.find((x) => x.value === buildingInput.value)?.additional || 0);
    const floorsArray = Array(floors)
      .fill(0)
      .map((x, index) => ({ value: (index + 1).toString(), label: (index + 1).toString() }));
    setFloorOptions([{ value: 'all', label: 'All' }, ...floorsArray]);
    floorInput.onChange({ target: { value: 'all' } });
  }, [buildingOptions, buildingInput.value]);

  useEffect(() => {
    loadData();
    loadAudience();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [page, debouncedFilters]);

  const selectableBuildingOptions = useMemo(
    () => buildingOptions.filter((x) => x.value !== 'all'),
    [buildingOptions],
  );

  const selectableApartmentGroupOptions = useMemo(
    () => apartmentGroupOptions.filter((x) => x.value !== 'all'),
    [apartmentGroupOptions],
  );

  const selectableApartmentTypeOptions = useMemo(
    () => apartmentTypeOptions.filter((x) => x.value !== 'all'),
    [apartmentTypeOptions],
  );

  return (
    <LoadPermissionsContainer section={Sections.apartments}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Apartments
            </Typography>
          </Box>
          <SecureContainer sections={[Sections.apartments]} permission='modify'>
            <Box>
              <Button
                variant='contained'
                startIcon={<AddIcon />}
                onClick={() => onAdd()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                }}
              >
                Add
              </Button>
            </Box>
          </SecureContainer>
        </Box>
        <Box
          sx={{
            mb: 5,
            display: 'flex',
            alignItems: 'center',
            columnGap: '2rem',
          }}
        >
          <Typography variant='h6' fontWeight={600}>
            Filters:
          </Typography>
          <FormControl sx={{ width: 220 }} variant='standard'>
            <InputLabel id='building-checkbox-label'>Building</InputLabel>
            <Select
              labelId='building-checkbox-label'
              id='building-checkbox'
              value={buildingInput.value}
              onChange={buildingInput.onChange}
              renderValue={(selected) => buildingOptions.find((x) => x.value === selected)?.label}
            >
              {buildingOptions.map((item) => (
                <MenuItem
                  sx={{
                    px: 1,
                    py: 0,
                  }}
                  key={item.value}
                  value={item.value}
                >
                  <Radio size='small' checked={buildingInput.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 220 }} variant='standard'>
            <InputLabel id='type-checkbox-label'>Floor</InputLabel>
            <Select
              labelId='type-checkbox-label'
              id='type-checkbox'
              value={floorInput.value}
              onChange={floorInput.onChange}
              renderValue={(selected) => floorOptions.find((x) => x.value === selected)?.label}
            >
              {floorOptions.map((item) => (
                <MenuItem
                  sx={{
                    px: 1,
                    py: 0,
                  }}
                  key={item.value}
                  value={item.value}
                >
                  <Radio size='small' checked={floorInput.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 220 }} variant='standard'>
            <InputLabel id='group-checkbox-label'>Group</InputLabel>
            <Select
              labelId='group-checkbox-label'
              id='group-checkbox'
              value={apartmentGroupInput.value}
              onChange={apartmentGroupInput.onChange}
              renderValue={(selected) =>
                apartmentGroupOptions.find((x) => x.value === selected)?.label
              }
            >
              {apartmentGroupOptions.map((item) => (
                <MenuItem
                  sx={{
                    px: 1,
                    py: 0,
                  }}
                  key={item.value}
                  value={item.value}
                >
                  <Radio size='small' checked={apartmentGroupInput.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box height={'100%'}>
          {data.items &&
            data.items.length > 0 &&
            data.items.map((apartment, index) => (
              <ListItem
                key={index}
                data={apartment}
                buildingName={getBuildingName(apartment) || ''}
                baseUrl='apartments'
              />
            ))}
          {data.items && data.items.length > 0 && data.count > data.items.length && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
              <Button onClick={() => onShowMore()} variant='outlined' color='primary' size='small'>
                Load more
              </Button>
            </Box>
          )}
        </Box>
        <ManageApartmentDialog
          item={activeItem}
          open={openDialog}
          buildingOptions={selectableBuildingOptions}
          apartmentTypeOptions={selectableApartmentTypeOptions}
          apartmentGroupOptions={selectableApartmentGroupOptions}
          onClose={onCloseDialog}
          onDelete={() => setOpenDialog(false)}
        />
      </>
    </LoadPermissionsContainer>
  );
}
