import EventProvider from '@rs/providers/EventProvider';
import FilesProvider from '@rs/providers/FilesProvider';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { IBaseFilter } from '@rs/providers/interfaces/filters/IBaseFilter';
import { IEvent } from '@rs/providers/interfaces/entities/IEvent';
import { IEventAdditionalInfo } from '@rs/providers/interfaces/entities/IEventAdditionalInfo';
import { IEventAdditionalInfoFilter } from '@rs/providers/interfaces/filters/IEventAdditionalInfoFilter';
import { IEventFilter } from '@rs/providers/interfaces/filters/IEventFilter';
import { IEventRel } from '@rs/providers/interfaces/entities/IEventRel';
import { IEventRelFilter } from '@rs/providers/interfaces/filters/IEventRelFilter';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import { Sections } from '@rs/constants/Sections';
import TableDataProvider from '@rs/providers/TableDataProvider';

const filesProvider = new FilesProvider();
const eventDataProvider = new TableDataProvider<IEvent, IBaseFilter>(Sections.events);
const eventAdditionalInfoDataProvider = new TableDataProvider<
  IEventAdditionalInfo,
  IEventAdditionalInfoFilter
>(Sections.eventsAdditionalInfo);
const eventRelDataProvider = new TableDataProvider<IEventRel, IEventRelFilter>(Sections.eventsRel);
const eventProvider = new EventProvider();

const EventService = {
  getEvents: async (limit?: number, offset?: number, filters?: IEventFilter) => {
    const eventsResult = await eventProvider.getEvents(limit, offset, filters);

    eventsResult.items.forEach((item) => {
      item.files = item.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
      item.attachedFiles =
        item.events_additional_info && item.events_additional_info.length
          ? item.events_additional_info[0].files.map((file) => ({
              ...file,
              preview: filesProvider.generatePreviewLink(file),
            }))
          : [];
    });

    return eventsResult;
  },
  getEvent: async (id: number) => {
    const result = await EventService.getEvents(1, 0, {
      // eslint-disable-next-line camelcase
      fld_uid: id,
    });
    if (!result.items.length) {
      throw new Error('Event not found');
    }

    return result.items[0];
  },
  createEvent: async (value: Partial<IEvent>) => {
    // eslint-disable-next-line camelcase
    const { updatedAttachedFiles, updatedFiles } = value;
    const createEventResult = await eventProvider.createEvent(value);
    const id = createEventResult.event_id;
    const eventsAddInfoResult = await eventAdditionalInfoDataProvider.createTableDataRecord({
      // eslint-disable-next-line camelcase
      fld_event_id: id,
    });
    const addInfoId = eventsAddInfoResult.fld_uid;
    const attachedFilesToAdd = updatedAttachedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all([
      ...attachedFilesToAdd.map((x) =>
        filesProvider.createFile(Sections.eventsAdditionalInfo, addInfoId, x.file),
      ),
    ]);

    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all(filesToAdd.map((x) => filesProvider.createFile(Sections.events, id, x.file)));
  },
  updateEvent: async (
    id: number,
    newValue: Partial<IEvent>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    oldValue: IEvent,
  ) => {
    // eslint-disable-next-line camelcase
    const { events_relations, attachedFiles, files, updatedAttachedFiles, updatedFiles, ...data } =
      newValue;
    await eventDataProvider.updateTableDataRecord(id, data);

    // eslint-disable-next-line camelcase
    if (events_relations) {
      // eslint-disable-next-line camelcase
      const updatedEventRelations = { ...events_relations[0], fld_event_id: id };
      const [relData] = await eventRelDataProvider.getAllTableData({
        // eslint-disable-next-line camelcase
        fld_event_id: [id],
      });
      if (relData?.fld_uid) {
        await eventRelDataProvider.updateTableDataRecord(relData.fld_uid, updatedEventRelations);
      } else {
        await eventRelDataProvider.createTableDataRecord(updatedEventRelations);
      }
    }

    if (files && updatedFiles) {
      const remainingFiles = updatedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const filesToDelete =
        files?.filter((x) => !remainingFiles.find((c) => c.file_id === x.file_id)) || [];
      const filesToAdd = updatedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];

      await Promise.all([
        ...filesToAdd.map((x) => filesProvider.createFile(Sections.events, id, x.file)),
        ...filesToDelete.map((x) => filesProvider.deleteFile(Sections.events, id, x.file_id)),
      ]);
    }

    if (attachedFiles && updatedAttachedFiles) {
      // eslint-disable-next-line no-debugger
      const [apartmentAddInfoResult] = await eventAdditionalInfoDataProvider.getAllTableData({
        // eslint-disable-next-line camelcase
        fld_event_id: [id],
      });
      let addInfoId = apartmentAddInfoResult?.fld_uid;

      if (!apartmentAddInfoResult) {
        const newAdditionalInfo = await eventAdditionalInfoDataProvider.createTableDataRecord({
          // eslint-disable-next-line camelcase
          fld_event_id: id,
        });
        addInfoId = newAdditionalInfo.fld_uid;
      }

      const remainingAttachedFiles = updatedAttachedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const attachedFilesToDelete =
        attachedFiles?.filter(
          (x) => !remainingAttachedFiles.find((c) => c.file_id === x.file_id),
        ) || [];
      const attachedFilesToAdd = updatedAttachedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];

      if (attachedFilesToAdd.length || attachedFilesToDelete.length) {
        await Promise.all([
          ...attachedFilesToAdd.map((x) =>
            filesProvider.createFile(Sections.eventsAdditionalInfo, addInfoId, x.file),
          ),
          ...attachedFilesToDelete.map((x) =>
            filesProvider.deleteFile(Sections.eventsAdditionalInfo, addInfoId, x.file_id),
          ),
        ]);
      }
    }
  },
  deleteEvent: async (id: number) => {
    await eventProvider.deleteEvent(id);
  },
};

export default EventService;
