/* eslint-disable camelcase */

import { Box, IconButton, Theme, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import PublishIcon from '@mui/icons-material/Publish';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import { useDropzone } from 'react-dropzone';

interface IProps {
  attachedFiles: IAttachedFile[];
  maxAmount: number;
  onFilesUpdated: (items: UploaderFile[]) => void;
}

export default function FileUploader(props: IProps) {
  const { attachedFiles, maxAmount, onFilesUpdated } = props;

  const [files, setFiles] = useState<UploaderFile[]>([]);

  useEffect(() => {
    setFiles(attachedFiles.map((x) => ({ ...x })));
  }, [attachedFiles]);

  useEffect(() => {
    onFilesUpdated(files);
  }, [files]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) => ({
        file,
        file_name: file.name,
      })),
    ]);
  }, []);

  const onRemoveItem = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': [],
    },
    onDrop,
  });

  return (
    <Box sx={{ width: '100%' }}>
      {files.length < maxAmount && (
        <Box
          sx={{
            height: 50,
            position: 'relative',
            color: (t: Theme) => t.palette.primary.main,
            backgroundColor: (t: Theme) => t.palette.grey[100],
            borderRadius: 2,
            borderWidth: 2,
            borderStyle: 'dashed',
            cursor: 'pointer',
            borderColor: (t: Theme) => t.palette.primary.main,
          }}
          {...getRootProps()}
        >
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input {...getInputProps()} />
            <PublishIcon sx={{ mr: 1 }} />
            <Typography variant='body2'>UPLOAD</Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {files.map((x, i) => (
          <Box
            key={i}
            sx={{
              display: ' flex',
              alignItems: 'center',
              position: 'relative',
              border: 1,
              borderColor: (t: Theme) => t.palette.primary.main,
              borderRadius: 3,
              py: 0.5,
              pl: 1,
              pr: 4.5,
              mb: 1,
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                right: '-5px',
                top: '-4px',
              }}
              onClick={() => onRemoveItem(i)}
              color='primary'
              aria-label='Remove'
            >
              <CancelOutlinedIcon />
            </IconButton>
            <AttachFileIcon sx={{ fontSize: '1rem', mr: 1 }} />
            {x.file_name}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
