import type { AppDispatch, RootState } from '../store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';

import { setLoader } from '@rs/reducers/generalSlice';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useInput = <T>(initialValue: T) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event: {
    target: {
      value: T;
    };
  }) => {
    setValue(event.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export const useDebounce = <T>(value: T, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useUpdateEffect = (
  effect: () => void,
  dependencies: React.DependencyList | undefined,
) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, dependencies);
};

export const useApi = <TArgs extends Array<unknown>, TResult>(
  apiFunc: (...args: TArgs) => Promise<TResult>,
  onError?: (err: string) => void,
  onLoading?: (state: boolean) => void,
) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLoadingChanged = (state: boolean) => {
    if (onLoading) {
      onLoading(state);
    } else {
      dispatch(setLoader(state));
    }
  };

  const request = async (...args: TArgs) => {
    onLoadingChanged(true);
    try {
      const result = await apiFunc(...args);
      return result;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      const message = (error.message ? error.message : error) as string;
      if (onError) {
        onError(message);
      } else {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 401) {
            navigate('/sign-in');
          } else {
            alert.error(message);
          }
        } else if (message.includes('Authentication failed.')) {
          navigate('/sign-in');
        } else {
          alert.error(message);
        }
      }
    } finally {
      onLoadingChanged(false);
    }
  };

  return request;
};
