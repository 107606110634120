export default class User {
  id: number;
  residentId?: number;
  staffId?: number;
  name: string;
  email: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(obj?: any) {
    if (obj) {
      if (
        typeof obj.id !== 'number' ||
        !(typeof obj.residentId === 'number' || typeof obj.residentId === 'undefined') ||
        !(typeof obj.staffId === 'number' || typeof obj.staffId === 'undefined') ||
        typeof obj.name !== 'string' ||
        typeof obj.email !== 'string'
      ) {
        throw Error('Incorrect data type');
      }
      this.id = obj.id;
      this.residentId = obj.residentId;
      this.staffId = obj.staffId;
      this.name = obj.name;
      this.email = obj.email;
    } else {
      this.id = 0;
      this.residentId = undefined;
      this.staffId = undefined;
      this.name = '';
      this.email = '';
    }
  }
}
