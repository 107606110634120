/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { ICreateParcelRequest } from './interfaces/requests/ICreateParcelRequest';
import { IGetParcelsResponse } from './interfaces/responses/IGetParcelsResponse';
import { IParcel } from './interfaces/entities/IParcel';
import { IParcelFilter } from './interfaces/filters/IParcelFilter';

export default class ParcelProvider extends BaseProvider {
  getParcels = async (limit?: number, offset?: number, filters?: IParcelFilter) => {
    return this.get<IGetParcelsResponse>(`${this.getBaseApiUrl()}/rs/parcels/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  createParcel = async (value: Partial<IParcel>) => {
    const {
      fld_building_id,
      fld_apartment_id,
      fld_resident_id,
      fld_message,
      fld_status,
      fld_amount,
    } = value;
    return this.post<
      ICreateParcelRequest,
      {
        parcel_id: number;
      }
    >(
      `${this.getBaseApiUrl()}/rs/parcels/`,
      {
        building_id: fld_building_id,
        apartment_id: fld_apartment_id,
        resident_id: fld_resident_id,
        message: fld_message,
        status: fld_status,
        amount: fld_amount,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
}
