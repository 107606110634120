/* eslint-disable camelcase */

import FilesProvider from '@rs/providers/FilesProvider';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { IBaseFilter } from '@rs/providers/interfaces/filters/IBaseFilter';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import { IStaff } from '@rs/providers/interfaces/entities/IStaff';
import { IStaffFilter } from '@rs/providers/interfaces/filters/IStaffFilter';
import { Sections } from '@rs/constants/Sections';
import StaffProvider from '@rs/providers/StaffProvider';
import TableDataProvider from '@rs/providers/TableDataProvider';

const filesProvider = new FilesProvider();
const staffDataProvider = new TableDataProvider<IStaff, IBaseFilter>(Sections.staff);
const staffProvider = new StaffProvider();

const StaffService = {
  getStaff: async (limit?: number, offset?: number, filters?: IStaffFilter) => {
    const staffResult = await staffProvider.getStaff(limit, offset, filters);

    staffResult.items.forEach((item) => {
      item.files = item.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
    });

    return staffResult;
  },
  getStaffById: async (id: number) => {
    const result = await StaffService.getStaff(1, 0, {
      // eslint-disable-next-line camelcase
      fld_uid: id,
    });
    if (!result.items.length) {
      throw new Error('Staff not found');
    }

    return result.items[0];
  },
  findStaffByZutecId: async (id: number) => {
    const staff = await StaffService.getStaff(1, 0, {
      fld_zu_user_id: id,
    });
    return staff.items.length ? staff.items[0] : null;
  },
  updateStaff: async (id: number, value: Partial<IStaff>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { files, updatedFiles, ...data } = value;
    await staffDataProvider.updateTableDataRecord(id, data);

    if (files && updatedFiles) {
      const remainingFiles = updatedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const filesToDelete =
        files?.filter((x) => !remainingFiles.find((c) => c.file_id === x.file_id)) || [];
      const filesToAdd = updatedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];
      await Promise.all([
        ...filesToAdd.map((x) => filesProvider.createFile(Sections.staff, id, x.file)),
        ...filesToDelete.map((x) => filesProvider.deleteFile(Sections.staff, id, x.file_id)),
      ]);
    }
  },
};

export default StaffService;
