/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { ICreateResidentRequest } from './interfaces/requests/ICreateResidentRequest';
import { ICreateResidentResponse } from './interfaces/responses/ICreateResidentResponse';
import { IGetSectionPermissionsResponse } from './interfaces/responses/IGetSectionPermissionsResponse';
import { IGetUserGroupsResponse } from './interfaces/responses/IGetUserGroupsResponse';
import { IGetUserInfoResponse } from './interfaces/responses/IGetUserInfoResponse';

export default class UserProvider extends BaseProvider {
  getUserInfo = async () => {
    return this.get<IGetUserInfoResponse>(`${this.getBaseApiUrl()}/rs/user/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  getUserGroups = async () => {
    return this.get<IGetUserGroupsResponse>(`${this.getBaseApiUrl()}/user/groups/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  getSectionPermissions = async (nodeId: number) => {
    return this.get<IGetSectionPermissionsResponse>(
      `${this.getBaseApiUrl()}/node/${nodeId}/permissions/`,
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };

  createResident = async (data: ICreateResidentRequest) => {
    return this.post<ICreateResidentRequest, ICreateResidentResponse>(
      `${this.getBaseApiUrl()}/admin/users/`,
      data,
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };

  deleteResident = async (userId: number) => {
    return this.delete(`${this.getBaseApiUrl()}/admin/zone-user/`, {
      headers: {
        auth: this.getAuthToken(),
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        zone_id: this.getZoneId()?.id,
        user_id: userId,
      }),
    });
  };
}
