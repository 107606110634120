/* eslint-disable camelcase */
import { Box, IconButton, Theme, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { UploaderFile } from '@rs/providers/types/UploaderFile';

interface IProps {
  images: IAttachedFile[];
  maxAmount: number;
  onImagesUpdated: (items: UploaderFile[]) => void;
}

export default function ImageUploader(props: IProps) {
  const { images, maxAmount, onImagesUpdated } = props;

  const [files, setFiles] = useState<UploaderFile[]>([]);

  useEffect(() => {
    setFiles(images.map((x) => ({ ...x })));
  }, [images]);

  useEffect(() => {
    onImagesUpdated(files);
  }, [files]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      })),
    ]);
  }, []);

  const onRemoveItem = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': [],
    },
    onDrop,
  });

  const gridTemplateColumns = useMemo<string>(() => {
    const template = Array.from({ length: maxAmount || 0 }, () => '1fr').join(' ');
    return template;
  }, [maxAmount]);

  return (
    <Box sx={{ display: 'grid', gridGap: '1rem', gridTemplateColumns }}>
      {files.map((x, i) => (
        <Box
          key={i}
          sx={{
            borderRadius: 3,
            backgroundImage: `url('${x.preview}')`,
            backgroundSize: 'cover',
            position: 'relative',
            '&::after': {
              paddingBottom: '100%',
              content: '""',
              display: 'block',
            },
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              right: 1,
              top: 1,
            }}
            onClick={() => onRemoveItem(i)}
            color='primary'
            aria-label='Remove'
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
      ))}
      {files.length < maxAmount && (
        <Box
          sx={{
            position: 'relative',
            color: (t: Theme) => t.palette.primary.main,
            backgroundColor: (t: Theme) => t.palette.grey[100],
            borderRadius: 3,
            border: 2,
            borderStyle: 'dashed',
            cursor: 'pointer',
            borderColor: (t: Theme) => t.palette.primary.main,
            '&::before': {
              paddingBottom: '100%',
              content: '""',
              display: 'block',
            },
          }}
          {...getRootProps()}
        >
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input {...getInputProps()} />
            <AddIcon />
            <Typography variant='body2'>Upload</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
