import { Box, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Attachments from '@rs/components/Attachments';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { INews } from '@rs/providers/interfaces/entities/INews';
import ImagesView from '@rs/components/ImagesView';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import NewsService from '@rs/services/NewsService';
import { Sections } from '@rs/constants/Sections';
import moment from 'moment';
import parse from 'html-react-parser';
import { useApi } from '@rs/helpers/hooks';

export default function SingleNews() {
  const { id } = useParams();

  const [data, setData] = useState<INews>();
  const getNewsItemRequest = useApi(NewsService.getNewsItem);

  const loadData = useCallback(async () => {
    const item = await getNewsItemRequest(parseInt(id || ''));
    setData(item);
  }, [id]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <LoadPermissionsContainer section={Sections.news}>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 1,
              color: (t) => t.palette.primary.main,
              cursor: 'pointer',
              fontSize: (t) => t.typography.subtitle2.fontSize,
              fontWeight: 600,
            }}
          >
            <Link
              to='/news'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: (t) => t.typography.h5.fontSize, mr: 0.5 }} />
              Back to News
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              {data?.fld_heading}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ minWidth: '342px', display: 'flex', flexDirection: 'column' }}>
            <ImagesView files={data?.files} />
          </Box>
          <Box sx={{ pl: 3 }}>
            <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
              {data?.fld_heading}
            </Typography>
            <Typography
              component='span'
              variant='caption'
              sx={{ color: '#0000008A', display: 'flex' }}
            >
              <AccessTimeIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
              {moment(data?.fld_publish_date).fromNow()}
            </Typography>
            {parse(data?.fld_description || '')}
            <Attachments files={data?.attachedFiles} />
          </Box>
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
