import React, { useCallback, useEffect, useState } from 'react';
import { selectAuthSections, setSectionPermissions } from '@rs/reducers/authSlice';
import { useApi, useAppDispatch, useAppSelector } from '@rs/helpers/hooks';

import AuthService from '@rs/services/AuthService';
import { ISection } from '@rs/providers/interfaces/ISection';
import { Sections } from '@rs/constants/Sections';

interface IProps {
  children: React.ReactElement;
  section: Sections;
}

export default function LoadPermissionsContainer(props: IProps) {
  const dispatch = useAppDispatch();
  const authSections = useAppSelector(selectAuthSections);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const getSectionPermissionsRequest = useApi(AuthService.getSectionPermissions);

  const getSection = useCallback(
    (section: string) => {
      return authSections.find((x) => section === x.name);
    },
    [authSections],
  );

  const loadSectionPermissions = useCallback(async (sectionToCheck: ISection) => {
    if (sectionToCheck.permissions) {
      setPermissionsLoaded(true);
      return;
    }
    const permissions = await getSectionPermissionsRequest(sectionToCheck.id);
    if (permissions) {
      dispatch(setSectionPermissions({ sectionId: sectionToCheck.id, permissions }));
      setPermissionsLoaded(true);
    }
  }, []);

  useEffect(() => {
    const allowedSection = getSection(props.section);
    const allowed = !!allowedSection;
    if (allowed) {
      loadSectionPermissions(allowedSection);
    }
  }, [getSection, loadSectionPermissions, props.section]);

  return permissionsLoaded ? props.children : null;
}
