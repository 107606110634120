/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { IGetInformationCategoriesResponse } from './interfaces/responses/IGetInformationCategoriesResponse';
import { IInformationCategoryFilter } from './interfaces/filters/IInformationCategoryFilter';

export default class InformationCategoryProvider extends BaseProvider {
  getInformationCategories = async (
    limit?: number,
    offset?: number,
    filters?: IInformationCategoryFilter,
  ) => {
    const filtersArray: string[] = [];
    if (filters?.fld_building_id !== undefined) {
      filtersArray.push(
        `information-category-relation.fld_building_id = ${filters.fld_building_id}`,
      );
    }
    if (filters?.fld_apartment_group_id !== undefined) {
      filtersArray.push(
        `information-category-relation.fld_apartment_group_id = ${filters.fld_apartment_group_id}`,
      );
    }
    if (filters?.fld_apartment_type_id !== undefined) {
      filtersArray.push(
        `information-category-relation.fld_apartment_type_id = ${filters.fld_apartment_type_id}`,
      );
    }
    return this.get<IGetInformationCategoriesResponse>(
      `${this.getBaseApiUrl()}/rs/information-categories/`,
      {
        params: {
          limit: limit || 100,
          offset: offset || 0,
          ...(filtersArray.length && {
            filter: filtersArray.join(' and '),
          }),
        },
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
}
