import { Box, Button, Typography } from '@mui/material';
import { useApi, useAppSelector, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { INews } from '@rs/providers/interfaces/entities/INews';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import ListItem from '@rs/pages/resident/news/components/ListItem';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import NewsService from '@rs/services/NewsService';
import ResidentService from '@rs/services/ResidentService';
import { Sections } from '@rs/constants/Sections';
import { selectAuthUser } from '@rs/reducers/authSlice';

export default function News() {
  const user = useAppSelector(selectAuthUser);
  const [resident, setResident] = useState<IResident>();
  const [page, setPage] = useState(0);
  const pageSize = useMemo(() => 10, []);

  const getNewsRequest = useApi(NewsService.getNews);
  const getResidentRequest = useApi(ResidentService.getResident);

  const [data, setData] = useState<{ count: number; items: INews[] }>({
    count: 0,
    items: [],
  });

  const loadData = useCallback(
    async (reset: boolean) => {
      if (!resident) return;
      const result = await getNewsRequest(pageSize, page * pageSize, {
        // eslint-disable-next-line camelcase
        fld_building_id: resident.apartment?.fld_building_id,
        // eslint-disable-next-line camelcase
        fld_apartment_group_id: resident.apartment?.fld_apartment_group_id,
        // eslint-disable-next-line camelcase
        fld_apartment_type_id: resident.apartment?.fld_apartment_type_id,
        // eslint-disable-next-line camelcase
        sort_column: 'news.fld_publish_date',
        // eslint-disable-next-line camelcase
        sort_direction: 'desc',
      });
      if (reset) {
        setData({ count: result?.total_count || 0, items: result?.items || [] });
      } else {
        setData((prevData) => ({
          count: result?.total_count || 0,
          items: [...prevData.items, ...(result?.items || [])],
        }));
      }
    },
    [page, pageSize, resident],
  );

  const onShowMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    (async () => {
      if (user?.residentId) {
        const result = await getResidentRequest(user.residentId);
        if (result) setResident(result);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (resident) loadData(true);
  }, [resident]);

  useUpdateEffect(() => {
    loadData(false);
  }, [page]);

  return (
    <LoadPermissionsContainer section={Sections.news}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4'>
              News
            </Typography>
          </Box>
        </Box>
        <Box height={'100%'}>
          {data.items &&
            data.items.length > 0 &&
            data.items.map((newsItem, index) => (
              <ListItem key={index} data={newsItem} baseUrl='news' />
            ))}
          {data.items && data.items.length > 0 && data.count > data.items.length && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
              <Button onClick={() => onShowMore()} variant='outlined' color='primary' size='small'>
                <FormattedMessage id="Pages.Resident.News.loadMore" defaultMessage="Read more" description="Read more link" />
              </Button>
            </Box>
          )}
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
