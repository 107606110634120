import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { NavLinkProps, NavLink } from 'react-router-dom';
import { IProps } from './interfaces';

export default function DrawerMenuItem(props: IProps) {
  type CustomNavLinkProps = Omit<NavLinkProps, 'to'>;
  const CustomNavLink = React.useMemo(() => {
    const component = React.forwardRef<HTMLAnchorElement, CustomNavLinkProps>(
      (navLinkProps, ref) => {
        const { className: previousClasses, ...rest } = navLinkProps;
        const elementClasses = previousClasses?.toString() ?? '';
        return (
          <NavLink
            {...rest}
            ref={ref}
            to={props.href}
            end
            className={({ isActive }) =>
              isActive ? elementClasses + ' Mui-selected' : elementClasses
            }
          />
        );
      },
    );
    component.displayName = 'CustomNavLink';
    return component;
  }, [props.href]);

  return (
    <ListItemButton sx={{ px: 3 }} component={CustomNavLink}>
      {props.icon ? (
        <ListItemIcon
          sx={{
            mr: -2,
            color: (t) => t.palette.grey[600],
            '.Mui-selected > &': { color: (theme) => theme.palette.primary.main },
          }}
        >
          <props.icon />
        </ListItemIcon>
      ) : null}
      <ListItemText>
        <Typography
          variant='body1'
          fontWeight={500}
          sx={{
            fontWeight: 500,
            color: (t) => t.palette.grey[600],
            '.Mui-selected &': { color: (theme) => theme.palette.primary.main },
          }}
        >
          {props.label}
        </Typography>
      </ListItemText>
      {props.badge ? (
        <Box
          sx={{
            backgroundColor: (t) => t.palette.primary.main,
            color: (t) => t.palette.primary.contrastText,
            px: 1,
            borderRadius: 1,
          }}
        >
          <Typography variant='caption' sx={{ fontWeight: 500, lineHeight: 2.25 }}>
            {props.badge}
          </Typography>
        </Box>
      ) : null}
    </ListItemButton>
  );
}
