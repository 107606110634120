/* eslint-disable camelcase */
import BaseProvider from './BaseProvider';
import { IGetTreeBranchResponse } from './interfaces/responses/IGetTreeBranchResponse';

export default class NavigationProvider extends BaseProvider {
  getTreeBranch = async (nodeId: number) => {
    return this.get<IGetTreeBranchResponse>(`${this.getBaseApiUrl()}/navigation/tree/${nodeId}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
