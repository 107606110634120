import { Theme } from '@mui/material';

export default function (color: (t: Theme) => string, backgroundColor: (t: Theme) => string) {
  return {
    backgroundColor,
    color,
    py: 0.5,
    px: 1.5,
    borderRadius: 1,
    fontSize: (t: Theme) => t.typography.overline.fontSize,
    lineHeight: (t: Theme) => t.typography.overline.lineHeight,
    letterSpacing: (t: Theme) => t.typography.overline.letterSpacing,
    fontWeight: (t: Theme) => t.typography.overline.fontWeight,
  };
}
