import FilesProvider from '@rs/providers/FilesProvider';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { IBaseFilter } from '@rs/providers/interfaces/filters/IBaseFilter';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import { INews } from '@rs/providers/interfaces/entities/INews';
import { INewsAdditionalInfo } from '@rs/providers/interfaces/entities/INewsAdditionalInfo';
import { INewsAdditionalInfoFilter } from '@rs/providers/interfaces/filters/INewsAdditionalInfoFilter';
import { INewsFilter } from '@rs/providers/interfaces/filters/INewsFilter';
import { INewsRel } from '@rs/providers/interfaces/entities/INewsRel';
import { INewsRelFilter } from '@rs/providers/interfaces/filters/INewsRelFilter';
import NewsProvider from '@rs/providers/NewsProvider';
import { Sections } from '@rs/constants/Sections';
import TableDataProvider from '@rs/providers/TableDataProvider';

const filesProvider = new FilesProvider();
const newsDataProvider = new TableDataProvider<INews, IBaseFilter>(Sections.news);
const newsAdditionalInfoDataProvider = new TableDataProvider<
  INewsAdditionalInfo,
  INewsAdditionalInfoFilter
>(Sections.newsAdditionalInfo);
const newsRelDataProvider = new TableDataProvider<INewsRel, INewsRelFilter>(Sections.newsRel);
const newsProvider = new NewsProvider();

const NewsService = {
  getNews: async (limit?: number, offset?: number, filters?: INewsFilter) => {
    const newsResult = await newsProvider.getNews(limit, offset, filters);

    newsResult.items.forEach((item) => {
      item.files = item.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
      item.attachedFiles =
        item.news_additional_info && item.news_additional_info.length
          ? item.news_additional_info[0].files.map((file) => ({
              ...file,
              preview: filesProvider.generatePreviewLink(file),
            }))
          : [];
    });

    return newsResult;
  },
  getNewsItem: async (id: number) => {
    const result = await NewsService.getNews(1, 0, {
      // eslint-disable-next-line camelcase
      fld_uid: id,
    });
    if (!result.items.length) {
      throw new Error('News not found');
    }

    return result.items[0];
  },
  createNews: async (value: Partial<INews>) => {
    // eslint-disable-next-line camelcase
    const { updatedAttachedFiles, updatedFiles } = value;
    const createNewsResult = await newsProvider.createNews(value);
    const id = createNewsResult.news_id;
    const newsAddInfoResult = await newsAdditionalInfoDataProvider.createTableDataRecord({
      // eslint-disable-next-line camelcase
      fld_news_id: id,
    });
    const addInfoId = newsAddInfoResult.fld_uid;
    const attachedFilesToAdd = updatedAttachedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all([
      ...attachedFilesToAdd.map((x) =>
        filesProvider.createFile(Sections.newsAdditionalInfo, addInfoId, x.file),
      ),
    ]);
    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all(filesToAdd.map((x) => filesProvider.createFile(Sections.news, id, x.file)));
  },
  updateNews: async (
    id: number,
    newValue: Partial<INews>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    oldValue: INews,
  ) => {
    // eslint-disable-next-line camelcase
    const { news_relations, attachedFiles, files, updatedAttachedFiles, updatedFiles, ...data } =
      newValue;
    await newsDataProvider.updateTableDataRecord(id, data);

    // eslint-disable-next-line camelcase
    if (news_relations) {
      // eslint-disable-next-line camelcase
      const updatedNewsRelations = { ...news_relations[0], fld_news_id: id };
      const [relData] = await newsRelDataProvider.getAllTableData({
        // eslint-disable-next-line camelcase
        fld_news_id: [id],
      });
      if (relData?.fld_uid) {
        await newsRelDataProvider.updateTableDataRecord(relData.fld_uid, updatedNewsRelations);
      } else {
        await newsRelDataProvider.createTableDataRecord(updatedNewsRelations);
      }
    }

    if (files && updatedFiles) {
      const remainingFiles = updatedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const filesToDelete =
        files?.filter((x) => !remainingFiles.find((c) => c.file_id === x.file_id)) || [];
      const filesToAdd = updatedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];

      await Promise.all([
        ...filesToAdd.map((x) => filesProvider.createFile(Sections.news, id, x.file)),
        ...filesToDelete.map((x) => filesProvider.deleteFile(Sections.news, id, x.file_id)),
      ]);
    }

    if (attachedFiles && updatedAttachedFiles) {
      // eslint-disable-next-line no-debugger
      const [apartmentAddInfoResult] = await newsAdditionalInfoDataProvider.getAllTableData({
        // eslint-disable-next-line camelcase
        fld_news_id: [id],
      });
      let addInfoId = apartmentAddInfoResult?.fld_uid;

      if (!apartmentAddInfoResult) {
        const newAdditionalInfo = await newsAdditionalInfoDataProvider.createTableDataRecord({
          // eslint-disable-next-line camelcase
          fld_news_id: id,
        });
        addInfoId = newAdditionalInfo.fld_uid;
      }

      const remainingAttachedFiles = updatedAttachedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const attachedFilesToDelete =
        attachedFiles?.filter(
          (x) => !remainingAttachedFiles.find((c) => c.file_id === x.file_id),
        ) || [];
      const attachedFilesToAdd = updatedAttachedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];

      if (attachedFilesToAdd.length || attachedFilesToDelete.length) {
        await Promise.all([
          ...attachedFilesToAdd.map((x) =>
            filesProvider.createFile(Sections.newsAdditionalInfo, addInfoId, x.file),
          ),
          ...attachedFilesToDelete.map((x) =>
            filesProvider.deleteFile(Sections.newsAdditionalInfo, addInfoId, x.file_id),
          ),
        ]);
      }
    }
  },
  deleteNews: async (id: number) => {
    await newsProvider.deleteNews(id);
  },
};

export default NewsService;
