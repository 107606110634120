/* eslint-disable camelcase */

import TableDataProvider, { OrderingOptions } from '@rs/providers/TableDataProvider';

import BookingTypeProvider from '@rs/providers/BookingTypeProvider';
import FilesProvider from '@rs/providers/FilesProvider';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { IBookingType } from '@rs/providers/interfaces/entities/IBookingType';
import { IBookingTypeClosed } from '@rs/providers/interfaces/entities/IBookingTypeClosed';
import { IBookingTypeClosedFilter } from '@rs/providers/interfaces/filters/IBookingTypeClosedFilter';
import { IBookingTypeFilter } from '@rs/providers/interfaces/filters/IBookingTypeFilter';
import { IBookingTypeOpen } from '@rs/providers/interfaces/entities/IBookingTypeOpen';
import { IBookingTypeOpenFilter } from '@rs/providers/interfaces/filters/IBookingTypeOpenFilter';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import { Sections } from '@rs/constants/Sections';

const bookingTypeProvider = new BookingTypeProvider();
const filesProvider = new FilesProvider();
const bookingTypeDataProvider = new TableDataProvider<IBookingType, IBookingTypeFilter>(
  Sections.bookingTypes,
);
const bookingTypeOpenDataProvider = new TableDataProvider<IBookingTypeOpen, IBookingTypeOpenFilter>(
  Sections.bookingTypesOpen,
);
const bookingTypeClosedDataProvider = new TableDataProvider<
  IBookingTypeClosed,
  IBookingTypeClosedFilter
>(Sections.bookingTypesClosed);

const BookingTypeService = {
  getBookingTypes: async (limit?: number, offset?: number, filters?: IBookingTypeFilter) => {
    const bookingTypesResult = await bookingTypeProvider.getBookingTypes(limit, offset, filters);
    bookingTypesResult.items.forEach((x) => {
      x.files = x.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
    });
    return bookingTypesResult;
  },
  getBookingType: async (id: number) => {
    const bookingType = await BookingTypeService.getBookingTypes(1, 0, {
      fld_uid: [id],
    });
    return bookingType.items[0];
  },
  getBookingTypesCount: async (filters?: IBookingTypeFilter) => {
    const bookingTypesCountResult = await bookingTypeDataProvider.getRecordCount(filters);
    return bookingTypesCountResult.record_count;
  },
  updateBookingType: async (
    id: number,
    newValue: Partial<IBookingType>,
    oldValue: IBookingType,
  ) => {
    const { booking_type_open, booking_type_closed, files, updatedFiles, ...data } = newValue;
    const { booking_type_open: oldOpen, booking_type_closed: oldClosed } = oldValue;
    await bookingTypeDataProvider.updateTableDataRecord(id, data);

    // open records
    const openRecordsToCreate =
      booking_type_open?.filter((x) => !oldOpen.find((c) => c.fld_open_day === x.fld_open_day)) ||
      [];
    const openRecordsToUpdate =
      booking_type_open?.filter((x) => oldOpen.find((c) => c.fld_open_day === x.fld_open_day)) ||
      [];
    const openRecordsToDelete =
      oldOpen?.filter((x) => !booking_type_open?.find((c) => c.fld_open_day === x.fld_open_day)) ||
      [];

    await Promise.all([
      ...openRecordsToCreate.map((x) => bookingTypeOpenDataProvider.createTableDataRecord(x)),
      ...openRecordsToUpdate.map((x) =>
        bookingTypeOpenDataProvider.updateTableDataRecord(x.fld_uid, x),
      ),
      ...openRecordsToDelete.map((x) =>
        bookingTypeOpenDataProvider.deleteTableDataRecord(x.fld_uid),
      ),
    ]);

    // closed records
    const closedRecordsToCreate =
      booking_type_closed?.filter((x) => !oldClosed.find((c) => c.fld_uid === x.fld_uid)) || [];
    const closedRecordsToUpdate =
      booking_type_closed?.filter((x) => oldClosed.find((c) => c.fld_uid === x.fld_uid)) || [];
    const closedRecordsToDelete =
      oldClosed?.filter((x) => !booking_type_closed?.find((c) => c.fld_uid === x.fld_uid)) || [];

    await Promise.all([
      ...closedRecordsToCreate.map((x) => bookingTypeClosedDataProvider.createTableDataRecord(x)),
      ...closedRecordsToUpdate.map((x) =>
        bookingTypeClosedDataProvider.updateTableDataRecord(x.fld_uid, x),
      ),
      ...closedRecordsToDelete.map((x) =>
        bookingTypeClosedDataProvider.deleteTableDataRecord(x.fld_uid),
      ),
    ]);

    const remainingFiles = updatedFiles?.filter(
      (x) => !(x as INewAttachedFile).file,
    ) as IAttachedFile[];
    const filesToDelete =
      files?.filter((x) => !remainingFiles.find((c) => c.file_id === x.file_id)) || [];
    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    if (filesToDelete || filesToAdd) {
      await Promise.all([
        ...filesToAdd.map((x) => filesProvider.createFile(Sections.bookingTypes, id, x.file)),
        ...filesToDelete.map((x) => filesProvider.deleteFile(Sections.bookingTypes, id, x.file_id)),
      ]);
    }
  },
  deleteBookingType: async (id: number) => {
    await bookingTypeProvider.deleteBookingType(id);
  },
  createBookingType: async (value: Partial<IBookingType>) => {
    const { booking_type_open, booking_type_closed, updatedFiles, ...data } = value;
    const createBookingTypeResult = await bookingTypeDataProvider.createTableDataRecord(data);
    const id = createBookingTypeResult.fld_uid;
    const updatedBookingTypeOpen =
      booking_type_open?.map((x) => ({ ...x, fld_booking_type_id: id })) || [];
    const updatedBookingTypeClosed =
      booking_type_closed?.map((x) => ({ ...x, fld_booking_type_id: id })) || [];

    await Promise.all([
      ...updatedBookingTypeOpen.map((x) => bookingTypeOpenDataProvider.createTableDataRecord(x)),
      ...updatedBookingTypeClosed.map((x) =>
        bookingTypeClosedDataProvider.createTableDataRecord(x),
      ),
    ]);

    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all(
      filesToAdd.map((x) => filesProvider.createFile(Sections.bookingTypes, id, x.file)),
    );
  },
  updateOrdering: async (data: OrderingOptions) => {
    await bookingTypeDataProvider.updateTableDataOrdering(data);
  },
};

export default BookingTypeService;
