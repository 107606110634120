import { Box } from '@mui/material';
import { IProps } from './interfaces';
import TextWithNewLines from '../TextWithNewLines';

export default function BoxWithMultilineText({ text }: IProps) {
  return (
    <Box
      sx={{
        display: '-webkit-box',
        visibility: 'visible',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
      }}
    >
      <TextWithNewLines text={text} />
    </Box>
  );
}
