import { Box, Button, TextField, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApi, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ApartmentService from '@rs/services/ApartmentService';
import Attachments from '@rs/components/Attachments';
import BuildingService from '@rs/services/BuildingService';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { IInformationCategory } from '@rs/providers/interfaces/entities/IInformationCategory';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import ImagesView from '@rs/components/ImagesView';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageApartmentDialog from './components/ManageApartmentDialog';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Sections } from '@rs/constants/Sections';
import accordionStyles from '@rs/styles/accordion';
import parse from 'html-react-parser';
import searchInputStyles from '@rs/styles/searchInput';

export default function Apartment() {
  const navigate = useNavigate();
  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, 1000);
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);

  const [apartmentData, setApartmentData] = useState<IApartment>();
  const [categories, setCategories] = useState<IInformationCategory[]>();
  const [filterCategories, setFilterCategories] = useState<IInformationCategory[]>();

  const [buildingOptions, setBuildingOptions] = useState<ISelectOption[]>([]);
  const [apartmentTypeOptions, setApartmentTypeOptions] = useState<ISelectOption[]>([]);
  const [apartmentGroupOptions, setApartmentGroupOptions] = useState<ISelectOption[]>([]);

  const getApartmentRequest = useApi(ApartmentService.getApartment);
  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const getApartmentTypesRequest = useApi(ApartmentService.getApartmentTypes);
  const getApartmentGroupsRequest = useApi(ApartmentService.getApartmentGroups);
  const updateApartmentRequest = useApi(ApartmentService.updateApartment);
  const deleteApartmentRequest = useApi(ApartmentService.deleteApartment);

  const loadData = useCallback(async () => {
    if (id) {
      const info = (await getApartmentRequest(parseInt(id))) || {
        apartment: undefined,
        categories: [],
      };
      setApartmentData(info.apartment);
      setCategories(info.categories);
      setFilterCategories(info.categories);
    }
  }, [id]);

  const loadAudience = useCallback(async () => {
    const buildings = (await getBuildingsRequest())?.items || [];
    setBuildingOptions([
      ...buildings.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_building_name,
        additional: x.fld_floors.toString(),
      })),
    ]);
    const apartmentTypes = (await getApartmentTypesRequest()) || [];
    setApartmentTypeOptions([
      ...apartmentTypes.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_apartment_type_name,
      })),
    ]);
    const apartmentGroups = (await getApartmentGroupsRequest()) || [];
    setApartmentGroupOptions([
      ...apartmentGroups.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_apartment_group_name,
      })),
    ]);
  }, []);

  const onEdit = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<IApartment>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      await updateApartmentRequest(apartmentData?.fld_uid || 1, item);
      setOpenDialog(false);
      await loadData();
    }
  };

  const onApartmentDelete = async () => {
    await deleteApartmentRequest(apartmentData?.fld_uid || 1);
    setOpenDialog(false);
    navigate('/apartments');
  };

  const getBuildingName = () => {
    const buildingName = buildingOptions.find(
      (x) => x.value === String(apartmentData?.fld_building_id),
    );
    return buildingName?.label;
  };

  const getApartmentGroup = () => {
    const apartmentGroup = apartmentGroupOptions.find(
      (x) => x.value === String(apartmentData?.fld_apartment_group_id),
    );
    return apartmentGroup?.label;
  };

  const getApartmentType = () => {
    const apartmentType = apartmentTypeOptions.find(
      (x) => x.value === String(apartmentData?.fld_apartment_type_id),
    );
    return apartmentType?.label;
  };

  useEffect(() => {
    (async () => {
      await loadData();
      await loadAudience();
    })();
  }, []);

  useUpdateEffect(() => {
    const filterCategories = categories?.filter((x) =>
      x.fld_title.toLowerCase().includes(searchInput.value.toLowerCase()),
    );
    setFilterCategories(filterCategories);
  }, [search]);

  return (
    <LoadPermissionsContainer section={Sections.apartments}>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 1,
              color: (t) => t.palette.primary.main,
              cursor: 'pointer',
              fontSize: (t) => t.typography.subtitle2.fontSize,
              fontWeight: 600,
            }}
          >
            <Link
              to='/apartments'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: (t) => t.typography.h5.fontSize, mr: 0.5 }} />
              Back to Apartments
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              {apartmentData?.fld_apartment_name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ minWidth: '342px', display: 'flex', flexDirection: 'column' }}>
            <ImagesView files={apartmentData?.files} />

            {!!categories?.length && (
              <Box sx={{ bgcolor: '#F7F9FA', borderRadius: 2, mt: 4 }}>
                <Box sx={{ p: 1, borderRadius: 0.8 }}>
                  <TextField
                    sx={{
                      ...searchInputStyles,
                      ...{
                        width: '100%',
                        m: 0,
                        borderRadius: 1,
                        '& .MuiInputBase-input': { borderRadius: 1, bgcolor: '#fff', py: 1.25 },
                      },
                    }}
                    onChange={searchInput.onChange}
                    value={searchInput.value}
                    placeholder='Search'
                    variant='outlined'
                    margin='normal'
                  />
                </Box>

                {filterCategories?.length ? (
                  filterCategories.map((category, index) => (
                    <Accordion
                      sx={accordionStyles}
                      key={category.fld_uid}
                      defaultExpanded={index === 0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${category.fld_uid}a-content`}
                        id={`panel${category.fld_uid}a-header`}
                      >
                        <Typography component='span' variant='subtitle2'>
                          {category.fld_title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {category.information_articles &&
                          category.information_articles.map((article) => (
                            <Link
                              to={`/articles/${article.fld_uid}`}
                              style={{ textDecoration: 'none' }}
                              key={article.fld_uid}
                            >
                              <Box
                                key={article.fld_uid}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  bgcolor: '#fff',
                                  borderRadius: 0.8,
                                  py: 1,
                                  px: 2,
                                  mb: 1,
                                }}
                              >
                                <Box sx={{ display: 'flex', color: '#000000DE' }}>
                                  <Typography component='span' variant='subtitle2'>
                                    {article.fld_title}
                                  </Typography>
                                </Box>
                                <Box>
                                  <ChevronRightIcon
                                    sx={{
                                      display: 'flex',
                                      color: (t) => t.palette.primary.main,
                                      bgcolor: '#007DFF1A',
                                      borderRadius: 0.4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Link>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Box
                    sx={{
                      py: 1.5,
                      px: 3,
                    }}
                  >
                    <Typography component='span' variant='subtitle2'>
                      Categories not found
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box sx={{ width: '100%', pl: 3 }}>
            <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
              {apartmentData?.fld_apartment_name}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: '#0000008A' }}>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ display: 'flex', alignItems: 'center', pr: 2 }}
                >
                  <ApartmentIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  {getBuildingName()}
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{
                    position: 'relative',
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&:before, &:after': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: '10px',
                      left: 0,
                      width: '3px',
                      height: '3px',
                      borderRadius: '50%',
                      bgcolor: '#2121218A',
                    },
                    '&:after': { left: 'unset', right: 0 },
                  }}
                >
                  {apartmentData?.fld_floor} Floor
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ display: 'flex', alignItems: 'center', px: 2 }}
                >
                  <MapsHomeWorkIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  Apartment: {apartmentData?.fld_apartment_order}
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{
                    position: 'relative',
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&:before, &:after': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: '10px',
                      left: 0,
                      width: '3px',
                      height: '3px',
                      borderRadius: '50%',
                      bgcolor: '#2121218A',
                    },
                    '&:after': { left: 'unset', right: 0 },
                  }}
                >
                  {getApartmentGroup()}
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ display: 'flex', alignItems: 'center', px: 2 }}
                >
                  {getApartmentType()}
                </Typography>
              </Box>
              <Box sx={{ position: 'relative', top: '-30px' }}>
                <Button
                  variant='text'
                  onClick={() => onEdit()}
                  sx={{
                    px: 2.5,
                    py: 1.5,
                    ml: 2,
                    color: '#0000008A',
                  }}
                >
                  <EditIcon sx={{ mr: 0.5 }} />
                  Edit
                </Button>
              </Box>
            </Box>

            <Box>{parse(apartmentData?.fld_description || '')}</Box>
            <Attachments files={apartmentData?.attachedFiles} />
          </Box>
        </Box>
        <ManageApartmentDialog
          item={apartmentData}
          type='edit'
          open={openDialog}
          buildingOptions={buildingOptions}
          apartmentTypeOptions={apartmentTypeOptions}
          apartmentGroupOptions={apartmentGroupOptions}
          onDelete={onApartmentDelete}
          onClose={onCloseDialog}
        />
      </>
    </LoadPermissionsContainer>
  );
}
