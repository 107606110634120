import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AlertTemplate = ({ close, message, options }: any) => {
  return (
    <Alert
      sx={{
        pointerEvents: 'auto',
        margin: '10px',
      }}
      onClose={close}
      severity={options.type}
    >
      <AlertTitle>{options.type?.toUpperCase()}</AlertTitle>
      {message}
    </Alert>
  );
};

export default AlertTemplate;
