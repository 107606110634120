import {
  Alert,
  Badge,
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useApi, useAppSelector, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import BookingService from '@rs/services/BookingService';
import { BookingStatuses } from '@rs/constants/BookingStatuses';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IBooking } from '@rs/providers/interfaces/entities/IBooking';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageBookingDialog from './components/ManageBookingDialog';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import dataGridStyles from '@rs/styles/dataGrid';
import moment from 'moment';
import searchInputStyles from '@rs/styles/searchInput';
import { selectGeneralLoader } from '@rs/reducers/generalSlice';

import {createIntl, createIntlCache} from '@formatjs/intl'
import englishTranslation from '../../../translations/en-GB.json'

export default function Bookings() {
  const loading = useAppSelector(selectGeneralLoader);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortOptions, setSortOptions] = useState<GridSortItem>({
    field: 'fld_booking_datetime',
    sort: 'desc',
  });
  const statusInput = useInput<string>(BookingStatuses.pending);
  const searchInput = useInput<string>('');

  const search = useDebounce<string>(searchInput.value, 1000);

  const [data, setData] = useState<{ count: number; items: IBooking[] }>({
    count: 0,
    items: [],
  });
  const [statusForUpdate, setStatusForUpdate] = useState(BookingStatuses.confirmed);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState<IBooking | undefined>(undefined);

  const getBookingsRequest = useApi(BookingService.getBookings);
  const updateBookingRequest = useApi(BookingService.updateBookingStatus);

  const loadData = useCallback(async () => {
    const filters = {
      // eslint-disable-next-line camelcase
      ...(search && { search_term: search }),
      ...(statusInput.value && {
        // eslint-disable-next-line camelcase
        fld_booking_status: [statusInput.value],
      }),
      // eslint-disable-next-line camelcase
      sort_column: sortOptions?.field || '',
      // eslint-disable-next-line camelcase
      sort_direction: sortOptions?.sort || '',
    };
    const bookingResponse = await getBookingsRequest(pageSize, page * pageSize, filters);
    setData({ count: bookingResponse?.total_count || 0, items: bookingResponse?.items || [] });
  }, [page, pageSize, search, statusInput.value, sortOptions]);

  const onPageChanged = (_page: number) => {
    setPage(_page);
  };

  const onPerPageChanged = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onSortModelChanged = async (_model: GridSortModel) => {
    setSortOptions(_model[0]);
  };

  const onChangeStatus = (item: IBooking, status: BookingStatuses) => {
    setStatusForUpdate(status);
    setActiveItem(item);
    setOpenDialog(true);
  };

  const onCloseDialog = async (data?: { status: BookingStatuses; notes: string }) => {
    if (!data) {
      setOpenDialog(false);
    } else {
      // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await updateBookingRequest(activeItem!.fld_uid, data.status, data.notes);

      setOpenDialog(false);
      await loadData();
    }
  };

  const getStatusAlertType = (status: BookingStatuses) => {
    switch (status) {
      case BookingStatuses.pending: {
        return 'warning';
      }
      case BookingStatuses.confirmed: {
        return 'success';
      }
      case BookingStatuses.rejected: {
        return 'error';
      }
      case BookingStatuses.cancelled: {
        return 'error';
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [sortOptions]);

  useUpdateEffect(() => {
    loadData();
  }, [page, pageSize]);

  useUpdateEffect(() => {
    loadData();
  }, [search, statusInput.value]);

  // This is optional but highly recommended since it prevents memory leak
  const cache = createIntlCache();

  // Setting which translation file will be used
  const intl = createIntl({
      locale: 'en-GB',
      messages: englishTranslation,
    },
    cache
  );

  const columns: GridColDef[] = [
    {
      field: 'bookingType.fld_booking_type_heading',
      headerName: 'Type',
      disableColumnMenu: true,
      flex: 15,
      sortable: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.booking_type.fld_booking_type_heading,
    },
    {
      field: 'resident',
      headerName: intl.formatMessage({
        id: "Pages.Manager.Bookings.requestedFrom",
        defaultMessage: 'Requested By'
      }), // 'Request From', 
      disableColumnMenu: true,
      sortable: false,
      flex: 15,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.resident?.fld_firstname || ''} ${
          params.row.resident?.fld_surname || ''
        }`.trim(),
    },
    {
      field: 'apartment',
      headerName: 'Apt.',
      disableColumnMenu: true,
      sortable: false,
      flex: 10,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.resident?.apartment?.fld_apartment_order || '',
    },
    {
      field: 'booking.fld_booking_datetime',
      headerName: 'Start Date & Time',
      disableColumnMenu: true,
      flex: 20,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.fld_booking_datetime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm, DD.MM.YYYY'),
    },
    {
      field: 'booking.fld_booking_duration',
      headerName: 'Duration',
      disableColumnMenu: true,
      sortable: false,
      flex: 10,
      valueGetter: (params: GridValueGetterParams) =>
        moment.utc(params.row.fld_booking_duration * 60 * 1000).format('HH:mm'),
    },
    {
      field: 'fld_booking_status',
      headerName: 'Status',
      disableColumnMenu: true,
      flex: 10,
      renderCell: (params: GridValueGetterParams) => {
        const type = getStatusAlertType(params.row.fld_booking_status);
        return (
          <Alert
            sx={{
              py: 0,
              '& .MuiAlert-message': {
                py: 0,
              },
              '& .MuiTypography-root': {
                lineHeight: '2rem',
              },
            }}
            icon={false}
            severity={type}
          >
            <Typography variant='overline'>{params.row.fld_booking_status}</Typography>
          </Alert>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      flex: 10,
      align: 'right',
      renderCell: (params: GridValueGetterParams) => {
        return (
          <SecureContainer sections={[Sections.bookings]} permission='modify'>
            <>
              {params.row.fld_booking_status === BookingStatuses.pending && (
                <Tooltip title='Confirm'>
                  <Button
                    variant='outlined'
                    color='success'
                    sx={{
                      width: 32,
                      height: 32,
                      minWidth: 32,
                    }}
                    onClick={() => onChangeStatus(params.row, BookingStatuses.confirmed)}
                  >
                    <CheckIcon fontSize='small' />
                  </Button>
                </Tooltip>
              )}
              {(params.row.fld_booking_status === BookingStatuses.confirmed ||
                params.row.fld_booking_status === BookingStatuses.pending) && (
                <Tooltip
                  title={
                    params.row.fld_booking_status === BookingStatuses.confirmed
                      ? 'Cancel'
                      : 'Reject'
                  }
                >
                  <Button
                    variant='outlined'
                    color='error'
                    sx={{
                      ml: 2,
                      width: 32,
                      height: 32,
                      minWidth: 32,
                    }}
                    onClick={() =>
                      onChangeStatus(
                        params.row,
                        params.row.fld_booking_status === BookingStatuses.confirmed
                          ? BookingStatuses.cancelled
                          : BookingStatuses.rejected,
                      )
                    }
                  >
                    <CloseIcon fontSize='small' />
                  </Button>
                </Tooltip>
              )}
            </>
          </SecureContainer>
        );
      },
    },
  ];

  return (
    <LoadPermissionsContainer section={Sections.bookings}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Booking Requests
            </Typography>
            <TextField
              sx={searchInputStyles}
              onChange={searchInput.onChange}
              value={searchInput.value}
              placeholder='Search a request'
              variant='outlined'
              margin='normal'
            />
          </Box>
        </Box>
        <Box height={'100%'}>
          <Tabs
            value={statusInput.value}
            onChange={(e, value) => statusInput.onChange({ target: { value } })}
          >
            <Tab
              sx={{ textTransform: 'capitalize', width: 160 }}
              value={BookingStatuses.pending}
              label={
                <Box>
                  {BookingStatuses.pending.toLowerCase()}&nbsp;
                  {!loading && statusInput.value === BookingStatuses.pending && (
                    <Badge badgeContent={data.count} color='primary' sx={{ ml: 2 }} />
                  )}
                </Box>
              }
            />
            <Tab
              sx={{ textTransform: 'capitalize', width: 160 }}
              value={BookingStatuses.confirmed}
              label={
                <Box>
                  {BookingStatuses.confirmed.toLowerCase()}&nbsp;
                  {!loading && statusInput.value === BookingStatuses.confirmed && (
                    <Badge badgeContent={data.count} color='primary' sx={{ ml: 2 }} />
                  )}
                </Box>
              }
            />
            <Tab
              sx={{ textTransform: 'capitalize', width: 160 }}
              value={BookingStatuses.rejected}
              label={
                <Box>
                  {BookingStatuses.rejected.toLowerCase()}&nbsp;
                  {!loading && statusInput.value === BookingStatuses.rejected && (
                    <Badge badgeContent={data.count} color='primary' sx={{ ml: 2 }} />
                  )}
                </Box>
              }
            />
            <Tab
              sx={{ textTransform: 'capitalize', width: 160 }}
              value={BookingStatuses.cancelled}
              label={
                <Box>
                  {BookingStatuses.cancelled.toLowerCase()}&nbsp;
                  {!loading && statusInput.value === BookingStatuses.cancelled && (
                    <Badge badgeContent={data.count} color='primary' sx={{ ml: 2 }} />
                  )}
                </Box>
              }
            />
            <Tab
              sx={{ textTransform: 'capitalize', width: 160 }}
              value=''
              label={
                <Box>
                  All&nbsp;
                  {!loading && !statusInput.value && (
                    <Badge badgeContent={data.count} color='primary' sx={{ ml: 2 }} />
                  )}
                </Box>
              }
            />
          </Tabs>
          <DataGrid
            isRowSelectable={() => false}
            sx={dataGridStyles}
            rows={data.items}
            rowCount={data.count}
            columns={columns}
            page={page}
            pageSize={pageSize}
            sortModel={sortOptions ? [sortOptions] : []}
            sortingMode='server'
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={onPageChanged}
            onPageSizeChange={onPerPageChanged}
            onSortModelChange={onSortModelChanged}
            // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getRowId={(row: IBooking) => row.fld_uid!}
          />
        </Box>
        <ManageBookingDialog
          item={activeItem}
          status={statusForUpdate}
          open={openDialog}
          onClose={onCloseDialog}
        />
      </>
    </LoadPermissionsContainer>
  );
}
