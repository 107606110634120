/* eslint-disable camelcase */

import { Box, Button, Dialog, DialogContent, TextField, Theme, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BookingStatuses } from '@rs/constants/BookingStatuses';
import { DateTimePicker } from '@mui/x-date-pickers';
import { IBooking } from '@rs/providers/interfaces/entities/IBooking';
import { IBookingType } from '@rs/providers/interfaces/entities/IBookingType';
import moment from 'moment';
import { selectAuthUser } from '@rs/reducers/authSlice';
import { useAppSelector } from '@rs/helpers/hooks';
import { useEffect } from 'react';

interface IProps {
  isEdit: boolean;
  booking?: IBooking;
  item?: IBookingType;
  open: boolean;
  onClose: (data?: Partial<IBooking>) => void;
}

interface FormData {
  startTime: string;
  endTime: string;
  notes: string;
}

export default function ManageBookingDialog(props: IProps) {
  const user = useAppSelector(selectAuthUser);
  const { onClose, booking, open, item, isEdit } = props;

  const formDefaultData = {
    startTime: (booking
      ? moment(booking.fld_booking_datetime, 'YYYY-MM-DD HH:mm:ss')
      : moment()
    ).format('YYYY-MM-DD HH:mm:ss.sss'),
    endTime: (booking
      ? moment(booking.fld_booking_datetime, 'YYYY-MM-DD HH:mm:ss').add(
          booking.fld_booking_duration,
          'minutes',
        )
      : moment()
    ).format('YYYY-MM-DD HH:mm:ss.sss'),
    notes: booking ? booking.fld_booking_notes : '',
  };

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  useEffect(() => {
    form.reset(formDefaultData);
  }, [item]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const formData = form.getValues();

    const updatedItem: Partial<IBooking> = {
      fld_booking_type_id: item?.fld_uid,
      fld_resident_id: user?.residentId || 1,
      fld_booking_datetime: moment(formData.startTime).format('YYYY-MM-DD hh:mm:ss'),
      fld_booking_duration: moment(formData.endTime).diff(moment(formData.startTime), 'minutes'),
      fld_booking_notes: formData.notes,
      fld_booking_status: BookingStatuses.pending,
    };

    onClose(updatedItem);
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='md'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3.75 }}>
            <Typography component='h2' variant='h4' fontWeight={700}>
              {isEdit ? 'Edit booking' : `Book ${item?.fld_booking_type_heading}`}
            </Typography>
            <Box>
              <Button
                variant='outlined'
                onClick={() => onCancel()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                  ml: 2,
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                type='submit'
                form='form'
                color={'primary'}
                sx={{
                  px: 2.5,
                  py: 1.5,
                  ml: 2,
                }}
              >
                {isEdit ? 'Change' : 'Book'}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box>
              <Box
                sx={{
                  pb: 3,
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: (t: Theme) => t.palette.grey[100],
                }}
              >
                <Controller
                  name={'startTime'}
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      ampm={true}
                      disablePast={false}
                      disableMaskedInput
                      label='Start Date & Time'
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      inputFormat='DD MMMM YYYY hh:mm a'
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          sx={{ justifyContent: 'flex-end', mt: 0.5, mr: 3, minWidth: '260px' }}
                          variant='standard'
                          {...params}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name={'endTime'}
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      ampm={true}
                      disablePast={false}
                      disableMaskedInput
                      label='End Date & Time'
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      inputFormat='DD MMMM YYYY hh:mm a'
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          sx={{ justifyContent: 'flex-end', mt: 0.5, mr: 3, minWidth: '260px' }}
                          variant='standard'
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: 5 }}>
                <Typography variant='h6'>Special request:</Typography>
                <Box>
                  <Controller
                    name={'notes'}
                    control={form.control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please enter the notes',
                      },
                      maxLength: {
                        value: 256,
                        message: 'Max length should be less or equal 256 characters',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        sx={{ mt: 2 }}
                        multiline
                        minRows={4}
                        fullWidth
                        variant='outlined'
                        placeholder='I’d like to book a 3 wheel bicycle for my child'
                        error={!!formState.errors.notes}
                        helperText={formState.errors.notes?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
