import TableDataProvider, { OrderingOptions } from '@rs/providers/TableDataProvider';

import FilesProvider from '@rs/providers/FilesProvider';
import { IMessage } from '@rs/providers/interfaces/entities/IMessage';
import { IMessageFilter } from '@rs/providers/interfaces/filters/IMessageFilter';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import MessageProvider from '@rs/providers/MessageProvider';
import { Sections } from '@rs/constants/Sections';

const filesProvider = new FilesProvider();
const messageDataProvider = new TableDataProvider<IMessage, IMessageFilter>(Sections.messages);
const messageProvider = new MessageProvider();

const MessageService = {
  getMessages: async (limit?: number, offset?: number, filters?: IMessageFilter) => {
    let messages: IMessage[] = [];
    if (limit || offset) {
      const messagesResult = await messageDataProvider.getTableData(limit, offset, filters);
      messages = messagesResult.items;
    } else {
      messages = await messageDataProvider.getAllTableData(filters);
    }

    messages.forEach((item) => {
      item.files = item.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
    });

    return messages;
  },
  getMessagesCount: async (filters?: IMessageFilter) => {
    const messagesCountResult = await messageDataProvider.getRecordCount(filters);
    return messagesCountResult.record_count;
  },
  createMessage: async (value: Partial<IMessage>) => {
    const { updatedFiles, ...data } = value;
    const createMessageResult = await messageProvider.createMessage(data);
    const id = createMessageResult.message_id;
    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all(
      filesToAdd.map((x) => filesProvider.createFile(Sections.messages, id, x.file)),
    );
    return id;
  },
  updateMessage: async (id: number, newValue: Partial<IMessage>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { files, updatedFiles, ...data } = newValue;
    await messageDataProvider.updateTableDataRecord(id, data);
    return id;
  },
  updateOrdering: async (data: OrderingOptions) => {
    await messageDataProvider.updateTableDataOrdering(data);
  },
};

export default MessageService;
