import React from 'react';
import { IProps } from './interfaces';

export default function TextWithNewLines({ text }: IProps) {
  const newText = text
    .replaceAll('<br />', '')
    .split('\n')
    .map((str, i) => (
      <React.Fragment key={i}>
        {i !== 0 && <br />}
        {str}
      </React.Fragment>
    ));
  return <>{newText}</>;
}
