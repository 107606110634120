/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { IGetStaffResponse } from './interfaces/responses/IGetStaffResponse';
import { IStaffFilter } from './interfaces/filters/IStaffFilter';

export default class StaffProvider extends BaseProvider {
  getStaff = async (limit?: number, offset?: number, filters?: IStaffFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_uid !== undefined) {
      filtersArray.push(`staff.fld_uid = "${filters.fld_uid}"`);
    }
    if (filters?.fld_teamid !== undefined) {
      filtersArray.push(`team.fld_uid = ${filters.fld_teamid}`);
    }
    if (filters?.fld_zu_user_id !== undefined) {
      filtersArray.push(`staff.fld_zu_user_id = ${filters.fld_zu_user_id}`);
    }

    return this.get<IGetStaffResponse>(`${this.getBaseApiUrl()}/rs/staff/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
