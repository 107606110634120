import AuthProvider from "@rs/providers/AuthProvider";
import { IAuthenticateData } from "@rs/providers/interfaces/IAuthenticateData";
import { ISection } from "@rs/providers/interfaces/ISection";
import { ISectionPermissions } from "@rs/providers/interfaces/ISectionPermissions";
import { IUserGroup } from "@rs/providers/interfaces/IUserGroup";
import { IUserInfo } from "@rs/providers/interfaces/IUserInfo";
import { IZone } from "@rs/providers/interfaces/IZone";
import { IZoneStyle } from "@rs/providers/interfaces/IZoneStyle";
import NavigationProvider from "@rs/providers/NavigationProvider";
import UserProvider from "@rs/providers/UserProvider";
import ZoneProvider from "@rs/providers/ZoneProvider";

const authProvider = new AuthProvider();
const navigationProvider = new NavigationProvider();
const userProvider = new UserProvider();
const zoneProvider = new ZoneProvider();

const AuthService = {
  authenticate: async (
    email: string,
    password: string
  ): Promise<{ userId: number }> => {
    const globalResult = await authProvider.globalAuthenticate(email, password);
    if (!globalResult.data) {
      throw new Error("Incorrect email or password");
    }

    return globalResult.data;
  },

  getZones: async (email: string, password: string): Promise<IZone[]> => {
    const globalResult = await authProvider.globalGetZones(email, password);
    if (!globalResult.data.deployments) {
      throw new Error("Incorrect email or password");
    }

    if (globalResult.data.deployments.length === 0) {
      throw new Error("Not a member of any projects");
    }

    // flat map over the data to build the array
    const zones = globalResult.data.deployments.flatMap((deploy) => deploy.server.accessiblezones.map((z) => ({
      id: z.fld_security_zoneid,
      name: z.fld_name,
      image: z.image_path,
      deployment: {
        id: deploy.deployment.fld_deploymentid,
        name: deploy.deployment.fld_name,
        database: deploy.deployment.fld_database_instance,
        url: deploy.deployment.fld_deployment_url
      }
    })));

    return zones
  },

  authenticateWithDeployment: async (
    email: string,
    password: string,
    deployment: IZone
  ): Promise<{
    apiUrl: string;
    deploymentId: string;
    dataBaseInstance: string;
    token: string;
  }> => {
    const deploymentUrl = new URL(deployment.deployment.url);
    const apiUrl = `${deploymentUrl.protocol}//${deploymentUrl.hostname}/api/v1`;
    const deploymentId = deployment.deployment.id;
    const dataBaseInstance = deployment.deployment.database;
    const result = await authProvider.authenticate(
      email,
      password,
      apiUrl,
      deploymentId
    );

    return {
      apiUrl,
      deploymentId,
      dataBaseInstance,
      token: result.data.token,
    };
  },

  getUserGroups: async (): Promise<IUserGroup[]> => {
    const userGroupsResult = await userProvider.getUserGroups();
    const userGroups = userGroupsResult.items.map((x) => ({
      id: x.group_id,
      name: x.group_name,
    }));
    return userGroups;
  },

  getUserinfo: async (): Promise<IUserInfo> => {
    const { user_id, name, email } = await userProvider.getUserInfo();
    return {
      id: user_id,
      name,
      email,
    };
  },

  getAccessibleSections: async (): Promise<ISection[]> => {
    const rootNodesResult = await navigationProvider.getTreeBranch(0);
    const accessibleSectionsResult = await navigationProvider.getTreeBranch(
      rootNodesResult.items[0].node_id
    );
    const accessibleSections = accessibleSectionsResult.items.map((x) => ({
      id: x.node_id,
      name: x.display_name,
    }));
    return accessibleSections;
  },

  getSectionPermissions: async (
    nodeId: number
  ): Promise<ISectionPermissions> => {
    const sectionPermissionsResult = await userProvider.getSectionPermissions(
      nodeId
    );

    return {
      read: sectionPermissionsResult.read,
      modify: sectionPermissionsResult.modify_data,
      delete: sectionPermissionsResult.delete_data,
    };
  },

  getZoneStyle: async (): Promise<IZoneStyle> => {
    const zoneStyleResult = await zoneProvider.getZoneStyle();

    return {
      logo: zoneProvider.formatLogoUrl(zoneStyleResult.logo_file_name),
      primaryColor: zoneStyleResult.app_primary_colour,
      onPrimaryColor: zoneStyleResult.app_on_primary_colour,
      secondaryColor: zoneStyleResult.app_secondary_colour,
      onSecondaryColor: zoneStyleResult.app_on_secondary_colour,
    };
  },
};

export default AuthService;
