/* eslint-disable camelcase */

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useApi, useUpdateEffect } from '@rs/helpers/hooks';
import { useEffect, useMemo, useState } from 'react';

import ApartmentService from '@rs/services/ApartmentService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DatePicker } from '@mui/x-date-pickers';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { IResidentAdditionalInfo } from '@rs/providers/interfaces/entities/IResidentAdditionalInfo';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import ImageUploader from '@rs/components/ImageUploader';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import moment from 'moment';

interface IProps {
  item?: IResident;
  open: boolean;
  readonly?: boolean;
  buildingOptions: ISelectOption[];
  onClose: (data?: Partial<IResident>) => void;
}

interface FormData {
  isOwner: boolean;
  isPrimaryResident: boolean;
  vipCode: string;
  firstName: string;
  lastName: string;
  birthday: string;
  title: string;
  mobile: string;
  additionalPhone: string;
  email: string;
  additionalEmail: string;
  buildingId: string;
  apartmentId: string;
  doorAccessCode: string;
  contactPhone: boolean;
  contactEmail: boolean;
  notes: string;
  lettingCompanyName: string;
  lettingCompanyPhone: string;
  lettingCompanyAddress: string;
  lettingCompanyEmail: string;
}

const titleOptions: ISelectOption[] = ['Mrs', 'Mr', 'Miss', 'Ms', 'Dr'].map((x) => ({
  value: x,
  label: x,
}));

export default function ManageResidentDialog(props: IProps) {
  const { onClose, open, buildingOptions, readonly, item } = props;

  const [files, setFiles] = useState<UploaderFile[]>([]);

  const [apartmentOptions, setApartmentOptions] = useState<ISelectOption[]>([]);
  const [apartments, setApartments] = useState<IApartment[]>([]);

  const getApartmentsRequest = useApi(ApartmentService.getApartments);

  const formDefaultData = useMemo(
    () => ({
      isOwner: item?.fld_owner || false,
      isPrimaryResident: item?.fld_primary_resident || false,
      vipCode: item?.additionalInfo?.fld_vip_code || '',
      firstName: item?.fld_firstname || '',
      lastName: item?.fld_surname || '',
      birthday: item?.fld_birthday
        ? moment(item?.fld_birthday, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD 00:00:00.000')
        : moment().format('YYYY-MM-DD 00:00:00.000'),
      title: item?.fld_title || '',
      mobile: item?.fld_mobile || '',
      additionalPhone: item?.fld_phone || '',
      email: item?.fld_email || '',
      alternativeEmail: item?.fld_alternative_email || '',
      buildingId: item?.apartment?.building?.fld_uid.toString() || '',
      apartmentId: item?.apartment?.fld_uid.toString() || '',
      doorAccessCode: item?.additionalInfo?.fld_door_access_code || '',
      contactPhone: item?.fld_contact_tel || false,
      contactEmail: item?.fld_contact_email || false,
      contactSms: false,
      lettingCompanyName: item?.fld_letting_company_name || '',
      lettingCompanyPhone: item?.fld_letting_company_phone || '',
      lettingCompanyAddress: item?.fld_letting_company_address || '',
      lettingCompanyEmail: item?.fld_letting_company_email || '',
    }),
    [item],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const watchBuildingId = form.watch('buildingId');

  useUpdateEffect(() => {
    (async () => {
      if (watchBuildingId) {
        const loadedApartments =
          (
            await getApartmentsRequest(undefined, undefined, {
              fld_building_id: +watchBuildingId,
            })
          )?.items || [];
        setApartments(loadedApartments);
        const apartmentOptions = loadedApartments.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_apartment_name,
        }));
        setApartmentOptions(apartmentOptions);
        const apartmentId = form.getValues('apartmentId');
        const existingOption = apartmentOptions.find((x) => x.value === apartmentId);
        if (!existingOption) {
          form.setValue('apartmentId', '');
        }
      }
    })();
  }, [watchBuildingId]);

  useEffect(() => {
    form.reset(formDefaultData);
  }, [item]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const formData = form.getValues();

    const apartment = apartments.find((x) => x.fld_uid === +formData.apartmentId);

    const updatedItem: Partial<IResident> = {
      ...(item || { fld_active: true }),
      ...{
        apartment,
        fld_apartment_id: +formData.apartmentId,
        fld_owner: formData.isOwner,
        fld_primary_resident: formData.isPrimaryResident,
        fld_firstname: formData.firstName,
        fld_surname: formData.lastName,
        fld_birthday: moment(formData.birthday).format('YYYY-MM-DD 00:00:00.000'),
        fld_title: formData.title,
        fld_mobile: formData.mobile,
        fld_phone: formData.additionalPhone,
        fld_email: formData.email,
        fld_alternative_email: formData.additionalEmail,
        fld_contact_tel: formData.contactPhone,
        fld_contact_email: formData.contactEmail,
        fld_contact_sms: false,
        fld_letting_company_name: formData.lettingCompanyName,
        fld_letting_company_phone: formData.lettingCompanyPhone,
        fld_letting_company_address: formData.lettingCompanyAddress,
        fld_letting_company_email: formData.lettingCompanyEmail,
      },
    };

    const updatedAdditionalInfo: Partial<IResidentAdditionalInfo> = {
      ...(item?.additionalInfo || {}),
      ...{
        fld_vip_code: formData.vipCode,
        fld_door_access_code: formData.doorAccessCode,
        fld_vip_activated: !!formData.vipCode,
      },
    };

    updatedItem.additionalInfo = updatedAdditionalInfo as IResidentAdditionalInfo;
    updatedItem.updatedFiles = files;

    onClose(updatedItem);
  };

  const images = useMemo(() => item?.files || [], [item]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {item ? 'Resident info' : 'New Resident'}
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>
            {!readonly && (
              <Button
                variant='contained'
                type='submit'
                form='form'
                color='primary'
                sx={{
                  px: 2.5,
                  py: 1.5,
                  ml: 2,
                }}
              >
                {item ? 'Save & Close' : 'Create'}
              </Button>
            )}
          </Box>
        </Box>
        <Box>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gridGap: '2rem' }}>
              <Box sx={{ mt: 5 }}>
                <ImageUploader
                  maxAmount={1}
                  images={images}
                  onImagesUpdated={(items) => {
                    setFiles(items);
                  }}
                />
                <Box
                  sx={{
                    pb: 1,
                    mb: 1,
                    display: 'grid',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderBottomColor: (t: Theme) => t.palette.grey[100],
                  }}
                >
                  <Controller
                    name={'isOwner'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        row
                        name='owner'
                        value={value.toString()}
                        onChange={(event, value) => onChange(value === 'true')}
                      >
                        <FormControlLabel
                          value='true'
                          disabled={readonly}
                          control={<Radio />}
                          label={<Typography variant='body2'>Owner</Typography>}
                        />
                        <FormControlLabel
                          value='false'
                          disabled={readonly}
                          control={<Radio />}
                          label={<Typography variant='body2'>Tenant</Typography>}
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
                <Box sx={{ display: 'grid' }}>
                  <Controller
                    name={'isPrimaryResident'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        disabled={readonly}
                        label={<Typography variant='body2'>Primary Resident</Typography>}
                        control={<Checkbox checked={value} onChange={onChange} />}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ mt: 5, display: 'grid' }}>
                  <Controller
                    name={'vipCode'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled={readonly}
                        label='Vip Code'
                        variant='standard'
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 5 }}>
                <Typography variant='h6'>Info</Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 3fr 3fr 1fr',
                    gridGap: '1.5rem',
                    columnGap: '1.5rem',
                    mt: 2,
                  }}
                >
                  <Controller
                    name={'firstName'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the first name',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='First Name'
                        variant='outlined'
                        error={!!formState.errors.firstName}
                        helperText={formState.errors.firstName?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'lastName'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the last name',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='Last Name'
                        variant='outlined'
                        error={!!formState.errors.lastName}
                        helperText={formState.errors.lastName?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />

                  <Controller
                    name={'birthday'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the birthday',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <DatePicker
                        disabled={readonly}
                        label='Birthday'
                        components={{
                          OpenPickerIcon: ArrowDropDownIcon,
                        }}
                        inputFormat='DD/MM/YYYY'
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ mt: 1 }}
                            error={!!formState.errors.birthday}
                            helperText={formState.errors.birthday?.message}
                            variant='standard'
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name={'title'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the title',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <FormControl sx={{ mt: 1 }} variant='standard'>
                        <InputLabel id='title-select-label'>Title</InputLabel>
                        <Select
                          disabled={readonly}
                          labelId='title-select-label'
                          id='title-checkbox'
                          error={!!formState.errors.title}
                          value={value}
                          onChange={onChange}
                          renderValue={(selected) =>
                            titleOptions.find((x) => x.value === selected)?.label
                          }
                        >
                          {titleOptions.map((item) => (
                            <MenuItem
                              sx={{
                                px: 1,
                                py: 0,
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Radio size='small' checked={value === item.value} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
                <Typography variant='h6' sx={{ mt: 5 }}>
                  Contact
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridRowGap: '1.5rem',
                    gridColumnGap: '1.5rem',
                    mt: 2,
                  }}
                >
                  <Controller
                    name={'mobile'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the mobile number',
                      },
                      pattern: {
                        value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                        message: 'Please enter the valid mobile number',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='Mobile Number'
                        variant='outlined'
                        error={!!formState.errors.mobile}
                        helperText={formState.errors.mobile?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'additionalPhone'}
                    control={form.control}
                    rules={{
                      pattern: {
                        value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                        message: 'Please enter the valid phone number',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='Alternative Number'
                        variant='outlined'
                        error={!!formState.errors.additionalPhone}
                        helperText={formState.errors.additionalPhone?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'email'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the email',
                      },
                      pattern: {
                        value:
                          // eslint-disable-next-line no-control-regex
                          /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                        message: 'Please enter the valid email',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='Email'
                        variant='outlined'
                        error={!!formState.errors.email}
                        helperText={formState.errors.email?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'additionalEmail'}
                    control={form.control}
                    rules={{
                      pattern: {
                        value:
                          // eslint-disable-next-line no-control-regex
                          /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                        message: 'Please enter the valid email',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='Additional Email'
                        variant='outlined'
                        error={!!formState.errors.additionalEmail}
                        helperText={formState.errors.additionalEmail?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Box>
                <Typography variant='h6' sx={{ mt: 5 }}>
                  Apartment
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridRowGap: '1.5rem',
                    gridColumnGap: '1.5rem',
                    mt: 2,
                  }}
                >
                  <Controller
                    name={'buildingId'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the building',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <FormControl variant='standard'>
                        <InputLabel id='building-select-label'>Building</InputLabel>
                        <Select
                          disabled={readonly}
                          labelId='building-select-label'
                          id='building-checkbox'
                          value={value}
                          error={!!formState.errors.buildingId}
                          onChange={onChange}
                          renderValue={(selected) =>
                            buildingOptions.find((x) => x.value === selected)?.label
                          }
                        >
                          {buildingOptions.map((item) => (
                            <MenuItem
                              sx={{
                                px: 1,
                                py: 0,
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Radio size='small' checked={value === item.value} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={'apartmentId'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the apartment',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <FormControl variant='standard'>
                        <InputLabel id='apartment-select-label'>Apartment</InputLabel>
                        <Select
                          disabled={readonly || !form.getValues('buildingId')}
                          labelId='apartment-select-label'
                          id='apartment-checkbox'
                          value={value}
                          error={!!formState.errors.apartmentId}
                          onChange={onChange}
                          renderValue={(selected) =>
                            apartmentOptions.find((x) => x.value === selected)?.label
                          }
                        >
                          {apartmentOptions.map((item) => (
                            <MenuItem
                              sx={{
                                px: 1,
                                py: 0,
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Radio size='small' checked={value === item.value} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={'doorAccessCode'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled={readonly}
                        label='Door Access Code'
                        variant='standard'
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Box>
                <Typography variant='h6' sx={{ mt: 5 }}>
                  Contact preferences
                </Typography>
                <Box sx={{ display: 'flex', columnGap: '1.5rem', rowGap: '1.5rem', mt: 2 }}>
                  <Controller
                    name={'contactPhone'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        disabled={readonly}
                        label={<Typography variant='body2'>Phone</Typography>}
                        control={<Checkbox checked={value} onChange={onChange} />}
                      />
                    )}
                  />
                  <Controller
                    name={'contactEmail'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        disabled={readonly}
                        label={<Typography variant='body2'>Email</Typography>}
                        control={<Checkbox checked={value} onChange={onChange} />}
                      />
                    )}
                  />
                </Box>
                <Typography variant='h6' sx={{ mt: 5 }}>
                  Notes
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Controller
                    name={'notes'}
                    control={form.control}
                    rules={{
                      maxLength: {
                        value: 256,
                        message: 'Max length should be less or equal 256 characters',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        multiline
                        minRows={4}
                        fullWidth
                        variant='outlined'
                        placeholder='Some Notes here'
                        error={!!formState.errors.notes}
                        helperText={formState.errors.notes?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Box>
                <Typography variant='h6' sx={{ mt: 5 }}>
                  Letting Company
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridRowGap: '1.5rem',
                    gridColumnGap: '1.5rem',
                    mt: 2,
                  }}
                >
                  <Controller
                    name={'lettingCompanyName'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled={readonly}
                        label='Company Name'
                        variant='outlined'
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'lettingCompanyPhone'}
                    control={form.control}
                    rules={{
                      pattern: {
                        value: /^[+]?[(]?[0-9]{3,4}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/im,
                        message: 'Please enter the valid phone number',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='Company Phone'
                        variant='outlined'
                        error={!!formState.errors.lettingCompanyPhone}
                        helperText={formState.errors.lettingCompanyPhone?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'lettingCompanyAddress'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled={readonly}
                        label='Company Address'
                        variant='outlined'
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'lettingCompanyEmail'}
                    control={form.control}
                    rules={{
                      pattern: {
                        value:
                          // eslint-disable-next-line no-control-regex
                          /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                        message: 'Please enter the valid email',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        disabled={readonly}
                        label='Company Email'
                        variant='outlined'
                        error={!!formState.errors.lettingCompanyEmail}
                        helperText={formState.errors.lettingCompanyEmail?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
