/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { IBuilding } from './interfaces/entities/IBuilding';
import { IBuildingFilter } from './interfaces/filters/IBuildingFilter';
import { IGetBuildingsResponse } from './interfaces/responses/IGetBuildingsResponse';

export default class BuildingProvider extends BaseProvider {
  getBuildings = async (limit?: number, offset?: number, filters?: IBuildingFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_uid !== undefined) {
      filtersArray.push(`building.fld_uid = ${filters.fld_uid}`);
    }
    return this.get<IGetBuildingsResponse>(`${this.getBaseApiUrl()}/rs/buildings/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
  getBuilding = async (id: number) => {
    return this.get<IBuilding>(`${this.getBaseApiUrl()}/rs/buildings/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
