/* eslint-disable camelcase */

import TableDataProvider, { OrderingOptions } from '@rs/providers/TableDataProvider';

import FilesProvider from '@rs/providers/FilesProvider';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { IResidentAdditionalInfo } from '@rs/providers/interfaces/entities/IResidentAdditionalInfo';
import { IResidentAdditionalInfoFilter } from '@rs/providers/interfaces/filters/IResidentAdditionalInfoFilter';
import { IResidentFilter } from '@rs/providers/interfaces/filters/IResidentFilter';
import ResidentProvider from '@rs/providers/ResidentProvider';
import { Sections } from '@rs/constants/Sections';
import { UserGroups } from '@rs/constants/UserGroups';
import UserProvider from '@rs/providers/UserProvider';

const filesProvider = new FilesProvider();
const residentProvider = new ResidentProvider();
const userProvider = new UserProvider();
const residentDataProvider = new TableDataProvider<IResident, IResidentFilter>(Sections.residents);
const residentAdditionalInfoDataProvider = new TableDataProvider<
  IResidentAdditionalInfo,
  IResidentAdditionalInfoFilter
>(Sections.residentAdditionalInfo);

const ResidentService = {
  getResidents: async (limit?: number, offset?: number, filters?: IResidentFilter) => {
    const residentsResult = await residentProvider.getResidents(limit, offset, filters);
    residentsResult.items.forEach((x) => {
      x.files = x.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
    });
    return residentsResult;
  },
  getResident: async (id: number) => {
    const result = await ResidentService.getResidents(1, 0, {
      fld_uid: id,
    });
    if (!result.items.length) {
      throw new Error('Resident not found');
    }

    return result.items[0];
  },
  findResidentByZutecId: async (id: number) => {
    const residents = await ResidentService.getResidents(1, 0, {
      fld_zu_user_id: id,
    });
    return residents.items.length ? residents.items[0] : null;
  },
  updateResident: async (id: number, value: Partial<IResident>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { additionalInfo, apartment, vehicle, files, updatedFiles, ...data } = value;
    await residentDataProvider.updateTableDataRecord(id, data);
    if (additionalInfo && additionalInfo.fld_uid) {
      await residentAdditionalInfoDataProvider.updateTableDataRecord(
        additionalInfo.fld_uid,
        additionalInfo,
      );
    } else {
      const additional = {
        ...additionalInfo,
        fld_resident_id: id,
      };
      await residentAdditionalInfoDataProvider.createTableDataRecord(additional);
    }
    if (files && updatedFiles) {
      const remainingFiles = updatedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const filesToDelete =
        files?.filter((x) => !remainingFiles.find((c) => c.file_id === x.file_id)) || [];
      const filesToAdd = updatedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];
      await Promise.all([
        ...filesToAdd.map((x) => filesProvider.createFile(Sections.residents, id, x.file)),
        ...filesToDelete.map((x) => filesProvider.deleteFile(Sections.residents, id, x.file_id)),
      ]);
    }
  },
  updateResidentAdditionalInfo: async (value: Partial<IResidentAdditionalInfo>) => {
    if (value?.fld_uid) {
      await residentAdditionalInfoDataProvider.updateTableDataRecord(value?.fld_uid, value);
    } else {
      await residentAdditionalInfoDataProvider.createTableDataRecord(value);
    }
  },
  deleteResident: async (id: number, zutecId: number | null) => {
    await residentProvider.deleteResident(id);
    if (zutecId) await userProvider.deleteResident(zutecId);
  },
  createResident: async (value: Partial<IResident>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { additionalInfo, apartment, vehicle, updatedFiles, files, ...data } = value;
    const createResidentUserResult = await userProvider.createResident({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: data.fld_email!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      first_name: data.fld_firstname!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      surname: data.fld_surname!,
      group_name: UserGroups.resident,
    });
    const createResidentResult = await residentDataProvider.createTableDataRecord({
      ...data,

      fld_zu_user_id: createResidentUserResult.user_id,
    });
    const id = createResidentResult.fld_uid;
    if (additionalInfo) {
      additionalInfo.fld_resident_id = id;
      await residentAdditionalInfoDataProvider.createTableDataRecord(additionalInfo);
    }
    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all(
      filesToAdd.map((x) => filesProvider.createFile(Sections.residents, id, x.file)),
    );
  },
  getResidentsCount: async (filters?: IResidentFilter) => {
    const residentsCountResult = await residentDataProvider.getRecordCount(filters);
    return residentsCountResult.record_count;
  },
  updateOrdering: async (data: OrderingOptions) => {
    await residentDataProvider.updateTableDataOrdering(data);
  },
};

export default ResidentService;
