/* eslint-disable camelcase */

import { Alert, Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { selectAuthMainUserGroup, selectAuthUser } from '@rs/reducers/authSlice';
import { useApi, useAppSelector, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CollectParcelDialog from './components/CollectParcelDialog';
import HistoryDialog from './components/HistoryDialog';
import HistoryIcon from '@mui/icons-material/History';
import { IParcel } from '@rs/providers/interfaces/entities/IParcel';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageParcelDialog from './components/ManageParcelDialog';
import ParcelService from '@rs/services/ParcelService';
import { ParcelStatuses } from '@rs/constants/ParcelStatuses';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import SendIcon from '@mui/icons-material/Send';
import SendReminderDialog from './components/SendReminderDialog';
import dataGridStyles from '@rs/styles/dataGrid';
import moment from 'moment';
import searchInputStyles from '@rs/styles/searchInput';
import { useConfirm } from 'material-ui-confirm';

import {createIntl, createIntlCache} from '@formatjs/intl';
import englishTranslation from '../../../translations/en-GB.json';

export default function Parcels() {
  const confirm = useConfirm();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortOptions, setSortOptions] = useState<GridSortItem>({
    field: 'fld_last_action_date',
    sort: 'desc',
  });
  const user = useAppSelector(selectAuthUser);
  const role = useAppSelector(selectAuthMainUserGroup);
  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, 1000);
  const [data, setData] = useState<{ count: number; items: IParcel[] }>({ count: 0, items: [] });
  const [activeItem, setActiveItem] = useState<IParcel>();
  const [openManageParcelDialog, setOpenManageParcelDialog] = useState(false);
  const [openCollectDialog, setOpenCollectDialog] = useState(false);
  const [openReminderDialog, setOpenReminderDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);

  const getParcelsRequest = useApi(ParcelService.getParcels);
  const createParcelRequest = useApi(ParcelService.createParcel);
  const updateParcelRequest = useApi(ParcelService.updateParcel);
  const createParcelHistoryRequest = useApi(ParcelService.createParcelHistory);

  const loadData = useCallback(async () => {
    const filters = {
      // eslint-disable-next-line camelcase
      ...(search && { search_term: search }),
      // eslint-disable-next-line camelcase
      sort_column: sortOptions?.field || '',
      // eslint-disable-next-line camelcase
      sort_direction: sortOptions?.sort || '',
    };
    const result = await getParcelsRequest(pageSize, page * pageSize, filters);
    setData({ count: result?.total_count || 0, items: result?.items || [] });
  }, [page, pageSize, sortOptions]);

  const createParcelHistoryRecord = async (
    parcelId: number,
    status: ParcelStatuses,
    name: string,
    role: string,
    phone: string,
  ) => {
    const data = {
      fld_parcel_id: parcelId,
      fld_status: status,
      fld_action_date: moment().format(),
      fld_person_name: name,
      fld_person_role: role,
      fld_phone: phone,
    };
    await createParcelHistoryRequest(data);
  };

  const onPageChanged = (_page: number) => {
    setPage(_page);
  };

  const onPerPageChanged = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onSortModelChanged = async (_model: GridSortModel) => {
    setSortOptions(_model[0]);
  };

  const onAdd = () => {
    setOpenManageParcelDialog(true);
  };

  const onSendNotification = () => {
    setOpenReminderDialog(true);
  };

  const onCollect = async (item: IParcel) => {
    setActiveItem(item);
    setOpenCollectDialog(true);
  };

  const onShowHistory = (item: IParcel) => {
    setActiveItem(item);
    setOpenHistoryDialog(true);
  };

  const onReturn = async (item: IParcel) => {
    try {
      await confirm({ description: 'Change the status of this parcel to Returned.' });
    } catch {
      return;
    }
    const updatedItem = { ...item };

    updatedItem.fld_status = ParcelStatuses.returned;
    (updatedItem.fld_last_action_date = moment().format()),
      await updateParcelRequest(item.fld_uid, updatedItem);
    createParcelHistoryRecord(item.fld_uid, ParcelStatuses.returned, user?.name || '', role, '');
    await loadData();
  };

  const onCloseManageParcelDialog = async (item?: Partial<IParcel>) => {
    if (!item) {
      setOpenManageParcelDialog(false);
    } else {
      const parcelId = await createParcelRequest(item);
      if (parcelId) {
        createParcelHistoryRecord(parcelId, ParcelStatuses.received, user?.name || '', role, '');
      }
      setOpenManageParcelDialog(false);
      await loadData();
    }
  };

  const onCloseReminderDialog = () => {
    setOpenReminderDialog(false);
  };

  const onCloseCollectDialog = () => {
    setActiveItem(undefined);
    setOpenCollectDialog(false);
  };

  const onCollectParcel = async (name: string, role: string, phone?: string) => {
    const updatedItem = { ...activeItem };
    updatedItem.fld_status = ParcelStatuses.collected;
    updatedItem.fld_last_action_date = moment().format();
    await updateParcelRequest(activeItem?.fld_uid || 1, updatedItem as IParcel);

    createParcelHistoryRecord(
      activeItem?.fld_uid || 1,
      ParcelStatuses.collected,
      name,
      role,
      phone || '',
    );

    onCloseCollectDialog();
    await loadData();
  };

  const onCloseHistoryDialog = () => {
    setActiveItem(undefined);
    setOpenHistoryDialog(false);
  };

  const getStatusAlertType = (status: ParcelStatuses) => {
    switch (status) {
      case ParcelStatuses.received: {
        return 'warning';
      }
      case ParcelStatuses.collected: {
        return 'success';
      }
      case ParcelStatuses.returned: {
        return 'error';
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [sortOptions]);

  useUpdateEffect(() => {
    loadData();
  }, [search, page, pageSize]);

  // This is optional but highly recommended since it prevents memory leak
  const cache = createIntlCache();

  // Setting which translation file will be used
  const intl = createIntl({
      locale: 'en-GB',
      messages: englishTranslation,
    },
    cache
  );

  const columns: GridColDef[] = [
    {
      field: 'fld_resident_id',
      headerName: 'For',
      disableColumnMenu: true,
      sortable: false,
      flex: 15,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.resident?.fld_firstname || ''} ${
          params.row.resident?.fld_lastname || ''
        }`.trim();
      },
    },
    {
      field: 'parcel.fld_amount',
      headerName: 'Amount',
      disableColumnMenu: true,
      flex: 10,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.fld_amount;
      },
    },
    {
      field: 'fld_apartment_id',
      headerName: 'Apt.',
      disableColumnMenu: true,
      sortable: false,
      flex: 15,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.resident?.apartment?.fld_apartment_order} (${params.row.resident?.apartment?.fld_apartment_name})`;
      },
    },
    {
      field: 'parcel.fld_last_action_date',
      headerName: intl.formatMessage({
        id: "Pages.Manager.Parcels.dateAndTimeOfLastAction",
        defaultMessage: 'Date and Time of Last Action'
      }),
      disableColumnMenu: true,
      flex: 25,
      renderCell: (params: GridValueGetterParams) => {
        return moment(params.row.fld_last_action_date, 'YYYY-MM-DD HH:mm:ss').format(
          'HH:mm a, DD MMMM YYYY',
        );
      },
    },
    {
      field: 'parcel.fld_status',
      headerName: 'Status',
      disableColumnMenu: true,
      flex: 10,
      renderCell: (params: GridValueGetterParams) => {
        const type = getStatusAlertType(params.row.fld_status);
        return (
          <Alert
            sx={{
              py: 0,
              '& .MuiAlert-message': {
                py: 0,
              },
              '& .MuiTypography-root': {
                lineHeight: '2rem',
              },
            }}
            icon={false}
            severity={type}
          >
            <Typography variant='overline'>{params.row.fld_status}</Typography>
          </Alert>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      flex: 20,
      align: 'right',
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.row.fld_status === ParcelStatuses.received && (
            <SecureContainer sections={[Sections.parcels]} permission='modify'>
              <Tooltip title='Notify'>
                <IconButton
                  onClick={() => onSendNotification()}
                  sx={{
                    mr: 2,
                    px: 1,
                    color: (t) => t.palette.primary.main,
                    border: 1,
                    borderRadius: 1,
                  }}
                  aria-label='Notify'
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            </SecureContainer>
          )}
          {params.row.fld_status === ParcelStatuses.received && (
            <SecureContainer sections={[Sections.parcels]} permission='modify'>
              <Tooltip title='Collect'>
                <IconButton
                  onClick={() => onCollect(params.row)}
                  sx={{
                    px: 1,
                    color: (t) => t.palette.success.main,
                    border: 1,
                    borderRadius: 1,
                  }}
                  aria-label='Collect'
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            </SecureContainer>
          )}
          {params.row.fld_status !== ParcelStatuses.received && (
            <SecureContainer sections={[Sections.parcels]} permission='modify'>
              <Tooltip title='History'>
                <IconButton
                  onClick={() => onShowHistory(params.row)}
                  sx={{
                    ml: 2,
                    px: 1,
                    color: (t) => t.palette.primary.main,
                    border: 1,
                    borderRadius: 1,
                  }}
                  aria-label='History'
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </SecureContainer>
          )}
          {params.row.fld_status !== ParcelStatuses.returned && (
            <SecureContainer sections={[Sections.parcels]} permission='modify'>
              <Tooltip title='Return'>
                <IconButton
                  onClick={() => onReturn(params.row)}
                  sx={{
                    ml: 2,
                    px: 1,
                    color: (t) => t.palette.error.main,
                    border: 1,
                    borderRadius: 1,
                  }}
                  aria-label='Return'
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </SecureContainer>
          )}
        </>
      ),
    },
  ];

  return (
    <LoadPermissionsContainer section={Sections.parcels}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Parcels
            </Typography>
            <TextField
              sx={{ ...searchInputStyles, width: 250 }}
              onChange={searchInput.onChange}
              value={searchInput.value}
              placeholder='Search apartment or resident'
              variant='outlined'
              margin='normal'
            />
          </Box>
          <Box>
            <SecureContainer sections={[Sections.parcels]} permission='modify'>
              <Box>
                <Button
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => onAdd()}
                  sx={{
                    px: 2.5,
                    py: 1.5,
                  }}
                >
                  New Parcel
                </Button>
              </Box>
            </SecureContainer>
          </Box>
        </Box>

        <Box height={'100%'}>
          <DataGrid
            sx={dataGridStyles}
            rows={data.items}
            rowCount={data.count}
            columns={columns}
            page={page}
            pageSize={pageSize}
            sortModel={sortOptions ? [sortOptions] : []}
            sortingMode='server'
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={onPageChanged}
            onPageSizeChange={onPerPageChanged}
            onSortModelChange={onSortModelChanged}
            getRowId={(row: IParcel) => row.fld_uid}
          />
        </Box>
        <ManageParcelDialog open={openManageParcelDialog} onClose={onCloseManageParcelDialog} />
        <SendReminderDialog open={openReminderDialog} onClose={onCloseReminderDialog} />
        {openCollectDialog && (
          <CollectParcelDialog
            item={activeItem}
            open={openCollectDialog}
            onClose={onCloseCollectDialog}
            onCollect={onCollectParcel}
          />
        )}
        {openHistoryDialog && (
          <HistoryDialog
            item={activeItem}
            open={openHistoryDialog}
            onClose={onCloseHistoryDialog}
          />
        )}
      </>
    </LoadPermissionsContainer>
  );
}
