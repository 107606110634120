/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { ICreateEventRequest } from './interfaces/requests/ICreateEventRequest';
import { IEvent } from './interfaces/entities/IEvent';
import { IEventFilter } from './interfaces/filters/IEventFilter';
import { IGetEventsResponse } from './interfaces/responses/IGetEventsResponse';

export default class EventProvider extends BaseProvider {
  getEvents = async (limit?: number, offset?: number, filters?: IEventFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_uid !== undefined) {
      filtersArray.push(`event.fld_uid = ${filters.fld_uid}`);
    }
    if (filters?.fld_building_id !== undefined) {
      filtersArray.push(`event-relation.fld_building_id = ${filters.fld_building_id}`);
    }
    if (filters?.fld_apartment_type_id !== undefined) {
      filtersArray.push(`event-relation.fld_apartment_type_id = ${filters.fld_apartment_type_id}`);
    }
    if (filters?.fld_apartment_group_id !== undefined) {
      filtersArray.push(
        `event-relation.fld_apartment_group_id = ${filters.fld_apartment_group_id}`,
      );
    }
    return this.get<IGetEventsResponse>(`${this.getBaseApiUrl()}/rs/events/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
  createEvent = async (value: Partial<IEvent>) => {
    const {
      events_relations,
      fld_heading,
      fld_description,
      fld_location,
      fld_publish_date,
      fld_date_start,
      fld_date_end,
      fld_all_day,
    } = value;
    const [{ fld_building_id, fld_apartment_type_id, fld_apartment_group_id }] =
      events_relations || [];
    return this.post<
      ICreateEventRequest,
      {
        event_id: number;
      }
    >(
      `${this.getBaseApiUrl()}/rs/events/`,
      {
        heading: fld_heading,
        description: fld_description,
        location: fld_location,
        start_date: fld_date_start,
        end_date: fld_date_end,
        all_day: fld_all_day,
        publish_date: fld_publish_date,
        building_id: fld_building_id,
        apartment_type_id: fld_apartment_type_id,
        apartment_group_id: fld_apartment_group_id,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
  deleteEvent = async (id: number) => {
    return this.delete(`${this.getBaseApiUrl()}/rs/events/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
