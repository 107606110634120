/* eslint-disable camelcase  */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */

import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi, useAppSelector } from '@rs/helpers/hooks';

import ApartmentService from '@rs/services/ApartmentService';
import BuildingService from '@rs/services/BuildingService';
import EditIcon from '@mui/icons-material/Edit';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { IResidentAdditionalInfo } from '@rs/providers/interfaces/entities/IResidentAdditionalInfo';
import ImageUploader from '@rs/components/ImageUploader';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import { MobileDatePicker } from '@mui/x-date-pickers';
import ResidentService from '@rs/services/ResidentService';
import SaveIcon from '@mui/icons-material/Save';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import moment from 'moment';
import profileSettingsInputStyles from '@rs/styles/profileSettingsInput';
import { selectAuthUser } from '@rs/reducers/authSlice';

interface PersonalFormData {
  firstname: string;
  surname: string;
  birthday: string;
  title: string;
}
interface ContactFormData {
  mobile: string;
  altMobile: string;
  email: string;
  addEmail: string;
}
interface ApartmentFormData {
  address: string;
  building: string;
  apartment: string;
  doorCode: string;
}
interface LettingFormData {
  lettingCompanyName: string;
  lettingCompanyAddress: string;
  lettingCompanyPhone: string;
  lettingCompanyEmail: string;
}

export default function Profile() {
  const user = useAppSelector(selectAuthUser);
  const [resident, setResident] = useState<IResident>();
  const [apartment, setApartment] = useState<IApartment>();
  const [building, setBuilding] = useState<IBuilding>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, setFiles] = useState<UploaderFile[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState<IResidentAdditionalInfo>();
  const [editPersonalSection, setEditPersonalSection] = useState<boolean>(false);
  const [editContactSection, setEditContactSection] = useState<boolean>(false);
  const [editApartmentSection, setEditApartmentSection] = useState<boolean>(false);
  const [editLettingSection, setEditLettingSection] = useState<boolean>(false);

  const getResidentRequest = useApi(ResidentService.getResident);
  const updateResidentRequest = useApi(ResidentService.updateResident);
  const updateResidentAdditionalInfoRequest = useApi(ResidentService.updateResidentAdditionalInfo);
  const updateBuildingRequest = useApi(BuildingService.updateBuilding);
  const updateApartmentRequest = useApi(ApartmentService.updateApartment);

  const loadResidentData = useCallback(async () => {
    if (user?.residentId) {
      const residentResult = await getResidentRequest(user.residentId);
      if (residentResult) {
        setResident(residentResult);
        setApartment(residentResult.apartment);
        setBuilding(residentResult.apartment?.building);
        setAdditionalInfo(residentResult.additionalInfo);
      }
    }
  }, [user]);

  const personalFormDefaultData = useMemo(
    () => ({
      firstname: resident?.fld_firstname || '',
      surname: resident?.fld_surname || '',
      birthday: moment(resident?.fld_birthday, 'YYYY-MM-DD HH:mm').format() || '',
      title: resident?.fld_title || '',
    }),
    [resident],
  );

  const contactFormDefaultData = useMemo(
    () => ({
      mobile: resident?.fld_mobile || '',
      altMobile: resident?.fld_phone || '',
      email: resident?.fld_email || '',
      addEmail: resident?.fld_alternative_email || '',
    }),
    [resident],
  );

  const apartmentFormDefaultData = useMemo(
    () => ({
      address: resident?.apartment?.building?.fld_address || '',
      building: resident?.apartment?.building?.fld_building_name || '',
      apartment: resident?.apartment?.fld_apartment_name || '',
      doorCode: resident?.additionalInfo?.fld_door_access_code || '',
    }),
    [resident],
  );

  const lettingFormDefaultData = useMemo(
    () => ({
      lettingCompanyName: resident?.fld_letting_company_name || '',
      lettingCompanyAddress: resident?.fld_letting_company_address || '',
      lettingCompanyPhone: resident?.fld_letting_company_phone || '',
      lettingCompanyEmail: resident?.fld_letting_company_email || '',
    }),
    [resident],
  );

  const personalForm = useForm<PersonalFormData>({
    defaultValues: personalFormDefaultData,
  });
  const contactForm = useForm<ContactFormData>({
    defaultValues: contactFormDefaultData,
  });
  const apartmentForm = useForm<ApartmentFormData>({
    defaultValues: apartmentFormDefaultData,
  });
  const lettingForm = useForm<LettingFormData>({
    defaultValues: lettingFormDefaultData,
  });

  const onUpdateAvatar = async (items: UploaderFile[]) => {
    setFiles(items);
    if (items.length) {
      const updatedResident = { ...resident };
      updatedResident.updatedFiles = items;
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      await updateResidentRequest(resident?.fld_uid!, updatedResident);
    }
  };

  const onUpdatePersonalSection = async () => {
    setEditPersonalSection((prevState) => !prevState);
    if (editPersonalSection) {
      const formData = personalForm.getValues();
      const updatedResident = {
        ...resident,
        fld_firstname: formData.firstname,
        fld_surname: formData.surname,
        fld_birthday: moment(formData.birthday).format('DD/MM/YYYY HH:mm'),
        fld_title: formData.title,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      await updateResidentRequest(resident?.fld_uid!, updatedResident);
      if (resident) {
        setResident({
          ...resident,
          fld_firstname: formData.firstname,
          fld_surname: formData.surname,
          fld_birthday: moment(formData.birthday).format('DD/MM/YYYY HH:mm'),
          fld_title: formData.title,
        });
      }
    }
  };

  const onUpdateContactSection = async () => {
    setEditContactSection((prevState) => !prevState);
    if (editContactSection) {
      const formData = contactForm.getValues();
      const updatedResident = {
        ...resident,
        fld_mobile: formData.mobile,
        fld_phone: formData.altMobile,
        fld_email: formData.email,
        fld_alternative_email: formData.addEmail,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      await updateResidentRequest(resident?.fld_uid!, updatedResident);
      if (resident) {
        setResident({
          ...resident,
          fld_mobile: formData.mobile,
          fld_phone: formData.altMobile,
          fld_email: formData.email,
          fld_alternative_email: formData.addEmail,
        });
      }
    }
  };

  const onUpdateApartmentSection = async () => {
    setEditApartmentSection((prevState) => !prevState);
    if (editApartmentSection) {
      const formData = apartmentForm.getValues();
      if (
        formData.building !== building?.fld_building_name ||
        formData.address !== building?.fld_address
      ) {
        const updatedBuilding = {
          ...building,
          fld_building_name: formData.building,
          fld_address: formData.address,
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        await updateBuildingRequest(building?.fld_uid!, updatedBuilding);
        if (building) {
          setBuilding({
            ...building,
            fld_building_name: formData.building,
            fld_address: formData.address,
          });
        }
      }
      if (formData.apartment !== apartment?.fld_apartment_name) {
        const updatedApartment = {
          ...apartment,
          fld_apartment_name: formData.apartment,
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        await updateApartmentRequest(apartment?.fld_uid!, updatedApartment);
        if (apartment) {
          setApartment({
            ...apartment,
            fld_apartment_name: formData.apartment,
          });
        }
      }
      if (formData.doorCode !== additionalInfo?.fld_door_access_code) {
        const updatedInfo = {
          ...additionalInfo,
          fld_resident_id: resident?.fld_uid,
          fld_door_access_code: formData.doorCode,
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        await updateResidentAdditionalInfoRequest(updatedInfo);
        if (additionalInfo) {
          setAdditionalInfo({
            ...additionalInfo,
            fld_resident_id: resident?.fld_uid || 1,
            fld_door_access_code: formData.doorCode,
          });
        }
      }
    }
  };

  const onUpdateLettingSection = async () => {
    setEditLettingSection((prevState) => !prevState);
    if (editLettingSection) {
      const formData = lettingForm.getValues();
      const updatedResident = {
        ...resident,
        fld_letting_company_name: formData.lettingCompanyName,
        fld_letting_company_address: formData.lettingCompanyAddress,
        fld_letting_company_phone: formData.lettingCompanyPhone,
        fld_letting_company_email: formData.lettingCompanyEmail,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      await updateResidentRequest(resident?.fld_uid!, updatedResident);
      if (resident) {
        setResident({
          ...resident,
          fld_letting_company_name: formData.lettingCompanyName,
          fld_letting_company_address: formData.lettingCompanyAddress,
          fld_letting_company_phone: formData.lettingCompanyPhone,
          fld_letting_company_email: formData.lettingCompanyEmail,
        });
      }
    }
  };

  useEffect(() => {
    personalForm.reset(personalFormDefaultData);
    contactForm.reset(contactFormDefaultData);
    apartmentForm.reset(apartmentFormDefaultData);
    lettingForm.reset(lettingFormDefaultData);
  }, [resident]);

  useEffect(() => {
    if (user?.residentId) {
      loadResidentData();
    }
  }, [user]);

  const images = useMemo(() => resident?.files || [], [resident]);

  return (
    <LoadPermissionsContainer section={Sections.residents}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4'>
              Profile
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%', alignItems: 'flex-start' }}>
          <Box sx={{ width: '196px', height: '196px', mr: 5 }}>
            <ImageUploader
              maxAmount={1}
              images={images}
              onImagesUpdated={(items) => {
                onUpdateAvatar(items);
              }}
            />
            <Typography component='h6' variant='h6' sx={{ mt: 2 }}>
              {resident?.fld_firstname} {resident?.fld_surname}
            </Typography>
            {resident?.fld_owner && (
              <Typography component='span' variant='overline'>
                Owner
              </Typography>
            )}
          </Box>

          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', width: '100%', mb: 5 }}>
              <Box
                sx={{
                  width: 'calc(45% - 20px)',
                  bgcolor: '#F7F9FA',
                  p: 1,
                  borderRadius: 2,
                  mr: 5,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 2,
                    mb: 2,
                  }}
                >
                  <Typography component='h6' variant='h6'>
                    Personal Info
                  </Typography>
                  <SecureContainer sections={[Sections.residents]} permission='modify'>
                    <Tooltip title={editPersonalSection ? 'Save' : 'Edit'}>
                      <IconButton
                        onClick={() => onUpdatePersonalSection()}
                        sx={{
                          color: (t) => t.palette.primary.main,
                        }}
                        aria-label={editPersonalSection ? 'Save' : 'Edit'}
                      >
                        {editPersonalSection ? <SaveIcon /> : <EditIcon />}
                      </IconButton>
                    </Tooltip>
                  </SecureContainer>
                </Box>
                <form id='personalForm' noValidate style={{ width: '100%' }}>
                  <Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        First Name
                      </Typography>
                      <Controller
                        name={'firstname'}
                        control={personalForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editPersonalSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Last Name
                      </Typography>
                      <Controller
                        name={'surname'}
                        control={personalForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editPersonalSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Birthday
                      </Typography>
                      <Controller
                        name={'birthday'}
                        control={personalForm.control}
                        rules={{
                          required: {
                            value: false,
                            message: 'Please select the publish date',
                          },
                        }}
                        render={({ field: { onChange, value }, formState }) => (
                          <MobileDatePicker
                            inputFormat='DD/MM/YYYY'
                            value={value}
                            onChange={onChange}
                            disabled={!editPersonalSection}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  ...profileSettingsInputStyles,
                                  ...{
                                    width: '100%',
                                    mt: 0,
                                    mx: 0,
                                  },
                                }}
                                error={!!formState.errors.birthday}
                                helperText={formState.errors.birthday?.message}
                                variant='standard'
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Title
                      </Typography>
                      <Controller
                        name={'title'}
                        control={personalForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editPersonalSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </form>
              </Box>

              <Box sx={{ width: 'calc(55% - 20px)', bgcolor: '#F7F9FA', p: 1, borderRadius: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 2,
                    mb: 2,
                  }}
                >
                  <Typography component='h6' variant='h6'>
                    Contact Details
                  </Typography>
                  <SecureContainer sections={[Sections.residents]} permission='modify'>
                    <Tooltip title={editContactSection ? 'Save' : 'Edit'}>
                      <IconButton
                        onClick={() => onUpdateContactSection()}
                        sx={{
                          color: (t) => t.palette.primary.main,
                        }}
                        aria-label={editContactSection ? 'Save' : 'Edit'}
                      >
                        {editContactSection ? <SaveIcon /> : <EditIcon />}
                      </IconButton>
                    </Tooltip>
                  </SecureContainer>
                </Box>
                <form id='contactForm' noValidate style={{ width: '100%' }}>
                  <Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Mobile Number
                      </Typography>
                      <Controller
                        name={'mobile'}
                        control={contactForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editContactSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Alternative Number
                      </Typography>
                      <Controller
                        name={'altMobile'}
                        control={contactForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editContactSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Email
                      </Typography>
                      <Controller
                        name={'email'}
                        control={contactForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editContactSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Add. Email
                      </Typography>
                      <Controller
                        name={'addEmail'}
                        control={contactForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editContactSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box
                sx={{
                  width: 'calc(45% - 20px)',
                  bgcolor: '#F7F9FA',
                  p: 1,
                  borderRadius: 2,
                  mr: 5,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 2,
                    mb: 2,
                    height: 40,
                  }}
                >
                  <Typography component='h6' variant='h6'>
                    Apartment
                  </Typography>
                </Box>
                <form id='apartmentForm' noValidate style={{ width: '100%' }}>
                  <Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Address
                      </Typography>
                      <Controller
                        name={'address'}
                        control={apartmentForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editApartmentSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Building
                      </Typography>
                      <Controller
                        name={'building'}
                        control={apartmentForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editApartmentSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Appartment
                      </Typography>
                      <Controller
                        name={'apartment'}
                        control={apartmentForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editApartmentSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Door Access Code
                      </Typography>
                      <Controller
                        name={'doorCode'}
                        control={apartmentForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editApartmentSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </form>
              </Box>

              <Box sx={{ width: 'calc(55% - 20px)', bgcolor: '#F7F9FA', p: 1, borderRadius: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 2,
                    mb: 2,
                    height: 40,
                  }}
                >
                  <Typography component='h6' variant='h6'>
                    Letting Company
                  </Typography>
                </Box>
                <form id='lettingForm' noValidate style={{ width: '100%' }}>
                  <Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Company Name
                      </Typography>
                      <Controller
                        name={'lettingCompanyName'}
                        control={lettingForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editLettingSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Address
                      </Typography>
                      <Controller
                        name={'lettingCompanyAddress'}
                        control={lettingForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editLettingSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', position: 'relative', mb: 1 }}
                    >
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Company Phone
                      </Typography>
                      <Controller
                        name={'lettingCompanyPhone'}
                        control={lettingForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editLettingSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <Typography
                        component='span'
                        variant='body2'
                        sx={{ position: 'absolute', zIndex: 1, left: '16px' }}
                      >
                        Company Email
                      </Typography>
                      <Controller
                        name={'lettingCompanyEmail'}
                        control={lettingForm.control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              ...profileSettingsInputStyles,
                              ...{ width: '100%', mt: 0, mx: 0 },
                            }}
                            disabled={!editLettingSection}
                            variant='outlined'
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
