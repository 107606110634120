/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { BookingStatuses } from '@rs/constants/BookingStatuses';
import { IBooking } from './interfaces/entities/IBooking';
import { IBookingFilter } from '@rs/providers/interfaces/filters/IBookingFilter';
import { ICreateBookingRequest } from './interfaces/requests/ICreateBookingRequest';
import { IGetBookingResponse } from './interfaces/responses/IGetBookingResponse';
import { IUpdateBookingStatusRequest } from './interfaces/requests/IUpdateBookingStatusRequest';

export default class BookingProvider extends BaseProvider {
  getBookings = async (limit?: number, offset?: number, filters?: IBookingFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_booking_status !== undefined) {
      filtersArray.push(`fld_booking_status = "${filters.fld_booking_status}"`);
    }
    if (filters?.fld_resident_id !== undefined) {
      filtersArray.push(`fld_resident_id = ${filters.fld_resident_id}`);
    }

    return this.get<IGetBookingResponse>(`${this.getBaseApiUrl()}/rs/bookings/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
  createBooking = async (value: Partial<IBooking>) => {
    const {
      fld_resident_id,
      fld_booking_type_id,
      fld_booking_datetime,
      fld_booking_duration,
      fld_booking_notes,
    } = value;
    return this.post<
      ICreateBookingRequest,
      {
        booking_id: number;
      }
    >(
      `${this.getBaseApiUrl()}/rs/bookings/`,
      {
        resident_id: fld_resident_id,
        booking_type_id: fld_booking_type_id,
        datetime: fld_booking_datetime,
        duration: fld_booking_duration,
        notes: fld_booking_notes,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
  updateBookingStatus = async (id: number, status: BookingStatuses, notes?: string) => {
    return this.post<IUpdateBookingStatusRequest, void>(
      `${this.getBaseApiUrl()}/rs/bookings/${id}/`,
      {
        status,
        notes,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
}
