/* eslint-disable camelcase */

import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useApi, useAppSelector, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useEffect, useMemo, useState } from 'react';

import ApartmentService from '@rs/services/ApartmentService';
import BuildingService from '@rs/services/BuildingService';
import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import { ConversationTypes } from '@rs/constants/ConversationTypes';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import { IMessage } from '@rs/providers/interfaces/entities/IMessage';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import ResidentService from '@rs/services/ResidentService';
import { UserGroups } from '@rs/constants/UserGroups';
import { selectAuthMainUserGroup } from '@rs/reducers/authSlice';

interface IProps {
  open: boolean;
  onClose: (data?: Partial<IConversation>) => void;
  conversationTypes: IConversationType[] | undefined;
}

interface IFilters {
  building: string;
  floor: string;
}

interface FormData {
  topic: string;
  message: string;
  resident: IResident | null;
}

export default function NewConversationDialog(props: IProps) {
  const { onClose, open, conversationTypes } = props;
  const role = useAppSelector(selectAuthMainUserGroup);

  const formDefaultData = {
    resident: null,
    topic: '',
    message: '',
  };

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const buildingInput = useInput<string>('');
  const apartmentInput = useInput<string>('all');
  const buildingFloorInput = useInput<string>('all');
  const [buildings, setBuildings] = useState<IBuilding[]>([]);
  const [apartments, setApartments] = useState<IApartment[]>([]);
  const [residents, setResidents] = useState<IResident[]>([]);

  const getApartmentsRequest = useApi(ApartmentService.getApartments);
  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const getResidentsRequest = useApi(ResidentService.getResidents);

  const filters = useMemo<IFilters>(
    () => ({
      building: buildingInput.value,
      floor: buildingFloorInput.value,
    }),
    [buildingInput.value, buildingFloorInput.value],
  );

  const debouncedFilters = useDebounce<IFilters>(filters, 500);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const formData = form.getValues();

    const newItem: Partial<IConversation> = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fld_resident_id: formData.resident!.fld_uid,
      fld_status: ConversationStatuses.new,
      fld_title: formData.topic,
      fld_conversation_type_id: conversationTypes?.find(
        (x) => x.fld_name === ConversationTypes.message,
      )?.fld_uid,
      messages: [
        {
          fld_text: formData.message,
          fld_author_role: role as UserGroups,
        } as IMessage,
      ],
    };

    onClose(newItem);
  };

  useEffect(() => {
    (async () => {
      if (!open) {
        setBuildings([]);
        setApartments([]);
        setResidents([]);
        buildingInput.onChange({ target: { value: '' } });
        buildingFloorInput.onChange({ target: { value: 'all' } });
        apartmentInput.onChange({ target: { value: 'all' } });
        form.reset(formDefaultData);
      } else {
        const buildings = (await getBuildingsRequest())?.items || [];
        setBuildings(buildings);
        if (buildings.length) {
          buildingInput.onChange({ target: { value: buildings[0].fld_uid.toString() } });
          buildingFloorInput.onChange({ target: { value: 'all' } });
          apartmentInput.onChange({ target: { value: 'all' } });
        }
      }
    })();
  }, [open]);

  const buildingOptions = useMemo<ISelectOption[]>(
    () =>
      buildings.map((x) => ({
        label: x.fld_building_name,
        value: x.fld_uid.toString(),
      })),
    [buildings],
  );

  const buildingFloorOptions = useMemo<ISelectOption[]>(() => {
    const building = buildings.find((x) => x.fld_uid === +buildingInput.value);
    if (building) {
      return [
        {
          value: 'all',
          label: 'All',
        },
        ...Array.from({ length: building.fld_floors + 1 || 0 }, (_, i) => ({
          value: i.toString(),
          label: i.toString(),
        })),
      ];
    } else {
      return [
        {
          value: 'all',
          label: 'All',
        },
      ];
    }
  }, [buildingInput.value]);

  const apartmentOptions = useMemo<ISelectOption[]>(() => {
    return [
      {
        value: 'all',
        label: 'All',
      },
      ...apartments.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_apartment_name,
      })),
    ];
  }, [apartments]);

  useUpdateEffect(() => {
    apartmentInput.onChange({ target: { value: 'all' } });
  }, [buildingFloorInput.value]);

  useUpdateEffect(() => {
    buildingFloorInput.onChange({ target: { value: 'all' } });
  }, [buildingInput.value]);

  useUpdateEffect(() => {
    (async () => {
      if (debouncedFilters.building) {
        const loadedApartments =
          (
            await getApartmentsRequest(undefined, undefined, {
              fld_building_id: +debouncedFilters.building,
              fld_floor: debouncedFilters.floor === 'all' ? undefined : +debouncedFilters.floor,
            })
          )?.items || [];
        setApartments(loadedApartments);
      }
    })();
  }, [debouncedFilters]);

  useUpdateEffect(() => {
    (async () => {
      if (buildingInput.value) {
        const filters = {
          ...(buildingInput.value !== 'all' && {
            fld_building_id: +buildingInput.value,
          }),
          ...(buildingFloorInput.value !== 'all' && {
            fld_floor: +buildingFloorInput.value,
          }),
          ...(apartmentInput.value !== 'all' && {
            fld_apartment_id: +apartmentInput.value,
          }),
        };
        if (Object.keys(filters).length) {
          const loadedResidents =
            (await getResidentsRequest(undefined, undefined, filters))?.items || [];
          setResidents(loadedResidents);
        } else {
          setResidents([]);
        }
        form.setValue('resident', null);
      }
    })();
  }, [apartmentOptions, buildingInput.value, buildingFloorInput.value, apartmentInput.value]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='md'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            New Message
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              form='form'
              color='primary'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Send
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box sx={{ mt: 5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>Send message to:</Typography>
                <Alert severity='info' sx={{ px: 2, py: 0, ml: 2 }}>
                  <Typography variant='subtitle2'>
                    Search a tenant you want to send a message.
                  </Typography>
                </Alert>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gridColumnGap: '2rem',
                }}
              >
                <FormControl variant='standard'>
                  <InputLabel id='building-select-label'>Building</InputLabel>
                  <Select
                    labelId='building-select-label'
                    id='building-select'
                    value={buildingInput.value}
                    onChange={buildingInput.onChange}
                    renderValue={(selected) =>
                      buildingOptions.find((x) => x.value === selected)?.label
                    }
                  >
                    {buildingOptions.map((item) => (
                      <MenuItem
                        sx={{
                          px: 1,
                          py: 0,
                        }}
                        key={item.value}
                        value={item.value}
                      >
                        <Radio size='small' checked={buildingInput.value === item.value} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant='standard'>
                  <InputLabel id='type-select-label'>Floor</InputLabel>
                  <Select
                    labelId='type-select-label'
                    id='type-select'
                    value={buildingFloorInput.value}
                    onChange={buildingFloorInput.onChange}
                    renderValue={(selected) =>
                      buildingFloorOptions.find((x) => x.value === selected)?.label
                    }
                  >
                    {buildingFloorOptions.map((item) => (
                      <MenuItem
                        sx={{
                          px: 1,
                          py: 0,
                        }}
                        key={item.value}
                        value={item.value}
                      >
                        <Radio size='small' checked={buildingFloorInput.value === item.value} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 220 }} variant='standard'>
                  <InputLabel id='apartment-select-label'>Apartment</InputLabel>
                  <Select
                    labelId='apartment-select-label'
                    id='apartment-select'
                    value={apartmentInput.value}
                    onChange={apartmentInput.onChange}
                    renderValue={(selected) =>
                      apartmentOptions.find((x) => x.value === selected)?.label
                    }
                  >
                    {apartmentOptions.map((item) => (
                      <MenuItem
                        sx={{
                          px: 1,
                          py: 0,
                        }}
                        key={item.value}
                        value={item.value}
                      >
                        <Radio size='small' checked={apartmentInput.value === item.value} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Controller
                  name={'resident'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select the resident',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <Autocomplete
                      value={value}
                      onChange={(_, value) => onChange({ target: { value } })}
                      options={residents}
                      clearIcon={false}
                      popupIcon={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            '& .MuiInputBase-root': {
                              backgroundColor: (t) => t.palette.grey[100],
                            },
                          }}
                          error={!!formState.errors.resident}
                          helperText={formState.errors.resident?.message}
                          placeholder='Search resident'
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option.fld_uid === value.fld_uid}
                      getOptionLabel={(option) =>
                        `${option.fld_firstname} ${option.fld_surname}`.trim()
                      }
                      renderOption={(props, option) => {
                        const name = `${option.fld_firstname} ${option.fld_surname}`.trim();
                        const subtitle = `Build: ${
                          option.apartment?.building?.fld_building_name
                        } • Apt: ${option.apartment?.fld_apartment_name} • Group: ${
                          option.apartment?.group?.fld_apartment_group_name || ''
                        }`;
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            component='li'
                            {...props}
                            key={option.fld_uid}
                          >
                            {option.files && (
                              <Avatar src={option.files[0]?.preview}>
                                {!option.files[0]?.preview && name.at(0)}
                              </Avatar>
                            )}
                            <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
                              <Typography variant='subtitle2'>{name}</Typography>
                              <Typography
                                sx={{
                                  color: (t) => t.palette.grey[600],
                                }}
                                variant='caption'
                              >
                                {subtitle}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Controller
                  name={'topic'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the topic',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      label='Topic of Conversation'
                      variant='outlined'
                      error={!!formState.errors.topic}
                      helperText={formState.errors.topic?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={'message'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the message',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ mt: 2 }}
                      multiline
                      minRows={4}
                      fullWidth
                      variant='outlined'
                      placeholder='Type message here'
                      error={!!formState.errors.message}
                      helperText={formState.errors.message?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
