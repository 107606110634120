import { Box, Button, TextField, Typography } from '@mui/material';
import { useApi, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import BookingTypeService from '@rs/services/BookingTypeService';
import { IBookingType } from '@rs/providers/interfaces/entities/IBookingType';
import ListItem from '@rs/pages/resident/bookings/components/ListItem';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import { Sections } from '@rs/constants/Sections';
import searchInputStyles from '@rs/styles/searchInput';

export default function Bookings() {
  const [page, setPage] = useState(0);
  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, 1000);
  const pageSize = useMemo(() => 10, []);

  const [data, setData] = useState<{ count: number; items: IBookingType[] }>({
    count: 0,
    items: [],
  });

  const getBookingTypesRequest = useApi(BookingTypeService.getBookingTypes);

  const loadData = useCallback(
    async (reset: boolean) => {
      const filters = {
        // eslint-disable-next-line camelcase
        fld_is_bookable: true,
        // eslint-disable-next-line camelcase
        ...(search && { search_term: search }),
      };
      const bookingTypeResponse = await getBookingTypesRequest(pageSize, page * pageSize, filters);
      if (reset) {
        setData({
          count: bookingTypeResponse?.total_count || 0,
          items: bookingTypeResponse?.items || [],
        });
      } else {
        setData((prevData) => ({
          count: bookingTypeResponse?.total_count || 0,
          items: [...prevData.items, ...(bookingTypeResponse?.items || [])],
        }));
      }
    },
    [page, search],
  );

  const onShowMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    loadData(true);
  }, []);

  useUpdateEffect(() => {
    loadData(false);
  }, [page]);

  useUpdateEffect(() => {
    loadData(true);
  }, [search]);

  return (
    <LoadPermissionsContainer section={Sections.bookingTypes}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' sx={{ mr: 5 }}>
              Bookings
            </Typography>
            <TextField
              sx={{ ...searchInputStyles, ...{ width: '100%', m: 0 } }}
              onChange={searchInput.onChange}
              value={searchInput.value}
              placeholder='Search'
              variant='outlined'
              margin='normal'
            />
          </Box>
        </Box>
        <Box height={'100%'}>
          {data.items &&
            data.items.length > 0 &&
            data.items.map((booking, index) => (
              <ListItem key={index} data={booking} baseUrl='resident-bookings' />
            ))}
          {data.items && data.items.length > 0 && data.count > data.items.length && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
              <Button onClick={() => onShowMore()} variant='outlined' color='primary' size='small'>
                Load more
              </Button>
            </Box>
          )}
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
