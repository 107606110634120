import { Alert, Box, Button, Typography } from '@mui/material';

import { BookingStatuses } from '@rs/constants/BookingStatuses';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { IBooking } from '@rs/providers/interfaces/entities/IBooking';
import moment from 'moment';

interface IProps {
  data: IBooking;
  openDialog?: () => void;
  cancelBooking?: () => void;
}

export default function MyBookingsListItem({ data, openDialog, cancelBooking }: IProps) {
  const image =
    data.booking_type?.files && data.booking_type.files.length > 0
      ? data.booking_type.files[0].preview
      : null;

  const getStatusAlertType = (status: BookingStatuses) => {
    switch (status) {
      case BookingStatuses.pending: {
        return 'warning';
      }
      case BookingStatuses.confirmed: {
        return 'success';
      }
      case BookingStatuses.rejected: {
        return 'error';
      }
      case BookingStatuses.cancelled: {
        return 'error';
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: '#F7F9FA',
          borderRadius: 2.5,
          py: 1.5,
          px: 2.5,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              width: '68px',
              height: '68px',
              borderRadius: '50%',
              backgroundColor: 'gray',
              backgroundImage: image ? 'url("' + image + '")' : undefined,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              mr: 1.5,
            }}
          ></Box>
          <Box>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography component='h6' variant='h6' sx={{ mr: 2 }}>
                {data.booking_type?.fld_booking_type_heading}
              </Typography>
              <Alert
                sx={{
                  py: 0,
                  '& .MuiAlert-message': {
                    py: 0,
                  },
                }}
                icon={false}
                severity={getStatusAlertType(data.fld_booking_status)}
              >
                <Typography variant='overline'>{data.fld_booking_status}</Typography>
              </Alert>
            </Box>
            <Box sx={{ display: 'flex', mb: 0.5 }}>
              <Typography component='span' variant='caption' sx={{ mr: 0.5, color: '#0000008A' }}>
                Start:
              </Typography>
              <Typography component='span' variant='overline'>
                {moment(data.fld_booking_datetime, 'YYYY-MM-DD HH:mm:ss').format(
                  'h:mmA, DD MMMM YYYY',
                )}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography component='span' variant='caption' sx={{ mr: 0.5, color: '#0000008A' }}>
                Duration:
              </Typography>
              <Typography component='span' variant='overline'>
                {moment.duration(data.fld_booking_duration, 'minutes').humanize()}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={openDialog}
            variant='text'
            startIcon={<EditIcon />}
            sx={{ color: '#00000061' }}
          >
            Edit
          </Button>
          {data.fld_booking_status !== BookingStatuses.cancelled && (
            <Button
              onClick={cancelBooking}
              variant='text'
              startIcon={<DeleteForeverIcon />}
              sx={{ color: (t) => t.palette.error.main, ml: 5 }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
