import TableDataProvider, { OrderingOptions } from '@rs/providers/TableDataProvider';

import ConversationProvider from '@rs/providers/ConversationProvider';
import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import FilesProvider from '@rs/providers/FilesProvider';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationFilter } from '@rs/providers/interfaces/filters/IConversationFilter';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import MessageProvider from '@rs/providers/MessageProvider';
import { Sections } from '@rs/constants/Sections';

const conversationProvider = new ConversationProvider();
const filesProvider = new FilesProvider();
const conversationDataProvider = new TableDataProvider<IConversation, IConversationFilter>(
  Sections.conversations,
);
const messageProvider = new MessageProvider();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const conversationTypeDataProvider = new TableDataProvider<IConversationType, any>(
  Sections.conversationTypes,
);

const ConversationService = {
  getConversations: async (limit?: number, offset?: number, filters?: IConversationFilter) => {
    const conversationsResult = await conversationProvider.getConversations(limit, offset, filters);

    conversationsResult.items.forEach((item) => {
      if (item.resident) {
        item.resident.files = item.resident.files.map((file) => ({
          ...file,
          preview: filesProvider.generatePreviewLink(file),
        }));
      }
      if (item.last_message) {
        item.last_message.files = item.last_message.files.map((file) => ({
          ...file,
          preview: filesProvider.generatePreviewLink(file),
        }));
      }
    });

    return conversationsResult;
  },
  getConversation: async (id: number) => {
    const result = await ConversationService.getConversations(1, 0, {
      // eslint-disable-next-line camelcase
      fld_uid: id,
    });
    if (!result.items.length) {
      throw new Error('Conversation not found');
    }

    const conversation = result.items[0];
    conversation.messages = conversation.last_message ? [conversation.last_message] : [];

    return conversation;
  },
  getConversationTypes: async () => {
    const conversationTypes = await conversationTypeDataProvider.getAllTableData();
    return conversationTypes;
  },
  createConversation: async (value: Partial<IConversation>) => {
    const { messages, ...data } = value;
    const createConversationResult = await conversationProvider.createConversation(data);
    const id = createConversationResult.conversation_id;
    messages?.forEach((x) => {
      // eslint-disable-next-line camelcase
      x.fld_conversation_id = id;
    });

    if (messages && messages.length) {
      await Promise.all(
        messages.map(async (x) => {
          const { updatedFiles, ...data } = x;
          const createMessageResult = await messageProvider.createMessage(data);
          const id = createMessageResult.message_id;
          const filesToAdd = (updatedFiles?.filter((f) => (f as INewAttachedFile).file) ||
            []) as INewAttachedFile[];
          await Promise.all(
            filesToAdd.map((f) => filesProvider.createFile(Sections.messages, id, f.file)),
          );
        }),
      );
    }
  },
  updateConversation: async (id: number, value: Partial<IConversation>) => {
    await conversationDataProvider.updateTableDataRecord(id, value);
  },
  updateConversationStatus: async (id: number, status: ConversationStatuses) => {
    await conversationProvider.updateConversationStatus(id, status);
  },
  updateOrdering: async (data: OrderingOptions) => {
    await conversationDataProvider.updateTableDataOrdering(data);
  },
};

export default ConversationService;
