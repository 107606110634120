import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useApi, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AlertIcon from '@rs/assets/alert.svg';
import ApartmentService from '@rs/services/ApartmentService';
import BuildingService from '@rs/services/BuildingService';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { INews } from '@rs/providers/interfaces/entities/INews';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageNewsDialog from './components/ManageNewsDialog';
import NewsService from '@rs/services/NewsService';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import dataGridStyles from '@rs/styles/dataGrid';
import { useConfirm } from 'material-ui-confirm';

export default function News() {
  const confirm = useConfirm();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [data, setData] = useState<{ count: number; items: INews[] }>({
    count: 0,
    items: [],
  });

  const [buildingOptions, setBuildingOptions] = useState<ISelectOption[]>([]);
  const [apartmentTypeOptions, setApartmentTypeOptions] = useState<ISelectOption[]>([]);
  const [apartmentGroupOptions, setApartmentGroupOptions] = useState<ISelectOption[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState<INews | undefined>(undefined);

  const getNewsRequest = useApi(NewsService.getNews);
  const createNewsRequest = useApi(NewsService.createNews);
  const updateNewsRequest = useApi(NewsService.updateNews);
  const deleteNewsRequest = useApi(NewsService.deleteNews);
  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const getApartmentTypesRequest = useApi(ApartmentService.getApartmentTypes);
  const getApartmentGroupsRequest = useApi(ApartmentService.getApartmentGroups);

  const loadData = useCallback(async () => {
    const result = await getNewsRequest(pageSize, page * pageSize, {
      // eslint-disable-next-line camelcase
      sort_column: 'news.fld_heading,asc',
      // eslint-disable-next-line camelcase
      sort_direction: 'asc',
    });
    setData({ count: result?.total_count || 0, items: result?.items || [] });
  }, [page, pageSize]);

  const loadAudience = useCallback(async () => {
    const buildings = (await getBuildingsRequest())?.items || [];
    setBuildingOptions([
      ...buildings.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_building_name,
      })),
    ]);
    const apartmentTypes = (await getApartmentTypesRequest()) || [];
    setApartmentTypeOptions([
      ...apartmentTypes.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_apartment_type_name,
      })),
    ]);
    const apartmentGroups = (await getApartmentGroupsRequest()) || [];
    setApartmentGroupOptions([
      ...apartmentGroups.map((x) => ({
        value: x.fld_uid.toString(),
        label: x.fld_apartment_group_name,
      })),
    ]);
  }, []);

  const onPageChanged = (_page: number) => {
    setPage(_page);
  };

  const onPerPageChanged = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onEdit = (item: INews) => {
    setActiveItem(item);
    setOpenDialog(true);
  };

  const onDelete = async (item: INews) => {
    try {
      await confirm({ description: 'This will permanently delete the news.' });
    } catch {
      return;
    }
    await deleteNewsRequest(item.fld_uid);
    await loadData();
  };

  const onAdd = () => {
    setActiveItem(undefined);
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<INews>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      if (activeItem) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await updateNewsRequest(item.fld_uid!, item, activeItem);
      } else {
        await createNewsRequest(item);
      }
      setOpenDialog(false);
      await loadData();
    }
  };

  useEffect(() => {
    (async () => {
      await loadData();
      await loadAudience();
    })();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [page, pageSize]);

  const columns: GridColDef[] = [
    {
      field: 'fld_heading',
      headerName: 'Title',
      disableColumnMenu: true,
      flex: 3,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        const isImportant = params.row.fld_important;
        return (
          <>
            {isImportant && (
              <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                <img src={AlertIcon} alt='important' />
              </Box>
            )}
            {params.row.fld_heading}
          </>
        );
      },
    },
    {
      field: 'fld_audience',
      headerName: 'Audience',
      disableColumnMenu: true,
      flex: 2,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        let audience = null;
        if (params.row.news_relations?.length) {
          const [rel] = params.row.news_relations;
          const itemAudience = apartmentGroupOptions.find(
            (a) => parseInt(a.value) === rel.fld_apartment_group_id,
          );
          audience = itemAudience?.label;
        }
        return <>{audience}</>;
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      align: 'right',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <SecureContainer sections={[Sections.news]} permission='modify'>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => onEdit(params.row)}
                sx={{ p: 0, color: (t) => t.palette.grey[600] }}
                aria-label='Edit'
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
          <SecureContainer sections={[Sections.news]} permission='delete'>
            <Tooltip title='Delete'>
              <IconButton
                onClick={() => onDelete(params.row)}
                sx={{ ml: 5, p: 0, color: (t) => t.palette.error.main }}
                aria-label='Delete'
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
        </>
      ),
    },
  ];

  return (
    <LoadPermissionsContainer section={Sections.news}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              News
            </Typography>
          </Box>
          <SecureContainer sections={[Sections.news]} permission='modify'>
            <Box>
              <Button
                variant='contained'
                startIcon={<AddIcon />}
                onClick={() => onAdd()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                }}
              >
                New
              </Button>
            </Box>
          </SecureContainer>
        </Box>
        <Box height={'100%'}>
          <DataGrid
            isRowSelectable={() => false}
            sx={dataGridStyles}
            rows={data.items}
            rowCount={data.count}
            columns={columns}
            page={page}
            pageSize={pageSize}
            paginationMode='server'
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={onPageChanged}
            onPageSizeChange={onPerPageChanged}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getRowId={(row: INews) => row.fld_uid!}
          />
        </Box>
        <ManageNewsDialog
          item={activeItem}
          buildingOptions={buildingOptions}
          apartmentTypeOptions={apartmentTypeOptions}
          apartmentGroupOptions={apartmentGroupOptions}
          open={openDialog}
          onClose={onCloseDialog}
        />
      </>
    </LoadPermissionsContainer>
  );
}
