import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { selectGeneralLoader, selectGeneralTheme } from '@rs/reducers/generalSlice';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AlertTemplate from '@rs/helpers/AlertTemplate';
import AuthenticatedContainer from './AuthenticatedContainer';
import CommonSingleArticlePage from '@rs/pages/common/articles/SingleArticle';
import { ConfirmProvider } from 'material-ui-confirm';
import ForgotPasswordPage from '@rs/pages/forgotPassword';
import HomePage from '@rs/pages/home';
import LoadPermissionsContainer from './LoadPermissionsContainer';
import Loader from '@rs/components/Loader';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ManagerApartmentSinglePage from '@rs/pages/manager/apartments/SinglePage';
import ManagerApartmentsPage from '@rs/pages/manager/apartments';
import ManagerBookingTypesPage from '@rs/pages/manager/bookingTypes';
import ManagerBookingsPage from '@rs/pages/manager/bookings';
import ManagerBuildingSinglePage from '@rs/pages/manager/buildings/SinglePage';
import ManagerBuildingsPage from '@rs/pages/manager/buildings';
import ManagerEventsPage from '@rs/pages/manager/events';
import ManagerKeyWaiversPage from '@rs/pages/manager/keyWaivers';
import ManagerNewsPage from '@rs/pages/manager/news';
import ManagerParcelsPage from '@rs/pages/manager/parcels';
import ManagerProfilePage from '@rs/pages/manager/profile';
import ManagerRequestTypesPage from '@rs/pages/manager/requestTypes';
import ManagerResidentsPage from '@rs/pages/manager/residents';
import ManagerServiceDeskPage from '@rs/pages/manager/serviceDesk';
import ResidentAmenitiesPage from '@rs/pages/resident/bookings/Amenities';
import ResidentBookingsPage from '@rs/pages/resident/bookings';
import ResidentBuildingPage from '@rs/pages/resident/building';
import ResidentBuildingTeamPage from '@rs/pages/resident/building/Team';
import ResidentEventSinglePage from '@rs/pages/resident/events/SingleEvent';
import ResidentEventsPage from '@rs/pages/resident/events';
import ResidentHelpDeskPage from '@rs/pages/resident/helpDesk';
import ResidentMyBookingsPage from '@rs/pages/resident/bookings/MyBookings';
import ResidentNewsPage from '@rs/pages/resident/news';
import ResidentNewsSinglePage from '@rs/pages/resident/news/SingleNews';
import ResidentProfilePage from '@rs/pages/resident/profile';
import ResidentSingleBookingPage from '@rs/pages/resident/bookings/SingleBooking';
import ProjectsPage from "@rs/pages/projects";
import { Sections } from '@rs/constants/Sections';
import SecureContainer from './SecureContainer';
import SignInPage from '@rs/pages/signIn';
import { Suspense } from 'react';
import { UserGroups } from '@rs/constants/UserGroups';
import { selectAuthMainUserGroup } from '@rs/reducers/authSlice';
import { useAppSelector } from '@rs/helpers/hooks';

export default function AppContainer() {
  const loader = useAppSelector(selectGeneralLoader);
  const themeOptions = useAppSelector(selectGeneralTheme);
  const role = useAppSelector(selectAuthMainUserGroup);
  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider
        template={AlertTemplate}
        position={positions.TOP_RIGHT}
        timeout={5000}
        transition={transitions.FADE}
        containerStyle={{ zIndex: 1400 }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ConfirmProvider>
            <Loader show={loader} />

            <Suspense fallback={<Loader show />}>
              <Router>
                <Routes>
                  <Route path='/' element={<HomePage />} />
                  <Route path='/sign-in' element={<SignInPage />} />
                  <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                  <Route path="/projects" element={<ProjectsPage/>} />
                  <Route path='/' element={<AuthenticatedContainer />}>
                    <Route
                      path='/residents'
                      element={
                        <LoadPermissionsContainer section={Sections.residents}>
                          <SecureContainer
                            sections={[Sections.residents]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerResidentsPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/service-desk'
                      element={
                        <LoadPermissionsContainer section={Sections.conversations}>
                          <SecureContainer
                            sections={[Sections.conversations]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerServiceDeskPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/request-types'
                      element={
                        <LoadPermissionsContainer section={Sections.conversationOptions}>
                          <SecureContainer
                            sections={[Sections.conversationOptions]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerRequestTypesPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/bookings'
                      element={
                        <LoadPermissionsContainer section={Sections.bookings}>
                          <SecureContainer
                            sections={[Sections.bookings]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerBookingsPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/booking-types'
                      element={
                        <LoadPermissionsContainer section={Sections.bookingTypes}>
                          <SecureContainer
                            sections={[Sections.bookingTypes]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerBookingTypesPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/parcels'
                      element={
                        <LoadPermissionsContainer section={Sections.parcels}>
                          <SecureContainer
                            sections={[Sections.parcels]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerParcelsPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/key-waivers'
                      element={
                        <LoadPermissionsContainer section={Sections.keyWaiver}>
                          <SecureContainer
                            sections={[Sections.keyWaiver]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerKeyWaiversPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/buildings'
                      element={
                        <LoadPermissionsContainer section={Sections.buildings}>
                          <SecureContainer
                            sections={[Sections.buildings]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerBuildingsPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/buildings/:id'
                      element={
                        <LoadPermissionsContainer section={Sections.buildings}>
                          <SecureContainer
                            sections={[Sections.buildings]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerBuildingSinglePage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/apartments'
                      element={
                        <LoadPermissionsContainer section={Sections.apartments}>
                          <SecureContainer
                            sections={[Sections.apartments]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerApartmentsPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/apartments/:id'
                      element={
                        <LoadPermissionsContainer section={Sections.apartments}>
                          <SecureContainer
                            sections={[Sections.apartments]}
                            userGroups={[UserGroups.admin, UserGroups.concierge]}
                            redirect
                          >
                            <ManagerApartmentSinglePage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/news'
                      element={
                        <LoadPermissionsContainer section={Sections.news}>
                          <SecureContainer
                            sections={[Sections.news]}
                            userGroups={[
                              UserGroups.admin,
                              UserGroups.concierge,
                              UserGroups.resident,
                            ]}
                            redirect
                          >
                            {role === UserGroups.resident ? (
                              <ResidentNewsPage />
                            ) : (
                              <ManagerNewsPage />
                            )}
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/events'
                      element={
                        <LoadPermissionsContainer section={Sections.events}>
                          <SecureContainer
                            sections={[Sections.events]}
                            userGroups={[
                              UserGroups.admin,
                              UserGroups.concierge,
                              UserGroups.resident,
                            ]}
                            redirect
                          >
                            {role === UserGroups.resident ? (
                              <ResidentEventsPage />
                            ) : (
                              <ManagerEventsPage />
                            )}
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/news/:id'
                      element={
                        <LoadPermissionsContainer section={Sections.news}>
                          <SecureContainer
                            sections={[Sections.news]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentNewsSinglePage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/events/:id'
                      element={
                        <LoadPermissionsContainer section={Sections.events}>
                          <SecureContainer
                            sections={[Sections.events]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentEventSinglePage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/amenities'
                      element={
                        <LoadPermissionsContainer section={Sections.bookingTypes}>
                          <SecureContainer
                            sections={[Sections.bookingTypes]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentAmenitiesPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/amenities/:id'
                      element={
                        <LoadPermissionsContainer section={Sections.bookingTypes}>
                          <SecureContainer
                            sections={[Sections.bookingTypes]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentSingleBookingPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/my/:type'
                      element={
                        <LoadPermissionsContainer section={Sections.buildings}>
                          <SecureContainer
                            sections={[Sections.buildings]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentBuildingPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/my/:type/:id/team'
                      element={
                        <LoadPermissionsContainer section={Sections.buildings}>
                          <SecureContainer
                            sections={[Sections.buildings]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentBuildingTeamPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/articles/:id'
                      element={
                        <LoadPermissionsContainer section={Sections.informationCategories}>
                          <SecureContainer
                            sections={[Sections.informationCategories]}
                            userGroups={[
                              UserGroups.resident,
                              UserGroups.concierge,
                              UserGroups.admin,
                            ]}
                            redirect
                          >
                            <CommonSingleArticlePage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/resident-bookings'
                      element={
                        <LoadPermissionsContainer section={Sections.bookingTypes}>
                          <SecureContainer
                            sections={[Sections.bookingTypes]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentBookingsPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/resident-bookings/:id'
                      element={
                        <LoadPermissionsContainer section={Sections.bookingTypes}>
                          <SecureContainer
                            sections={[Sections.bookingTypes]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentSingleBookingPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/my-bookings'
                      element={
                        <LoadPermissionsContainer section={Sections.bookings}>
                          <SecureContainer
                            sections={[Sections.bookings]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentMyBookingsPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/help-desk'
                      element={
                        <LoadPermissionsContainer section={Sections.conversations}>
                          <SecureContainer
                            sections={[Sections.conversations]}
                            userGroups={[UserGroups.resident]}
                            redirect
                          >
                            <ResidentHelpDeskPage />
                          </SecureContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                    <Route
                      path='/profile'
                      element={
                        <LoadPermissionsContainer section={Sections.residents}>
                          <LoadPermissionsContainer section={Sections.staff}>
                            <SecureContainer
                              sections={[Sections.residents, Sections.staff]}
                              userGroups={[
                                UserGroups.admin,
                                UserGroups.concierge,
                                UserGroups.resident,
                              ]}
                              redirect
                            >
                              {role === UserGroups.resident ? (
                                <ResidentProfilePage />
                              ) : (
                                <ManagerProfilePage />
                              )}
                            </SecureContainer>
                          </LoadPermissionsContainer>
                        </LoadPermissionsContainer>
                      }
                    />
                  </Route>
                </Routes>
              </Router>
            </Suspense>
          </ConfirmProvider>
        </LocalizationProvider>
      </AlertProvider>
    </ThemeProvider>
  );
}
