/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { IGetTableDataResponse } from './interfaces/responses/IGetTableDataResponse';
import { IGetTableRecordCountResponse } from './interfaces/responses/IGetTableRecordCountResponse';
import { Sections } from '@rs/constants/Sections';

export interface OrderingOptions {
  order_direction: 'asc' | 'desc';
  order_column: string;
}

export default class TableDataProvider<T, TF> extends BaseProvider {
  private section: Sections;

  constructor(_section: Sections) {
    super();
    this.section = _section;
  }

  getRecordCount = async (filters?: TF) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    const filtersArray: string[] = [];

    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = (filters as any)[key];
        if (value !== undefined) {
          filtersArray.push(`${key} = ${value}`);
        }
      });
    }

    return this.get<IGetTableRecordCountResponse>(`${this.getBaseApiUrl()}/node/${nodeId}/count/`, {
      params: {
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  getTableDataRecord = (id: number) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    return this.get<T>(`${this.getBaseApiUrl()}/node/${nodeId}/record/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  getTableData = (limit?: number, offset?: number, filters?: TF) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    const filtersArray: string[] = [];

    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = (filters as any)[key];
        if (value !== undefined) {
          filtersArray.push(`${key} = ${value}`);
        }
      });
    }

    return this.get<IGetTableDataResponse<T>>(`${this.getBaseApiUrl()}/node/${nodeId}/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  getAllTableData = async (filters?: TF) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    const filtersArray: string[] = [];

    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = (filters as any)[key];
        if (value !== undefined) {
          filtersArray.push(`${key} = ${value}`);
        }
      });
    }

    let result: T[] = [];

    let i = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const response = await this.get<IGetTableDataResponse<T>>(
        `${this.getBaseApiUrl()}/node/${nodeId}/`,
        {
          params: {
            limit: 100,
            offset: i * 100,
            ...(filtersArray.length && {
              filter: filtersArray.join(' and '),
            }),
          },
          headers: {
            auth: this.getAuthToken(),
          },
        },
      );
      result = [...result, ...response.items];
      if (response.count < 100) {
        break;
      } else {
        i++;
      }
    }
    return result;
  };

  deleteTableDataRecord = (id: number) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    return this.delete(`${this.getBaseApiUrl()}/node/${nodeId}/record/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  updateTableDataRecord = (id: number, data: Partial<T>) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    return this.post<T, void>(`${this.getBaseApiUrl()}/node/${nodeId}/record/${id}/`, data, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  createTableDataRecord = (data: Partial<T>) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    return this.post<T, { fld_uid: number }>(`${this.getBaseApiUrl()}/node/${nodeId}/`, data, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };

  updateTableDataOrdering = (data: OrderingOptions) => {
    const section = this.getSections().find((x) => x.name === this.section);
    if (!section) throw new Error('Failed to detect the section');
    const nodeId = section.id;

    return this.post<OrderingOptions, void>(
      `${this.getBaseApiUrl()}/node/${nodeId}/column-ordering/`,
      data,
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
}
