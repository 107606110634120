import TableDataProvider, { OrderingOptions } from '@rs/providers/TableDataProvider';

import ConversationOptionProvider from '@rs/providers/ConversationOptionProvider';
import { IBaseFilter } from '@rs/providers/interfaces/filters/IBaseFilter';
import { IConversationOption } from '@rs/providers/interfaces/entities/IConversationOption';
import { IConversationOptionField } from '@rs/providers/interfaces/entities/IConversationOptionField';
import { IConversationOptionFieldFilter } from '@rs/providers/interfaces/filters/IConversationOptionFieldFilter';
import { IConversationOptionFieldType } from '@rs/providers/interfaces/entities/IConversationOptionFieldType';
import { IConversationOptionFilter } from '@rs/providers/interfaces/filters/IConversationOptionFilter';
import { Sections } from '@rs/constants/Sections';

const conversationOptionProvider = new ConversationOptionProvider();
const conversationOptionDataProvider = new TableDataProvider<
  IConversationOption,
  IConversationOptionFilter
>(Sections.conversationOptions);
const conversationOptionFieldDataProvider = new TableDataProvider<
  IConversationOptionField,
  IConversationOptionFieldFilter
>(Sections.conversationOptionFields);
const conversationOptionFieldTypeDataProvider = new TableDataProvider<
  IConversationOptionFieldType,
  IBaseFilter
>(Sections.conversationOptionFieldTypes);

const ConversationOptionService = {
  getConversationOptions: async (
    limit?: number,
    offset?: number,
    filters?: IConversationOptionFilter,
  ) => {
    const conversationOptionsResult = await conversationOptionProvider.getConversationOptions(
      limit,
      offset,
      filters,
    );
    conversationOptionsResult.items.forEach((item) => {
      item.conversation_option_fields?.sort((a, b) => {
        const aOrder = a.fld_order || 0;
        const bOrder = b.fld_order || 0;
        return aOrder - bOrder;
      });
    });
    return conversationOptionsResult;
  },
  getConversationOptionFieldTypes: async () => {
    const conversationOptionFieldTypes =
      await conversationOptionFieldTypeDataProvider.getAllTableData();
    return conversationOptionFieldTypes;
  },
  createConversationOption: async (value: Partial<IConversationOption>) => {
    // eslint-disable-next-line camelcase
    const { conversation_option_fields: conversationOptionFields, ...data } = value;
    const createConversationOptionResult =
      await conversationOptionDataProvider.createTableDataRecord(data);
    const id = createConversationOptionResult.fld_uid;

    if (conversationOptionFields) {
      conversationOptionFields.forEach((x) => {
        // eslint-disable-next-line camelcase
        x.fld_conversation_option_id = id;
      });

      await Promise.all(
        conversationOptionFields.map((x) =>
          conversationOptionFieldDataProvider.createTableDataRecord(x),
        ),
      );
    }
  },
  updateConversationOption: async (
    id: number,
    newValue: Partial<IConversationOption>,
    oldValue: IConversationOption,
  ) => {
    const { conversation_option_fields: conversationOptionFields, ...data } = newValue;
    const { conversation_option_fields: oldConversationOptionFields } = oldValue;
    await conversationOptionDataProvider.updateTableDataRecord(id, data);

    const conversationOptionFieldsToCreate =
      conversationOptionFields?.filter(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (x) => !oldConversationOptionFields!.find((c) => c.fld_uid === x.fld_uid),
      ) || [];
    const conversationOptionFieldsToUpdate =
      conversationOptionFields?.filter((x) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        oldConversationOptionFields!.find((c) => c.fld_uid === x.fld_uid),
      ) || [];
    const conversationOptionFieldsToDelete =
      oldConversationOptionFields?.filter(
        (x) => !conversationOptionFields?.find((c) => c.fld_uid === x.fld_uid),
      ) || [];

    await Promise.all([
      ...conversationOptionFieldsToCreate.map((x) =>
        conversationOptionFieldDataProvider.createTableDataRecord(x),
      ),
      ...conversationOptionFieldsToUpdate.map((x) =>
        conversationOptionFieldDataProvider.updateTableDataRecord(x.fld_uid, x),
      ),
      ...conversationOptionFieldsToDelete.map((x) =>
        conversationOptionFieldDataProvider.deleteTableDataRecord(x.fld_uid),
      ),
    ]);
  },
  deleteConversationOption: async (id: number) => {
    await conversationOptionProvider.deleteConversationOption(id);
  },
  updateOrdering: async (data: OrderingOptions) => {
    await conversationOptionDataProvider.updateTableDataOrdering(data);
  },
  updateConversationOptionFieldOrdering: async (data: OrderingOptions) => {
    await conversationOptionFieldDataProvider.updateTableDataOrdering(data);
  },
};

export default ConversationOptionService;
