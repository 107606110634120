import { selectAuthMainUserGroup, selectAuthUser } from '@rs/reducers/authSlice';

import { UserGroups } from '@rs/constants/UserGroups';
import { useAppSelector } from '@rs/helpers/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();
  const role = useAppSelector(selectAuthMainUserGroup);
  const user = useAppSelector(selectAuthUser);

  useEffect(() => {
    if (!user) {
      navigate('/sign-in');
    } else if (role === UserGroups.admin || role === UserGroups.concierge) {
      navigate('/residents');
    } else if (role === UserGroups.resident) {
      navigate('/my-bookings');
    }
  }, [user, role]);

  return null;
}
