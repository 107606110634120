/* eslint-disable camelcase */

import { Box, Button, Dialog, DialogContent, TextField, Theme, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import { ConversationTypes } from '@rs/constants/ConversationTypes';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import { IMessage } from '@rs/providers/interfaces/entities/IMessage';
import { UserGroups } from '@rs/constants/UserGroups';
import { selectAuthUser } from '@rs/reducers/authSlice';
import { useAppSelector } from '@rs/helpers/hooks';
import { useEffect } from 'react';

interface IProps {
  open: boolean;
  onClose: (data?: Partial<IConversation>) => void;
  conversationTypes: IConversationType[] | undefined;
}

interface FormData {
  topic: string;
  message: string;
}

export default function NewConversationDialog(props: IProps) {
  const user = useAppSelector(selectAuthUser);
  const { onClose, open, conversationTypes } = props;

  const formDefaultData = {
    resident: null,
    topic: '',
    message: '',
  };

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const formData = form.getValues();

    if (!user) {
      return;
    }

    const newItem: Partial<IConversation> = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fld_resident_id: user.residentId,
      fld_status: ConversationStatuses.new,
      fld_title: formData.topic,
      fld_conversation_type_id: conversationTypes?.find(
        (x) => x.fld_name === ConversationTypes.message,
      )?.fld_uid,
      messages: [
        {
          fld_text: formData.message,
          fld_author_role: UserGroups.resident,
        } as IMessage,
      ],
    };

    onClose(newItem);
  };

  useEffect(() => {
    if (!open) {
      form.reset(formDefaultData);
    }
  }, [open]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='md'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            Contact Concierge
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              form='createConversationForm'
              color='primary'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Send
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 5 }}>
          <form
            id='createConversationForm'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box sx={{ mt: 5 }}>
              <Box>
                <Controller
                  name={'topic'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the topic',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      label='Topic of Conversation'
                      variant='outlined'
                      error={!!formState.errors.topic}
                      helperText={formState.errors.topic?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={'message'}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the message',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ mt: 2 }}
                      multiline
                      minRows={4}
                      fullWidth
                      variant='outlined'
                      placeholder='Type message here'
                      error={!!formState.errors.message}
                      helperText={formState.errors.message?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
