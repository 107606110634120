/* eslint-disable camelcase */

import { Box, TextField, Typography } from '@mui/material';
import { useApi, useAppSelector, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ActionControls from './components/ActionControls';
import BoxWithMultilineText from '@rs/components/BoxWithMultilineText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConversationService from '@rs/services/ConversationService';
import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import { ConversationTypes } from '@rs/constants/ConversationTypes';
import ConversationView from './components/ConversationView';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import { IMessage } from '@rs/providers/interfaces/entities/IMessage';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import Lottie from 'lottie-react';
import MessageService from '@rs/services/MessagesService';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import conversationStatusLabel from '@rs/styles/conversationStatusLabel';
import { dateToFromNowDaily } from '@rs/helpers/date';
import emptyConversationsAnimation from './animations/empty-conversations.json';
import searchInputStyles from '@rs/styles/searchInputSmall';
import { selectAuthUser } from '@rs/reducers/authSlice';
import { selectGeneralLogo } from '@rs/reducers/generalSlice';

export default function HelpDesk() {
  const user = useAppSelector(selectAuthUser);
  const logo = useAppSelector(selectGeneralLogo);

  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, 1000);
  const pageSize = useMemo(() => 10, []);

  const [conversationTypes, setConversationTypes] = useState<IConversationType[]>([]);
  const [data, setData] = useState<{ count: number; items: IConversation[] }>({
    count: 0,
    items: [],
  });

  const [activeItem, setActiveItem] = useState<IConversation | undefined>(undefined);

  const getConversationTypesRequest = useApi(ConversationService.getConversationTypes);
  const getConversationsRequest = useApi(ConversationService.getConversations);
  const getConversationRequest = useApi(ConversationService.getConversation);
  const updateOrderingRequest = useApi(MessageService.updateOrdering);

  useEffect(() => {
    (async () => {
      const existingConversationTypes = (await getConversationTypesRequest()) || [];
      setConversationTypes(existingConversationTypes);
    })();
  }, []);

  const loadData = useCallback(
    async (reset: boolean) => {
      const filters = {
        ...(user?.residentId && { fld_resident_id: user.residentId }),
        ...(search && { search_term: search }),
        sort_column: 'conversation.fld_timestamp',
        sort_direction: 'desc',
      };
      const response = await getConversationsRequest(
        pageSize,
        reset ? 0 : data.items.length,
        filters,
      );
      if (reset) {
        setData({
          count: response?.total_count || 0,
          items: response?.items || [],
        });
      } else {
        setData((prevData) => ({
          count: response?.total_count || 0,
          items: [...prevData.items, ...(response?.items || [])],
        }));
      }
    },
    [data.items.length, search],
  );

  const reloadActiveItem = useCallback(async () => {
    if (!activeItem) return;
    const item = await getConversationRequest(activeItem.fld_uid);

    setData((prevData) => ({
      count: prevData.count,
      items: prevData.items.map((x) => (x.fld_uid !== item?.fld_uid ? x : item)),
    }));
  }, [activeItem]);

  const onSelectItem = (item: IConversation) => {
    setActiveItem(item);
    reloadActiveItem();
  };

  const onConversationLastMessageUpdated = (message: IMessage) => {
    setData((prevData) => ({
      count: prevData.count,
      items: prevData.items.map((x) =>
        x.fld_uid !== activeItem?.fld_uid ? x : { ...x, last_message: message },
      ),
    }));
  };

  const onConversationCreated = () => {
    loadData(true);
  };

  useEffect(() => {
    (async () => {
      await updateOrderingRequest({
        // eslint-disable-next-line camelcase
        order_column: 'fld_timestamp',
        // eslint-disable-next-line camelcase
        order_direction: 'desc',
      });
      await loadData(true);
    })();
  }, []);

  useUpdateEffect(() => {
    loadData(true);
  }, [search]);

  const requestConversationTypeId = useMemo(() => {
    const type = conversationTypes.find((x) => x.fld_name === ConversationTypes.request);
    return type?.fld_uid || -1;
  }, [conversationTypes]);

  const issueConversationTypeId = useMemo(() => {
    const type = conversationTypes.find((x) => x.fld_name === ConversationTypes.issue);
    return type?.fld_uid || -1;
  }, [conversationTypes]);

  const hasMore = useMemo(() => data.items.length < data.count, [data.items.length, data.count]);

  const getStatusLabel = (x: IConversation) => {
    if (x.fld_conversation_type_id === requestConversationTypeId) {
      return x.fld_status === ConversationStatuses.new ? (
        <Box
          sx={conversationStatusLabel(
            (t) => t.palette.warning.main,
            (t) => `${t.palette.warning.main}10`,
          )}
        >
          PENDING
        </Box>
      ) : x.fld_status === ConversationStatuses.rejected ? (
        <Box
          sx={conversationStatusLabel(
            (t) => t.palette.error.main,
            (t) => `${t.palette.error.main}10`,
          )}
        >
          REJECTED
        </Box>
      ) : (
        <Box
          sx={conversationStatusLabel(
            (t) => t.palette.success.main,
            (t) => `${t.palette.success.main}10`,
          )}
        >
          APPROVED
        </Box>
      );
    } else if (x.fld_conversation_type_id === issueConversationTypeId) {
      return x.fld_status === ConversationStatuses.new ? (
        <Box
          sx={conversationStatusLabel(
            (t) => t.palette.grey[800],
            (t) => t.palette.grey[200],
          )}
        >
          WAITING
        </Box>
      ) : x.fld_status === ConversationStatuses.inProgress ? (
        <Box
          sx={conversationStatusLabel(
            (t) => t.palette.warning.main,
            (t) => `${t.palette.warning.main}10`,
          )}
        >
          IN PROGRESS
        </Box>
      ) : (
        <Box
          sx={conversationStatusLabel(
            (t) => t.palette.success.main,
            (t) => `${t.palette.success.main}10`,
          )}
        >
          RESOLVED
        </Box>
      );
    }
    return null;
  };

  return (
    <LoadPermissionsContainer section={Sections.conversations}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Help Desk
            </Typography>
          </Box>
          <Box>
            <SecureContainer sections={[Sections.conversations]} permission='modify'>
              <>
                {data.items.length > 0 && (
                  <ActionControls
                    onConversationCreated={onConversationCreated}
                    conversationTypes={conversationTypes}
                  />
                )}
              </>
            </SecureContainer>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ...(!data.items.length
              ? { display: 'flex', alignItems: 'center', justifyContent: 'center' }
              : {}),
          }}
        >
          {data.items.length ? (
            <Box
              sx={{
                display: 'grid',
                gap: '1px',
                gridTemplateColumns: '400px 3fr',
                gridTemplateRows: '57px 1fr',
                borderRadius: 2,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: (t) => t.palette.grey[100],
                backgroundColor: (t) => t.palette.grey[100],
                maxHeight: '600px',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  borderTopLeftRadius: 8,
                  backgroundColor: '#fff',
                  p: 1.5,
                }}
              >
                <TextField
                  sx={{ ...searchInputStyles, ...{ width: '100%', m: 0 } }}
                  onChange={searchInput.onChange}
                  value={searchInput.value}
                  placeholder='Search'
                  variant='outlined'
                  margin='normal'
                />
              </Box>
              <Box
                sx={{
                  borderTopRightRadius: 8,
                  backgroundColor: '#fff',
                  display: 'flex',
                  columnGap: 1,
                  alignItems: 'center',
                  px: 2,
                }}
              >
                {activeItem && (
                  <>
                    <Box>
                      <img src={logo} style={{ maxWidth: '40px' }} />
                    </Box>
                    <Box>
                      <Typography variant='subtitle2'>{activeItem.fld_title}</Typography>
                    </Box>
                    {getStatusLabel(activeItem)}
                  </>
                )}
              </Box>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  overflowY: 'auto',
                  maxHeight: 540,
                  borderBottomLeftRadius: 8,
                }}
              >
                {data.items.map((x, i) => (
                  <Box
                    key={`${x.fld_uid}-${i}`}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      columnGap: 1,
                      alignItems: 'center',
                      p: 2,
                      borderBottomWidth: 1,
                      borderBottomStyle: 'solid',
                      borderBottomColor: (t) => t.palette.grey[100],
                      backgroundColor: (t) =>
                        activeItem && activeItem.fld_uid === x.fld_uid
                          ? t.palette.grey[100]
                          : '#fff',
                      height: '88px',
                    }}
                    onClick={() => {
                      onSelectItem(x);
                    }}
                  >
                    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                      <Box sx={{ mr: 1 }}>
                        <img src={logo} style={{ maxWidth: '40px' }} />
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography variant='subtitle2' sx={{ lineHeight: 1.25 }}>
                              {x.fld_title}
                            </Typography>
                            {getStatusLabel(x)}
                          </Box>
                          <Box>
                            <ChevronRightIcon
                              color='primary'
                              sx={{
                                visibility:
                                  activeItem && activeItem.fld_uid === x.fld_uid
                                    ? 'hidden'
                                    : 'visible',
                              }}
                            />
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography sx={{ color: (t) => t.palette.grey[600] }} variant='caption'>
                            <BoxWithMultilineText
                              text={
                                x.last_message?.fld_text ||
                                (x.last_message?.files?.length
                                  ? 'File attachment'
                                  : 'No messages here')
                              }
                            />
                          </Typography>
                          <Typography
                            component='div'
                            variant='caption'
                            sx={{
                              color: (t) => t.palette.grey[600],
                              minWidth: 100,
                              textAlign: 'right',
                            }}
                          >
                            {dateToFromNowDaily(
                              x.last_message?.fld_timestamp || x.fld_timestamp,
                              true,
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}

                {hasMore && (
                  <Box
                    sx={{ color: (t) => t.palette.primary.main, p: 2 }}
                    onClick={() => loadData(false)}
                  >
                    <Typography
                      variant='subtitle2'
                      sx={{
                        textAlign: 'center',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                        cursor: 'pointer',
                      }}
                    >
                      Load more
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box sx={{ maxHeight: 540, borderBottomRightRadius: 8 }}>
                {activeItem && (
                  <ConversationView
                    conversation={activeItem}
                    onLastMessageUpdated={onConversationLastMessageUpdated}
                  />
                )}
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Lottie
                animationData={emptyConversationsAnimation}
                loop={true}
                style={{ maxWidth: '240px' }}
              />
              <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
                How can we help you?
              </Typography>
              <Typography variant='subtitle2' sx={{ mb: 5, color: (t) => t.palette.primary.main }}>
                You can report an issue or create license request
              </Typography>
              <ActionControls
                onConversationCreated={onConversationCreated}
                conversationTypes={conversationTypes}
              />
            </Box>
          )}
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
