/* eslint-disable camelcase */

import { Box, Button, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useApi, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ConversationDialog from './components/ConversationDialog';
import ConversationService from '@rs/services/ConversationService';
import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import { ConversationTypes } from '@rs/constants/ConversationTypes';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageResidentDialog from '../residents/components/ManageResidentDialog';
import MessageService from '@rs/services/MessagesService';
import NewConversationDialog from './components/NewConversationDialog';
import ResidentService from '@rs/services/ResidentService';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import SendIcon from '@mui/icons-material/Send';
import TableCellWithMultilineText from '@rs/components/TableCellWithMultilineText';
import { UserGroups } from '@rs/constants/UserGroups';
import conversationStatusLabel from '@rs/styles/conversationStatusLabel';
import dataGridStyles from '@rs/styles/dataGrid';
import moment from 'moment';

export default function ServiceDesk() {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortOptions, setSortOptions] = useState<GridSortItem>({
    field: 'conversation.fld_timestamp',
    sort: 'desc',
  });
  const [conversationTypes, setConversationTypes] = useState<IConversationType[] | undefined>([]);
  const typeInput = useInput<string>(ConversationTypes.message);

  const [data, setData] = useState<{ count: number; items: IConversation[] }>({
    count: 0,
    items: [],
  });

  const [openNewConversationDialog, setOpenNewConversationDialog] = useState(false);
  const [openResidentDialog, setOpenResidentDialog] = useState(false);
  const [activeResidentItem, setActiveResidentItem] = useState<IResident | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState<IConversation | undefined>(undefined);

  const getConversationsRequest = useApi(ConversationService.getConversations);
  const getConversationTypesRequest = useApi(ConversationService.getConversationTypes);
  const createConversationRequest = useApi(ConversationService.createConversation);
  const getResidentRequest = useApi(ResidentService.getResident);
  const updateOrderingRequest = useApi(MessageService.updateOrdering);

  const loadData = useCallback(async () => {
    const type = conversationTypes?.find((x) => x.fld_name === typeInput.value);
    const filters = {
      ...(type && { fld_conversation_type_id: type.fld_uid }),
      sort_column: sortOptions?.field || '',
      sort_direction: sortOptions?.sort || '',
    };
    const response = await getConversationsRequest(pageSize, page * pageSize, filters);
    setData({ count: response?.total_count || 0, items: response?.items || [] });
  }, [page, pageSize, typeInput.value, conversationTypes, sortOptions]);

  const onPageChanged = (_page: number) => {
    setPage(_page);
  };

  const onPerPageChanged = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onSortModelChanged = async (_model: GridSortModel) => {
    setSortOptions(_model[0]);
  };

  const onPreviewResident = async (item: IResident) => {
    const resident = await getResidentRequest(item.fld_uid);
    setActiveResidentItem(resident);
    setOpenResidentDialog(true);
  };

  const onCloseResidentDialog = async () => {
    setOpenResidentDialog(false);
  };

  const onOpenDialog = (item: IConversation) => {
    setActiveItem(item);
    setOpenDialog(true);
  };

  const onCloseDialog = async () => {
    setOpenDialog(false);
    setActiveItem(undefined);
    await loadData();
  };

  const onAdd = () => {
    setOpenNewConversationDialog(true);
  };

  const onCloseNewConversationDialog = async (item?: Partial<IConversation>) => {
    if (!item) {
      setOpenNewConversationDialog(false);
    } else {
      await createConversationRequest(item);
      setOpenNewConversationDialog(false);
      await loadData();
    }
  };

  const residentBuildingOptions = useMemo<ISelectOption[]>(() => {
    if (activeResidentItem) {
      return [
        {
          label: activeResidentItem.apartment?.building?.fld_building_name || '',
          value: activeResidentItem.apartment?.building?.fld_uid.toString() || '',
        },
      ];
    } else {
      return [];
    }
  }, [activeResidentItem]);

  useEffect(() => {
    (async () => {
      const existingConversationTypes = await getConversationTypesRequest();
      setConversationTypes(existingConversationTypes);
    })();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [conversationTypes]);

  useUpdateEffect(() => {
    loadData();
  }, [sortOptions]);

  useUpdateEffect(() => {
    loadData();
  }, [page, pageSize]);

  useUpdateEffect(() => {
    setData({ count: 0, items: [] });
    loadData();
  }, [typeInput.value]);

  useEffect(() => {
    updateOrderingRequest({
      // eslint-disable-next-line camelcase
      order_column: 'fld_timestamp',
      // eslint-disable-next-line camelcase
      order_direction: 'desc',
    });
  }, []);

  const columns = useMemo<GridColDef[]>(
    () =>
      [
        {
          field: 'resident.fld_firstname',
          headerName: 'From',
          disableColumnMenu: true,
          sortable: false,
          flex: 15,
          renderCell: (params: GridValueGetterParams) => (
            <Box sx={{ cursor: 'pointer' }} onClick={() => onPreviewResident(params.row.resident)}>
              {`${params.row.resident?.fld_firstname || ''} ${
                params.row.resident?.fld_surname || ''
              }`.trim()}
            </Box>
          ),
        },
        {
          field: 'conversation.fld_title',
          headerName: 'Subject',
          disableColumnMenu: true,
          flex: 20,
          valueGetter: (params: GridValueGetterParams) => params.row.fld_title,
        },
        {
          field: 'conversation.fld_timestamp',
          headerName: 'Date & Time',
          disableColumnMenu: true,
          flex: 15,
          valueGetter: (params: GridValueGetterParams) =>
            moment(params.row.fld_timestamp, 'YYYY-MM-DD HH:mm').format('HH:mm, DD.MM.YYYY'),
        },
        {
          field: 'apartment',
          headerName: 'Apt.',
          disableColumnMenu: true,
          sortable: false,
          flex: 10,
          valueGetter: (params: GridValueGetterParams) =>
            params.row.resident?.apartment?.fld_apartment_order || '',
        },
        typeInput.value === ConversationTypes.message
          ? {
              field: 'last_message',
              headerName: 'Message',
              disableColumnMenu: true,
              sortable: false,
              flex: 20,
              renderCell: (params: GridValueGetterParams) => (
                <TableCellWithMultilineText
                  text={
                    params.row.last_message?.fld_text ||
                    (params.row.last_message?.files?.length
                      ? 'File attachment'
                      : 'No messages here')
                  }
                />
              ),
            }
          : undefined,
        typeInput.value === ConversationTypes.message
          ? {
              field: 'conversation.fld_status',
              headerName: 'Status',
              sortable: true,
              disableColumnMenu: true,
              flex: 10,
              renderCell: (params: GridValueGetterParams) =>
                params.row.fld_status === ConversationStatuses.new &&
                params.row.last_message &&
                params.row.last_message.fld_author_role === UserGroups.resident &&
                !params.row.last_message.fld_read_at ? (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.primary.main,
                      (t) => `${t.palette.primary.main}10`,
                    )}
                  >
                    NEW
                  </Box>
                ) : (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.grey[800],
                      (t) => t.palette.grey[200],
                    )}
                  >
                    READ
                  </Box>
                ),
            }
          : undefined,
        typeInput.value === ConversationTypes.request
          ? {
              field: 'conversation.fld_status',
              headerName: 'Status',
              sortable: true,
              disableColumnMenu: true,
              flex: 10,
              renderCell: (params: GridValueGetterParams) =>
                params.row.fld_status === ConversationStatuses.new ? (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.warning.main,
                      (t) => `${t.palette.warning.main}10`,
                    )}
                  >
                    PENDING
                  </Box>
                ) : params.row.fld_status === ConversationStatuses.rejected ? (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.error.main,
                      (t) => `${t.palette.error.main}10`,
                    )}
                  >
                    REJECTED
                  </Box>
                ) : (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.success.main,
                      (t) => `${t.palette.success.main}10`,
                    )}
                  >
                    APPROVED
                  </Box>
                ),
            }
          : undefined,
        typeInput.value === ConversationTypes.issue
          ? {
              field: 'conversation.fld_status',
              headerName: 'Status',
              sortable: true,
              disableColumnMenu: true,
              flex: 10,
              renderCell: (params: GridValueGetterParams) =>
                params.row.fld_status === ConversationStatuses.new ? (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.grey[800],
                      (t) => t.palette.grey[200],
                    )}
                  >
                    WAITING
                  </Box>
                ) : params.row.fld_status === ConversationStatuses.inProgress ? (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.warning.main,
                      (t) => `${t.palette.warning.main}10`,
                    )}
                  >
                    IN PROGRESS
                  </Box>
                ) : (
                  <Box
                    sx={conversationStatusLabel(
                      (t) => t.palette.success.main,
                      (t) => `${t.palette.success.main}10`,
                    )}
                  >
                    RESOLVED
                  </Box>
                ),
            }
          : undefined,
        {
          field: 'actions',
          headerName: '',
          sortable: false,
          disableColumnMenu: true,
          flex: 8,
          align: 'right',
          renderCell: (params: GridValueGetterParams) => (
            <>
              <SecureContainer sections={[Sections.conversations]} permission='modify'>
                <Tooltip title='Dialog'>
                  <IconButton
                    onClick={() => onOpenDialog(params.row)}
                    sx={{
                      p: 0.5,
                      color: (t) => t.palette.primary.main,
                      border: 1,
                      borderRadius: 1,
                      borderColor: (t) => t.palette.primary.main,
                    }}
                    aria-label='Dialog'
                  >
                    <SendIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </SecureContainer>
            </>
          ),
        },
      ].filter((x) => !!x) as unknown as GridColDef[],
    [typeInput.value],
  );

  return (
    <LoadPermissionsContainer section={Sections.conversations}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Service Desk
            </Typography>
          </Box>
          <Box>
            {typeInput.value === ConversationTypes.message && (
              <SecureContainer sections={[Sections.conversations]} permission='modify'>
                <Box>
                  <Button
                    variant='contained'
                    startIcon={<AddIcon />}
                    onClick={() => onAdd()}
                    sx={{
                      px: 2.5,
                      py: 1.5,
                    }}
                  >
                    New Message
                  </Button>
                </Box>
              </SecureContainer>
            )}
          </Box>
        </Box>
        <Box height={'100%'}>
          <Tabs
            value={typeInput.value}
            onChange={(e, value) => typeInput.onChange({ target: { value } })}
          >
            <Tab sx={{ width: 160 }} value={ConversationTypes.message} label={'Messages'} />
            <Tab sx={{ width: 160 }} value={ConversationTypes.request} label={'Requests'} />
            <Tab sx={{ width: 160 }} value={ConversationTypes.issue} label={'Issues'} />
          </Tabs>
          <DataGrid
            isRowSelectable={() => false}
            sx={dataGridStyles}
            rows={data.items}
            rowCount={data.count}
            columns={columns}
            page={page}
            pageSize={pageSize}
            sortModel={sortOptions ? [sortOptions] : []}
            sortingMode='server'
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={onPageChanged}
            onPageSizeChange={onPerPageChanged}
            onSortModelChange={onSortModelChanged}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getRowId={(row: IConversation) => row.fld_uid!}
          />
        </Box>
        <ManageResidentDialog
          readonly={true}
          item={activeResidentItem}
          open={openResidentDialog}
          onClose={onCloseResidentDialog}
          buildingOptions={residentBuildingOptions}
        />

        <ConversationDialog
          item={activeItem}
          open={openDialog}
          conversationTypes={conversationTypes}
          onClose={onCloseDialog}
        />

        <NewConversationDialog
          open={openNewConversationDialog}
          onClose={onCloseNewConversationDialog}
          conversationTypes={conversationTypes}
        />
      </>
    </LoadPermissionsContainer>
  );
}
