import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
}));

export default useStyles;
