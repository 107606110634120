import { Theme } from '@mui/material';

export default {
  '& .ql-toolbar': {
    backgroundColor: (t: Theme) => t.palette.grey[100],
    borderTopColor: (t: Theme) => t.palette.grey[100],
    borderRightColor: (t: Theme) => t.palette.grey[100],
    borderLeftColor: (t: Theme) => t.palette.grey[100],
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    '& button': {
      mx: 0.5,
      '& svg > *': {
        stroke: (t: Theme) => t.palette.grey[600],
      },
    },
  },
  '& .ql-container': {
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    fontSize: (t: Theme) => t.typography.body1.fontSize,
    lineHeight: (t: Theme) => t.typography.body1.lineHeight,
    letterSpacing: (t: Theme) => t.typography.body1.letterSpacing,
    fontWeight: (t: Theme) => t.typography.body1.fontWeight,
  },
};
