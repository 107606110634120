/* eslint-disable camelcase */

import 'react-quill/dist/quill.snow.css';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import ApartmentService from '@rs/services/ApartmentService';
import BuildingService from '@rs/services/BuildingService';
import { IParcel } from '@rs/providers/interfaces/entities/IParcel';
import { IResidentSelectOption } from '@rs/providers/interfaces/IResidentSelectOption';
import { ISelectOption } from '@rs/providers/interfaces/ISelectOption';
import ResidentService from '@rs/services/ResidentService';
import { useApi } from '@rs/helpers/hooks';

interface IProps {
  item?: IParcel;
  open: boolean;
  onClose: () => void;
  onCollect: (name: string, role: string, phone?: string) => void;
}

interface FormData {
  buildingId: string;
  apartmentId: string;
  residentId: string;
  fullName: string;
  phone: string;
}

export default function CollectParcelDialog(props: IProps) {
  const { onClose, onCollect, open, item } = props;
  const [collectByResident, setCollectByResident] = useState<string>('yes');

  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const getApartmentsRequest = useApi(ApartmentService.getApartments);
  const getResidentsRequest = useApi(ResidentService.getResidents);

  const [buildingOptions, setBuildingOptions] = useState<ISelectOption[]>([]);
  const [residentOptions, setResidentOptions] = useState<IResidentSelectOption[]>([]);
  const [apartmentOptions, setApartmentOptions] = useState<ISelectOption[]>([]);

  const formDefaultData = useMemo(
    () => ({
      buildingId: String(item?.fld_building_id) || '',
      apartmentId: String(item?.fld_apartment_id) || '',
      residentId: String(item?.fld_resident_id) || '',
      fullName: '',
      phone: '',
    }),
    [],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const watchBuildingId = form.watch('buildingId');
  const watchApartmentId = form.watch('apartmentId');
  const watchResidentId = form.watch('residentId');

  useEffect(() => {
    (async () => {
      const buildings = (await getBuildingsRequest())?.items || [];
      setBuildingOptions([
        ...buildings.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_building_name,
        })),
      ]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const apartments =
        (
          await getApartmentsRequest(undefined, 0, {
            fld_building_id: +watchBuildingId,
          })
        )?.items || [];
      setApartmentOptions([
        ...apartments.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_apartment_name,
          description: x.fld_building_id.toString(),
        })),
      ]);
    })();
  }, [watchBuildingId]);

  useEffect(() => {
    (async () => {
      const residents =
        (
          await getResidentsRequest(undefined, 0, {
            fld_apartment_id: +watchApartmentId,
          })
        )?.items || [];
      setResidentOptions([
        ...residents.map((x) => ({
          value: x.fld_uid.toString(),
          label: x.fld_firstname,
          lastName: x.fld_surname,
          description: x.fld_apartment_id?.toString(),
          primary: x.fld_primary_resident,
          image: x.files && x.files[0],
        })),
      ]);
    })();
  }, [watchApartmentId]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const formData = form.getValues();
    const isResidentCollected = collectByResident === 'yes';
    const selectedResident = isResidentCollected
      ? residentOptions.find((x) => x.value === watchResidentId)
      : null;
    const userName = selectedResident
      ? `${selectedResident?.label} ${selectedResident?.lastName}`.trim()
      : formData.fullName;
    const role = selectedResident
      ? selectedResident.primary
        ? 'Primary Resident'
        : 'Resident'
      : '';
    const userPhone = selectedResident ? '' : formData.phone;
    onCollect(userName, role, userPhone);
  };

  const getResidentName = (resident: IResidentSelectOption) => {
    return `${resident?.label} ${resident?.lastName}`.trim();
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='md'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            Parcel
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              type='submit'
              form='form'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Collect
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>Resident:</Typography>
                <Alert severity='info' sx={{ px: 2, py: 0, ml: 2 }}>
                  <Typography variant='subtitle2'>The parcel will be collected by</Typography>
                </Alert>
              </Box>

              <Box sx={{ mt: 5 }}>
                <RadioGroup
                  row
                  aria-labelledby='controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={collectByResident}
                  onChange={(e) => setCollectByResident(e.target.value)}
                >
                  <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                  <FormControlLabel value='no' control={<Radio />} label='No' />
                </RadioGroup>
              </Box>

              {collectByResident === 'yes' ? (
                <Box sx={{ display: 'flex', mt: 4 }}>
                  <Controller
                    name={'buildingId'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the building',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ width: '33%', mr: 5 }} variant='standard'>
                        <InputLabel id='building-checkbox-label'>Building</InputLabel>
                        <Select
                          labelId='building-checkbox-label'
                          id='building-checkbox'
                          value={value}
                          disabled
                          onChange={onChange}
                          renderValue={(selected) =>
                            buildingOptions.find((x) => x.value === selected)?.label
                          }
                        >
                          {buildingOptions.map((item) => (
                            <MenuItem
                              sx={{
                                px: 1,
                                py: 0,
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Radio size='small' checked={value === item.value} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={'apartmentId'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the apartment',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ width: '33%', mr: 5 }} variant='standard'>
                        <InputLabel id='apartment-checkbox-label'>Apartment</InputLabel>
                        <Select
                          labelId='apartment-checkbox-label'
                          id='apartment-checkbox'
                          value={value}
                          disabled
                          onChange={onChange}
                          renderValue={(selected) =>
                            apartmentOptions.find((x) => x.value === selected)?.label
                          }
                        >
                          {apartmentOptions.map((item) => (
                            <MenuItem
                              sx={{
                                px: 1,
                                py: 0,
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Radio size='small' checked={value === item.value} />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={'residentId'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the resident',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ width: '33%' }} variant='standard'>
                        <InputLabel id='resident-checkbox-label'>Resident</InputLabel>
                        <Select
                          labelId='resident-checkbox-label'
                          id='resident-checkbox'
                          value={value}
                          onChange={onChange}
                          renderValue={(selected) => {
                            const resident = residentOptions.find((x) => x.value === selected);
                            return resident ? getResidentName(resident) : '';
                          }}
                        >
                          {residentOptions.map((item) => (
                            <MenuItem
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                px: 1,
                                py: 0,
                                borderBottom: 1,
                                borderColor: (t: Theme) => t.palette.grey[100],
                              }}
                              key={item.value}
                              value={item.value}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                  sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    backgroundColor: '#f3f3f3',
                                    backgroundImage: `url(${item?.image?.preview})`,
                                    backgroundSize: 'cover',
                                    mr: 1,
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    py: 1,
                                  }}
                                >
                                  <Typography variant='subtitle2'>
                                    {getResidentName(item)}
                                  </Typography>
                                  <Typography variant='caption'>
                                    {item?.primary ? 'Primary Resident' : 'Resident'}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Radio size='small' checked={value === item.value} />
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
              ) : (
                <Box sx={{ mt: 4 }}>
                  <Typography variant='h6'>Info</Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Controller
                      name={'fullName'}
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: '100%', mt: 2, mr: 5 }}
                          label='Full Name'
                          variant='outlined'
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    <Controller
                      name={'phone'}
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: '100%', mt: 2 }}
                          label='Phone number'
                          variant='outlined'
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
