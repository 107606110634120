export enum Sections {
  amenities = 'amenities',
  amenityOpen = 'aopen',
  amenityClosed = 'aclosed',
  bookingTypes = 'booking_types_v2',
  bookingTypesOpen = 'booking_types_open_v1',
  bookingTypesClosed = 'bookings_types_closed_v2',
  buildings = 'buildings_v1',
  buildingAdditionalInfo = 'buildings_additional_info_v2',
  apartments = 'apartments_v1',
  apartmentAdditionalInfo = 'apartments_additional_info_v1',
  apartmentTypes = 'apartment_types_v1',
  apartmentGroups = 'apartment _groups_v1',
  residents = 'residents_v1',
  residentAdditionalInfo = 'resident_additonal_info_V1',
  vehicles = 'vehicles_v1',
  messagesRegister = 'messages_register',
  licenceToAlter = 'licence_to_alter',
  bookings = 'bookings_v1',
  keyWaiver = 'key_waiver',
  messages = 'message_v1',
  conversations = 'conversation_v1',
  conversationTypes = 'conversation_type_v1',
  conversationOptionFieldTypes = 'conversation_option_field_type_v1',
  conversationOptions = 'conversation_option_v1',
  conversationOptionFields = 'conversation_option_field_v1',
  informationCategories = 'information_categories_v1',
  informationArticles = 'information_articles_v1',
  informationArticleAdditionalInfo = 'information_article_additional_info',
  informationCategoriesRel = 'information_categories_rel_v1',
  events = 'events_v2',
  eventsAdditionalInfo = 'events_additional_info_v1',
  news = 'news_v1',
  newsAdditionalInfo = 'news_additional_info_v1',
  newsRel = 'news_rel_v2',
  eventsRel = 'events_rel_v1',
  parcels = 'parcels_V1',
  parcelsHistory = 'parcels_history_v1',
  staff = 'staff_v2',
}
