import { Box, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IProps } from './interfaces';

export default function Attachments({ files }: IProps) {
  if (!files?.length) return null;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography component='h4' variant='subtitle2' sx={{ mb: 1 }}>
        Attachments:
      </Typography>
      <Box sx={{ display: 'flex', flexFlow: 'wrap' }}>
        {files?.map((file) => (
          <Box
            key={file.file_id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: '#007DFF14',
              py: 0.5,
              pr: 1,
              pl: 0.5,
              mr: 1,
              mb: 1,
              borderRadius: 10,
              color: (t) => t.palette.primary.main,
            }}
          >
            <a
              href={file.preview}
              download
              target='_blank'
              rel='noreferrer'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <AttachFileIcon sx={{ fontSize: '1rem', mr: 1 }} />
              <Typography component='h4' variant='subtitle2'>
                {file.file_name}
              </Typography>
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
