import ApartmentProvider from '@rs/providers/ApartmentProvider';
import FilesProvider from '@rs/providers/FilesProvider';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { IApartmentAdditionalInfo } from '@rs/providers/interfaces/entities/IApartmentAdditionalInfo';
import { IApartmentAdditionalInfoFilter } from '@rs/providers/interfaces/filters/IApartmentAdditionalInfoFilter';
import { IApartmentFilter } from '@rs/providers/interfaces/filters/IApartmentFilter';
import { IApartmentGroup } from '@rs/providers/interfaces/entities/IApartmentGroup';
import { IApartmentType } from '@rs/providers/interfaces/entities/IApartmentType';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { IBaseFilter } from '@rs/providers/interfaces/filters/IBaseFilter';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { IResidentFilter } from '@rs/providers/interfaces/filters/IResidentFilter';
import InformationCategoryProvider from '@rs/providers/InformationCategoryProvider';
import { Sections } from '@rs/constants/Sections';
import TableDataProvider from '@rs/providers/TableDataProvider';

const filesProvider = new FilesProvider();

const apartmentProvider = new ApartmentProvider();
const apartmentDataProvider = new TableDataProvider<IApartment, IApartmentFilter>(
  Sections.apartments,
);

const apartmentAdditionalInfoDataProvider = new TableDataProvider<
  IApartmentAdditionalInfo,
  IApartmentAdditionalInfoFilter
>(Sections.apartmentAdditionalInfo);
const apartmentTypeDataProvider = new TableDataProvider<IApartmentType, IBaseFilter>(
  Sections.apartmentTypes,
);
const apartmentGroupDataProvider = new TableDataProvider<IApartmentGroup, IBaseFilter>(
  Sections.apartmentGroups,
);
const residentDataProvider = new TableDataProvider<IResident, IResidentFilter>(Sections.residents);

const informationCategoryDataProvider = new InformationCategoryProvider();

const ApartmentService = {
  getApartments: async (limit?: number, offset?: number, filters?: IApartmentFilter) => {
    const apartmentsResult = await apartmentProvider.getApartments(limit, offset, filters);
    apartmentsResult.items.forEach((item) => {
      item.files = item.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
    });

    return apartmentsResult;
  },
  getApartment: async (id: number) => {
    const apartment = await apartmentProvider.getApartment(id);

    apartment.files = apartment.files.map((file) => ({
      ...file,
      preview: filesProvider.generatePreviewLink(file),
    }));

    apartment.attachedFiles =
      apartment.additional_info && apartment.additional_info.length
        ? apartment.additional_info[0].files.map((file) => ({
            ...file,
            preview: filesProvider.generatePreviewLink(file),
          }))
        : [];

    const informationCategoriesData =
      await informationCategoryDataProvider.getInformationCategories(100, 0, {
        // eslint-disable-next-line camelcase
        fld_building_id: apartment?.fld_building_id,
        // eslint-disable-next-line camelcase
        fld_apartment_type_id: apartment?.fld_apartment_type_id,
        // eslint-disable-next-line camelcase
        fld_apartment_group_id: apartment?.fld_apartment_group_id,
      });

    return { apartment: apartment, categories: informationCategoriesData.items };
  },
  createApartment: async (value: Partial<IApartment>) => {
    const { updatedAttachedFiles, updatedFiles, ...data } = value;
    const createApartmentResult = await apartmentDataProvider.createTableDataRecord(data);
    const id = createApartmentResult.fld_uid;

    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all(
      filesToAdd.map((x) => filesProvider.createFile(Sections.apartments, id, x.file)),
    );

    const apartmentAddInfoResult = await apartmentAdditionalInfoDataProvider.createTableDataRecord({
      // eslint-disable-next-line camelcase
      fld_apartment_id: id,
    });
    const addInfoId = apartmentAddInfoResult.fld_uid;
    const attachedFilesToAdd = updatedAttachedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all([
      ...attachedFilesToAdd.map((x) =>
        filesProvider.createFile(Sections.apartmentAdditionalInfo, addInfoId, x.file),
      ),
    ]);
  },
  updateApartment: async (id: number, newValue: Partial<IApartment>) => {
    const { attachedFiles, files, updatedAttachedFiles, updatedFiles, ...data } = newValue;
    await apartmentDataProvider.updateTableDataRecord(id, data);

    if (files && updatedFiles) {
      const remainingFiles = updatedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const filesToDelete =
        files?.filter((x) => !remainingFiles.find((c) => c.file_id === x.file_id)) || [];
      const filesToAdd = updatedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];

      await Promise.all([
        ...filesToAdd.map((x) => filesProvider.createFile(Sections.apartments, id, x.file)),
        ...filesToDelete.map((x) => filesProvider.deleteFile(Sections.apartments, id, x.file_id)),
      ]);
    }

    if (attachedFiles && updatedAttachedFiles) {
      // eslint-disable-next-line no-debugger
      const [apartmentAddInfoResult] = await apartmentAdditionalInfoDataProvider.getAllTableData({
        // eslint-disable-next-line camelcase
        fld_apartment_id: [id],
      });
      let addInfoId = apartmentAddInfoResult?.fld_uid;

      if (!apartmentAddInfoResult) {
        const newAdditionalInfo = await apartmentAdditionalInfoDataProvider.createTableDataRecord({
          // eslint-disable-next-line camelcase
          fld_apartment_id: id,
        });
        addInfoId = newAdditionalInfo.fld_uid;
      }

      const remainingAttachedFiles = updatedAttachedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const attachedFilesToDelete =
        attachedFiles?.filter(
          (x) => !remainingAttachedFiles.find((c) => c.file_id === x.file_id),
        ) || [];
      const attachedFilesToAdd = updatedAttachedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];

      if (attachedFilesToAdd.length || attachedFilesToDelete.length) {
        await Promise.all([
          ...attachedFilesToAdd.map((x) =>
            filesProvider.createFile(Sections.apartmentAdditionalInfo, addInfoId, x.file),
          ),
          ...attachedFilesToDelete.map((x) =>
            filesProvider.deleteFile(Sections.apartmentAdditionalInfo, addInfoId, x.file_id),
          ),
        ]);
      }
    }
  },
  deleteApartment: async (id: number) => {
    await apartmentDataProvider.deleteTableDataRecord(id);
  },
  getResidentsApartment: async (id: number) => {
    const residentsResult = await residentDataProvider.getTableData(1, 0, {
      // eslint-disable-next-line camelcase
      fld_uid: id,
    });
    const [resident] = residentsResult.items;

    const { apartment, categories } = await ApartmentService.getApartment(
      resident?.fld_apartment_id,
    );
    return { apartment, categories };
  },
  getApartmentTypes: async () => {
    const apartmentTypes = await apartmentTypeDataProvider.getAllTableData();
    return apartmentTypes;
  },
  getApartmentGroups: async () => {
    const apartmentGroups = await apartmentGroupDataProvider.getAllTableData();
    return apartmentGroups;
  },
};

export default ApartmentService;
