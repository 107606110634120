import { Box, Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import ConversationOptionService from '@rs/services/ConversationOptionService';
import ConversationService from '@rs/services/ConversationService';
import { ConversationTypes } from '@rs/constants/ConversationTypes';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationOption } from '@rs/providers/interfaces/entities/IConversationOption';
import { IConversationOptionFieldType } from '@rs/providers/interfaces/entities/IConversationOptionFieldType';
import { IConversationType } from '@rs/providers/interfaces/entities/IConversationType';
import NewConversationDialog from './NewConversationDialog';
import NewRequestDialog from './NewRequestDialog';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import SelectConversationOptionDialog from './SelectConversationOptionDialog';
import SendIcon from '@mui/icons-material/Send';
import { useApi } from '@rs/helpers/hooks';

interface IProps {
  onConversationCreated: () => void;
  conversationTypes: IConversationType[];
}

export default function ActionControls({ onConversationCreated, conversationTypes }: IProps) {
  const [fieldTypes, setFieldTypes] = useState<IConversationOptionFieldType[]>([]);
  const [openNewConversationDialog, setOpenNewConversationDialog] = useState(false);
  const [openNewRequestDialog, setOpenNewRequestDialog] = useState(false);
  const [openSelectConversationOptionDialog, setOpenSelectConversationOptionDialog] =
    useState(false);
  const [selectedConversationType, setSelectedConversationType] = useState<IConversationType>();
  const [selectedConversationOption, setSelectedConversationOption] =
    useState<IConversationOption>();
  const getConversationOptionsFieldTypesRequest = useApi(
    ConversationOptionService.getConversationOptionFieldTypes,
  );
  const createConversationRequest = useApi(ConversationService.createConversation);

  useEffect(() => {
    (async () => {
      const fieldTypes = (await getConversationOptionsFieldTypesRequest()) || [];
      setFieldTypes(fieldTypes);
    })();
  }, []);

  const onCloseSelectConversationOptionDialog = async (item?: IConversationOption) => {
    if (!item) {
      setOpenSelectConversationOptionDialog(false);
    } else {
      setSelectedConversationOption(item);
      setOpenNewRequestDialog(true);
      setOpenSelectConversationOptionDialog(false);
    }
  };

  const onNewRequest = () => {
    const conversationType = conversationTypes.find(
      (x) => x.fld_name === ConversationTypes.request,
    );
    setSelectedConversationType(conversationType);
    setOpenSelectConversationOptionDialog(true);
  };

  const onReportIssue = () => {
    const conversationType = conversationTypes.find((x) => x.fld_name === ConversationTypes.issue);
    setSelectedConversationType(conversationType);
    setOpenSelectConversationOptionDialog(true);
  };

  const onContactConcierge = () => {
    setOpenNewConversationDialog(true);
  };

  const onCloseNewConversationDialog = async (item?: Partial<IConversation>) => {
    if (!item) {
      setOpenNewConversationDialog(false);
    } else {
      await createConversationRequest(item);
      setOpenNewConversationDialog(false);
      onConversationCreated();
    }
  };

  const onCloseNewRequestDialog = async (item?: Partial<IConversation>) => {
    if (!item) {
      setOpenNewRequestDialog(false);
      setSelectedConversationOption(undefined);
    } else {
      await createConversationRequest(item);
      setOpenNewRequestDialog(false);
      setSelectedConversationOption(undefined);
      onConversationCreated();
    }
  };

  const selectConversationOptionDialogTitle = useMemo(() => {
    switch (selectedConversationType?.fld_name) {
      case ConversationTypes.request: {
        return 'New Request';
      }
      case ConversationTypes.issue: {
        return 'Report issue';
      }
      default: {
        return 'Unknown';
      }
    }
  }, [selectedConversationType]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Button
          variant='contained'
          startIcon={<PresentToAllIcon />}
          onClick={onNewRequest}
          sx={{
            px: 1.5,
            py: 1,
            borderRadius: 50,
            color: '#fff',
          }}
        >
          New Request
        </Button>
        <Button
          variant='contained'
          startIcon={<ErrorOutlineIcon />}
          onClick={onReportIssue}
          sx={{
            px: 1.5,
            py: 1,
            borderRadius: 50,
            color: '#fff',
          }}
        >
          Report issue
        </Button>
        <Button
          variant='contained'
          startIcon={<SendIcon />}
          onClick={onContactConcierge}
          sx={{
            px: 1.5,
            py: 1,
            borderRadius: 50,
            color: '#fff',
          }}
        >
          Contact Concierge
        </Button>
      </Box>
      <NewConversationDialog
        open={openNewConversationDialog}
        onClose={onCloseNewConversationDialog}
        conversationTypes={conversationTypes}
      />
      {selectedConversationType && (
        <SelectConversationOptionDialog
          open={openSelectConversationOptionDialog}
          onClose={onCloseSelectConversationOptionDialog}
          title={selectConversationOptionDialogTitle}
          conversationTypeId={selectedConversationType.fld_uid}
        />
      )}
      {selectedConversationOption && (
        <NewRequestDialog
          open={openNewRequestDialog}
          onClose={onCloseNewRequestDialog}
          conversationOption={selectedConversationOption}
          conversationOptionFieldTypes={fieldTypes}
        />
      )}
    </>
  );
}
