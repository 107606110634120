import { Theme } from '@mui/material';

export default {
  marginX: 1,
  marginY: 0,
  width: '100%',
  '& .MuiOutlinedInput-root': {
    padding: 0,
    '& .MuiInputBase-input': {
      backgroundColor: (t: Theme) => t.palette.grey[100],
      color: (t: Theme) => t.palette.grey[600],
      paddingX: 2,
      paddingY: 1,
      borderRadius: 5,
      fontSize: (t: Theme) => t.typography.subtitle2.fontSize,
      lineHeight: (t: Theme) => t.typography.subtitle2.lineHeight,
      letterSpacing: (t: Theme) => t.typography.subtitle2.letterSpacing,
      fontWeight: (t: Theme) => t.typography.subtitle2.fontWeight,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: 0,
    },
  },
};
