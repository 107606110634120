import { Box, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import { Sections } from '@rs/constants/Sections';
import { setLoader } from '@rs/reducers/generalSlice';
import { useAppDispatch } from '@rs/helpers/hooks';

export default function Team() {
  const dispatch = useAppDispatch();

  const { id, type } = useParams();

  const loadData = useCallback(() => {
    (async () => {
      dispatch(setLoader(true));
      // get team members request here
      dispatch(setLoader(false));
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const items = [
    { name: 'Kristi Dickinson', role: 'Consierge' },
    { name: 'Edward Reynolds', role: 'Cleaning' },
    { name: 'Tracy Beier', role: 'Receptionist' },
  ];

  return (
    <LoadPermissionsContainer section={Sections.events}>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 1,
              color: (t) => t.palette.primary.main,
              cursor: 'pointer',
              fontSize: (t) => t.typography.subtitle2.fontSize,
              fontWeight: 600,
            }}
          >
            <Link
              to={`/my/${type}`}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: (t) => t.typography.h5.fontSize, mr: 0.5 }} />
              Back to {type === 'building' ? 'Building' : 'Apartment'}
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              Our team
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {items.map((item) => (
            <Box
              sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #E6E8F0', p: 2 }}
              key={item.name}
            >
              <Box>
                <img
                  src=''
                  alt=''
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#f3f3f3',
                  }}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <Typography component='p' variant='subtitle2'>
                  {item.name}
                </Typography>
                <Typography component='p' variant='caption'>
                  {item.role}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
