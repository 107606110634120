/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { ICreateNewsRequest } from './interfaces/requests/ICreateNewsRequest';
import { IGetNewsResponse } from './interfaces/responses/IGetNewsResponse';
import { INews } from './interfaces/entities/INews';
import { INewsFilter } from './interfaces/filters/INewsFilter';

export default class NewsProvider extends BaseProvider {
  getNews = async (limit?: number, offset?: number, filters?: INewsFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_uid !== undefined) {
      filtersArray.push(`news.fld_uid = ${filters.fld_uid}`);
    }
    if (filters?.fld_building_id !== undefined) {
      filtersArray.push(`news-relation.fld_building_id = ${filters.fld_building_id}`);
    }
    if (filters?.fld_apartment_type_id !== undefined) {
      filtersArray.push(`news-relation.fld_apartment_type_id = ${filters.fld_apartment_type_id}`);
    }
    if (filters?.fld_apartment_group_id !== undefined) {
      filtersArray.push(`news-relation.fld_apartment_group_id = ${filters.fld_apartment_group_id}`);
    }
    return this.get<IGetNewsResponse>(`${this.getBaseApiUrl()}/rs/news/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
  createNews = async (value: Partial<INews>) => {
    const { news_relations, fld_heading, fld_description, fld_important, fld_publish_date } = value;
    const [{ fld_building_id, fld_apartment_type_id, fld_apartment_group_id }] =
      news_relations || [];
    return this.post<
      ICreateNewsRequest,
      {
        news_id: number;
      }
    >(
      `${this.getBaseApiUrl()}/rs/news/`,
      {
        heading: fld_heading,
        description: fld_description,
        important: fld_important,
        publish_date: fld_publish_date,
        building_id: fld_building_id,
        apartment_type_id: fld_apartment_type_id,
        apartment_group_id: fld_apartment_group_id,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
  deleteNews = async (id: number) => {
    return this.delete(`${this.getBaseApiUrl()}/rs/news/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
