/* eslint-disable camelcase */

import { Box, Button, Dialog, DialogContent, TextField, Theme, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import { BookingStatuses } from '@rs/constants/BookingStatuses';
import { IBooking } from '@rs/providers/interfaces/entities/IBooking';

interface IProps {
  item?: IBooking;
  status: BookingStatuses;
  open: boolean;
  onClose: (data?: { status: BookingStatuses; notes: string }) => void;
}

interface FormData {
  notes: string;
}

interface StatusData {
  button: {
    label: string;
    color: 'success' | 'error';
  };
  field: {
    label: string;
    placeholder: string;
    required: boolean;
  };
}

export default function ManageBookingDialog(props: IProps) {
  const { onClose, open, status, item } = props;

  const formDefaultData = {
    notes: '',
  };

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  useEffect(() => {
    form.reset(formDefaultData);
  }, [item, status]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const { notes } = form.getValues();

    onClose({ status, notes });
  };

  const statusData: StatusData | undefined = useMemo(() => {
    switch (status) {
      case BookingStatuses.confirmed: {
        return {
          button: {
            label: 'Confirm',
            color: 'success',
          },
          field: {
            label: 'Notes',
            placeholder: 'You can add some notes here.',
            required: false,
          },
        };
      }
      case BookingStatuses.rejected: {
        return {
          button: {
            label: 'Reject',
            color: 'error',
          },
          field: {
            label: 'Reason',
            placeholder: 'Describe the reason of rejecting.',
            required: true,
          },
        };
      }
      case BookingStatuses.cancelled: {
        return {
          button: {
            label: 'Cancel',
            color: 'error',
          },
          field: {
            label: 'Reason',
            placeholder: 'Describe the reason of cancelling.',
            required: true,
          },
        };
      }
    }
  }, [status]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='md'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {statusData?.button.label} request
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              form='form'
              color={statusData?.button.color || 'primary'}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              {statusData?.button.label}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box sx={{ mt: 5 }}>
              <Typography variant='h6'>{statusData?.field.label}</Typography>
              <Box>
                <Controller
                  name={'notes'}
                  control={form.control}
                  rules={{
                    required: {
                      value: statusData?.field.required || false,
                      message: `Please enter the ${statusData?.field.label.toLowerCase()}`,
                    },
                    maxLength: {
                      value: 256,
                      message: 'Max length should be less or equal 256 characters',
                    },
                  }}
                  render={({ field: { onChange, value }, formState }) => (
                    <TextField
                      sx={{ mt: 2 }}
                      multiline
                      minRows={4}
                      fullWidth
                      variant='outlined'
                      placeholder={statusData?.field.placeholder}
                      error={!!formState.errors.notes}
                      helperText={formState.errors.notes?.message}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
