import { Box, Button, Typography } from '@mui/material';
import { useApi, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import BuildingService from '@rs/services/BuildingService';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import ListItem from './components/ListItem';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageBuildingDialog from './components/ManageBuildingDialog';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';

export default function Buildings() {
  const [pageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [data, setData] = useState<{ count: number; items: IBuilding[] }>({
    count: 0,
    items: [],
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem] = useState<IBuilding | undefined>(undefined);

  const getBuildingsRequest = useApi(BuildingService.getBuildings);
  const createBuildingRequest = useApi(BuildingService.createBuilding);

  const loadData = useCallback(async () => {
    const result = await getBuildingsRequest(pageSize, page * pageSize);
    setData({ count: result?.total_count || 0, items: result?.items || [] });
  }, [page, pageSize]);

  const onShowMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const onAdd = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<IBuilding>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      await createBuildingRequest(item);
      setOpenDialog(false);
      await loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [page]);

  return (
    <LoadPermissionsContainer section={Sections.buildings}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Buildings
            </Typography>
          </Box>
          <SecureContainer sections={[Sections.buildings]} permission='modify'>
            <Box>
              <Button
                variant='contained'
                startIcon={<AddIcon />}
                onClick={() => onAdd()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                }}
              >
                Add
              </Button>
            </Box>
          </SecureContainer>
        </Box>
        <Box height={'100%'}>
          {data.items &&
            data.items.length > 0 &&
            data.items.map((building, index) => (
              <ListItem key={index} data={building} baseUrl='buildings' />
            ))}
          {data.items && data.items.length > 0 && data.count > data.items.length && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
              <Button onClick={() => onShowMore()} variant='outlined' color='primary' size='small'>
                Load more
              </Button>
            </Box>
          )}
        </Box>
        <ManageBuildingDialog
          item={activeItem}
          open={openDialog}
          onClose={onCloseDialog}
          onDelete={() => setOpenDialog(false)}
        />
      </>
    </LoadPermissionsContainer>
  );
}
