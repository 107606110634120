/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { IBookingTypeFilter } from '@rs/providers/interfaces/filters/IBookingTypeFilter';
import { IGetBookingTypeResponse } from './interfaces/responses/IGetBookingTypeResponse';

export default class BookingTypeProvider extends BaseProvider {
  getBookingTypes = async (limit?: number, offset?: number, filters?: IBookingTypeFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_uid !== undefined) {
      filtersArray.push(`fld_uid = ${filters.fld_uid}`);
    }
    if (filters?.fld_is_bookable !== undefined) {
      filtersArray.push(`fld_is_bookable = ${filters.fld_is_bookable}`);
    }
    return this.get<IGetBookingTypeResponse>(`${this.getBaseApiUrl()}/rs/booking-types/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
  deleteBookingType = async (id: number) => {
    return this.delete(`${this.getBaseApiUrl()}/rs/booking-types/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
