import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useApi, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ConversationOptionFieldTypes } from '@rs/constants/ConversationOptionFieldTypes';
import ConversationOptionService from '@rs/services/ConversationOptionService';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IConversationOption } from '@rs/providers/interfaces/entities/IConversationOption';
import { IConversationOptionFieldType } from '@rs/providers/interfaces/entities/IConversationOptionFieldType';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import ManageRequestTypeDialog from './components/ManageRequestTypeDialog';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import dataGridStyles from '@rs/styles/dataGrid';
import searchInputStyles from '@rs/styles/searchInput';
import { useConfirm } from 'material-ui-confirm';

import {createIntl, createIntlCache} from '@formatjs/intl';
import englishTranslation from '../../../translations/en-GB.json';

export default function RequestTypes() {
  const confirm = useConfirm();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortOptions, setSortOptions] = useState<GridSortItem>({
    field: 'fld_name',
    sort: 'asc',
  });
  const searchInput = useInput<string>('');

  const search = useDebounce<string>(searchInput.value, 1000);

  const [data, setData] = useState<{ count: number; items: IConversationOption[] }>({
    count: 0,
    items: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState<IConversationOption | undefined>(undefined);
  const [fieldTypes, setFieldTypes] = useState<IConversationOptionFieldType[]>([]);

  const getFieldTypeLabel = useCallback((type: string) => {
    switch (type) {
      case ConversationOptionFieldTypes.boolean:
        return 'Checkbox';
      case ConversationOptionFieldTypes.date:
        return 'Date Picker';
      case ConversationOptionFieldTypes.time:
        return 'Time Picker';
      case ConversationOptionFieldTypes.image:
        return 'Image Uploader';
      case ConversationOptionFieldTypes.string:
        return 'Input Field (text)';
      case ConversationOptionFieldTypes.number:
        return 'Input Field (number)';
      default:
        return type;
    }
  }, []);

  const getConversationOptionsFieldTypesRequest = useApi(
    ConversationOptionService.getConversationOptionFieldTypes,
  );
  const getConversationOptionsRequest = useApi(ConversationOptionService.getConversationOptions);
  const deleteConversationOptionRequest = useApi(
    ConversationOptionService.deleteConversationOption,
  );
  const createConversationOptionRequest = useApi(
    ConversationOptionService.createConversationOption,
  );
  const updateConversationOptionRequest = useApi(
    ConversationOptionService.updateConversationOption,
  );

  const loadData = useCallback(async () => {
    const filters = {
      // eslint-disable-next-line camelcase
      ...(search && { search_term: search }),
      // eslint-disable-next-line camelcase
      sort_column: sortOptions?.field || '',
      // eslint-disable-next-line camelcase
      sort_direction: sortOptions?.sort || '',
    };
    const response = await getConversationOptionsRequest(pageSize, page * pageSize, filters);
    setData({ count: response?.total_count || 0, items: response?.items || [] });
  }, [page, pageSize, sortOptions, search]);

  const onPageChanged = (_page: number) => {
    setPage(_page);
  };

  const onPerPageChanged = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onSortModelChanged = async (_model: GridSortModel) => {
    setSortOptions(_model[0]);
  };

  const onEdit = (item: IConversationOption) => {
    setActiveItem(item);
    setOpenDialog(true);
  };

  const onDelete = async (item: IConversationOption) => {
    try {
      await confirm({ description: 'This will permanently delete the request or issue.' });
    } catch {
      return;
    }
    await deleteConversationOptionRequest(item.fld_uid);
    await loadData();
  };

  const onAdd = () => {
    setActiveItem(undefined);
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<IConversationOption>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      if (activeItem) {
        await updateConversationOptionRequest(
          // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          item.fld_uid!,
          item,
          activeItem,
        );
      } else {
        await createConversationOptionRequest(item);
      }
      setOpenDialog(false);
      await loadData();
    }
  };

  useEffect(() => {
    (async () => {
      const fieldTypes = (await getConversationOptionsFieldTypesRequest()) || [];
      setFieldTypes(fieldTypes);
      await loadData();
    })();
  }, []);

  useUpdateEffect(() => {
    loadData();
  }, [sortOptions]);

  useUpdateEffect(() => {
    loadData();
  }, [search, page, pageSize]);

  // This is optional but highly recommended since it prevents memory leak
  const cache = createIntlCache();

  // Setting which translation file will be used
  const intl = createIntl({
      locale: 'en-GB',
      messages: englishTranslation,
    },
    cache
  );

  const columns: GridColDef[] = [
    {
      field: 'conversation-type.fld_name',
      headerName: 'Type',
      disableColumnMenu: true,
      flex: 20,
      // // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridValueGetterParams<any, IConversationOption>) => (
        <Box
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {params.row.conversation_type?.fld_name || ''}
        </Box>
      ),
    },
    {
      field: 'conversation-option.fld_name',
      headerName: intl.formatMessage({
        id: "Pages.Manager.RequestTypes.requestName",
        defaultMessage: 'Request Name'
      }),
      disableColumnMenu: true,
      flex: 20,
      // // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridValueGetterParams<any, IConversationOption>) => (
        <Box>{params.row.fld_name || ''}</Box>
      ),
    },
    {
      field: 'fields',
      headerName: 'Fields',
      disableColumnMenu: true,
      sortable: false,
      flex: 45,
      // // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridValueGetterParams<any, IConversationOption>) => (
        <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {params.row.conversation_option_fields
            ?.map((x) =>
              getFieldTypeLabel(
                fieldTypes.find((ft) => ft.fld_uid === x.fld_conversation_option_field_type_id)
                  ?.fld_name || '',
              ),
            )
            .join(', ') || ''}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      flex: 15,
      align: 'right',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <SecureContainer sections={[Sections.conversationOptions]} permission='modify'>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => onEdit(params.row)}
                sx={{ p: 0, color: (t) => t.palette.grey[600] }}
                aria-label='Edit'
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
          <SecureContainer sections={[Sections.conversationOptions]} permission='delete'>
            <Tooltip title='Delete'>
              <IconButton
                onClick={() => onDelete(params.row)}
                sx={{ ml: 5, p: 0, color: (t) => t.palette.error.main }}
                aria-label='Delete'
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </SecureContainer>
        </>
      ),
    },
  ];

  return (
    <LoadPermissionsContainer section={Sections.conversationOptions}>
      <>
        <Box
          sx={{
            py: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='h1' variant='h4' fontWeight={600}>
              Requests & Issues Types
            </Typography>
            <TextField
              sx={searchInputStyles}
              onChange={searchInput.onChange}
              value={searchInput.value}
              placeholder='Search by name'
              variant='outlined'
              margin='normal'
            />
          </Box>
          <SecureContainer sections={[Sections.conversationOptions]} permission='modify'>
            <Box>
              <Button
                variant='contained'
                startIcon={<AddIcon />}
                onClick={() => onAdd()}
                sx={{
                  px: 2.5,
                  py: 1.5,
                }}
              >
                Add
              </Button>
            </Box>
          </SecureContainer>
        </Box>
        <Box height={'100%'}>
          <DataGrid
            isRowSelectable={() => false}
            sx={dataGridStyles}
            rows={data.items}
            rowCount={data.count}
            columns={columns}
            page={page}
            pageSize={pageSize}
            sortModel={sortOptions ? [sortOptions] : []}
            sortingMode='server'
            sortingOrder={['asc', 'desc']}
            paginationMode='server'
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={onPageChanged}
            onPageSizeChange={onPerPageChanged}
            onSortModelChange={onSortModelChanged}
            // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getRowId={(row: IConversationOption) => row.fld_uid!}
          />
        </Box>
        {openDialog && (
          <ManageRequestTypeDialog
            item={activeItem}
            open={openDialog}
            onClose={onCloseDialog}
            getFieldTypeLabel={getFieldTypeLabel}
            conversationOptionFieldTypes={fieldTypes}
          />
        )}
      </>
    </LoadPermissionsContainer>
  );
}
