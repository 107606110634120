import ApartmentProvider from '@rs/providers/ApartmentProvider';
import BuildingProvider from '@rs/providers/BuildingProvider';
import FilesProvider from '@rs/providers/FilesProvider';
import { IAttachedFile } from '@rs/providers/interfaces/entities/IAttachedFile';
import { IBaseFilter } from '@rs/providers/interfaces/filters/IBaseFilter';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import { IBuildingAdditionalInfo } from '@rs/providers/interfaces/entities/IBuildingAdditionalInfo';
import { IBuildingAdditionalInfoFilter } from '@rs/providers/interfaces/filters/IBuildingAdditionalInfoFilter';
import { IBuildingFilter } from '@rs/providers/interfaces/filters/IBuildingFilter';
import { INewAttachedFile } from '@rs/providers/interfaces/entities/INewAttachedFile';
import { IResident } from '@rs/providers/interfaces/entities/IResident';
import { IResidentFilter } from '@rs/providers/interfaces/filters/IResidentFilter';
import InformationCategoryProvider from '@rs/providers/InformationCategoryProvider';
import { Sections } from '@rs/constants/Sections';
import TableDataProvider from '@rs/providers/TableDataProvider';

const filesProvider = new FilesProvider();
const residentDataProvider = new TableDataProvider<IResident, IResidentFilter>(Sections.residents);
const buildingDataProvider = new TableDataProvider<IBuilding, IBaseFilter>(Sections.buildings);
const buildingAdditionalInfoDataProvider = new TableDataProvider<
  IBuildingAdditionalInfo,
  IBuildingAdditionalInfoFilter
>(Sections.buildingAdditionalInfo);
const buildingProvider = new BuildingProvider();
const apartmentProvider = new ApartmentProvider();

const informationCategoryDataProvider = new InformationCategoryProvider();

const BuildingService = {
  getBuildings: async (limit?: number, offset?: number, filters?: IBuildingFilter) => {
    const buildingsResult = await buildingProvider.getBuildings(limit, offset, filters);
    buildingsResult.items.forEach((item) => {
      item.files = item.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      }));
    });

    return buildingsResult;
  },
  getBuilding: async (id: number) => {
    const building = await buildingProvider.getBuilding(id);

    building.files = building.files.map((file) => ({
      ...file,
      preview: filesProvider.generatePreviewLink(file),
    }));

    building.attachedFiles =
      building.additional_info && building.additional_info.length
        ? building.additional_info[0].files.map((file) => ({
            ...file,
            preview: filesProvider.generatePreviewLink(file),
          }))
        : [];

    const informationCategoriesData =
      await informationCategoryDataProvider.getInformationCategories(100, 0, {
        // eslint-disable-next-line camelcase
        fld_building_id: building?.fld_uid,
        // eslint-disable-next-line camelcase
        fld_apartment_type_id: 0,
        // eslint-disable-next-line camelcase
        fld_apartment_group_id: 0,
      });

    return { building: building, categories: informationCategoriesData.items };
  },
  getBuildingsCount: async (filters?: IBaseFilter) => {
    const buildingsCountResult = await buildingDataProvider.getRecordCount(filters);
    return buildingsCountResult.record_count;
  },
  getResidentsBuilding: async (id: number) => {
    const residentsResult = await residentDataProvider.getTableData(1, 0, {
      // eslint-disable-next-line camelcase
      fld_uid: id,
    });
    const [resident] = residentsResult.items;

    const apartment = await apartmentProvider.getApartment(resident?.fld_apartment_id);

    const { building, categories } = await BuildingService.getBuilding(apartment?.fld_building_id);

    return { building, categories };
  },
  createBuilding: async (value: Partial<IBuilding>) => {
    const { updatedAttachedFiles, updatedFiles, ...data } = value;
    const createBuildingResult = await buildingDataProvider.createTableDataRecord(data);
    const id = createBuildingResult.fld_uid;

    const filesToAdd = updatedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all(
      filesToAdd.map((x) => filesProvider.createFile(Sections.buildings, id, x.file)),
    );

    const buildingAddInfoResult = await buildingAdditionalInfoDataProvider.createTableDataRecord({
      // eslint-disable-next-line camelcase
      fld_building_id: id,
    });
    const addInfoId = buildingAddInfoResult.fld_uid;
    const attachedFilesToAdd = updatedAttachedFiles?.filter(
      (x) => (x as INewAttachedFile).file,
    ) as INewAttachedFile[];
    await Promise.all([
      ...attachedFilesToAdd.map((x) =>
        filesProvider.createFile(Sections.buildingAdditionalInfo, addInfoId, x.file),
      ),
    ]);
  },
  updateBuilding: async (id: number, newValue: Partial<IBuilding>) => {
    const { attachedFiles, files, updatedAttachedFiles, updatedFiles, ...data } = newValue;
    await buildingDataProvider.updateTableDataRecord(id, data);

    if (files && updatedFiles) {
      const remainingFiles = updatedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const filesToDelete =
        files?.filter((x) => !remainingFiles.find((c) => c.file_id === x.file_id)) || [];
      const filesToAdd = updatedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];
      await Promise.all([
        ...filesToAdd.map((x) => filesProvider.createFile(Sections.buildings, id, x.file)),
        ...filesToDelete.map((x) => filesProvider.deleteFile(Sections.buildings, id, x.file_id)),
      ]);
    }

    if (attachedFiles && updatedAttachedFiles) {
      // eslint-disable-next-line no-debugger
      const [buildingAddInfoResult] = await buildingAdditionalInfoDataProvider.getAllTableData({
        // eslint-disable-next-line camelcase
        fld_building_id: [id],
      });
      let addInfoId = buildingAddInfoResult?.fld_uid;

      if (!buildingAddInfoResult) {
        const newAdditionalInfo = await buildingAdditionalInfoDataProvider.createTableDataRecord({
          // eslint-disable-next-line camelcase
          fld_building_id: id,
        });
        addInfoId = newAdditionalInfo.fld_uid;
      }

      const remainingAttachedFiles = updatedAttachedFiles?.filter(
        (x) => !(x as INewAttachedFile).file,
      ) as IAttachedFile[];
      const attachedFilesToDelete =
        attachedFiles?.filter(
          (x) => !remainingAttachedFiles.find((c) => c.file_id === x.file_id),
        ) || [];
      const attachedFilesToAdd = updatedAttachedFiles?.filter(
        (x) => (x as INewAttachedFile).file,
      ) as INewAttachedFile[];

      if (attachedFilesToAdd.length || attachedFilesToDelete.length) {
        await Promise.all([
          ...attachedFilesToAdd.map((x) =>
            filesProvider.createFile(Sections.buildingAdditionalInfo, addInfoId, x.file),
          ),
          ...attachedFilesToDelete.map((x) =>
            filesProvider.deleteFile(Sections.buildingAdditionalInfo, addInfoId, x.file_id),
          ),
        ]);
      }
    }
  },
  deleteBuilding: async (id: number) => {
    await buildingDataProvider.deleteTableDataRecord(id);
  },
};

export default BuildingService;
