/* eslint-disable camelcase */

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormLabel,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ConversationOptionFieldTypes } from '@rs/constants/ConversationOptionFieldTypes';
import { ConversationStatuses } from '@rs/constants/ConversationStatuses';
import { IConversation } from '@rs/providers/interfaces/entities/IConversation';
import { IConversationOption } from '@rs/providers/interfaces/entities/IConversationOption';
import { IConversationOptionFieldType } from '@rs/providers/interfaces/entities/IConversationOptionFieldType';
import { IMessage } from '@rs/providers/interfaces/entities/IMessage';
import ImageUploader from '@rs/components/ImageUploader';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import { UserGroups } from '@rs/constants/UserGroups';
import moment from 'moment';
import { selectAuthUser } from '@rs/reducers/authSlice';
import { useAppSelector } from '@rs/helpers/hooks';

interface IField {
  id: number;
  label: string;
  isRequired: boolean;
  type: ConversationOptionFieldTypes;
  value: string;
}

interface IProps {
  open: boolean;
  onClose: (data?: Partial<IConversation>) => void;
  conversationOption: IConversationOption;
  conversationOptionFieldTypes: IConversationOptionFieldType[];
}

interface FormData {
  fields: IField[];
}

export default function NewRequestDialog(props: IProps) {
  const user = useAppSelector(selectAuthUser);
  const { onClose, open, conversationOption, conversationOptionFieldTypes } = props;
  const [images, setImages] = useState<Map<number, UploaderFile[]>>(new Map());

  const formDefaultData = {
    fields:
      conversationOption.conversation_option_fields?.map((x) => ({
        id: x.fld_uid,
        label: x.fld_label,
        isRequired: x.fld_required,
        type:
          conversationOptionFieldTypes.find(
            (ft) => ft.fld_uid === x.fld_conversation_option_field_type_id,
          )?.fld_name || ConversationOptionFieldTypes.string,
        value: '',
      })) || [],
  };

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const { fields, update } = useFieldArray({ name: 'fields', control: form.control });

  const onCancel = () => {
    onClose();
  };

  const formatFieldValue = (field: IField) => {
    if (!field.value) return null;
    switch (field.type) {
      case ConversationOptionFieldTypes.date: {
        return moment(field.value).format('DD/MM/YYYY');
      }
      case ConversationOptionFieldTypes.time: {
        return moment(field.value).format('HH:MM a');
      }
      case ConversationOptionFieldTypes.image: {
        return null;
      }
      default: {
        return field.value;
      }
    }
  };

  const getFiles = (field: IField) => {
    if (!field.value) return [];
    switch (field.type) {
      case ConversationOptionFieldTypes.image: {
        return images.get(field.id) || [];
      }
      default: {
        return [];
      }
    }
  };

  const onSave = () => {
    if (!user) {
      return;
    }

    const { fields } = form.getValues();

    const message = fields.reduce((prev, field) => {
      const formattedFieldValue = formatFieldValue(field);
      if (!formattedFieldValue) return prev;
      return prev
        ? `${prev}\n\n${field.label}\n${formattedFieldValue}`
        : `${field.label}\n${formattedFieldValue}`;
    }, '');

    const files = fields.reduce((prev, field) => {
      const fieldFiles = getFiles(field);
      if (!fieldFiles.length) return prev;
      return [...prev, ...fieldFiles];
    }, [] as UploaderFile[]);

    const newItem: Partial<IConversation> = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fld_resident_id: user.residentId,
      fld_status: ConversationStatuses.new,
      fld_title: conversationOption.fld_name,
      fld_conversation_type_id: conversationOption.fld_conversation_type_id,
      messages: [
        {
          fld_text: message,
          fld_author_role: UserGroups.resident,
          updatedFiles: files,
        } as IMessage,
      ],
    };

    onClose(newItem);
  };

  useEffect(() => {
    if (!open) {
      form.reset(formDefaultData);
      setImages(new Map());
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const images = new Map<number, UploaderFile[]>();
      const imageOptionFields =
        conversationOption.conversation_option_fields?.filter((x) => {
          const type =
            conversationOptionFieldTypes.find(
              (ft) => ft.fld_uid === x.fld_conversation_option_field_type_id,
            )?.fld_name || ConversationOptionFieldTypes.string;
          return type === ConversationOptionFieldTypes.image;
        }) || [];
      imageOptionFields.forEach((field) => {
        images.set(field.fld_uid, []);
      });
      setImages(images);
    }
  }, [conversationOption, open]);

  const updateImages = (id: number, items: UploaderFile[]) => {
    setImages(new Map(images.set(id, items)));
    const index = fields.findIndex((x) => x.id === id);
    if (index === -1) return;
    const field = fields[index];
    const imageFieldValue = items.length ? 'uploaded' : '';
    if (field && field.value !== imageFieldValue) {
      update(index, { ...field, value: imageFieldValue });
    }
  };

  const defaultImages = useMemo(() => [], []);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='sm'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {conversationOption.fld_name}
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              form='createRequestForm'
              color='primary'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Send
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 5 }}>
          <form
            id='createRequestForm'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            {fields.map((x, i) => (
              <Box key={i} sx={{ mt: 5 }}>
                <Box>
                  <Controller
                    name={`fields.${i}.value`}
                    control={form.control}
                    rules={{
                      required: {
                        value: x.isRequired,
                        message: 'Please fill the field',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <>
                        {x.type === ConversationOptionFieldTypes.string && (
                          <TextField
                            label={x.label}
                            variant='outlined'
                            error={
                              formState.errors.fields ? !!formState.errors.fields[i]?.value : false
                            }
                            helperText={
                              (formState.errors.fields &&
                                formState.errors.fields[i]?.value?.message) ||
                              undefined
                            }
                            onChange={onChange}
                            value={value}
                            fullWidth
                          />
                        )}
                        {x.type === ConversationOptionFieldTypes.number && (
                          <TextField
                            label={x.label}
                            variant='outlined'
                            type='number'
                            error={
                              formState.errors.fields ? !!formState.errors.fields[i]?.value : false
                            }
                            helperText={
                              (formState.errors.fields &&
                                formState.errors.fields[i]?.value?.message) ||
                              undefined
                            }
                            onChange={onChange}
                            value={value}
                            fullWidth
                          />
                        )}
                        {x.type === ConversationOptionFieldTypes.boolean && (
                          <FormControlLabel
                            label={<Typography variant='body2'>{x.label}</Typography>}
                            control={
                              <Checkbox
                                checked={value === 'yes'}
                                onChange={(e, checked) =>
                                  onChange({ target: { value: checked ? 'yes' : 'no' } })
                                }
                              />
                            }
                          />
                        )}
                        {x.type === ConversationOptionFieldTypes.date && (
                          <DatePicker
                            label={x.label}
                            components={{
                              OpenPickerIcon: ArrowDropDownIcon,
                            }}
                            inputFormat='DD/MM/YYYY'
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  formState.errors.fields
                                    ? !!formState.errors.fields[i]?.value
                                    : false
                                }
                                helperText={
                                  (formState.errors.fields &&
                                    formState.errors.fields[i]?.value?.message) ||
                                  undefined
                                }
                                variant='standard'
                              />
                            )}
                          />
                        )}
                        {x.type === ConversationOptionFieldTypes.time && (
                          <TimePicker
                            label={x.label}
                            components={{
                              OpenPickerIcon: ArrowDropDownIcon,
                            }}
                            ampm={true}
                            minutesStep={5}
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  formState.errors.fields
                                    ? !!formState.errors.fields[i]?.value
                                    : false
                                }
                                helperText={
                                  (formState.errors.fields &&
                                    formState.errors.fields[i]?.value?.message) ||
                                  undefined
                                }
                                variant='standard'
                              />
                            )}
                          />
                        )}
                        {x.type === ConversationOptionFieldTypes.image && (
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '2fr 1fr',
                              gridGap: '1.5rem',
                            }}
                          >
                            <Box>
                              <FormLabel sx={{ mb: 1.5, display: 'inline-block' }}>
                                {x.label}
                              </FormLabel>
                              <ImageUploader
                                maxAmount={1}
                                images={defaultImages}
                                onImagesUpdated={(items) => {
                                  updateImages(x.id, items);
                                }}
                              />
                              {formState.errors.fields && !!formState.errors.fields[i]?.value && (
                                <TextField
                                  className='test'
                                  sx={{ '& fieldset': { border: 'none' } }}
                                  variant='outlined'
                                  type='hidden'
                                  error={
                                    formState.errors.fields
                                      ? !!formState.errors.fields[i]?.value
                                      : false
                                  }
                                  helperText={
                                    (formState.errors.fields &&
                                      formState.errors.fields[i]?.value?.message) ||
                                    undefined
                                  }
                                  onChange={onChange}
                                  value={value}
                                  fullWidth
                                />
                              )}
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Box>
            ))}
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
