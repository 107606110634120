import { Box, TextField, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useApi, useAppSelector, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ApartmentService from '@rs/services/ApartmentService';
import Attachments from '@rs/components/Attachments';
import BuildingService from '@rs/services/BuildingService';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IApartment } from '@rs/providers/interfaces/entities/IApartment';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import { IInformationCategory } from '@rs/providers/interfaces/entities/IInformationCategory';
import ImagesView from '@rs/components/ImagesView';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Sections } from '@rs/constants/Sections';
import accordionStyles from '@rs/styles/accordion';
import parse from 'html-react-parser';
import searchInputStyles from '@rs/styles/searchInput';
import { selectAuthUser } from '@rs/reducers/authSlice';

export default function Building() {
  const user = useAppSelector(selectAuthUser);
  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, 1000);
  const { type } = useParams();

  const [buildingData, setBuildingData] = useState<IBuilding>();
  const [apartmentData, setApartmentData] = useState<IApartment>();
  const [categories, setCategories] = useState<IInformationCategory[]>();
  const [filterCategories, setFilterCategories] = useState<IInformationCategory[]>();
  const getResidentsBuildingRequest = useApi(BuildingService.getResidentsBuilding);
  const getResidentsApartmentRequest = useApi(ApartmentService.getResidentsApartment);

  const loadData = useCallback(
    async (type: string) => {
      if (!user?.residentId) return;
      if (type === 'building') {
        const info = await getResidentsBuildingRequest(user.residentId);
        setBuildingData(info?.building);
        setCategories(info?.categories);
        setFilterCategories(info?.categories);
      } else {
        const info = await getResidentsApartmentRequest(user.residentId);
        setApartmentData(info?.apartment);
        setCategories(info?.categories);
        setFilterCategories(info?.categories);
      }
    },
    [user],
  );

  useEffect(() => {
    if (user?.residentId) {
      loadData(type || 'building');
    }
  }, [type, user]);

  useUpdateEffect(() => {
    const filterCategories = categories?.filter((x) =>
      x.fld_title.toLowerCase().includes(searchInput.value.toLowerCase()),
    );
    setFilterCategories(filterCategories);
  }, [search]);

  return (
    <LoadPermissionsContainer
      section={type === 'building' ? Sections.buildings : Sections.apartments}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              {type === 'building' ? 'Building' : 'Apartment'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ minWidth: '342px', display: 'flex', flexDirection: 'column' }}>
            <ImagesView files={type === 'building' ? buildingData?.files : apartmentData?.files} />

            {!!categories?.length && (
              <Box sx={{ bgcolor: '#F7F9FA', borderRadius: 2, mt: 4 }}>
                <Box sx={{ p: 1, borderRadius: 0.8 }}>
                  <TextField
                    sx={{
                      ...searchInputStyles,
                      ...{
                        width: '100%',
                        m: 0,
                        borderRadius: 1,
                        '& .MuiInputBase-input': { borderRadius: 1, bgcolor: '#fff', py: 1.25 },
                      },
                    }}
                    onChange={searchInput.onChange}
                    value={searchInput.value}
                    placeholder='Search'
                    variant='outlined'
                    margin='normal'
                  />
                </Box>

                {filterCategories?.length ? (
                  filterCategories.map((category, index) => (
                    <Accordion
                      sx={accordionStyles}
                      key={category.fld_uid}
                      defaultExpanded={index === 0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${category.fld_uid}a-content`}
                        id={`panel${category.fld_uid}a-header`}
                      >
                        <Typography component='span' variant='subtitle2'>
                          {category.fld_title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {category.information_articles &&
                          category.information_articles.map((article) => (
                            <Link
                              to={`/articles/${article.fld_uid}`}
                              style={{ textDecoration: 'none' }}
                              key={article.fld_uid}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  bgcolor: '#fff',
                                  borderRadius: 0.8,
                                  py: 1,
                                  px: 2,
                                  mb: 1,
                                }}
                              >
                                <Box sx={{ display: 'flex', color: '#000000DE' }}>
                                  <Typography component='span' variant='subtitle2'>
                                    {article.fld_title}
                                  </Typography>
                                </Box>
                                <Box>
                                  <ChevronRightIcon
                                    sx={{
                                      display: 'flex',
                                      color: (t) => t.palette.primary.main,
                                      bgcolor: '#007DFF1A',
                                      borderRadius: 0.4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Link>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Box
                    sx={{
                      py: 1.5,
                      px: 3,
                    }}
                  >
                    <Typography component='span' variant='subtitle2'>
                      Categories not found
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box sx={{ pl: 3 }}>
            <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
              {type === 'building'
                ? buildingData?.fld_building_name
                : apartmentData?.fld_apartment_name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: '#0000008A' }}>
              <Typography
                component='span'
                variant='subtitle2'
                sx={{ display: 'flex', alignItems: 'center', pr: 2 }}
              >
                <LocationOnIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                {type === 'building' ? buildingData?.fld_address : apartmentData?.fld_address}
              </Typography>
              <Typography
                component='span'
                variant='subtitle2'
                sx={{
                  position: 'relative',
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&:before, &:after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '10px',
                    left: 0,
                    width: '3px',
                    height: '3px',
                    borderRadius: '50%',
                    bgcolor: '#2121218A',
                  },
                  '&:after': { left: 'unset', right: 0 },
                }}
              >
                {type === 'apartment' && <ApartmentIcon sx={{ fontSize: '1rem', mr: 0.5 }} />}
                {type === 'building' ? buildingData?.fld_floors : apartmentData?.fld_floor} Floor
                {type === 'building' && buildingData?.fld_floors !== 1 && 's'}
              </Typography>
              <Typography
                component='span'
                variant='subtitle2'
                sx={{ display: 'flex', alignItems: 'center', pl: 2 }}
              >
                <MapsHomeWorkIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                {type === 'building' ? (
                  <>
                    {buildingData?.apartment_count} Apartment
                    {buildingData?.apartment_count !== 1 && 's'}
                  </>
                ) : (
                  <>Apartment: {apartmentData?.fld_apartment_order}</>
                )}
              </Typography>
            </Box>
            <Link
              to={`/my/${type}/${
                type === 'building' ? buildingData?.fld_uid : apartmentData?.fld_uid
              }/team`}
              style={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: 'fit-content',
                  justifyContent: 'space-between',
                  minWidth: '350px',
                  bgcolor: '#F7F9FA',
                  borderRadius: 0.8,
                  py: 1.5,
                  px: 2,
                  mb: 1,
                }}
              >
                <Box sx={{ display: 'flex', color: '#000000DE' }}>
                  <AccessibilityNewIcon sx={{ mr: 1.5 }} />
                  <Typography component='span' variant='subtitle2'>
                    Our team
                  </Typography>
                </Box>
                <Box>
                  <ChevronRightIcon
                    sx={{
                      display: 'flex',
                      color: (t) => t.palette.primary.main,
                      bgcolor: '#007DFF1A',
                      borderRadius: 0.4,
                    }}
                  />
                </Box>
              </Box>
            </Link>
            <Box>
              {parse(
                type === 'building'
                  ? buildingData?.fld_description || ''
                  : apartmentData?.fld_description || '',
              )}
            </Box>
            <Attachments
              files={
                type === 'building' ? buildingData?.attachedFiles : apartmentData?.attachedFiles
              }
            />
          </Box>
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
