import TableDataProvider, { OrderingOptions } from '@rs/providers/TableDataProvider';

import { IBaseFilter } from '@rs/providers/interfaces/filters/IBaseFilter';
import { IParcel } from '@rs/providers/interfaces/entities/IParcel';
import { IParcelFilter } from '@rs/providers/interfaces/filters/IParcelFilter';
import { IParcelHistory } from '@rs/providers/interfaces/entities/IParcelHistory';
import { IParcelHistoryFilter } from '@rs/providers/interfaces/filters/IParcelHistoryFilter';
import ParcelProvider from '@rs/providers/ParcelProvider';
import { Sections } from '@rs/constants/Sections';

const parcelDataProvider = new TableDataProvider<IParcel, IBaseFilter>(Sections.parcels);
const parcelHistoryDataProvider = new TableDataProvider<IParcelHistory, IParcelHistoryFilter>(
  Sections.parcelsHistory,
);
const parcelProvider = new ParcelProvider();

const ParcelService = {
  getParcels: async (limit?: number, offset?: number, filters?: IParcelFilter) => {
    const conversationsResult = await parcelProvider.getParcels(limit, offset, filters);
    return conversationsResult;
  },
  createParcel: async (value: Partial<IParcel>) => {
    const createParcelResult = await parcelProvider.createParcel(value);
    return createParcelResult.parcel_id;
  },
  updateParcel: async (id: number, newValue: IParcel) => {
    await parcelDataProvider.updateTableDataRecord(id, newValue);
  },
  updateOrdering: async (data: OrderingOptions) => {
    await parcelDataProvider.updateTableDataOrdering(data);
  },
  getParcelHistory: async (limit?: number, offset?: number, filters?: IParcelHistoryFilter) => {
    let items: IParcelHistory[] = [];
    if (limit || offset) {
      const historyResult = await parcelHistoryDataProvider.getTableData(limit, offset, filters);
      items = historyResult.items;
    } else {
      items = await parcelHistoryDataProvider.getAllTableData(filters);
    }
    return items;
  },
  createParcelHistory: async (value: Partial<IParcelHistory>) => {
    await parcelHistoryDataProvider.createTableDataRecord(value);
  },
  updateParcelHistory: async (id: number, value: Partial<IParcelHistory>) => {
    await parcelHistoryDataProvider.updateTableDataRecord(id, value);
  },
};

export default ParcelService;
