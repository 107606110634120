import { Box, Button, TextField, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApi, useDebounce, useInput, useUpdateEffect } from '@rs/helpers/hooks';
import { useCallback, useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Attachments from '@rs/components/Attachments';
import BuildingService from '@rs/services/BuildingService';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IBuilding } from '@rs/providers/interfaces/entities/IBuilding';
import { IInformationCategory } from '@rs/providers/interfaces/entities/IInformationCategory';
import ImagesView from '@rs/components/ImagesView';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ManageBuildingDialog from './components/ManageBuildingDialog';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Sections } from '@rs/constants/Sections';
import accordionStyles from '@rs/styles/accordion';
import parse from 'html-react-parser';
import searchInputStyles from '@rs/styles/searchInput';

export default function Building() {
  const navigate = useNavigate();
  const searchInput = useInput<string>('');
  const search = useDebounce<string>(searchInput.value, 1000);
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);

  const [buildingData, setBuildingData] = useState<IBuilding>();
  const [categories, setCategories] = useState<IInformationCategory[]>();
  const [filterCategories, setFilterCategories] = useState<IInformationCategory[]>();

  const getBuildingRequest = useApi(BuildingService.getBuilding);
  const updateBuildingRequest = useApi(BuildingService.updateBuilding);
  const deleteBuildingRequest = useApi(BuildingService.deleteBuilding);

  const loadData = useCallback(async () => {
    if (id) {
      const info = (await getBuildingRequest(parseInt(id))) || {
        building: undefined,
        categories: [],
      };
      setBuildingData(info.building);
      setCategories(info.categories);
      setFilterCategories(info.categories);
    }
  }, [id]);

  const onEdit = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = async (item?: Partial<IBuilding>) => {
    if (!item) {
      setOpenDialog(false);
    } else {
      await updateBuildingRequest(buildingData?.fld_uid || 1, item);
      setOpenDialog(false);
      await loadData();
    }
  };

  const onBuildingDelete = async () => {
    await deleteBuildingRequest(buildingData?.fld_uid || 1);
    setOpenDialog(false);
    navigate('/buildings');
  };

  useEffect(() => {
    loadData();
  }, []);

  useUpdateEffect(() => {
    const filterCategories = categories?.filter((x) =>
      x.fld_title.toLowerCase().includes(searchInput.value.toLowerCase()),
    );
    setFilterCategories(filterCategories);
  }, [search]);

  return (
    <LoadPermissionsContainer section={Sections.buildings}>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 1,
              color: (t) => t.palette.primary.main,
              cursor: 'pointer',
              fontSize: (t) => t.typography.subtitle2.fontSize,
              fontWeight: 600,
            }}
          >
            <Link
              to='/buildings'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: (t) => t.typography.h5.fontSize, mr: 0.5 }} />
              Back to Buildings
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              {buildingData?.fld_building_name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ minWidth: '342px', display: 'flex', flexDirection: 'column' }}>
            <ImagesView files={buildingData?.files} />

            {!!categories?.length && (
              <Box sx={{ bgcolor: '#F7F9FA', borderRadius: 2, mt: 4 }}>
                <Box sx={{ p: 1, borderRadius: 0.8 }}>
                  <TextField
                    sx={{
                      ...searchInputStyles,
                      ...{
                        width: '100%',
                        m: 0,
                        borderRadius: 1,
                        '& .MuiInputBase-input': { borderRadius: 1, bgcolor: '#fff', py: 1.25 },
                      },
                    }}
                    onChange={searchInput.onChange}
                    value={searchInput.value}
                    placeholder='Search'
                    variant='outlined'
                    margin='normal'
                  />
                </Box>

                {filterCategories?.length ? (
                  filterCategories.map((category, index) => (
                    <Accordion
                      sx={accordionStyles}
                      key={category.fld_uid}
                      defaultExpanded={index === 0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${category.fld_uid}a-content`}
                        id={`panel${category.fld_uid}a-header`}
                      >
                        <Typography component='span' variant='subtitle2'>
                          {category.fld_title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {category.information_articles &&
                          category.information_articles.map((article) => (
                            <Link
                              to={`/articles/${article.fld_uid}`}
                              style={{ textDecoration: 'none' }}
                              key={article.fld_uid}
                            >
                              <Box
                                key={article.fld_uid}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  bgcolor: '#fff',
                                  borderRadius: 0.8,
                                  py: 1,
                                  px: 2,
                                  mb: 1,
                                }}
                              >
                                <Box sx={{ display: 'flex', color: '#000000DE' }}>
                                  <Typography component='span' variant='subtitle2'>
                                    {article.fld_title}
                                  </Typography>
                                </Box>
                                <Box>
                                  <ChevronRightIcon
                                    sx={{
                                      display: 'flex',
                                      color: (t) => t.palette.primary.main,
                                      bgcolor: '#007DFF1A',
                                      borderRadius: 0.4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Link>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Box
                    sx={{
                      py: 1.5,
                      px: 3,
                    }}
                  >
                    <Typography component='span' variant='subtitle2'>
                      Categories not found
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box sx={{ width: '100%', pl: 3 }}>
            <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
              {buildingData?.fld_building_name}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: '#0000008A' }}>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ display: 'flex', alignItems: 'center', pr: 2 }}
                >
                  <LocationOnIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  {buildingData?.fld_address}
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{
                    position: 'relative',
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&:before, &:after': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: '10px',
                      left: 0,
                      width: '3px',
                      height: '3px',
                      borderRadius: '50%',
                      bgcolor: '#2121218A',
                    },
                    '&:after': { left: 'unset', right: 0 },
                  }}
                >
                  <ApartmentIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  {buildingData?.fld_floors} Floor{buildingData?.fld_floors !== 1 && 's'}
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ display: 'flex', alignItems: 'center', pl: 2 }}
                >
                  <MapsHomeWorkIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  {buildingData?.apartment_count} Apartment
                  {buildingData?.apartment_count !== 1 && 's'}
                </Typography>
              </Box>
              <Box sx={{ position: 'relative', top: '-30px' }}>
                <Button
                  variant='text'
                  onClick={() => onEdit()}
                  sx={{
                    px: 2.5,
                    py: 1.5,
                    ml: 2,
                    color: '#0000008A',
                  }}
                >
                  <EditIcon sx={{ mr: 0.5 }} />
                  Edit
                </Button>
              </Box>
            </Box>

            <Box>{parse(buildingData?.fld_description || '')}</Box>
            <Attachments files={buildingData?.attachedFiles} />
          </Box>
        </Box>
        <ManageBuildingDialog
          item={buildingData}
          type='edit'
          open={openDialog}
          onDelete={onBuildingDelete}
          onClose={onCloseDialog}
        />
      </>
    </LoadPermissionsContainer>
  );
}
