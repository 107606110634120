/* eslint-disable camelcase */

import 'react-quill/dist/quill.snow.css';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { IBookingType } from '@rs/providers/interfaces/entities/IBookingType';
import { IBookingTypeClosed } from '@rs/providers/interfaces/entities/IBookingTypeClosed';
import { IBookingTypeOpen } from '@rs/providers/interfaces/entities/IBookingTypeOpen';
import ImageUploader from '@rs/components/ImageUploader';
import ReactQuill from 'react-quill';
import { UploaderFile } from '@rs/providers/types/UploaderFile';
import { UserGroups } from '@rs/constants/UserGroups';
import moment from 'moment';
import quillEditorStyles from '@rs/styles/quillEditor';
import { selectAuthMainUserGroup } from '@rs/reducers/authSlice';
import { useAlert } from 'react-alert';
import { useAppSelector } from '@rs/helpers/hooks';

interface IProps {
  item?: IBookingType;
  open: boolean;
  onClose: (data?: Partial<IBookingType>) => void;
}

interface IDay {
  selected: boolean;
  allDay: boolean;
  startTime: moment.Moment;
  endTime: moment.Moment;
}

interface IClosedDays {
  id?: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface Step1FormData {
  name: string;
  description: string;
  availableForBooking: boolean;
}

interface Step2FormData {
  simultaniousBookings: string;
}

const formatTimeData = (data: number | null) => {
  if (!data) return '00';
  if (data < 10) {
    return `0${data}`;
  }
  return data.toString();
};

export default function ManageBookingTypeDialog(props: IProps) {
  const { onClose, open, item } = props;
  const alert = useAlert();
  const role = useAppSelector(selectAuthMainUserGroup);

  const [files, setFiles] = useState<UploaderFile[]>([]);

  const stepOnEdit = useMemo(() => {
    if (role === UserGroups.concierge) {
      return 2;
    } else if (role === UserGroups.admin) {
      return 1;
    }
    return 0;
  }, [role]);

  const [step, setStep] = useState(1);

  const step1FormDefaultData = useMemo(
    () => ({
      name: item?.fld_booking_type_heading || '',
      description: item?.fld_booking_type_description || '',
      availableForBooking: item?.fld_is_bookable || false,
    }),
    [item],
  );

  const step1Form = useForm<Step1FormData>({
    defaultValues: step1FormDefaultData,
  });

  const step2FormDefaultData = useMemo(
    () => ({
      simultaniousBookings: item?.fld_max_residents?.toString() || '1',
    }),
    [item],
  );

  const step2Form = useForm<Step2FormData>({
    defaultValues: step2FormDefaultData,
  });

  const [days, setDays] = useState<IDay[]>(() => {
    const data = [];
    for (let i = 1; i <= 7; i++) {
      data.push({
        selected: false,
        allDay: true,
        startTime: moment('2000-01-01T09:00:00.000'),
        endTime: moment('2000-01-01T22:00:00.000'),
      });
    }
    return data;
  });

  const allDaysSelected = useMemo(() => days.filter((x) => x.selected).length === 7, [days]);

  useEffect(() => {
    if (!item) {
      const openDaysData: IDay[] = [];
      for (let i = 1; i <= 7; i++) {
        openDaysData.push({
          selected: false,
          allDay: true,
          startTime: moment('2000-01-01T00:00:00.000'),
          endTime: moment('2000-01-01T00:00:00.000'),
        });
      }
      setDays(openDaysData);
      setClosedDays([]);
      setSimultaniousBookingsAvailable(true);
      setStep(1);
      step1Form.reset(step1FormDefaultData);
      step2Form.reset(step2FormDefaultData);
    } else {
      const openDaysData: IDay[] = [];
      for (let i = 1; i <= 7; i++) {
        const openDay = item.booking_type_open.find((x) => x.fld_open_day === i);
        if (openDay) {
          openDaysData.push({
            selected: true,
            allDay: !openDay.fld_open_hour,
            startTime: moment(
              `2000-01-01T${formatTimeData(openDay.fld_open_hour)}:${formatTimeData(
                openDay.fld_open_min,
              )}:00.000`,
            ),
            endTime: moment(
              `2000-01-01T${formatTimeData(openDay.fld_close_hour)}:${formatTimeData(
                openDay.fld_close_min,
              )}:00.000`,
            ),
          });
        } else {
          openDaysData.push({
            selected: false,
            allDay: true,
            startTime: moment('2000-01-01T00:00:00.000'),
            endTime: moment('2000-01-01T00:00:00.000'),
          });
        }
      }

      const closedDaysData: IClosedDays[] = item.booking_type_closed.map((x) => ({
        id: x.fld_uid,
        startDate: moment(x.fld_closed_date_start, 'YYYY-MM-DD HH:mm:ss'),
        endDate: moment(x.fld_closed_date_end, 'YYYY-MM-DD HH:mm:ss'),
      }));
      setDays(openDaysData);
      setClosedDays(closedDaysData);
      setSimultaniousBookingsAvailable(!!item?.fld_max_residents);
      setStep(stepOnEdit);
      step1Form.reset(step1FormDefaultData);
      step2Form.reset(step2FormDefaultData);
    }
  }, [item]);

  const [closedDays, setClosedDays] = useState<IClosedDays[]>([]);

  const [simultaniousBookingsAvailable, setSimultaniousBookingsAvailable] = useState<boolean>(true);

  const onDaySelectionChanged = (index: number, value: boolean) => {
    const updatedDays = [...days];
    updatedDays[index].selected = value;
    setDays(updatedDays);
  };

  const onDayModeChanged = (index: number, value: boolean) => {
    const updatedDays = [...days];
    updatedDays[index].allDay = value;
    setDays(updatedDays);
  };
  const onSimultaniousBookingsAvailableChanged = (value: boolean) => {
    setSimultaniousBookingsAvailable(value);
  };

  const onDayStartTimeChanged = (index: number, value: moment.Moment | null) => {
    const updatedDays = [...days];
    updatedDays[index].startTime = value || moment('2000-01-01T00:00:00.000');
    setDays(updatedDays);
  };

  const onDayEndTimeChanged = (index: number, value: moment.Moment | null) => {
    const updatedDays = [...days];
    updatedDays[index].endTime = value || moment('2000-01-01T00:00:00.000');
    setDays(updatedDays);
  };

  const onClosedDaysStartDateChanged = (index: number, value: moment.Moment | null) => {
    const updatedDays = [...closedDays];
    updatedDays[index].startDate = value || moment();
    setClosedDays(updatedDays);
  };

  const onClosedDaysEndDateChanged = (index: number, value: moment.Moment | null) => {
    const updatedDays = [...closedDays];
    updatedDays[index].endDate = value || moment();
    setClosedDays(updatedDays);
  };

  const onAddClosedDays = () => {
    const updatedDays = [
      ...closedDays,
      {
        startDate: moment(),
        endDate: moment(),
      },
    ];
    setClosedDays(updatedDays);
  };

  const onAllDaysSelectionChanged = (value: boolean) => {
    const updatedDays = days.map((x) => ({
      ...x,
      selected: value,
    }));
    setDays(updatedDays);
  };

  const onClosedDaysDeleted = (index: number) => {
    const updatedDays = closedDays.filter((x, i) => i !== index);
    setClosedDays(updatedDays);
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    let updatedItem: Partial<IBookingType>;
    if (item) {
      const step1FormData = step1Form.getValues();
      const step2FormData = step2Form.getValues();

      updatedItem = { ...item, booking_type_open: [], booking_type_closed: [] };

      days.forEach((openDay, index) => {
        if (!openDay.selected) {
          return;
        }
        const existingDay = item.booking_type_open.find((x) => x.fld_open_day === index + 1);
        const newDay: Partial<IBookingTypeOpen> = existingDay
          ? { ...existingDay }
          : {
              fld_booking_type_id: item.fld_uid,
              fld_open_day: index + 1,
            };

        if (openDay.allDay) {
          newDay.fld_open_hour = null;
          newDay.fld_open_min = null;
          newDay.fld_close_hour = null;
          newDay.fld_close_min = null;
        } else {
          newDay.fld_open_hour = openDay.startTime.hours();
          newDay.fld_open_min = openDay.startTime.minutes();
          newDay.fld_close_hour = openDay.endTime.hours();
          newDay.fld_close_min = openDay.endTime.minutes();
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedItem.booking_type_open!.push(newDay as IBookingTypeOpen);
      });

      closedDays.forEach((cd) => {
        const existingDay = cd.id
          ? item.booking_type_closed.find((x) => x.fld_uid === cd.id)
          : undefined;
        const newDay: Partial<IBookingTypeClosed> = existingDay
          ? { ...existingDay }
          : {
              fld_booking_type_id: item.fld_uid,
            };

        newDay.fld_closed_date_start = cd.startDate.format('YYYY-MM-DD 00:00:00.000');
        newDay.fld_closed_date_end = cd.endDate.format('YYYY-MM-DD 00:00:00.000');
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedItem.booking_type_closed!.push(newDay as IBookingTypeClosed);
      });

      updatedItem.fld_max_residents = simultaniousBookingsAvailable
        ? Number(step2FormData.simultaniousBookings)
        : 0;

      updatedItem.fld_booking_type_heading = step1FormData.name;
      updatedItem.fld_booking_type_description = step1FormData.description;
      updatedItem.fld_is_bookable = step1FormData.availableForBooking;
    } else {
      const step1FormData = step1Form.getValues();
      const step2FormData = step2Form.getValues();

      updatedItem = {
        // unused fields
        fld_team_id: -1,
        fld_block_length: -1,
        fld_min_booking_length: -1,
        fld_max_booking_length: -1,

        fld_booking_type_heading: step1FormData.name,
        fld_booking_type_description: step1FormData.description,
        fld_max_residents: simultaniousBookingsAvailable
          ? Number(step2FormData.simultaniousBookings)
          : 0,
        booking_type_open: [],
        booking_type_closed: [],
      };

      days.forEach((openDay, index) => {
        if (!openDay.selected) {
          return;
        }
        const newDay: Partial<IBookingTypeOpen> = {
          fld_open_day: index + 1,
        };

        if (openDay.allDay) {
          newDay.fld_open_hour = null;
          newDay.fld_open_min = null;
          newDay.fld_close_hour = null;
          newDay.fld_close_min = null;
        } else {
          newDay.fld_open_hour = openDay.startTime.hours();
          newDay.fld_open_min = openDay.startTime.minutes();
          newDay.fld_close_hour = openDay.endTime.hours();
          newDay.fld_close_min = openDay.endTime.minutes();
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedItem.booking_type_open!.push(newDay as IBookingTypeOpen);
      });

      closedDays.forEach((cd) => {
        const newDay: Partial<IBookingTypeClosed> = {};

        newDay.fld_closed_date_start = cd.startDate.format('YYYY-MM-DD 00:00:00.000');
        newDay.fld_closed_date_end = cd.endDate.format('YYYY-MM-DD 00:00:00.000');
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedItem.booking_type_closed!.push(newDay as IBookingTypeClosed);
      });
    }

    updatedItem.updatedFiles = files;

    onClose(updatedItem);
  };

  const images = useMemo(() => item?.files || [], [item]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {item ? 'Edit' : 'Add'} booking
          </Typography>
          <Typography variant='subtitle2'>
            Step:{' '}
            <Box component='span' sx={{ color: (t) => t.palette.primary.main }}>
              {step}/2
            </Box>
          </Typography>
          <Box>
            {step === 2 && (
              <Button
                variant='text'
                startIcon={<ArrowBackIcon />}
                onClick={() => setStep(step - 1)}
                sx={{
                  px: 2.5,
                  py: 1.5,
                  backgroundColor: 'transparent',
                }}
              >
                Back
              </Button>
            )}
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>
            {step === 1 ? (
              <Button
                variant='contained'
                type='submit'
                form='step1form'
                sx={{
                  px: 2.5,
                  py: 1.5,
                  ml: 2,
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                variant='contained'
                type='submit'
                form='step2form'
                sx={{
                  px: 2.5,
                  py: 1.5,
                  ml: 2,
                }}
              >
                {item ? 'Save' : 'Publish'}
              </Button>
            )}
          </Box>
        </Box>
        {step === 1 && (
          <Box sx={{ mt: 5 }}>
            <form
              id='step1form'
              onSubmit={step1Form.handleSubmit(() => {
                if (!files.length) {
                  alert.error('Please upload at least 1 image');
                  return;
                }
                setStep(2);
              })}
              noValidate
            >
              <Box>
                <Typography variant='h6'>Picture:</Typography>
                <Box sx={{ mt: 2 }}>
                  <ImageUploader
                    maxAmount={4}
                    images={images}
                    onImagesUpdated={(items) => {
                      setFiles(items);
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '2rem',
                }}
              >
                <Box sx={{ mt: 5 }}>
                  <Typography variant='h6'>Title:</Typography>
                  <Controller
                    name={'name'}
                    control={step1Form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the booking name',
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        sx={{ mt: 2 }}
                        label='Booking name'
                        variant='outlined'
                        error={!!formState.errors.name}
                        helperText={formState.errors.name?.message}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ mt: 5 }}>
                  <Typography variant='h6'>Available for booking:</Typography>
                  <Controller
                    name={'availableForBooking'}
                    control={step1Form.control}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        row
                        name='availableForBooking'
                        value={value.toString()}
                        onChange={(event, value) => onChange(value === 'true')}
                      >
                        <FormControlLabel
                          value='true'
                          control={<Radio />}
                          label={<Typography variant='body2'>Yes</Typography>}
                        />
                        <FormControlLabel
                          value='false'
                          control={<Radio />}
                          label={<Typography variant='body2'>No</Typography>}
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 5 }}>
                <Typography variant='h6'>Description:</Typography>
                <Box>
                  <Controller
                    name={'description'}
                    control={step1Form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the description',
                      },
                      maxLength: {
                        value: 1024,
                        message: 'Max length should be less or equal 1024 characters',
                      },
                      validate: {
                        required: (value) => {
                          const textValue = value.replace(/<[^>]+>/g, '');
                          if (!textValue) return 'Please enter the description';
                        },
                        maxLength: (value) => {
                          const textValue = value.replace(/<[^>]+>/g, '');
                          if (textValue.length > 1024)
                            return 'Max length should be less or equal 1024 characters';
                        },
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <Box
                        sx={{
                          mt: 2,
                          ...quillEditorStyles,
                        }}
                      >
                        <ReactQuill
                          theme='snow'
                          onChange={onChange}
                          value={value}
                          modules={{
                            toolbar: [
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'link',
                                { list: 'ordered' },
                                { list: 'bullet' },
                                'blockquote',
                                'clean',
                              ],
                            ],
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          placeholder='Describe the booking item in few sentences. Max length 1024 characters.'
                        />
                        <TextField
                          error={!!formState.errors.description}
                          helperText={formState.errors.description?.message}
                          value={value}
                          type='hidden'
                          sx={{
                            '& fieldset': {
                              display: 'none',
                            },
                          }}
                        />
                      </Box>
                    )}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        )}
        {step === 2 && (
          <form
            id='step2form'
            onSubmit={step2Form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box sx={{ mt: 5 }}>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='h6'>Available booking days:</Typography>
                  <Alert severity='info' sx={{ px: 2, py: 0, ml: 2 }}>
                    <Typography variant='subtitle2'>
                      You can limit a booking hours. (minimum 10 min, maximum 24 hours)
                    </Typography>
                  </Alert>
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '7fr 5fr',
                    gridGap: '2rem',
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr',
                      px: 2,
                      mt: 2,
                      borderBottomWidth: 1,
                      borderBottomStyle: 'solid',
                      borderBottomColor: (t: Theme) => t.palette.grey[100],
                    }}
                  >
                    <FormControlLabel
                      label={<Typography variant='body2'>Select All</Typography>}
                      control={
                        <Checkbox
                          checked={allDaysSelected}
                          onChange={(event, checked) => onAllDaysSelectionChanged(checked)}
                        />
                      }
                    />
                  </Box>
                </Box>
                {days.map((day, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '7fr 5fr',
                      gridGap: '2rem',
                    }}
                  >
                    <Box
                      key={index}
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 2fr',
                        px: 2,
                        mt: 1,
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: (t: Theme) => t.palette.grey[100],
                      }}
                    >
                      <FormControlLabel
                        label={
                          <Typography variant='body2'>
                            {moment()
                              .day((index + 1) % 7)
                              .format('ddd')}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            checked={day.selected}
                            onChange={(event, checked) => onDaySelectionChanged(index, checked)}
                          />
                        }
                      />
                      {day.selected && (
                        <RadioGroup
                          row
                          name='allDay'
                          value={day.allDay.toString()}
                          onChange={(event, value) => onDayModeChanged(index, value === 'true')}
                        >
                          <FormControlLabel
                            value='true'
                            control={<Radio />}
                            label={<Typography variant='body2'>All Day</Typography>}
                          />
                          <FormControlLabel
                            sx={{ ml: 2 }}
                            value='false'
                            control={<Radio />}
                            label={<Typography variant='body2'>Limited Hours</Typography>}
                          />
                        </RadioGroup>
                      )}
                    </Box>
                    {day.selected && !day.allDay && (
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gridGap: '1rem',
                        }}
                      >
                        <TimePicker
                          label='Start time'
                          components={{
                            OpenPickerIcon: ArrowDropDownIcon,
                          }}
                          ampm={false}
                          minutesStep={5}
                          value={day.startTime}
                          onChange={(value) => onDayStartTimeChanged(index, value)}
                          renderInput={(params) => (
                            <TextField
                              sx={{ justifyContent: 'flex-end', mt: 0.5 }}
                              variant='standard'
                              {...params}
                            />
                          )}
                        />
                        <TimePicker
                          label='End time'
                          components={{
                            OpenPickerIcon: ArrowDropDownIcon,
                          }}
                          ampm={false}
                          minutesStep={5}
                          value={day.endTime}
                          onChange={(value) => onDayEndTimeChanged(index, value)}
                          renderInput={(params) => (
                            <TextField
                              sx={{ justifyContent: 'flex-end', mt: 0.5 }}
                              variant='standard'
                              {...params}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  mt: 5,
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '2rem',
                }}
              >
                <Box>
                  <Typography variant='h6'>Simultaneous booking:</Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Alert severity='info' sx={{ px: 2, py: 0, mt: 2 }}>
                      <Typography variant='subtitle2'>Amount of simultaneous users.</Typography>
                    </Alert>
                  </Box>
                  <Box sx={{ display: 'flex', mt: 2, px: 2 }}>
                    <RadioGroup
                      row
                      name='simultaniousBookings'
                      value={simultaniousBookingsAvailable.toString()}
                      onChange={(event, value) =>
                        onSimultaniousBookingsAvailableChanged(value === 'true')
                      }
                    >
                      <FormControlLabel
                        value='true'
                        control={<Radio />}
                        label={<Typography variant='body2'>Yes</Typography>}
                      />
                      <FormControlLabel
                        sx={{ ml: 2 }}
                        value='false'
                        control={<Radio />}
                        label={<Typography variant='body2'>No</Typography>}
                      />
                    </RadioGroup>
                    {simultaniousBookingsAvailable && (
                      <Controller
                        name={'simultaniousBookings'}
                        control={step2Form.control}
                        rules={{
                          required: {
                            value: simultaniousBookingsAvailable,
                            message: 'Please enter the amount',
                          },
                          pattern: simultaniousBookingsAvailable
                            ? {
                                value: /^[0-9]+$/,
                                message: 'Please enter a whole number',
                              }
                            : undefined,
                          min: simultaniousBookingsAvailable
                            ? {
                                value: 1,
                                message: 'Please enter a number bigger than or equal to 1',
                              }
                            : undefined,
                        }}
                        render={({ field: { onChange, value }, formState }) => (
                          <TextField
                            sx={{ ml: 2 }}
                            label='Enter amount'
                            variant='outlined'
                            type='number'
                            error={!!formState.errors.simultaniousBookings}
                            helperText={formState.errors.simultaniousBookings?.message}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    )}
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h6'>Closed days:</Typography>
                    <Box>
                      <Button
                        onClick={() => onAddClosedDays()}
                        variant='outlined'
                        startIcon={<AddIcon />}
                        sx={{
                          px: 2,
                          py: 1,
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex' }}>
                    <Alert severity='info' sx={{ px: 2, py: 0, mt: 1 }}>
                      <Typography variant='subtitle2'>
                        You can limit a booking on holiday.
                      </Typography>
                    </Alert>
                  </Box>
                  <Box>
                    {closedDays.map((days, index) => (
                      <Box
                        key={index}
                        sx={{
                          mt: 0.5,
                          display: 'grid',
                          gridTemplateColumns: '5fr 5fr 1fr',
                          gridGap: '1rem',
                        }}
                      >
                        <DatePicker
                          label='Start Date'
                          components={{
                            OpenPickerIcon: ArrowDropDownIcon,
                          }}
                          inputFormat='DD.MM.YYYY'
                          value={days.startDate}
                          onChange={(value) => onClosedDaysStartDateChanged(index, value)}
                          renderInput={(params) => (
                            <TextField
                              sx={{ justifyContent: 'flex-end', mt: 0.5 }}
                              variant='standard'
                              {...params}
                            />
                          )}
                        />
                        <DatePicker
                          label='End Date'
                          components={{
                            OpenPickerIcon: ArrowDropDownIcon,
                          }}
                          inputFormat='DD.MM.YYYY'
                          value={days.endDate}
                          onChange={(value) => onClosedDaysEndDateChanged(index, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ justifyContent: 'flex-end', mt: 0.5 }}
                              variant='standard'
                              error={days.endDate < days.startDate}
                            />
                          )}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => onClosedDaysDeleted(index)}
                            sx={{ p: 0, color: (t) => t.palette.error.main }}
                            aria-label='Logout'
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}
