import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISection } from '@rs/providers/interfaces/ISection';
import { ISectionPermissions } from '@rs/providers/interfaces/ISectionPermissions';
import { IUserGroup } from '@rs/providers/interfaces/IUserGroup';
import { IZone } from '@rs/providers/interfaces/IZone';
import { RootState } from '../store';
import User from '@rs/providers/models/User';
import { UserGroups } from '@rs/constants/UserGroups';

export interface AuthState {
  user?: User;
  token?: string;
  apiUrl?: string;
  deploymentId?: string;
  dataBaseInstance?: string;
  activeZone?: IZone;
  zones: IZone[];
  sections: ISection[];
  userGroups: IUserGroup[];
}

const initialState: AuthState = {
  user: undefined,
  token: undefined,
  zones: [],
  sections: [],
  userGroups: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = undefined;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    clearToken: (state) => {
      state.token = undefined;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setSections: (state, action: PayloadAction<ISection[]>) => {
      state.sections = action.payload;
    },
    setSectionPermissions: (
      state,
      action: PayloadAction<{ sectionId: number; permissions: ISectionPermissions }>,
    ) => {
      const sections = [...state.sections];
      const index = sections.findIndex((x) => x.id === action.payload.sectionId);
      if (index !== -1) {
        sections[index].permissions = action.payload.permissions;
        state.sections = sections;
      }
    },
    setUserGroups: (state, action: PayloadAction<IUserGroup[]>) => {
      state.userGroups = action.payload;
    },
    setActiveZone: (state, action: PayloadAction<IZone>) => {
      state.activeZone = action.payload
    },
    login: (
      state,
      action: PayloadAction<{
        token: string;
        apiUrl: string;
        deploymentId: string;
        dataBaseInstance: string;
        activeZone: IZone;
      }>,
    ) => {
      state.token = action.payload.token;
      state.apiUrl = action.payload.apiUrl;
      state.deploymentId = action.payload.deploymentId;
      state.dataBaseInstance = action.payload.dataBaseInstance;
      state.activeZone = action.payload.activeZone
    },
    logout: (state) => {
      state.token = undefined;
      state.user = undefined;
      state.zones = [];
      state.activeZone = undefined;
      state.apiUrl = undefined;
      state.deploymentId = undefined;
      state.dataBaseInstance = undefined;
      state.sections = [];
      state.userGroups = [];
    },
  },
});

export const {
  login,
  logout,
  setToken,
  clearToken,
  setUser,
  clearUser,
  setSections,
  setUserGroups,
  setSectionPermissions,
} = authSlice.actions;

export const selectAuthUser = (state: RootState) => state.auth.user;
export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectAuthSections = (state: RootState) => state.auth.sections;
export const selectAuthUserGroups = (state: RootState) => state.auth.userGroups;
export const selectAuthMainUserGroup = (state: RootState) => {
    if (state.auth.userGroups.find((x) => x.name.toLowerCase().includes(UserGroups.superUser.toLowerCase()))) {
    return UserGroups.superUser;
  }
    if (state.auth.userGroups.find((x) => x.name.toLowerCase().includes(UserGroups.admin.toLowerCase()))) {
    return UserGroups.admin;
  }
    if (state.auth.userGroups.find((x) => x.name.toLowerCase().includes(UserGroups.concierge.toLowerCase()))) {
    return UserGroups.concierge;
  }
    if (state.auth.userGroups.find((x) => x.name.toLowerCase().includes(UserGroups.resident.toLowerCase()))) {
    return UserGroups.resident;
  }
  return 'Unknown';
};

export default authSlice.reducer;
