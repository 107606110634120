import { Sections } from '@rs/constants/Sections';
import FilesProvider from '@rs/providers/FilesProvider';
import { IInformationArticle } from '@rs/providers/interfaces/entities/IInformationArticle';
import { IInformationArticleFilter } from '@rs/providers/interfaces/filters/IInformationArticleFilter';
import TableDataProvider from '@rs/providers/TableDataProvider';

const filesProvider = new FilesProvider();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const articleDataProvider = new TableDataProvider<IInformationArticle, IInformationArticleFilter>(
  Sections.informationArticles,
);

const InformationArticleService = {
  getArticles: async (limit?: number, offset?: number, filters?: IInformationArticleFilter) => {
    const articlesResult = await articleDataProvider.getTableData(limit, offset, filters);

    const articles = articlesResult.items.map((x) => ({
      ...x,
      files: x.files.map((file) => ({
        ...file,
        preview: filesProvider.generatePreviewLink(file),
      })),
    }));
    return articles;
  },
  getArticle: async (id: number) => {
    const [article] = await InformationArticleService.getArticles(1, 0, {
      // eslint-disable-next-line camelcase
      fld_uid: [id],
    });
    return article;
  },
};

export default InformationArticleService;
