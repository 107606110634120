import { Box } from '@mui/material';
import { Sections } from '@rs/constants/Sections';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';

export default function KeyWaivers() {
  return (
    <LoadPermissionsContainer section={Sections.keyWaiver}>
      <Box sx={{ display: 'flex' }}>Key waivers</Box>
    </LoadPermissionsContainer>
  );
}
