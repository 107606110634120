import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import AppContainer from './containers/AppContainer';
import { IntlProvider, useIntl, injectIntl } from 'react-intl';
import englishTranslation from './translations/en-GB.json';

// // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  
  /* IntlProvider is responsable for providing the translation file to all "<FormattedMessage>" components.
     The "locale" and "defaultLocale" attributes are used to format a Dates (using FormattedDate) */
  <IntlProvider messages={englishTranslation} locale="en-GB" defaultLocale="en">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppContainer />
      </PersistGate>
    </Provider>
  </IntlProvider>,

  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
