/* eslint-disable camelcase */

import { Alert, Box, Dialog, DialogContent, IconButton, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ConversationOptionService from '@rs/services/ConversationOptionService';
import { IConversationOption } from '@rs/providers/interfaces/entities/IConversationOption';
import { useApi } from '@rs/helpers/hooks';

interface IProps {
  title: string;
  open: boolean;
  conversationTypeId: number;
  onClose: (option?: IConversationOption) => void;
}

export default function SelectConversationOptionDialog(props: IProps) {
  const { onClose, open, title, conversationTypeId } = props;

  const [conversationOptions, setConversationOptions] = useState<IConversationOption[]>([]);

  const getConversationOptionsRequest = useApi(ConversationOptionService.getConversationOptions);

  const onCancel = () => {
    onClose();
  };

  const onSelected = (option: IConversationOption) => {
    onClose(option);
  };

  useEffect(() => {
    if (open) {
      (async () => {
        const options =
          (
            await getConversationOptionsRequest(undefined, undefined, {
              sort_column: 'conversationOption.fld_name',
              sort_direction: 'asc',
            })
          )?.items.filter((x) => x.fld_conversation_type_id === conversationTypeId) || [];
        setConversationOptions(options);
      })();
    } else {
      setConversationOptions([]);
    }
  }, [open, conversationTypeId]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='xs'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            {title}
          </Typography>
          <Box>
            <IconButton
              size='large'
              onClick={() => onCancel()}
              sx={{ ml: 2, p: 0, color: (t) => t.palette.grey[600] }}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            pt: 4.5,
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: (t: Theme) => t.palette.grey[100],
            textAlign: 'center',
          }}
        >
          {conversationOptions.map((x) => (
            <Alert
              icon={false}
              color='info'
              key={x.fld_uid}
              onClick={() => onSelected(x)}
              sx={{ display: 'inline-block', margin: 0.5, cursor: 'pointer' }}
            >
              {x.fld_name}
            </Alert>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
