import { Box, Typography } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IBookingType } from '@rs/providers/interfaces/entities/IBookingType';
import { Link } from 'react-router-dom';
import { useState } from 'react';

interface IProps {
  data: IBookingType;
  baseUrl?: string;
}

export default function ListItem({ data, baseUrl }: IProps) {
  const description = data.fld_booking_type_description.replace(/<[^>]+>/g, '');
  const [displayShowMore, setDisplayShowMore] = useState(description.length > 200);
  const image = data.files && data.files.length > 0 ? data.files[0].preview : null;

  return (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#F7F9FA',
          borderRadius: 2.5,
          py: 2,
          pl: 2,
          pr: 1.25,
        }}
      >
        <Box
          sx={{
            width: '170px',
            height: '140px',
            borderRadius: 2.5,
            backgroundColor: '#ebeeef',
            backgroundImage: image ? 'url("' + image + '")' : undefined,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></Box>
        <Box sx={{ width: '100%', pl: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Typography component='h2' variant='h6'>
              {data.fld_booking_type_heading}
            </Typography>
            <Link to={`/${baseUrl}/${data.fld_uid}`}>
              <Typography
                component='span'
                variant='subtitle2'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                View Details
                <ChevronRightIcon sx={{ fontSize: (t) => t.typography.body1.fontSize, ml: 0.5 }} />
              </Typography>
            </Link>
          </Box>

          <Box sx={{ backgroundColor: '#fff', p: 1, borderRadius: 2.5 }}>
            <Typography component='span' variant='subtitle2' fontWeight={400}>
              {displayShowMore ? description.slice(0, 200) + '...' : description}
              {displayShowMore && (
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ color: (t) => t.palette.primary.main, ml: 0.5, cursor: 'pointer' }}
                  onClick={() => setDisplayShowMore(false)}
                >
                  show more
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
