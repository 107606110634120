import debounce from "lodash.debounce";
import {
  TextField,
  Typography,
  Container,
  Grid,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// import { fetchProjects } from "../api/project_api";
import { Navbar } from "./components/navbar";

import ProjectList from "./components/projectList";
// import { getGlobalToken } from "../utils/cookie";
import { useMemo } from "react";
import {
  selectActiveUser,
  setLoader,
  setStyle,
} from "@rs/reducers/generalSlice";
import {
  useAppDispatch,
  useAppSelector,
  useUpdateEffect,
} from "@rs/helpers/hooks";
import AuthService from "@rs/services/AuthService";
import { IZone } from "@rs/providers/interfaces/IZone";
import { UserGroups } from "@rs/constants/UserGroups";
import User from "@rs/providers/models/User";
import {
  selectAuthMainUserGroup,
  login,
  setUser,
  setSections,
  setUserGroups,
} from "@rs/reducers/authSlice";
import ResidentService from "@rs/services/ResidentService";
import StaffService from "@rs/services/StaffService";

function SkeletonItem() {
  return (
    <Grid item lg={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Skeleton variant="rectangular" animation="wave" height={170} />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={32}
          width={"70%"}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={20}
          width={"40%"}
        />
      </Box>
    </Grid>
  );
}

export default function Projects() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeUser = useAppSelector(selectActiveUser);
  const role = useAppSelector(selectAuthMainUserGroup);

  const [projects, setProjects] = useState<IZone[] | null>(null);
  const [displayedProjects, setDisplayedProjects] = useState<IZone[] | null>(
    null
  );

  const filterProjects = debounce((query: string) => {
    if (!query) {
      setDisplayedProjects(projects);
    }

    if (projects) {
      setDisplayedProjects(
        projects.filter((project: IZone) =>
          project.name.toLowerCase().includes(query.trim().toLowerCase())
        )
      );
    }
  }, 500);

  useEffect(() => {
    if (activeUser) {
      fetchZones();
    }
  }, [activeUser]);

  const fetchZones = async () => {
    if (!activeUser) {
      return;
    }

    try {
      const { email, password } = activeUser;
      const res = await AuthService.getZones(email, password);

      if (res.length === 1) {
        onProjectClick(res[0]);
      } else {
        setProjects(res);
        setDisplayedProjects(res);
      }
    } catch (e) {
      // handle error
    }
  };

  const onProjectClick = async (project: IZone) => {
    if (!activeUser) {
      return;
    }

    dispatch(setLoader(true));

    const { email, password } = activeUser;
    const result = await AuthService.authenticateWithDeployment(
      email,
      password,
      project
    );
    dispatch(
      login({
        apiUrl: result.apiUrl,
        deploymentId: result.deploymentId,
        dataBaseInstance: result.dataBaseInstance,
        activeZone: project,
        token: result.token,
      })
    );
    const zoneStyles = await AuthService.getZoneStyle();
    const userInfo = await AuthService.getUserinfo();
    const userGroups = await AuthService.getUserGroups();
    const sections = await AuthService.getAccessibleSections();

    let residentId: number | undefined = undefined;
    let staffId: number | undefined = undefined;

    // if resident get resident id
    if (userGroups.find((x) => x.name === UserGroups.resident)) {
      const resident = await ResidentService.findResidentByZutecId(userInfo.id);
      if (resident) {
        residentId = resident.fld_uid;
      }
    }
    // else get staff id
    else {
      const staff = await StaffService.findStaffByZutecId(userInfo.id);
      if (staff) {
        staffId = staff.fld_uid;
      }
    }
    dispatch(
      setUser(
        new User({
          id: userInfo.id,
          email: userInfo.email,
          name: userInfo.name,
          residentId,
          staffId,
        })
      )
    );
    dispatch(setStyle(zoneStyles));
    dispatch(setSections(sections));
    dispatch(setUserGroups(userGroups));
    dispatch(setLoader(false));
  };

  useEffect(() => {
    if (role === UserGroups.admin || role === UserGroups.concierge) {
      navigate("/residents");
    } else if (role === UserGroups.resident) {
      navigate("/my-bookings");
    }
  }, [role]);

  return (
    <Box
      sx={{
        width: "100%",
        heigth: "100VH",
        maxHeight: "100VH",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Navbar />

      <Container
        maxWidth="xl"
        sx={{
          marginTop: "76px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {displayedProjects === null ? (
              <Skeleton variant="rectangular" height={40} width={"30%"} />
            ) : (
              <TextField
                size="small"
                label="Search Projects"
                onChange={(e) => {
                  filterProjects(e.target.value);
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              paddingTop: "16px",
            }}
          >
            {displayedProjects && displayedProjects.length > 0 ? (
              <ProjectList
                projects={displayedProjects}
                onProjectClick={onProjectClick}
              />
            ) : null}

            {/* if there are no projects to display */}
            {displayedProjects && displayedProjects.length === 0 ? (
              <Typography align="center">No Projects</Typography>
            ) : null}

            {displayedProjects === null ? (
              <Grid container spacing={2} sx={{ paddingTop: "16px" }}>
                {Array.from(Array(12).keys()).map((i) => (
                  <SkeletonItem key={i} />
                ))}
              </Grid>
            ) : null}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
