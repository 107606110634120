import { Box, Typography } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AlertIcon from '@rs/assets/alert.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { INews } from '@rs/providers/interfaces/entities/INews';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  data: INews;
  baseUrl?: string;
}

export default function ListItem({ data, baseUrl }: IProps) {
  const description = data.fld_description.replace(/<[^>]+>/g, '');
  const [displayShowMore, setDisplayShowMore] = useState(data.fld_description.length > 200);
  const image = data.files.length > 0 ? data.files[0].preview : null;

  return (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#F7F9FA',
          borderRadius: 2.5,
          py: 2,
          pl: 2,
          pr: 1.25,
        }}
      >
        <Box
          sx={{
            width: '170px',
            height: '140px',
            borderRadius: 2.5,
            backgroundColor: '#ebeeef',
            backgroundImage: 'url("' + image + '")',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
          }}
        >
          {data?.fld_important && (
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                right: 0,
                p: 0.5,
                bgcolor: '#fff',
                borderTopRightRadius: '9px',
                borderBottomLeftRadius: '9px',
              }}
            >
              <img src={AlertIcon} alt='important' />
            </Box>
          )}
        </Box>
        <Box sx={{ width: '100%', pl: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
            <Typography component='h2' variant='h6'>
              {data.fld_heading}
            </Typography>
            <Link to={`/${baseUrl}/${data.fld_uid}`}>
              <Typography
                component='span'
                variant='subtitle2'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <FormattedMessage id="Pages.Resident.News.Components.ListItem.viewDetails" defaultMessage="View Details" description="View details about the News" />
                <ChevronRightIcon sx={{ fontSize: (t) => t.typography.body1.fontSize, ml: 0.5 }} />
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                color: (t) => t.palette.primary.main,
              }}
            >
              <Typography
                component='span'
                variant='caption'
                sx={{ color: '#0000008A', display: 'flex' }}
              >
                <AccessTimeIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
                {moment(data.fld_publish_date).fromNow()}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: '#fff', p: 1, borderRadius: 2.5 }}>
            <Typography component='span' variant='subtitle2' fontWeight={400}>
              {displayShowMore ? description.slice(0, 200) + '...' : description}
              {displayShowMore && (
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{ color: (t) => t.palette.primary.main, ml: 0.5, cursor: 'pointer' }}
                  onClick={() => setDisplayShowMore(false)}
                >
                  show more
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
