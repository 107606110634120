import TableDataProvider, { OrderingOptions } from '@rs/providers/TableDataProvider';

import BookingProvider from '@rs/providers/BookingProvider';
import { BookingStatuses } from '@rs/constants/BookingStatuses';
import FilesProvider from '@rs/providers/FilesProvider';
import { IBooking } from '@rs/providers/interfaces/entities/IBooking';
import { IBookingFilter } from '@rs/providers/interfaces/filters/IBookingFilter';
import { Sections } from '@rs/constants/Sections';

const filesProvider = new FilesProvider();
const bookingProvider = new BookingProvider();
const bookingDataProvider = new TableDataProvider<IBooking, IBookingFilter>(Sections.bookings);

const BookingService = {
  getBookings: async (limit?: number, offset?: number, filters?: IBookingFilter) => {
    const bookingsResult = await bookingProvider.getBookings(limit, offset, filters);
    bookingsResult.items.forEach((x) => {
      if (x.booking_type) {
        x.booking_type.files = x.booking_type.files.map((file) => ({
          ...file,
          preview: filesProvider.generatePreviewLink(file),
        }));
      }
    });
    return bookingsResult;
  },
  createBooking: async (value: Partial<IBooking>) => {
    await bookingProvider.createBooking(value);
  },
  updateBookingStatus: async (id: number, status: BookingStatuses, notes?: string) => {
    await bookingProvider.updateBookingStatus(id, status, notes);
  },
  updateBooking: async (id: number, value: Partial<IBooking>) => {
    await bookingDataProvider.updateTableDataRecord(id, value);
  },
  updateOrdering: async (data: OrderingOptions) => {
    await bookingDataProvider.updateTableDataOrdering(data);
  },
};

export default BookingService;
