import { Box, Button, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import BookingDialog from './components/BookingDialog';
import BookingService from '@rs/services/BookingService';
import BookingTypeService from '@rs/services/BookingTypeService';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IBookingType } from '@rs/providers/interfaces/entities/IBookingType';
import ImagesView from '@rs/components/ImagesView';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import OpenHours from '@rs/components/OpenHours';
import { Sections } from '@rs/constants/Sections';
import SecureContainer from '@rs/containers/SecureContainer';
import parse from 'html-react-parser';
import { useApi } from '@rs/helpers/hooks';

export default function SingleBooking() {
  const [page] = useState(0);
  const pageSize = useMemo(() => 1, []);
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);

  const [data, setData] = useState<IBookingType | undefined>(undefined);

  const getBookingTypeRequest = useApi(BookingTypeService.getBookingType);
  const createBookingRequest = useApi(BookingService.createBooking);

  const loadData = useCallback(() => {
    (async () => {
      const response = await getBookingTypeRequest(parseInt(id || ''));
      setData(response);
    })();
  }, [page, pageSize]);

  const onCloseDialog = async (item?: Partial<IBookingType>) => {
    (async () => {
      if (!item) {
        setOpenDialog(false);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await createBookingRequest(item);
        setOpenDialog(false);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const bookingType = data?.fld_is_bookable ? 'resident-bookings' : 'amenities';

  return (
    <LoadPermissionsContainer section={Sections.bookingTypes}>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 1,
              color: (t) => t.palette.primary.main,
              cursor: 'pointer',
              fontSize: (t) => t.typography.subtitle2.fontSize,
              fontWeight: 600,
            }}
          >
            <Link
              to={`/${bookingType}`}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: (t) => t.typography.h5.fontSize, mr: 0.5 }} />
              Back to {bookingType === 'amenities' ? 'Amenities' : 'Bookings'}
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              {data?.fld_booking_type_heading}
            </Typography>
            {bookingType !== 'amenities' && (
              <LoadPermissionsContainer section={Sections.bookings}>
                <SecureContainer sections={[Sections.bookings]} permission='modify'>
                  <Button
                    onClick={() => setOpenDialog(true)}
                    variant='contained'
                    color='primary'
                    sx={{
                      px: 2.75,
                      py: 1.5,
                      textTransform: 'uppercase',
                    }}
                  >
                    Book now
                  </Button>
                </SecureContainer>
              </LoadPermissionsContainer>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ minWidth: '342px', display: 'flex', flexDirection: 'column' }}>
            <ImagesView files={data?.files} />
            <Box>
              <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
                Available:
              </Typography>
              <OpenHours open={data?.booking_type_open} />
            </Box>
          </Box>
          <Box sx={{ pl: 3 }}>
            <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
              {bookingType === 'amenities' ? 'About:' : 'When to return?'}
            </Typography>
            {parse(data?.fld_booking_type_description || '')}
          </Box>
        </Box>

        <BookingDialog isEdit={false} item={data} open={openDialog} onClose={onCloseDialog} />
      </>
    </LoadPermissionsContainer>
  );
}
