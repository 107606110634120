/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { IApartment } from './interfaces/entities/IApartment';
import { IApartmentFilter } from './interfaces/filters/IApartmentFilter';
import { IGetApartmentsResponse } from './interfaces/responses/IGetApartmentsResponse';

export default class ApartmentProvider extends BaseProvider {
  getApartments = async (limit?: number, offset?: number, filters?: IApartmentFilter) => {
    const filtersArray: string[] = [];
    if (filters?.fld_uid !== undefined) {
      filtersArray.push(`apartment.fld_uid = ${filters.fld_uid}`);
    }
    if (filters?.fld_building_id !== undefined) {
      filtersArray.push(`apartment.fld_building_id = ${filters.fld_building_id}`);
    }
    if (filters?.fld_floor !== undefined) {
      filtersArray.push(`apartment.fld_floor = ${filters.fld_floor}`);
    }
    if (filters?.fld_apartment_type_id !== undefined) {
      filtersArray.push(`apartment.fld_apartment_type_id = ${filters.fld_apartment_type_id}`);
    }
    if (filters?.fld_apartment_group_id !== undefined) {
      filtersArray.push(`apartment.fld_apartment_group_id = ${filters.fld_apartment_group_id}`);
    }
    return this.get<IGetApartmentsResponse>(`${this.getBaseApiUrl()}/rs/apartments/`, {
      params: {
        limit: limit || 100,
        offset: offset || 0,
        ...(filtersArray.length && {
          filter: filtersArray.join(' and '),
        }),
        ...(filters?.sort_column &&
          filters?.sort_direction && {
            sort: `${filters?.sort_column},${filters?.sort_direction}`,
          }),
        ...(filters?.search_term && {
          'search-term': filters?.search_term,
        }),
      },
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
  getApartment = async (id: number) => {
    return this.get<IApartment>(`${this.getBaseApiUrl()}/rs/apartments/${id}/`, {
      headers: {
        auth: this.getAuthToken(),
      },
    });
  };
}
