/* eslint-disable camelcase */

import BaseProvider from './BaseProvider';
import { ICreateMessageRequest } from './interfaces/requests/ICreateMessageRequest';
import { IMessage } from './interfaces/entities/IMessage';

export default class MessageProvider extends BaseProvider {
  createMessage = async (value: Partial<IMessage>) => {
    const { fld_text, fld_conversation_id } = value;
    return this.post<
      ICreateMessageRequest,
      {
        message_id: number;
      }
    >(
      `${this.getBaseApiUrl()}/rs/conversations/${fld_conversation_id}/messages/`,
      {
        text: fld_text,
      },
      {
        headers: {
          auth: this.getAuthToken(),
        },
      },
    );
  };
}
