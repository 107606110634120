import { Box, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Attachments from '@rs/components/Attachments';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EventService from '@rs/services/EventService';
import { IEvent } from '@rs/providers/interfaces/entities/IEvent';
import ImagesView from '@rs/components/ImagesView';
import LoadPermissionsContainer from '@rs/containers/LoadPermissionsContainer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Sections } from '@rs/constants/Sections';
import moment from 'moment';
import parse from 'html-react-parser';
import { useApi } from '@rs/helpers/hooks';

export default function SingleEvent() {
  const { id } = useParams();

  const [data, setData] = useState<IEvent>();
  const getEventRequest = useApi(EventService.getEvent);

  const loadData = useCallback(async () => {
    const item = await getEventRequest(parseInt(id || ''));
    setData(item);
  }, [id]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <LoadPermissionsContainer section={Sections.events}>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 1,
              color: (t) => t.palette.primary.main,
              cursor: 'pointer',
              fontSize: (t) => t.typography.subtitle2.fontSize,
              fontWeight: 600,
            }}
          >
            <Link
              to='/events'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: (t) => t.typography.h5.fontSize, mr: 0.5 }} />
              Back to Events
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}
          >
            <Typography component='h1' variant='h4' fontWeight={700} sx={{ minHeight: 36 }}>
              {data?.fld_heading}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ minWidth: '342px', display: 'flex', flexDirection: 'column' }}>
            <ImagesView files={data?.files} />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ my: 2 }}>
                <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
                  When:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: '#F7F9FA',
                    borderRadius: 2,
                    p: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 10 }}>
                    <CalendarTodayIcon sx={{ mr: 2, fontSize: '0.825rem' }} />
                    <Typography component='span' variant='caption'>
                      {moment(data?.fld_date_start, 'YYYY-MM-DD HH:mm').format('DD MMM')}
                    </Typography>
                  </Box>
                  <Typography
                    component='span'
                    variant='caption'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: (t) => t.palette.primary.main,
                    }}
                  >
                    <AccessTimeIcon sx={{ mr: 0.5, fontSize: '0.625rem' }} />
                    <Typography component='span' variant='overline'>
                      {moment(data?.fld_date_start, 'YYYY-MM-DD HH:mm').format('HH:MM a')} -{' '}
                      {moment(data?.fld_date_end, 'YYYY-MM-DD HH:mm').format('HH:MM a')}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
                  Where:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: '#F7F9FA',
                    borderRadius: 2,
                    p: 2,
                  }}
                >
                  <LocationOnIcon sx={{ mr: 0.5, fontSize: '0.75rem' }} />
                  <Typography component='span' variant='caption'>
                    {data?.fld_location}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ pl: 3 }}>
            <Typography component='h6' variant='h6' sx={{ mb: 2 }}>
              About
            </Typography>
            {parse(data?.fld_description || '')}
            <Attachments files={data?.attachedFiles} />
          </Box>
        </Box>
      </>
    </LoadPermissionsContainer>
  );
}
