import { CardActionArea, Grid, Tooltip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useEffect, useState } from "react";
import { IZone } from "@rs/providers/interfaces/IZone";

interface Props {
  project: IZone;
  onProjectClick: (project: IZone) => {}
}

export default function ProjectListItem({ project, onProjectClick }: Props) {
  const [projectImage, setProjectImage] = useState(
    "/zone_default_image.png"
  );

  useEffect(() => {
    var image = new Image();

    image.onload = function () {
      setProjectImage(project.image);
    };

    image.src = project.image;
  }, []);

  return (
    <Tooltip title={project.name}>
      <Grid item xs={12} lg={4} onClick={() => onProjectClick(project)}>
        <Card
          sx={{
            height: "256px",
            cursor: "pointer",
            "&:hover": {
              boxShadow:
                "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);",
            },
            backgroundSize: "cover",
            objectFit: "contain",
          }}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              alt="project image"
              height="170"
              image={projectImage}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div" noWrap>
                {project.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {project.deployment.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Tooltip>
  );
}
