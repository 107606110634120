/* eslint-disable camelcase */

import 'react-quill/dist/quill.snow.css';

import { Box, Button, Dialog, DialogContent, TextField, Theme, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import ReactQuill from 'react-quill';
import quillEditorStyles from '@rs/styles/quillEditor';
import { useMemo } from 'react';

interface IProps {
  open: boolean;
  onClose: () => void;
}

interface FormData {
  message: string;
}

export default function SendReminderDialog(props: IProps) {
  const { onClose, open } = props;

  const formDefaultData = useMemo(
    () => ({
      message: '',
    }),
    [],
  );

  const form = useForm<FormData>({
    defaultValues: formDefaultData,
  });

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    console.log('Not implemented yet');
    onClose();
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth='lg'
      fullWidth
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogContent
        sx={{
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (t: Theme) => t.palette.grey[100],
          }}
        >
          <Typography component='h2' variant='h4' fontWeight={700}>
            Send Reminder
          </Typography>
          <Box>
            <Button
              variant='outlined'
              onClick={() => onCancel()}
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              type='submit'
              form='form'
              sx={{
                px: 2.5,
                py: 1.5,
                ml: 2,
              }}
            >
              Send
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 5 }}>
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box>
              <Box sx={{ mt: 5 }}>
                <Box>
                  <Controller
                    name={'message'}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the message',
                      },
                      maxLength: {
                        value: 1024,
                        message: 'Max length should be less or equal 1024 characters',
                      },
                      validate: {
                        required: (value) => {
                          const textValue = value.replace(/<[^>]+>/g, '');
                          if (!textValue) return 'Please enter the message';
                        },
                        maxLength: (value) => {
                          const textValue = value.replace(/<[^>]+>/g, '');
                          if (textValue.length > 1024)
                            return 'Max length should be less or equal 1024 characters';
                        },
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <Box
                        sx={{
                          mt: 2,
                          ...quillEditorStyles,
                        }}
                      >
                        <ReactQuill
                          theme='snow'
                          onChange={onChange}
                          value={value}
                          modules={{
                            toolbar: [
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'link',
                                { list: 'ordered' },
                                { list: 'bullet' },
                                'blockquote',
                                'clean',
                              ],
                            ],
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          placeholder='Type message here. Max length 1024 characters.'
                        />
                        <TextField
                          error={!!formState.errors.message}
                          helperText={formState.errors.message?.message}
                          value={value}
                          type='hidden'
                          sx={{
                            '& fieldset': {
                              display: 'none',
                            },
                          }}
                        />
                      </Box>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
